import React from "react";

const BarcodeManagingComponent = () => {
  return (
    <div>
      <div className="qr-code-faq">
        <div className="qr-code-faq-main">
          <div className="qr-code-faq-heading">
            How can barcodes help in managing inventory?
          </div>
          <div className="qr-code-faq-content">

            
            <div className="qr-code-faq-question">
              <i class="fa-regular fa-lightbulb"></i>
              Saving time :
            </div>
            <div className="qr-code-faq-answer">
            Using a barcode sticker, you can make it quicker for your employees to feed information into your system.
            </div>


            
            <div className="qr-code-faq-question">
              <i class="fa-regular fa-lightbulb"></i>
              Helping monitor stock item :
            </div>
            <div className="qr-code-faq-answer">
            Store the batch number, expiry dates, and other product information easily using barcodes generated online.
            </div>


            
            <div className="qr-code-faq-question">
              <i class="fa-regular fa-lightbulb"></i>
              Providing better customer service :
            </div>
            <div className="qr-code-faq-answer">
            Quickly bill your customers by using barcodes generated by the Invoician app. It can help you provide better customer service by eliminating the requirement of typing data in each bill.
            </div>


            
            <div className="qr-code-faq-question">
              <i class="fa-regular fa-lightbulb"></i>
              Reducing errors :
            </div>
            <div className="qr-code-faq-answer">
            Barcodes essentially eliminate the risk of manual entry errors. It can help you reduce the chances of making any expensive mistake.
            </div>



          </div>
        </div>
      </div>
    </div>
  );
};

export default BarcodeManagingComponent;
