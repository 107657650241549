import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../context/AuthContext';
import PopUpAlertComponent from '../../../../components/extra/PopUpAlertComponent';

const UpdateItemStock = ({
  open,
  fetchBusinessData,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
  stockData,

}) => {

  console.log("stockData is :", stockData)
  
  
   // get from storage ============================================================================================

   const userId = localStorage.getItem("invoician-user-id");
   const businessId = localStorage.getItem("business-id");
 

   // useStates  ================================================================================================================
   const [loading, setLoading] = useState(false);
   
   
   const [data, setData] = useState({
     itemId : stockData.itemId,
        transactionType : stockData.transactionType,
        invoiceDate : new Date(stockData.invoiceDate).toISOString().split("T")[0],
        quantity : stockData.quantity,
      });    
    
      const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        
        setData({
          ...data,
          [name]: value,
        });
      };
    
      
      
      
      const { updateItemStock } = useContext(AuthContext);
      
      const handleCreate = async (e) => {
        setLoading(true);
        e.preventDefault();
        await updateItemStock(
          userId,
          businessId,
          stockData._id,
          data,
          setTimerPopupAlert,
          setTimerAlertMessage,
          setTimerAlertStatus,
          open,
        );
        fetchBusinessData();
        setLoading(false);
      };
      
      // ==============================================================================================================
      // delete ==============================================================================================================
      // ==============================================================================================================

      const [popUpAlert, setPopUpAlert] = useState(false);
      
      
        const { deleteSingleItemStock } = useContext(AuthContext);
      
        const handleDelete = async (e) => {
          setLoading(true);
          await deleteSingleItemStock(
            userId,
            businessId,
            stockData._id,
            open,
            setTimerPopupAlert,
            setTimerAlertMessage,
            setTimerAlertStatus
          );
          fetchBusinessData();
          setLoading(false);
        };
      
      
      
      
  return (
    <div>

{popUpAlert ? (
        <PopUpAlertComponent
          open={setPopUpAlert}
          ok={handleDelete}
          heading={`You want to delete stock data?`}
        />
      ) : (
        ""
      )}


<form onSubmit={handleCreate} className="create-party-popup">
        <div className="create-party-popup-main">
          <div className="create-party-popup-top">
            <div>Update Stock</div>
            <i onClick={() => open(false)} class="fa-solid fa-xmark"></i>
          </div>

          <div className="create-party-popup-form">


            <div className="create-party-popup-form-col">
              <label>
                Add or Reduce
              </label>
            <div className="create-popup-form-adjust-balance">
              <div className={data.transactionType === "Added Stock" ? "create-popup-form-adjust-balance-active":""} onClick={ () => setData({...data, transactionType : "Added Stock"}) }>Added Stock</div>
              <div className={data.transactionType === "Reduced Stock" ? "create-popup-form-adjust-balance-active":""} onClick={ () => setData({...data, transactionType : "Reduced Stock"}) }>Reduced Stock</div>
            </div>
              
            </div>

            <div className="create-party-popup-form-row">


            <div className="create-party-popup-form-col">
              <label>
              Enter Quantity<span>* </span>
              </label>
              <input
                type="number"
                required
                onChange={handleChange}
                name="quantity"
                value={data.quantity}
                min={1}
              />
            </div>

            <div className="create-party-popup-form-col">
              <label>
                Date<span>*</span>
              </label>
              <input
                type="date"
                required
                onChange={handleChange}
                name="invoiceDate"
                value={data.invoiceDate}
                placeholder=""
              />
            </div>
            </div>

            
          </div>

          <div className="create-party-popup-buttons">
            <div
              className="create-party-popup-button-delete"
              onClick={()=> setPopUpAlert(true) }
            >Delete</div>

            <button className="create-party-popup-button-save">Update</button>

          </div>
        </div>
      </form>
        
        
    </div>
  )
}

export default UpdateItemStock