import React from "react";
import qr from "../../../media/images/website/make-business-card.webp"

const BusinessCardUniqueComponent = () => {
  return (
    <div>
      <div className="qr-code-free">
        <div className="qr-code-free-main">

          <div className="qr-code-free-heading">Make a Business Card That’s Unique And On-brand</div>
          <div className="qr-code-free-text">We know that a brand image can make or break a first impression. That is why Invoician offers a free business card maker tool for business owners to create a unique identity for their brand. You can add your logo, use your brand colours, and choose complementary fonts to set up your brand’s identity.</div>
        
          
          <div className="business-card-unique-image">
            <img src={qr} alt="" />
          </div>

          <div className="qr-code-free-text">

          We’ve also got a library of free templates to help you start customising without working from scratch. You can choose the right business card template to create the best design for your brand.
<br />
<br />
Remember, your business card is how people remember you. With Invoician’s business card maker, you can demonstrate your services and make yourself visible among competitors.
            
          </div>
        
          
        </div>
      </div>
    </div>
  );
};

export default BusinessCardUniqueComponent;
