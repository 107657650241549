import React, { useEffect, useState } from "react";
import AppMobileNavbar from "../../pageComponents/AppMobileNavbar";
import { Link } from "react-router-dom";
import TimerPopupAlertComponent from "../../../../components/extra/TimerPopupAlertComponent";
import ViewDataCreditReturnComponent from "./ViewDataCreditReturnComponent";
import ViewDataDeliveryChallanComponent from "./ViewDataDeliveryChallanComponent";

const ViewDeliveryChallanPageComponent = ({ businessData, fetchBusinessData }) => {
  // useState ============================================================================================

  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerAlertStatus, setTimerAlertStatus] = useState("");

  const [filteredData, setFilteredData] = useState("");
  const [search, setsearch] = useState("");
  const [sortData, setSortData] = useState(businessData.deliveryChallanData);

  
  const [viewInoiceData, setViewInoiceData] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);

  // functions============================================================================================

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  // sort data ============================================================================================

  // sort ascending ------------------------------------------------------------------------------

  const [sortOrder, setSortOrder] = useState("");

  const handleAtoZ = () => {
    setSortOrder("z-a");
    const sorted = [...sortData].sort((a, b) => {
      return (
        a.invoicePartyData &&
        a.invoicePartyData.partyName.localeCompare(
          b.invoicePartyData && b.invoicePartyData.partyName
        )
      );
    });
    setSortData(sorted);
  };

  // sort descending ------------------------------------------------------------------------------

  const handleZtoA = () => {
    setSortOrder("a-z");
    const sorted = [...sortData].sort((a, b) => {
      return (
        b.invoicePartyData &&
        b.invoicePartyData.partyName.localeCompare(
          a.invoicePartyData && a.invoicePartyData.partyName
        )
      );
    });
    setSortData(sorted);
  };

  // sort number ascending ------------------------------------------------------------------------------

  const handlePriceAcs = () => {
    setSortOrder("p-9-1");
    const sorted = [...sortData].sort((a, b) => {
      return a.invoiceTotalAmount - b.invoiceTotalAmount;
    });
    setSortData(sorted);
  };

  // sort number descending ------------------------------------------------------------------------------

  const handlePriceDes = () => {
    setSortOrder("p-1-9");
    const sorted = [...sortData].sort((a, b) => {
      return b.invoiceTotalAmount - a.invoiceTotalAmount;
    });
    setSortData(sorted);
  };

  // sort date ascending ------------------------------------------------------------------------------

  const handleDateAcs = () => {
    const sorted = [...sortData].sort((a, b) => {
      return new Date(a.invoiceDate) - new Date(b.invoiceDate);
    });
    setSortData(sorted);
  };

  // sort date descending ------------------------------------------------------------------------------

  const handleDateDes = () => {
    const sorted = [...sortData].sort((a, b) => {
      return new Date(b.invoiceDate) - new Date(a.invoiceDate);
    });
    setSortData(sorted);
  };

  //  ----------------------------------------------------------------------------------------

  const handleFilter = (e) => {
    const value = e.target.value;

    if (value === "nameA") {
      handleAtoZ();
    } else if (value === "nameD") {
      handleZtoA();
    } else if (value === "amountA") {
      handlePriceAcs();
    } else if (value === "amountD") {
      handlePriceDes();
    } else if (value === "dateA") {
      handleDateAcs();
    } else if (value === "dateD") {
      handleDateDes();
    } else if (value === "default") {
      setSortData(businessData.deliveryChallanData);
    }
  };

  
  useEffect( () => {
    setSortData(businessData.deliveryChallanData)
  }, [businessData])
  
  // value ============================================================================================

  const totalAmount = formatNumber(
    businessData.deliveryChallanData
      .map((e) => e.invoiceTotalAmount)
      .reduce((a, b) => a + b, 0)
  );

  const paidAmount = formatNumber(
    businessData.deliveryChallanData
      .filter((e) => e.receivedAmount)
      .map((e) => e.receivedAmount)
      .reduce((a, b) => a + b, 0)
  );

  const unpaidAmount = formatNumber(
    businessData.deliveryChallanData
      .map((e) => e.invoiceTotalAmount)
      .reduce((a, b) => a + b, 0) -
      businessData.deliveryChallanData
        .map((e) => (e.receivedAmount ? e.receivedAmount : 0))
        .reduce((a, b) => a + b, 0)
  );

  return (
    <div>
      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}
      {viewInoiceData ? (
        <ViewDataDeliveryChallanComponent
          open={setViewInoiceData}
          invoiceId={invoiceId}
          businessData={businessData}
          fetchBusinessData={fetchBusinessData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
        />
      ) : (
        ""
      )}

      <div className="view-invoice-page">
        <div className="view-invoice-page-main">
          <div className="view-invoice-page-top">
            <div className="view-invoice-page-top-heading">
              <div className="view-invoice-page-top-bars">
                <AppMobileNavbar  businessData={businessData}/>

                {/* <button onClick={()=>{fetchBusinessData(); console.log("deliveryChallanData is :", businessData.deliveryChallanData)}}>fetch</button> */}
                
              </div>
              <div className="view-invoice-page-top-label">Delivery Challan</div>
            </div>

            <div className="view-invoice-page-top-options">
              <div className="view-invoice-page-search">
                <i class="fa-solid fa-magnifying-glass"></i>
                <input
                  type="text"
                  placeholder="search invoice...."
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                />
                {search ? (
                  <i
                    onClick={(e) => setsearch("")}
                    className="fa-solid fa-circle-xmark"
                  ></i>
                ) : (
                  ""
                )}
              </div>
              <Link to="/app/add-delivery-challan" className="view-invoice-page-top-button-main">
                Create Delivery Challan
              </Link>
            </div>
          </div>

          <div className="view-invoice-page-content">
          

            <div className="view-invoice-page-table-4">
              <div className="view-invoice-page-table-head">
                <div>Date</div>
                <div>Invoice No.</div>
                <div>Party Name</div>
                <div>Amount</div>
              </div>
              <div className="view-invoice-page-table-data">
                {sortData
                  .filter((e) =>
                    filteredData === "Paid"
                      ? e.receivedAmount > 0 || e.invoiceTotalAmount === 0
                      : filteredData === "Unpaid"
                      ? (e.receivedAmount < e.invoiceTotalAmount ||
                          e.receivedAmount === 0 ||
                          !e.receivedAmount) &&
                        e.invoiceTotalAmount > 0
                      : e
                  )
                  .filter((e) =>
                    search
                      ? e.invoicePartyData &&
                        e.invoicePartyData.partyName
                          .toLocaleLowerCase()
                          .includes(search.toLowerCase())
                      : e
                  ).length > 0 ? (
                  sortData
                    .filter((e) =>
                      filteredData === "Paid"
                        ? e.receivedAmount > 0 || e.invoiceTotalAmount === 0
                        : filteredData === "Unpaid"
                        ? (e.receivedAmount < e.invoiceTotalAmount ||
                            e.receivedAmount === 0 ||
                            !e.receivedAmount) &&
                          e.invoiceTotalAmount > 0
                        : e
                    )
                    .filter((e) =>
                      search
                        ? e.invoicePartyData &&
                          e.invoicePartyData.partyName
                            .toLocaleLowerCase()
                            .includes(search.toLowerCase())
                        : e
                    )
                    .reverse()
                    .map((e) => (
                      <div
                        className="view-invoice-page-table-body"
                        onClick={() => {
                          setViewInoiceData(true);
                          setInvoiceId(e._id);
                        }}
                      >
                        <div>
                          {e.invoiceDate
                            ? new Date(e.invoiceDate).toDateString()
                            : "-"}
                        </div>
                        <div> {e.invoiceNumber ? e.invoiceNumber : "-"}</div>
                        <div>
                          {e.invoicePartyData
                            ? e.invoicePartyData.partyName
                            : "-"}
                        </div>
                        <div>
                          ₹{" "}
                          {e.invoiceTotalAmount
                            ? new Intl.NumberFormat("en-IN").format(
                                formatNumber(e.invoiceTotalAmount)
                              )
                            : "-"}
                        </div>
                       
                      </div>
                    ))
                ) : (
                  <div className="view-invoice-page-table-empty">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22.7071 17.2929C23.0976 17.6834 23.0976 18.3166 22.7071 18.7071L18.7071 22.7071C18.3166 23.0976 17.6834 23.0976 17.2929 22.7071C16.9024 22.3166 16.9024 21.6834 17.2929 21.2929L21.2929 17.2929C21.6834 16.9024 22.3166 16.9024 22.7071 17.2929Z"
                        fill="#999"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.2929 17.2929C16.9024 17.6834 16.9024 18.3166 17.2929 18.7071L21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L18.7071 17.2929C18.3166 16.9024 17.6834 16.9024 17.2929 17.2929Z"
                        fill="#999"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.5 6C7.5 5.44772 7.94772 5 8.5 5H15.5C16.0523 5 16.5 5.44772 16.5 6C16.5 6.55229 16.0523 7 15.5 7H13.5994C13.8251 7.41807 13.9803 7.87124 14.0706 8.33333H15.5C16.0523 8.33333 16.5 8.78105 16.5 9.33333C16.5 9.88562 16.0523 10.3333 15.5 10.3333H14.0706C13.9371 11.0162 13.662 11.6796 13.2269 12.239C12.6698 12.9552 11.8702 13.4704 10.8688 13.6212L14.0404 16.7929C14.431 17.1834 14.431 17.8166 14.0404 18.2071C13.6499 18.5976 13.0168 18.5976 12.6262 18.2071L7.79289 13.3738C7.5069 13.0878 7.42134 12.6577 7.57612 12.284C7.7309 11.9103 8.09554 11.6667 8.5 11.6667H10.25C10.9008 11.6667 11.3435 11.4028 11.6483 11.011C11.7971 10.8197 11.9181 10.5894 12.0058 10.3333H8.5C7.94772 10.3333 7.5 9.88562 7.5 9.33333C7.5 8.78105 7.94772 8.33333 8.5 8.33333H12.0058C11.9181 8.07725 11.7971 7.84692 11.6483 7.65563C11.3435 7.26384 10.9008 7 10.25 7H8.5C7.94772 7 7.5 6.55229 7.5 6Z"
                        fill="#999"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.7587 1H15.2413C16.0463 0.999988 16.7106 0.999977 17.2518 1.04419C17.8139 1.09012 18.3306 1.18868 18.816 1.43597C19.5686 1.81947 20.1805 2.43139 20.564 3.18404C20.8113 3.66937 20.9099 4.18608 20.9558 4.74817C21 5.28936 21 5.95372 21 6.75868V14C21 14.5523 20.5523 15 20 15C19.4477 15 19 14.5523 19 14V6.8C19 5.94342 18.9992 5.36113 18.9624 4.91104C18.9266 4.47262 18.8617 4.24842 18.782 4.09202C18.5903 3.7157 18.2843 3.40973 17.908 3.21799C17.7516 3.1383 17.5274 3.07337 17.089 3.03755C16.6389 3.00078 16.0566 3 15.2 3H8.8C7.94342 3 7.36113 3.00078 6.91104 3.03755C6.47262 3.07337 6.24842 3.1383 6.09202 3.21799C5.7157 3.40973 5.40973 3.7157 5.21799 4.09202C5.1383 4.24842 5.07337 4.47262 5.03755 4.91104C5.00078 5.36113 5 5.94342 5 6.8V17.2C5 18.0566 5.00078 18.6389 5.03755 19.089C5.07337 19.5274 5.1383 19.7516 5.21799 19.908C5.40973 20.2843 5.7157 20.5903 6.09202 20.782C6.24843 20.8617 6.47262 20.9266 6.91101 20.9624C7.36107 20.9992 7.94333 21 8.79986 21H14C14.5523 21 15 21.4477 15 22C15 22.5523 14.5523 23 14 23H8.75854C7.95362 23 7.2893 23 6.74814 22.9558C6.18606 22.9099 5.66937 22.8113 5.18404 22.564C4.43139 22.1805 3.81947 21.5686 3.43597 20.816C3.18868 20.3306 3.09012 19.8139 3.04419 19.2518C2.99998 18.7106 2.99999 18.0463 3 17.2413V6.7587C2.99999 5.95373 2.99998 5.28937 3.04419 4.74817C3.09012 4.18608 3.18868 3.66937 3.43597 3.18404C3.81947 2.43139 4.43139 1.81947 5.18404 1.43597C5.66937 1.18868 6.18608 1.09012 6.74817 1.04419C7.28937 0.999977 7.95373 0.999988 8.7587 1Z"
                        fill="#999"
                      ></path>
                    </svg>

                    <div>No Such Invoice</div>
                  </div>
                )}
              </div>
            </div>

            <div className="view-invoice-page-list">
              <div className="view-invoice-page-list-options">
                <div className="view-invoice-page-search">
                  <i class="fa-solid fa-magnifying-glass"></i>
                  <input
                    type="text"
                    placeholder="search invoice...."
                    value={search}
                    onChange={(e) => setsearch(e.target.value)}
                  />
                  {search ? (
                    <i
                      onClick={(e) => setsearch("")}
                      className="fa-solid fa-circle-xmark"
                    ></i>
                  ) : (
                    ""
                  )}
                </div>

                <select onChange={handleFilter}>
                  <option value="" disabled selected>
                    sort by
                  </option>
                  <option value="default">Default</option>
                  <option value="nameA">Name &darr;</option>
                  <option value="nameD">Name &uarr;</option>
                  <option value="amountA">Amount &darr;</option>
                  <option value="amountD">Amount &uarr;</option>
                  <option value="dateA">Date &darr;</option>
                  <option value="dateD">Date &uarr;</option>
                </select>
              </div>

              <div className="view-invoice-page-list-data">
                {sortData
                  .filter((e) =>
                    filteredData === "Paid"
                      ? e.receivedAmount > 0 || e.invoiceTotalAmount === 0
                      : filteredData === "Unpaid"
                      ? (e.receivedAmount < e.invoiceTotalAmount ||
                          e.receivedAmount === 0 ||
                          !e.receivedAmount) &&
                        e.invoiceTotalAmount > 0
                      : e
                  )
                  .filter((e) =>
                    search
                      ? e.invoicePartyData &&
                        e.invoicePartyData.partyName
                          .toLocaleLowerCase()
                          .includes(search.toLowerCase())
                      : e
                  ).length > 0 ? (
                  sortData
                    .filter((e) =>
                      filteredData === "Paid"
                        ? e.receivedAmount > 0 || e.invoiceTotalAmount === 0
                        : filteredData === "Unpaid"
                        ? (e.receivedAmount < e.invoiceTotalAmount ||
                            e.receivedAmount === 0 ||
                            !e.receivedAmount) &&
                          e.invoiceTotalAmount > 0
                        : e
                    )
                    .filter((e) =>
                      search
                        ? e.invoicePartyData &&
                          e.invoicePartyData.partyName
                            .toLocaleLowerCase()
                            .includes(search.toLowerCase())
                        : e
                    )
                    .reverse()
                    .map((e) => (
                      <div
                        className="view-invoice-page-list-data-col"
                        onClick={() => {
                          setViewInoiceData(true);
                          setInvoiceId(e._id);
                        }}
                      >
                        <div className="view-invoice-page-list-data-row">
                          <div className="view-invoice-page-list-data-name">
                            {e.invoicePartyData
                              ? e.invoicePartyData.partyName
                              : "-"}
                          </div>
                          <div className="view-invoice-page-list-data-date">
                            {e.invoiceDate
                              ? new Date(e.invoiceDate).toDateString()
                              : "-"}
                          </div>
                        </div>

                        <div className="view-invoice-page-list-data-row">
                          <div className="view-invoice-page-list-data-number">
                            #{e.invoiceNumber ? e.invoiceNumber : "-"}
                          </div>
                          <div className="view-invoice-page-list-data-amount">
                            
                            ₹{" "}
                            {e.invoiceTotalAmount
                              ? new Intl.NumberFormat("en-IN").format(
                                  formatNumber(e.invoiceTotalAmount)
                                )
                              : "-"}
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="view-invoice-page-table-empty">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22.7071 17.2929C23.0976 17.6834 23.0976 18.3166 22.7071 18.7071L18.7071 22.7071C18.3166 23.0976 17.6834 23.0976 17.2929 22.7071C16.9024 22.3166 16.9024 21.6834 17.2929 21.2929L21.2929 17.2929C21.6834 16.9024 22.3166 16.9024 22.7071 17.2929Z"
                        fill="#999"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.2929 17.2929C16.9024 17.6834 16.9024 18.3166 17.2929 18.7071L21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L18.7071 17.2929C18.3166 16.9024 17.6834 16.9024 17.2929 17.2929Z"
                        fill="#999"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.5 6C7.5 5.44772 7.94772 5 8.5 5H15.5C16.0523 5 16.5 5.44772 16.5 6C16.5 6.55229 16.0523 7 15.5 7H13.5994C13.8251 7.41807 13.9803 7.87124 14.0706 8.33333H15.5C16.0523 8.33333 16.5 8.78105 16.5 9.33333C16.5 9.88562 16.0523 10.3333 15.5 10.3333H14.0706C13.9371 11.0162 13.662 11.6796 13.2269 12.239C12.6698 12.9552 11.8702 13.4704 10.8688 13.6212L14.0404 16.7929C14.431 17.1834 14.431 17.8166 14.0404 18.2071C13.6499 18.5976 13.0168 18.5976 12.6262 18.2071L7.79289 13.3738C7.5069 13.0878 7.42134 12.6577 7.57612 12.284C7.7309 11.9103 8.09554 11.6667 8.5 11.6667H10.25C10.9008 11.6667 11.3435 11.4028 11.6483 11.011C11.7971 10.8197 11.9181 10.5894 12.0058 10.3333H8.5C7.94772 10.3333 7.5 9.88562 7.5 9.33333C7.5 8.78105 7.94772 8.33333 8.5 8.33333H12.0058C11.9181 8.07725 11.7971 7.84692 11.6483 7.65563C11.3435 7.26384 10.9008 7 10.25 7H8.5C7.94772 7 7.5 6.55229 7.5 6Z"
                        fill="#999"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.7587 1H15.2413C16.0463 0.999988 16.7106 0.999977 17.2518 1.04419C17.8139 1.09012 18.3306 1.18868 18.816 1.43597C19.5686 1.81947 20.1805 2.43139 20.564 3.18404C20.8113 3.66937 20.9099 4.18608 20.9558 4.74817C21 5.28936 21 5.95372 21 6.75868V14C21 14.5523 20.5523 15 20 15C19.4477 15 19 14.5523 19 14V6.8C19 5.94342 18.9992 5.36113 18.9624 4.91104C18.9266 4.47262 18.8617 4.24842 18.782 4.09202C18.5903 3.7157 18.2843 3.40973 17.908 3.21799C17.7516 3.1383 17.5274 3.07337 17.089 3.03755C16.6389 3.00078 16.0566 3 15.2 3H8.8C7.94342 3 7.36113 3.00078 6.91104 3.03755C6.47262 3.07337 6.24842 3.1383 6.09202 3.21799C5.7157 3.40973 5.40973 3.7157 5.21799 4.09202C5.1383 4.24842 5.07337 4.47262 5.03755 4.91104C5.00078 5.36113 5 5.94342 5 6.8V17.2C5 18.0566 5.00078 18.6389 5.03755 19.089C5.07337 19.5274 5.1383 19.7516 5.21799 19.908C5.40973 20.2843 5.7157 20.5903 6.09202 20.782C6.24843 20.8617 6.47262 20.9266 6.91101 20.9624C7.36107 20.9992 7.94333 21 8.79986 21H14C14.5523 21 15 21.4477 15 22C15 22.5523 14.5523 23 14 23H8.75854C7.95362 23 7.2893 23 6.74814 22.9558C6.18606 22.9099 5.66937 22.8113 5.18404 22.564C4.43139 22.1805 3.81947 21.5686 3.43597 20.816C3.18868 20.3306 3.09012 19.8139 3.04419 19.2518C2.99998 18.7106 2.99999 18.0463 3 17.2413V6.7587C2.99999 5.95373 2.99998 5.28937 3.04419 4.74817C3.09012 4.18608 3.18868 3.66937 3.43597 3.18404C3.81947 2.43139 4.43139 1.81947 5.18404 1.43597C5.66937 1.18868 6.18608 1.09012 6.74817 1.04419C7.28937 0.999977 7.95373 0.999988 8.7587 1Z"
                        fill="#999"
                      ></path>
                    </svg>

                    <div>No Such Invoice</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDeliveryChallanPageComponent;
