import React from "react";
import img from '../../../media/images/website/qr-code-generator-what-is.webp'


const WhatIsQrCodeComponent = () => {
  return (
    <div>
      <div className="what-qr">
        <div className="what-qr-main">
          <div className="what-qr-image"><img src={img} alt="" /></div>
          <div className="what-qr-content">
          <div className="what-qr-heading">What is a QR Code?</div>
          <div className="what-qr-text">A "QR" code stands for "quick response," which means that it provides instant access to all the information hidden in the code. Black and white pixel patterns get used for creating a unique design.
          QR codes are two-dimensional versions of barcodes. They get widely used across industries forproviding quick access to all necessary information.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsQrCodeComponent;
