import React from "react";

const BusinessCardFaqComponent = () => {
  return (
    <div>
      <div className="qr-code-faq">
        <div className="qr-code-faq-main">
          <div className="qr-code-faq-heading">Frequently Asked Questions</div>
          <div className="qr-code-faq-content">


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Can I create a business card using Invoician?
            </div>
            <div className="qr-code-faq-answer">
            Yes. Using Invoician, you can generate a business card. You can start using a free template from the professional designs of Invoician or do it all from scratch.
            </div>


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Is the barcode different from SKU?
            </div>
            <div className="qr-code-faq-answer">
            Barcode is a specially generated code that can be read using a barcode scanner only. However, SKU is a unique alphanumeric code, and it is used to assist internal references for the products and services. Being alphanumeric, you need not scan SKU to read it.
            </div>


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Can I share the business cards I make on the Invoician app digitally?
            </div>
            <div className="qr-code-faq-answer">
            Yes. You can download the business card and share it with your customers. You can share it over to their WhatsApp or email from the software as it is feasible for your requirements.
            </div>


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Is the Invoician app free to use for making business cards?
            </div>
            <div className="qr-code-faq-answer">
            Yes. You can choose from a wide range of business card templates available on our website and create one for yourself. You can use it as you want for free. You can access premium features with premium plans.
            </div>


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Can I save my business card in Invoician to edit it later?
            </div>
            <div className="qr-code-faq-answer">
            Yes. You can save the business cards in the Invoician app to edit them later. It will help you save time to make it again when you change a phone number, title, or address.
            </div>


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Is my data safe with Invoician?
            </div>
            <div className="qr-code-faq-answer">
            Yes. Invoician keeps your data encrypted. Only you can access the data. You can create a backup of your data in Google Drive for local storage using the Invoician app.
            </div>


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Can I put my logo on a business card?
            </div>
            <div className="qr-code-faq-answer">
            Yes. Invoician business card templates are fully customisable, and you can add a logo, change the colour, and customise the business card as per your requirement.
            </div>


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Is Invoician business card maker available on iOs devices?
            </div>
            <div className="qr-code-faq-answer">
            No. The Invoician app is currently unavailable for iOs devices. You can use the business card maker tool on your Windows computer.
            </div>


            
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCardFaqComponent;
