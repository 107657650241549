import React, { useState } from "react";
import { Link } from "react-router-dom";
import TimerPopupAlertComponent from "../../../../components/extra/TimerPopupAlertComponent";
import ViewDataExpense from "./ViewDataExpense";
import AppMobileNavbar from "../../pageComponents/AppMobileNavbar";
// import ViewExpenseComponent from "../../pages/form/components/ViewExpenseComponent";

const AppExpensePageComponent = ( { businessData, fetchBusinessData } ) => {

   // get from storage ============================================================================================


   // useStates  ================================================================================================================ 
  
  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertStatus, setTimerAlertStatus] = useState("");


  const [viewExpense, setViewExpense] = useState(false)
  const [expenseData, setExpenseData] = useState(null)
  const [expenseId, setExpenseId] = useState(null)


 
   const staffTransactionData = businessData.staffTransactionData.map((e) => {
    return { ...e, 
      expenseDate: e.transactionDate,
      expenseCategory: `Staff ${e.transactionType}`,
      expenseTotalAmount: e.transactionAmount,
      staffName: e.staffData.staffName,
     };
   });
  
  const combinedData = [
    ...businessData.expenseData,
    ...staffTransactionData,

  ]

  
  combinedData.sort(
    (a, b) => new Date(b.expenseDate) - new Date(a.expenseDate)
  );
  
  
  console.log("combinedData is : ", combinedData)


// ==================================================================
  
  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  

  const handleViewExpense = (e) => {
    if(!e.transactionType){
      setViewExpense(true); 
      setExpenseData(e); 
      setExpenseId(e._id) 
    }
  }
  
  
  
  
  
  return (
    <div>

{
  viewExpense?
  <ViewDataExpense open={setViewExpense}
  fetchBusinessData={fetchBusinessData}
  businessData={businessData}
  expenseData={expenseData}
  expenseId={expenseId}
  setTimerAlertMessage = {setTimerAlertMessage}
  setTimerPopupAlert = {setTimerPopupAlert}
  setTimerAlertStatus = {setTimerAlertStatus}
  

  />
  :""
}




      
      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}

          // setTimerPopupAlert={setTimerPopupAlert}
          // setTimerAlertMessage={setTimerAlertMessage}
          // setTimerAlertStatus={setTimerAlertStatus}
          
        />
      ) : (
        ""
      )}

      
      
      
      <div className="app-expense-page">
        <div className="app-expense-page-main">
          <div className="app-expense-page-top">
            <div className="app-expense-page-top-heading">
              <AppMobileNavbar businessData={businessData}/><div>Expenses</div>
            </div>
            <div className="app-expense-page-top-button">
              <Link to="/app/add-expense" className="app-expense-page-top-button-create">
                Create Expense
              </Link>
            </div>
          </div>

          <div className="app-expense-page-content">
            <div className="app-expense-page-content-table">
              <div className="app-expense-page-content-table-head">
                <div>Date</div>
                <div>Expense Number</div>
                <div>Name</div>
                <div>Category</div>
                <div>Amount</div>
              </div>
              <div className="app-expense-page-content-table-data">
                {
                  // businessData && businessData.expenseData.length>0?
                  combinedData && combinedData.length>0?
                  
                  // businessData && businessData.expenseData.reverse().filter(e=>e).map(e => (
                    combinedData && combinedData.map(e => (
                    // <div className={e.transactionType?"app-expense-page-content-table-data-body" : "app-expense-page-content-table-data-body app-expense-page-content-table-data-body-hover" } onClick={ () => { setViewExpense(true); setExpenseData(e); setExpenseId(e._id) }}>
                    <div className={e.transactionType?"app-expense-page-content-table-data-body" : "app-expense-page-content-table-data-body app-expense-page-content-table-data-body-hover" } onClick={() => handleViewExpense(e)}>
                      <div>{ new Date(e.expenseDate).toDateString() }</div>
                      <div>{e.expenseNumber}</div>
                      <div>{ e.staffName ? e.staffName : e.withGst === true ? e.expensePartyData ? e.expensePartyData.partyName     :"--"   :"-"}</div>
                      {/* <div>{e.staffName}</div> */}
                      <div>{e.expenseCategory}</div>
                      <div>₹ {formatNumber(e.expenseTotalAmount)}</div>
                      </div>
                  ))
                  :

                  <div className="app-expense-page-content-table-data-empty">No Such Data</div>

                }
                    


                
              </div>
            </div>

            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* Mobile View------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}

            <div className="app-expense-page-content-small">

                    {
                        combinedData && combinedData.length>0?
                    
                        combinedData && combinedData.map( e=>
                            
            <div className="app-dashboard-small-list-col"  onClick={() => handleViewExpense(e)}>
                  
                  <div className="app-dashboard-small-list-row">
                    <div className="app-dashboard-small-list-name">{e.expenseCategory} <span>#{e.expenseNumber}</span></div>
                    <div className="app-dashboard-small-list-date">{new Date(e.expenseDate).toDateString()}</div>
                  </div>

                  <div className="app-dashboard-small-list-row">
                    <div className="app-dashboard-small-list-invoice">{ e.staffName ? e.staffName : e.withGst === true ? e.expensePartyData ? e.expensePartyData.partyName     :"--"   :"-"}</div>
                    <div className="app-dashboard-small-list-amount">₹ {formatNumber(e.expenseTotalAmount)}</div>
                  </div>

                </div>
                        )
                    
                :
                <div className="app-expense-page-content-table-data-empty">No Such Data</div>
                }
                
                

            </div>



          </div>
        </div>
      </div>
    </div>
  );
};

export default AppExpensePageComponent;
