import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AppConfiguration from './main/AppConfiguration';
import { AuthContext } from '../../context/AuthContext';
import PopUpAlertComponent from '../../components/extra/PopUpAlertComponent';
import LoadingComponent from '../../components/extra/LoadingComponent';

const SettingNavbar = () => {

  
  // get from storage ================================================================================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useStates ================================================================================================================================================

  const [popUpAlert, setPopUpAlert] = useState(false);
  const [appNavUserData, setAppNavUserData] = useState();
  const [appNavBusinessData, setAppNavBusinessData] = useState();
  
  
    
  const location = useLocation();
  const path = location.pathname;
  
  
  const { logout, fetchUser, fetchBusiness } = useContext(AuthContext);
  const navigate = useNavigate();
  
  const userLogout = (e) => {
    logout();
    navigate("/login");
  };
  

  const fetchUserData = (e) => fetchUser(userId, setAppNavUserData);
  const fetchBusinessData = (e) =>
    fetchBusiness(userId, businessId, setAppNavBusinessData);

  useEffect(() => {
    fetchUserData();
    fetchBusinessData();
  }, []);
  

  const changeBusiness = (e) => {
    localStorage.setItem("business-id", e);
    window.location.reload();
  }
  
  
  if(!appNavBusinessData) return <div><LoadingComponent/></div>
  
  // console.log("AppNavUserData is :", appNavUserData.businessData[0])
  
  
  return (
    <div> 
        <AppConfiguration/>
        {popUpAlert ? <PopUpAlertComponent open={setPopUpAlert} ok={userLogout} heading="You want to logout?" /> :""}

      <div className="app-navbar">
        <div className="app-navbar-main">
          <div className="app-navbar-main-content">
            {/* =================================================================================================================================== */}

            
            
            <div className="app-setting-navbar-company">

              
              <div className="app-setting-navbar-company-details">

              <div className="app-setting-navbar-company-profile">
                      <div className="app-setting-navbar-company-name">

                        {appNavBusinessData &&
                        appNavBusinessData.businessName.length > 22
                          ? `${appNavBusinessData.businessName.slice(0, 22)}...`
                          : appNavBusinessData.businessName}

                      </div>

                      <div className="app-setting-navbar-company-number">
                        {appNavBusinessData && appNavBusinessData.companyPhoneNumber}
                      </div>
                    </div>
              
                    <i class="fa-solid fa-angle-down"></i>
              
              
              </div>
              <div className="app-setting-navbar-company-list">
                

                {
                  appNavUserData&&appNavUserData.businessData.map( e => (
                <div onClick={() => changeBusiness(e._id)}>
                  <span>

                    {
                      e.businessName.length > 22
                      ? `${e.businessName.slice(0, 22)}...`
                      : e.businessName
                    }
                    
                  </span>
                  <input type="radio" checked={e._id === businessId ? true : false}  />
                </div>
                    
                  ) )
                }

                
              </div>

              
            </div>


            {/* back to home =================================================================================================================================== */}
            <Link to="/app" className="app-setting-navbar-back"><i className="fa-solid fa-house"></i> Back to Dashboard</Link>
            


            {/* menu =================================================================================================================================== */}

            <div className="app-navbar-menu">
              {/* <div className="app-navbar-menu-heading">General</div> */}
              <div className="app-navbar-menu-links">
                
                {/* <Link to="/app/setting" className={path === "/app/setting"?"app-navbar-menu-active":""}>
                  <div>
                  <i className="fa-solid fa-gears"></i>General
                  </div>
                </Link> */}

                <Link className={path === "/app/setting/account"?"app-navbar-menu-active":""} to="account">
                  <div>
                    <i className="fa-solid fa-user"></i>Account
                  </div>
                </Link>
                
                <Link to="business" className={path === "/app/setting/business"?"app-navbar-menu-active":""}>
                  <div>
                    <i className="fa-solid fa-address-card"></i>Manage Business
                  </div>
                </Link>
                
                
                <Link to="invoice" className={path === "/app/setting/invoice"?"app-navbar-menu-active":""}>
                  <div>
                  <i className="fa-solid fa-file-lines"></i> Invoice Setting
                  </div>
                </Link>
                
              </div>



            </div>
          </div>
          {/* =================================================================================================================================== */}

          <div onClick={e=>setPopUpAlert(true)}  className="app-navbar-logout">
            <span >
              Logout <i className="fa-solid fa-right-from-bracket"></i>
            </span>
          </div>

          {/* =================================================================================================================================== */}
        </div>
      </div>
        
        
    </div>
  )
}

export default SettingNavbar