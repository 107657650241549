import React from "react";

const BarcodeFreeGenerator = () => {
  return (
    <div>
      <div className="qr-code-free">
        <div className="qr-code-free-main">
          <div className="qr-code-free-heading">
          Free Barcode generator online.
          </div>
          <div className="qr-code-free-text">
          Using the free barcode generator by Invoician, you can create barcodes for every item in your inventory with a few simple steps. You can also use a barcode generator to create barcodes that lets users scan for shipping details or for payment links that can act as a convenient way to get payments digitally.


          </div>

        </div>
      </div>
    </div>
  );
};

export default BarcodeFreeGenerator;
