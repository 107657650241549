import React from "react";
import img from "../../../media/images/website/what-is-barcode.webp";

const WhatIsBarcodeComponent = () => {
  return (
    <div>
      <div className="what-qr">
        <div className="what-qr-main">
          <div className="what-qr-content">
            <div className="what-qr-heading">What is a Barcode?</div>
            <div className="what-qr-text">
            A barcode encrypts information about an item. It gets generated by using parallel bars of black and white. The thickness of parallel bars and space between them is read by a barcode scanner. It helps decrypt the data stored within a barcode. To decrypt the data, a barcode scanner is required. Using it, you can read the information stored in a barcode instantly.
            </div>
          </div>
          <div className="what-qr-image">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsBarcodeComponent;
