import React, { useState } from "react";
import logo from "../../../media/images/logo/logo-red.png";
import { toWords } from "number-to-words";
import html2pdf  from "html2pdf.js";
import DownloadingComponent from "../../../components/extra/DownloadingComponent";


const InvoiceGeneratorGenerateInvoice = () => {

  const [contentType, setContentType] = useState("form")
    const [downloading, setDownloading] = useState(false);
  

  const [itemList, setItemList] = useState([
    {
      id: 0,
      item: "Product - 1",
      quantity: 0,
      rate: 0,
    },
  ]);

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const [invoiceColor, setInvoiceColor] = useState("#ed1a3b")
  const [data, setData] = useState({
    CompanyName: "",
    CompanyAddress: "",
    CompanyGSTIN: "",
    CustomerName: "",
    CustomerAddress: "",
    InvoiceNumber: "",
    Date: new Date().toISOString().split("T")[0],
    DueDate: "",
    Tax: 0,
    Notes: "",
    Terms: "",
  });

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setContentType("preview");
  };

  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const handleAddItem = (e) => {
    setItemList([
      ...itemList,
      {
        id: itemList[itemList.length-1].id + 1,
        quantity: 0,
        rate: 0,
      },
    ]);
  };

  const handleRemoveItem = (e) => {
    if (itemList.length > 1) {
      setItemList(itemList.filter((a) => a.id !== e));
    } else {
      alert("Last row can't be deleted !");
    }
  };

  const handleItem = (id, value) => {
    const newList = itemList.map((item) =>
      item.id === id ? { ...item, item: value } : item
    );
    setItemList(newList);
  };

  const handleQuantity = (id, value) => {
    const newList = itemList.map((item) =>
      item.id === id ? { ...item, quantity: value } : item
    );
    setItemList(newList);
  };

  const handleUnit = (id, value) => {
    const newList = itemList.map((item) =>
      item.id === id ? { ...item, unit: value } : item
    );
    setItemList(newList);
  };

  const handleRate = (id, value) => {
    const newList = itemList.map((item) =>
      item.id === id ? { ...item, rate: value } : item
    );
    setItemList(newList);
  };

  const handleTotal = (id, value) => {
    const newList = itemList.map((item) =>
      item.id === id
        ? { ...item, rate: formatNumber(value / item.quantity) }
        : item
    );
    setItemList(newList);
  };

  const totalQuantity = itemList.reduce((acc, item) => {
    return acc + Number(item.quantity);
  }, 0);

  const totalAmount = itemList.reduce((acc, item) => {
    return acc + item.quantity * item.rate;
  }, 0);

  const totalTax = (data.Tax * totalAmount) / 100;

  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  
  const generatePDF = async () => {
    const element = document.getElementById("content-to-pdf");
    setDownloading(true);
    const options = {
      margin: 0,
      filename: `Invoice.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },

      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    };

    await html2pdf().from(element).set(options).save();
    setDownloading(false);
  };
  
  
  
  
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  
  return (
    <div>

      
{downloading ? <DownloadingComponent /> : ""}
      
      
      <div className="invoice-generate-invoice">
        <div className="invoice-generate-invoice-main">
          <div className="invoice-generate-invoice-heading">
            Generate Invoice Online Using <span>Invoice Generator</span>
          </div>
          
          <div className="invoice-generate-invoice-menu">
            <div className={`invoice-generate-invoice-menu-col ${contentType==="form"?"invoice-generate-invoice-menu-col-active" :""}`}><i class="fa-solid fa-file-pen"></i></div>
            <div className="invoice-generate-invoice-menu-line"></div>
            <div className={`invoice-generate-invoice-menu-col ${contentType==="preview"?"invoice-generate-invoice-menu-col-active" :""}`}><i class="fa-solid fa-magnifying-glass"></i></div>          
          </div>

          <div className="invoice-generate-invoice-menu-label">
            <div className="invoice-generate-invoice-menu-label-col">Enter Details</div>
            <div className="invoice-generate-invoice-menu-label-line"></div>
            <div className="invoice-generate-invoice-menu-label-col">Preview</div>          
          </div>

{
  contentType === "form"?

          <form
            onSubmit={handleSubmit}
            className="invoice-generate-invoice-content"
          >
            <div className="invoice-generate-invoice-form">
              <div className="invoice-generate-invoice-form-col-1">
                <div className="invoice-generate-invoice-form-heading-1">
                  INVOICE
                </div>

                <div className="invoice-generate-invoice-form-row">
                  <div>
                    <input
                      onChange={handleInput}
                      required
                      type="text"
                      placeholder="Your Company Name"
                      name="CompanyName"
                      value={data.CompanyName}
                    />
                    <textarea
                      onChange={handleInput}
                      name="CompanyAddress"
                      placeholder="Company Address"
                      value={data.CompanyAddress}
                    ></textarea>
                    <input
                      onChange={handleInput}
                      type="text"
                      placeholder="Your Company GSTIN"
                      name="CompanyGSTIN"
                      value={data.CompanyGSTIN}
                    />
                  </div>

                  <div>
                    {/* <div className="invoice-generate-invoice-form-logo">
                      <input type="file" placeholder="Your Company Name" />
                    </div> */}

                    <input
                      onChange={handleInput}
                      required
                      type="number"
                      placeholder="Inoice Number"
                      name="InvoiceNumber"
                      id=""
                      value={data.InvoiceNumber}
                    />
                  </div>
                </div>

                <div className="invoice-generate-invoice-form-heading-2">
                  Bill To:
                </div>

                <div className="invoice-generate-invoice-form-row">
                  <div>
                    <input
                      onChange={handleInput}
                      required
                      type="text"
                      placeholder="Customer's Name"
                      name="CustomerName"
                      value={data.CustomerName}
                    />
                    <textarea
                      onChange={handleInput}
                      name="CustomerAddress"
                      placeholder="Customer Address"
                      value={data.CustomerAddress}
                    ></textarea>
                  </div>

                  <div>
                    <div className="invoice-generate-invoice-form-date">
                      Date:
                      <input
                        onChange={handleInput}
                        required
                        type="date"
                        placeholder="Your Company Name"
                        name="Date"
                      value={data.Date}
                      />
                    </div>

                    <div className="invoice-generate-invoice-form-date">
                      Due Date:
                      <input
                        onChange={handleInput}
                        type="date"
                        placeholder="Inoice Number"
                        name="DueDate"
                      value={data.DueDate}
                      />
                    </div>
                  </div>
                </div>

                <div className="invoice-generate-invoice-form-tabel">
                  <table>
                    <tr>
                      <td>S.N.</td>
                      <td>Item</td>
                      <td>Quantity</td>
                      <td>Unit</td>
                      <td>Rate</td>
                      <td>Amount</td>
                      <td></td>
                    </tr>

                    {itemList.map((e, i) => (
                      <tr>
                        <td className="invoice-generate-invoice-form-tabel-no">
                          {i + 1}
                        </td>
                        <td className="invoice-generate-invoice-form-tabel-item">
                          <input
                            required
                            type="text"
                            value={e.item}
                            onChange={(a) => handleItem(e.id, a.target.value)}
                          />
                        </td>
                        <td className="invoice-generate-invoice-form-tabel-quantity">
                          <input
                            required
                            type="number"
                            min={0}
                            value={e.quantity}
                            onChange={(a) =>
                              handleQuantity(e.id, a.target.value)
                            }
                          />
                        </td>
                        <td className="invoice-generate-invoice-form-tabel-unit">
                          <input
                            type="text"
                            onChange={(a) => handleUnit(e.id, a.target.value)}
                            list="unit"
                      value={e.unit}
                          />

                          <datalist id="unit">
                            <option value="GM">GM</option>
                            <option value="KG">KG</option>
                            <option value="LTR">LTR</option>
                            <option value="PCS">PCS</option>
                            <option value="PAC">PAC</option>
                          </datalist>
                        </td>
                        <td className="invoice-generate-invoice-form-tabel-rate">
                          <input
                            required
                            type="number"
                            min={0}
                            value={e.rate}
                            onChange={(a) => handleRate(e.id, a.target.value)}
                          />
                        </td>
                        <td className="invoice-generate-invoice-form-tabel-amount">
                          <input
                            required
                            type="number"
                            min={0}
                            value={formatNumber(e.rate * e.quantity) * 1}
                            onChange={(a) => handleTotal(e.id, a.target.value)}
                          />
                        </td>
                        <td className="invoice-generate-invoice-form-tabel-close">
                          <i
                            onClick={() => handleRemoveItem(e.id)}
                            class="fa-regular fa-circle-xmark"
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </table>

                  <div
                    onClick={handleAddItem}
                    className="invoice-generate-invoice-form-tabel-button"
                  >
                    + Add Item
                  </div>
                </div>

                <div className="invoice-generate-invoice-form-table-end">
                  <div className="invoice-generate-invoice-form-table-total">
                    <div>GST % :</div>
                    <input
                      onChange={handleInput}
                      name="Tax"
                      type="number"
                      max={100}
                      min={0}
                      placeholder="%"
                      value={data.Tax}
                    />
                  </div>
                </div>

                <div className="invoice-generate-invoice-form-table-end">
                  <div className="invoice-generate-invoice-form-table-total">
                    <div>Total : ₹ </div>
                    <span>{ formatNumber(totalAmount + totalTax)}</span>
                  </div>
                </div>

                <div className="invoice-generate-invoice-form-end">
                  <label>Notes:</label>
                  <textarea
                    onChange={handleInput}
                    name="Notes"
                      value={data.Notes}
                  ></textarea>

                  <label>Terms & Conditions:</label>
                  <textarea
                    onChange={handleInput}
                    name="Terms"
                      value={data.Terms}
                    placeholder="Thanks for doing business with us !"
                  ></textarea>
                </div>

                <div className="invoice-generate-invoice-form-footer">
                  Powered by <img src={logo} />
                </div>
              </div>

              <div className="invoice-generate-invoice-form-col-2">
              <div className="invoice-generate-invoice-form-col-2-heading-1">Choose Colours</div>

              <div className="invoice-generate-invoice-form-col-2-color">
                <div className={invoiceColor === "#ed1a3b"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#ed1a3b") }></div>
                <div className={invoiceColor === "#9eb7a2"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#9eb7a2") }></div>
                <div className={invoiceColor === "#c7beb4"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#c7beb4") }></div>
                <div className={invoiceColor === "#337357"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#337357") }></div>
                <div className={invoiceColor === "#003c97"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#003c97") }></div>
                <div className={invoiceColor === "#96b5d8"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#96b5d8") }></div>
                <div className={invoiceColor === "#fc6736"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#fc6736") }></div>
                <div className={invoiceColor === "#ffd23f"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#ffd23f") }></div>
              </div>

              <div className="invoice-generate-invoice-form-col-2-button">
                <a href="https://web.whatsapp.com/send?text=Check out this invoice generator tool from Inoician. I love it! ❤️ %0Ahttps://invoician.com/invoice-generator"
                target="_blank"
                >
                <i class="fa-solid fa-share-nodes"></i>

                </a>
                </div>
              <div className="invoice-generate-invoice-form-col-2-heading-2">Share</div>




              
                
              </div>
            </div>
            <div className="invoice-generate-invoice-button">
              <button>Next</button>
            </div>
          </form>
 :
 contentType === "preview"?
 
 <div className="invoice-generate-invoice-content">
 <div className="invoice-generate-invoice-form">
   <div className="invoice-generate-invoice-form-preview" id="content-to-pdf">
     <div className="invoice-generate-invoice-form-preview-main">
       <div className="invoice-generate-invoice-form-preview-company">
         {data.CompanyName ? (
           <div className="invoice-generate-invoice-form-preview-company-name">
             {data.CompanyName}
           </div>
         ) : (
           ""
         )}

         {data.CompanyAddress ? (
           <div className="invoice-generate-invoice-form-preview-company-address">
             {data.CompanyAddress}
           </div>
         ) : (
           ""
         )}

         {data.CompanyGSTIN ? (
           <div className="invoice-generate-invoice-form-preview-company-gstin">
             {data.CompanyGSTIN}
           </div>
         ) : (
           ""
         )}
       </div>

       <div style={{color:invoiceColor}} className="invoice-generate-invoice-form-preview-heading">
         INVOICE
       </div>

       <div className="invoice-generate-invoice-form-preview-details">
         <div className="invoice-generate-invoice-form-preview-details-address">
           <div className="invoice-generate-invoice-form-preview-details-address-heading">
             Bill To:
           </div>

           {data.CustomerName ? (
             <div className="invoice-generate-invoice-form-preview-details-address-name">
               {data.CustomerName}
             </div>
           ) : (
             ""
           )}

           {data.CustomerAddress ? (
             <div className="invoice-generate-invoice-form-preview-details-address-address">
               {data.CustomerAddress}
             </div>
           ) : (
             ""
           )}
         </div>
         <div className="invoice-generate-invoice-form-preview-details-options">
           {data.InvoiceNumber ? (
             <div className="invoice-generate-invoice-form-preview-details-invoice-number">
               Invoice No: {data.InvoiceNumber}
             </div>
           ) : (
             ""
           )}

           {data.Date ? (
             <div className="invoice-generate-invoice-form-preview-details-invoice-date">
               Date: {new Date(data.Date).toLocaleDateString('en-GB')}
             </div>
           ) : (
             ""
           )}

           {data.DueDate ? (
             <div className="invoice-generate-invoice-form-preview-details-invoice-date">
               Due Date: {new Date(data.DueDate).toLocaleDateString('en-GB')}
             </div>
           ) : (
             ""
           )}
         </div>
       </div>

       <div className="invoice-generate-invoice-form-preview-table">
         <table>
           <tr style={{backgroundColor: invoiceColor}} >
             <td className="invoice-generate-invoice-form-preview-table-sno">
               #
             </td>
             <td className="invoice-generate-invoice-form-preview-table-item">
               Item name
             </td>
             <td className="invoice-generate-invoice-form-preview-table-quantity">
               Quantity
             </td>
             <td className="invoice-generate-invoice-form-preview-table-unit">
               Unit
             </td>
             <td className="invoice-generate-invoice-form-preview-table-price">
               Price/Unit
             </td>
             <td className="invoice-generate-invoice-form-preview-table-amount">
               Amount
             </td>
           </tr>
           {itemList.map((e, i) => (
             <tr>
               <td>{i + 1}</td>
               <td>{e.item}</td>
               <td>{e.quantity}</td>
               <td>{e.unit}</td>
               <td>₹ {formatNumber(Number(e.rate))}</td>
               <td>₹ {formatNumber(Number(e.rate) * Number(e.quantity))}</td>
             </tr>
           ))}

           <tr>
             <td></td>
             <td>Total</td>
             <td>{totalQuantity}</td>
             <td></td>
             <td></td>
             <td>₹ {totalAmount}</td>
           </tr>
         </table>
       </div>

       <div className="invoice-generate-invoice-form-preview-end">
         <div className="invoice-generate-invoice-form-preview-end-details">
           
           <div className="invoice-generate-invoice-form-preview-end-details-heading">INVOICE AMOUNT IN WORDS</div>
           <div className="invoice-generate-invoice-form-preview-end-details-text">{toWords(totalAmount + totalTax)} only</div>

{
data.Notes?
<>
           <div className="invoice-generate-invoice-form-preview-end-details-heading">NOTES</div>
           <div className="invoice-generate-invoice-form-preview-end-details-text"><pre>{data.Notes}</pre></div>
</>
:""
}

{
data.Terms?
<>
           <div className="invoice-generate-invoice-form-preview-end-details-heading">TERMS AND CONDITIONS</div>
           <div className="invoice-generate-invoice-form-preview-end-details-text"><pre>{data.Terms}</pre></div>
           </>
:""
}
           


       </div>

       <div className="invoice-generate-invoice-form-preview-end-total">
       <div className="invoice-generate-invoice-form-preview-end-total-value">
       <div className="invoice-generate-invoice-form-preview-end-total-gst">
         <div>GST ({data.Tax}%)</div>
         <div>₹ {formatNumber(totalTax)}</div>
       </div>
       <div style={{backgroundColor: invoiceColor}} className="invoice-generate-invoice-form-preview-end-total-total">
         <div>Total</div>
         <div>₹ {formatNumber(totalAmount + totalTax)}</div>
       </div>

       </div>
       <div className="invoice-generate-invoice-form-preview-end-total-logo">
         Powered by 
         <img src={logo} alt="" />
       </div>

       </div>




       </div>

       
       
       
     </div>
   </div>

   <div className="invoice-generate-invoice-form-col-2">
   <div className="invoice-generate-invoice-form-col-2-heading-1">Choose Colours</div>

<div className="invoice-generate-invoice-form-col-2-color">
  <div className={invoiceColor === "#ed1a3b"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#ed1a3b") }></div>
  <div className={invoiceColor === "#9eb7a2"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#9eb7a2") }></div>
  <div className={invoiceColor === "#c7beb4"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#c7beb4") }></div>
  <div className={invoiceColor === "#337357"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#337357") }></div>
  <div className={invoiceColor === "#003c97"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#003c97") }></div>
  <div className={invoiceColor === "#96b5d8"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#96b5d8") }></div>
  <div className={invoiceColor === "#fc6736"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#fc6736") }></div>
  <div className={invoiceColor === "#ffd23f"? "invoice-generate-invoice-form-col-2-color-active" :""} onClick={ () => setInvoiceColor("#ffd23f") }></div>
</div>
   <div className="invoice-generate-invoice-form-col-2-button-2"  onClick={generatePDF}>Download</div>

   </div>
 </div>
 <div className="invoice-generate-invoice-button">
              <div onClick={ () => setContentType("form") }>Prev</div>
            </div>
</div> 
 
 :""}

          
        </div>
      </div>
    </div>
  );
};

export default InvoiceGeneratorGenerateInvoice;
