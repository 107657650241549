import React from "react";
import img from '../../../media/images/website/benefits.webp'
import { Link } from "react-router-dom";

const QrCodeBenefitComponent = () => {
  return (
    <div>
      <div className="qr-benefit">
        <div className="qr-benefit-main">

          <div className="qr-benefit-heading">
            What are the Benefits of Using a QR code?
          </div>
          
          <div className="qr-benefit-content">

            <div className="qr-benefit-content-image">
              <img src={img} alt="" />
            </div>
            
            <div className="qr-benefit-content-section">
              <div className="qr-benefit-text">
                QR codes are widely popular, and the reason behind this success
                is their versatility. You can use them together with payments,
                ask for feedback, improve the product or service engagementwith
                your customers. 
                <br />
                You can use images and videos for promoting your
                business using discount coupons and event registrations. QR
                codes are adjustable with all types of requirements, and you can
                access the information of your QR code using your smartphone.
                <br />
                Provide convenience to your customers by enabling QR payments
                now! Download Invoician
              </div>
              <Link to="/app" className="qr-benefit-button">Start Invoicing Now!</Link>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCodeBenefitComponent;
