import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext';
import LoadingComponent from '../../../components/extra/LoadingComponent';

const AppPackageExpireComponent = () => {

    
  
  // get from storage ============================================================================================
  
  const userId = localStorage.getItem("invoician-user-id");
  
  
  const [data, setData] = useState("");


  const { fetchUser } = useContext(AuthContext);

  
  
  // useEffect ===============================================================================================================================

const fetchUserData = e =>  fetchUser( userId , setData)
  



useEffect(() => {
  fetchUserData()
}, []);




const packageEndDate = new Date(data.packageStartDate)
packageEndDate.setFullYear(packageEndDate.getFullYear() + 1);

const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(data&&packageEndDate));

useEffect(() => {
  const timer = setInterval(() => {
    setTimeRemaining(getTimeRemaining(data&&packageEndDate));
//   }, 100);
  });
  
  return () => clearInterval(timer); // Cleanup on component unmount
}, [data&&packageEndDate]);


function getTimeRemaining(targetDate) {
  const now = new Date();
  const end = new Date(targetDate);
  const difference = end - now;
  
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  
  return { days, hours, minutes, seconds };
}




if ( !data )return (<div><LoadingComponent /></div>);
    
  return (
    <div>
        <div className="app-package-expire">
            <div className="app-package-expire-main">
                <div className="app-package-expire-heading">
                Premium plan subscription, offer valid for
                </div>
                <div className="app-package-expire-time">
                <div className="app-package-expire-time-col">
                    <div>{timeRemaining.days.toString().padStart(2,0)}</div>
                    <span>Days</span>
                </div>
                <div className="app-package-expire-time-colon">:</div>
                <div className="app-package-expire-time-col">
                    <div>{timeRemaining.hours.toString().padStart(2,0)}</div>
                    <span>Hrs</span>
                </div>
                <div className="app-package-expire-time-colon">:</div>
                <div className="app-package-expire-time-col">
                    <div>{timeRemaining.minutes.toString().padStart(2,0)}</div>
                    <span>Min</span>
                </div>
                <div className="app-package-expire-time-colon">:</div>
                <div className="app-package-expire-time-col">
                    <div>{timeRemaining.seconds.toString().padStart(2,0)}</div>
                    <span>Sec</span>
                </div>

                </div>

            </div>

        </div>
    </div>
  )
}

export default AppPackageExpireComponent