import React from "react";
import img1 from '../../../media/images/website/qr-code-generator-how-to-1.webp'
import img2 from '../../../media/images/website/qr-code-generator-how-to-2.webp'
import img3 from '../../../media/images/website/qr-code-generator-how-to-3.webp'

import imgEnd from '../../../media/images/website/Ad-QR-Code-generator7.jpg'

const HowToGenerateQrComponent = () => {
  return (
    <div>
      <div className="how-generate-qr">

        <div className="how-generate-qr-main">
          <div className="how-generate-qr-head">
            <div className="how-generate-qr-head-heading">
              How to Create a QR Code for Free using Our QR Code Generator?
            </div>
            <div className="how-generate-qr-head-text">
              Creating QR codes is a simple process. You can use Invoician online
              QR generator to create a QR code for your business requirements.
              Here are three simple steps that you have to follow to generate a
              QR code.
            </div>
          </div>

          <div className="how-generate-qr-content">
            
            <div className="how-generate-qr-content-col">
              <div className="how-generate-qr-content-image">
                <img src={img1} alt="" />
              </div>
              <div className="how-generate-qr-content-heading">Select type of QR code</div>
              <div className="how-generate-qr-content-text">You can choose from multiple QR code options, including URL, vCard, plain text, SMS, WhatsApp, email, Wi-Fi, and many more.</div>
            </div>

            
            <div className="how-generate-qr-content-col">
              <div className="how-generate-qr-content-image">
                <img src={img2} alt="" />
              </div>
              <div className="how-generate-qr-content-heading">Fill in the details</div>
              <div className="how-generate-qr-content-text">Put in all the information that you need the QR code to save within itself. You can store a link to text contact data, or any other type of info. Once you have filled in data about the events, you can hit "Generate QR Code"</div>
            </div>

            
            <div className="how-generate-qr-content-col">
              <div className="how-generate-qr-content-image">
                <img src={img3} alt="" />
              </div>
              <div className="how-generate-qr-content-heading">Download QR Code</div>
              <div className="how-generate-qr-content-text">Once generated, You can choose your QR code in different sizes and formats & download it.</div>
            </div>


          </div>


          <a href="#qr-generator" className="how-generate-qr-button">Create Your QR Codes</a>          
          
          
        </div>

        
        <div className="how-generate-qr-end">
            <img src={imgEnd} alt="" />
        </div>
        
      </div>
    </div>
  );
};

export default HowToGenerateQrComponent;
