import React from "react";

const WhyInvoicianComponent = () => {
  return (
    <div>
      <div className="why-invoician-component">
        <div className="why-invoician-component-main">
          <div className="why-invoician-component-heading">Why Invoician?</div>
          <div className="why-invoician-component-content">

            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Create invoice within 10 seconds</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Customize invoices</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Multiple invoice templates</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Delivery Challan</div>
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>GSTR report</div> */}
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Credit note</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Share invoices on WhatsApp and Email</div>
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Payment reminders</div> */}
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Integrated payment gateway</div> */}
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Payment ledger</div> */}
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>POS billing</div> */}
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Thermal printing</div> */}
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Edit bills</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Purchase orders and purchase invoices</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Generate Estimates/Quotation</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Generate ProForma Invoices</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Stock and Inventory Management</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Unlimited Logins</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Unlimited Customers</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Unlimited Products</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Multiple User/Business accounts</div>
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>User Role Settings</div> */}
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Multiple GSTs Accounting</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Multiple Pricing (Wholesale/Retail)</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Customer/Vendor details</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Manage Expenses</div>
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Profit/Loss statement</div> */}
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Real time Analytics</div>
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Online Store</div> */}
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Add Product Variants</div>
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>E-way Bills</div> */}
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>E-Invoices</div> */}
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>TDS</div> */}
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Cess</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Recurring Invoices/ Subscriptions</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>SMS Campaigns</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Export/ SEZ Invoices</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Bill of Supply</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Serial Number & IMEI</div>
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Batch & Expiry</div>
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Manufacturing & Grouping</div> */}
            {/* <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Tally Integration</div> */}
            <div className="why-invoician-component-point"><i class="fa-solid fa-circle-check"></i>Advanced Column Linking</div>
            
            
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyInvoicianComponent;
