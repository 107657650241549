import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import { Link } from "react-router-dom";
import AppPackageDurationComponent from "../main/AppPackageDurationComponent";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";
import AppMobileSettingNavbar from "../pageComponents/AppMobileSettingNavbar";

const AppAccountSettingPageComponent = ({ data, fetchUserData }) => {


   
  // get from storage ============================================================================================
  
  
  const userId = localStorage.getItem("invoician-user-id");
  // const businessId = localStorage.getItem("business-id");
  

  // useStates ============================================================================================
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("");
  const [timerPopupAlert, setTimerPopupAlert] = useState(false)
  const [timerAlertMessage, setTimerAlertMessage] = useState("")
  const [submitButton, setSubmitButton] = useState(false)

  
  const [userData, setUserData] = useState({
    userName : data.userName, 
    phoneNumber : data.phoneNumber,
  });
  

  
  const handleChange = (e) => {
    const value = e.target.value;
    setSubmitButton(true)
    setError("")
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
  };
  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };
  
  const { updateUser } = useContext(AuthContext);

  const handleUser = async (e) => {
    setLoading(true);
    e.preventDefault();

    updateUser(userId, userData, setError, fetchUserData, setTimerPopupAlert, setTimerAlertMessage, setSubmitButton)

    setLoading(false);
  };

  
  
  



  return (
    <div>
      {loading ? <LoadingComponent /> : ""}

{
  timerPopupAlert?
  <TimerPopupAlertComponent message={timerAlertMessage} setTimerPopupAlert={setTimerPopupAlert}/>
  :""
}


      <div className="business-setting-component">
        <form onSubmit={handleUser} className="business-setting-component-main">
          <div className="business-setting-head">
            <div className="business-setting-head-heading"><AppMobileSettingNavbar/>Account Setting</div>
            <div>
              { error? <div className="business-setting-head-error">{error}</div> : "" }

              {/* <button className="business-setting-head-button">Save Changes</button> */}

              {
  loading === true?
   <div className="business-setting-head-button-disable">Updating...</div>
  :
  submitButton === true?
   <button className="business-setting-head-button">Save Changes</button> 
   :
  submitButton === "done"?
  <div className="business-setting-head-button-done"><i class="fa-regular fa-circle-check"></i>Saved</div>
  //  <button className="business-setting-head-button">Save Changes</button> 
   :
   
   <div className="business-setting-head-button-disable">Save Changes</div>
}

            </div>
          </div>

{/* <AppPackageDurationComponent/> */}

          <div className="business-setting-form">
          {/* <div className="business-setting-form-heading">Edit Details</div> */}

          <div className="business-setting-form-grid">
          <div className="business-setting-form-heading">
            <b>E-Mail</b> : {data.email}
          </div>
          <Link to="/update-password" className="business-setting-form-password">
            {/* <b>E-Mail</b> : deepanshu2808@gmail.com */}
            change password?
          </Link>
            
            <div className="business-setting-form-col">
              <label>User Name *</label>
              <input onKeyDown={handleKeyDown} 
                required
              onChange={handleChange}
                type="text"
                // value="Business"
                value={userData.userName}
                name="userName"
                placeholder="Enter user name"
                />
            </div>

            <div className="business-setting-form-col">
              <label>User Phone Number</label>
              <input onKeyDown={handleKeyDown} 
              onChange={handleChange}
                type="text"
                value={userData.phoneNumber}
                name="phoneNumber"
                placeholder="Enter your phone number"
                />
            </div>
            
          </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AppAccountSettingPageComponent;
