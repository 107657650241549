import React from "react";
import img from "../../../media/images/website/invoice-generator-hero-new.png"


const InvoiceGeneratorPageHeader = () => {
  return (
    <div>
      <div className="invoice-generator-header">
        <div className="invoice-generator-header-main">
          <div className="invoice-generator-header-content">
            <div className="invoice-generator-header-heading">
            Invoice Generator <br />Online Bill Maker
            </div>
            <div className="invoice-generator-header-text">
            Over 1 crore businesses trust Invoician’s invoice generator to create bills quickly and easily. Send reminders and get payments faster. Download Invoician now and try it for free!
            </div>
            <div className="invoice-generator-header-button">Start Free Invoicing</div>
          </div>
          <div className="invoice-generator-header-image">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceGeneratorPageHeader;
