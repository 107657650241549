import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingComponent from '../../../components/extra/LoadingComponent';
import { AuthContext } from '../../../context/AuthContext';
import ViewSharedInvoicePageComponent from '../../components/sharedInvoice/ViewSharedInvoicePageComponent';

const SharedInvoicePage = () => {
    const location = useLocation();

      // Get the pathname and split it by '/' to get the segments
  const pathSegments = location.pathname.split('/');

  // Get the last non-empty segment (this handles trailing slashes)
  const uniqueCode = pathSegments[pathSegments.length - 1] || 'Home'; // fallback if last segment is empty


  const navigate = useNavigate();


  const [invoiceData, setInvoiceData] = useState(null)
  
  const { fetchSharedInvoice } = useContext(AuthContext);

  const fetchBusinessData = () => fetchSharedInvoice(uniqueCode, setInvoiceData, navigate);

  useEffect(() => {
    fetchBusinessData();
  }, []);

  
  if (!invoiceData)

    return (
      <div>
        <LoadingComponent />
      </div>
    );
  
  

  return (
    <div>

      <ViewSharedInvoicePageComponent invoiceData={invoiceData}/>
      
    </div>
  )
}

export default SharedInvoicePage