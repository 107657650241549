import React, { useEffect, useState } from 'react'
import StylishA4Theme from '../themes/StylishA4Theme'
import html2pdf  from "html2pdf.js";
import DownloadingComponent from '../../../../components/extra/DownloadingComponent';
import LoadingComponent from '../../../../components/extra/LoadingComponent';


const DownloadStylishA4Invoice = ({open, data, businessData, heading, invoiceType }) => {


 
    
  return (
    <div>

{/* {downloading ? <DownloadingComponent /> : ""} */}
{/* <LoadingComponent/> */}

<DownloadingComponent />


        <span id="content-to-pdf">

        <StylishA4Theme
        data={data}
        businessData={businessData}
        heading={heading}
        open={open}
        />
        </span>
    </div>
  )
}

export default DownloadStylishA4Invoice