import React from "react";

const UseBarcodeComponent = () => {
  return (
    <div>
      <div className="qr-code-types">
        <div className="qr-code-types-main">
          <div className="qr-code-types-heading">
          Why do businesses use barcodes?
          </div>

          <div className="qr-code-types-content">
            <div className="qr-code-types-col">
              <div className="qr-code-types-col-icon">
                <i class="fa-solid fa-sack-dollar"></i>
              </div>
              <div className="qr-code-types-col-heading">Monitoring assets</div>
              <div className="qr-code-types-col-text">
                Easily track the movement of portable assets in your business by
                using a barcode. You can use the Invoician app to generate a
                barcode for any underlying asset in your company.
              </div>
            </div>

            <div className="qr-code-types-col">
              <div className="qr-code-types-col-icon">
                <i class="fa-solid fa-boxes-stacked"></i>
              </div>
              <div className="qr-code-types-col-heading">Tracking inventory</div>
              <div className="qr-code-types-col-text">
              Easily track down items in your warehouse, check out expiry dates, and identify products using serial codes and batch numbers. Using the barcode generated by the Invoician app, you can store this information instantly.
              </div>
            </div>

            <div className="qr-code-types-col">
              <div className="qr-code-types-col-icon">
                <i class="fa-solid fa-truck-fast"></i>
              </div>
              <div className="qr-code-types-col-heading">Delivery Challan</div>
              <div className="qr-code-types-col-text">
              Before making a delivery, you can scan barcode information on the package. It will help your logistics teams to cross-check details by scanning the item at any point in time.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseBarcodeComponent;
