import React from "react";
import img from '../../../media/images/website/stay-up-to-date.webp'


const CreateBusinessCardsComponent = () => {
  return (
    <div>
      <div className="what-qr">
        <div className="what-qr-main">
          <div className="create-business-cards-component-image"><img src={img} alt="" /></div>
          <div className="what-qr-content">
          <div className="what-qr-heading">Create Business Cards That Stay Up-to-Date</div>
          <div className="what-qr-text">
          Your business card design is saved in Invoician so that you can make changes when you need them. Changed your phone number? Moved office? Got a new title? Our business card maker takes the hassle out of changing your details from scratch. Simply open your saved design template and start editing. You can change anything you like.
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBusinessCardsComponent;
