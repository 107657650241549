import React, { useContext, useEffect, useRef, useState } from "react";
import LoadingComponent from "../../../../components/extra/LoadingComponent";
import { AuthContext } from "../../../../context/AuthContext";
import PopUpAlertComponent from "../../../../components/extra/PopUpAlertComponent";
import UpdateSalesInvoicePageComponent from "../update/UpdateSalesInvoicePageComponent";
import ViewInvoiceExcelTheme from "../../../pages/main/view/ViewInvoiceExcelTheme";
import ViewInvoiceGstTheme from "../../../pages/main/view/ViewInvoiceGstTheme";
import ViewBillBookTheme from "../../../pages/main/view/ViewBillBookTheme";
import DownloadingComponent from "../../../../components/extra/DownloadingComponent";
import html2pdf  from "html2pdf.js";
import ViewInvoiceStylishTheme from "../../../pages/main/view/ViewInvoiceStylishTheme";
import { Link } from "react-router-dom";
import StylishA4Theme from "../themes/StylishA4Theme";
import DownloadStylishA4Invoice from "../download/DownloadStylishA4Invoice";

const ViewDataSalesInvoiceComponent = ({
  open,
  invoiceId,
  businessData,
  fetchBusinessData,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // usestates ============================================================================================

  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [deleteAlert, setDeleteAlert] = useState(false);

  const [createSharePopup, setCreateSharePopup] = useState(false)
  const [deleteSharePopup, setDeleteSharePopup] = useState(false)

  const [updateInvoice, setUpdateInvoice] = useState(false);

  const [downloadA4, setDownloadA4] = useState(false)
  
  
  //  ============================================================================================

  const data = businessData.salesInvoiceData.find(
    (e) => e._id === invoiceId
  );

  //  ============================================================================================
  //  ============================================================================================

   //   ======================================================================================================================================================

   const divRef = useRef(null);
   const [invoiceHeight, setInvoiceHeight] = useState(1000);
 
   const handleInvoiceHeight = (e) => {
     if (divRef.current) {
       setInvoiceHeight(divRef.current.offsetHeight);
     }
   };
 
  
  //  ============================================================================================
  //  ============================================================================================
  
  const generatePDF = async () => {
    const element = document.getElementById("content-to-pdf");
    setDownloading(true);
    // margin: [0, 0, .3, 0],
    const options = {
      margin: 0,
      filename: `${
        data.invoicePartyData && data.invoicePartyData.partyName
      } Invoice-${data.invoiceNumber}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      // jsPDF: { unit: 'px', format: [800, 1100], orientation: 'portrait' },
      
      jsPDF: {
        unit: "px",
        format: [800, invoiceHeight + 80],
        // format: [800, invoiceHeight ],
        orientation: "portrait",
      },
      
      // jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    };
    await html2pdf().from(element).set(options).save();
    setDownloading(false);
  };
  
  
  useEffect(() => {
    // if (divRef.current) {
    //   setInvoiceHeight(divRef.current.offsetHeight);
    // }

    handleInvoiceHeight();
  }, [generatePDF]);
  
  //  ============================================================================================

  const { deleteSingleInvoice, createSharedInvoice, deleteSharedInvoice } = useContext(AuthContext);
  
  const handleDelete = async (e) => {
    setLoading(true);
    await deleteSingleInvoice(
      userId,
      businessId,
      invoiceId,
      open,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus
    );
    fetchBusinessData();
    setLoading(false);
  };

  
  
  const handleCreateSharedInvoice = async (e) => {
    setLoading(true);
    await createSharedInvoice(
      userId,
      businessId,
      invoiceId,
      {
        invoiceTheme : businessData.invoiceSettingData.invoiceTheme,
        invoiceThemeColor : businessData.invoiceSettingData.invoiceThemeColor,
        // invoiceType : "",
        invoiceData : data,
        invoiceTermsConditions : businessData.businessTermsConditions,
        invoiceCompanyData : {
          companyLogo : businessData.companyLogo ? businessData.companyLogo : false,
          businessName : businessData.businessName,
          companyPhoneNumber : businessData.invoiceSettingData.invoicePhoneNumber ? businessData.companyPhoneNumber : false,
          companyGSTIN : businessData.invoiceSettingData.invoiceGSTIN ? businessData.companyGSTIN : false,
          companyEmail : businessData.invoiceSettingData.invoiceEmail? businessData.companyEmail : false,
          companyAddress : businessData.invoiceSettingData.invoiceAddress
          ?
           `${
            businessData.companyBillingAddress? `${businessData.companyBillingAddress}`
              :""
           }${
            businessData.companyCity? `, ${businessData.companyCity}`
              :""
           }${
            businessData.companyState? `, ${businessData.companyState}`
              :""
           }${
            businessData.companyPincode? `- ${businessData.companyPincode}`
              :""
           }`
          : false,
        } ,
        
        invoiceBankData : 
        
  (data.paymentType === "Bank Transfer" ||
  data.paymentType === "UPI" ||
  data.paymentType === "Card" ||
  data.paymentType === "Cheque" ||
  data.paymentType === "Netbanking" )?
  data.selectedBankAccount?
  businessData.bankData&&businessData.bankData.find( e => data.selectedBankAccount === e._id)
  :"":""
        ,

      },
      fetchBusinessData,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus
    );
    setLoading(false);
  };
  
  const handleDeleteSharedInvoice = async (e) => {
    setLoading(true);
    await deleteSharedInvoice(
      userId,
      businessId,
      invoiceId,
      data.sharedCode,
      fetchBusinessData,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus
    );
    setLoading(false);
  };

  const handleCopyLink = () => {
    const textToCopy = `https://invoician.com/i/${data.sharedCode}`;
    
    
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setTimerPopupAlert(true)
        setTimerAlertMessage('Link copied successfully!');
        setTimerAlertStatus("True")
      })
      .catch((err) => {
        setTimerPopupAlert(true)
        setTimerAlertMessage('Failed to copy link!');
        setTimerAlertStatus("False")
      });
  };
  
  if (!data)
    return (
      <div>
        <LoadingComponent />
      </div>
    );
  return (
    <div>


{
  downloadA4?
<DownloadStylishA4Invoice
data={data}
businessData={businessData}
heading="tax invoice"
open={setDownloadA4}
/>
:""
}
      

{downloading ? <DownloadingComponent /> : ""}
        
      {deleteAlert ? (
        <PopUpAlertComponent
          open={setDeleteAlert}
          ok={handleDelete}
          heading={`You want to delete invoice data?`}
        />
      ) : (
        ""
      )}
        
      {createSharePopup ? (
        <PopUpAlertComponent
          open={setCreateSharePopup}
          ok={handleCreateSharedInvoice}
          heading={`want to create shareable invoice?`}
        />
      ) : (
        ""
      )}
        
      {deleteSharePopup ? (
        <PopUpAlertComponent
          open={setDeleteSharePopup}
          ok={handleDeleteSharedInvoice}
          heading={`want to delete shareable invoice?`}
        />
      ) : (
        ""
      )}

      {updateInvoice ? (
        <UpdateSalesInvoicePageComponent
          open={setUpdateInvoice}
          id={invoiceId}
          invoiceData={data}
          fetchBusinessData={fetchBusinessData}
          businessData={businessData}
          handleInvoiceHeight={handleInvoiceHeight}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
        />
      ) : (
        ""
      )}

      <div className="view-invoice-data">
        <div className="view-invoice-data-main">
          <div className="view-invoice-data-top">
            <div className="view-invoice-data-top-heading">
              <i
                onClick={() => open(false)}
                className="fa-solid fa-arrow-left"
              ></i>
              Sales Invoice {data.invoiceNumber?<>#{data.invoiceNumber}</>:""}
            </div>
            <div className="view-invoice-data-top-options">

              {
                data.sharedCode ?

              <div className="view-invoice-data-top-button-share">
                <i class="fa-solid fa-share-nodes"></i><span>Share</span>
                <div className="view-invoice-data-top-button-share-drop-list">
                  <a className="view-invoice-data-top-button-share-copy" onClick={()=>handleCopyLink()}>Copy Link</a>
                  <a className="view-invoice-data-top-button-share-wa" href={`https://web.whatsapp.com/send?text=Hi Sir/Ma'am,%0A%0AYou can view invoice here: https://invoician.com/i/${data.sharedCode}%0A%0A%0Ahttps://invoician.com/%0AIndia's Most Trusted and Free - GST Billing, Accounting and Inventory Software %0A%0AThank you`} target="_blank">Whatsapp</a>
                  <a className="view-invoice-data-top-button-share-wa-small" href={`https://wa.me/?text=Hi Sir/Ma'am,%0A%0AYou can view invoice here: https://invoician.com/i/${data.sharedCode}%0A%0A%0Ahttps://invoician.com/%0AIndia's Most Trusted and Free - GST Billing, Accounting and Inventory Software %0A%0AThank you`} target="_blank">Whatsapp</a>
                  <Link target="_blank" to={`/i/${data.sharedCode}`} className="view-invoice-data-top-button-share-open">Open Link</Link>
                  <a className="view-invoice-data-top-button-share-del" onClick={() => setDeleteSharePopup(true)}>Delete Share</a>
                </div>
              </div>
:
              <div onClick={() => setCreateSharePopup(true)} className="view-invoice-data-top-button-share-off">
                <i class="fa-solid fa-share-nodes"></i><span>Share</span>
              </div>
}
              
            {/* <div className="view-invoice-data-top-button-2" onClick={generatePDF}>
                <i class="fa-solid fa-download"></i> <span>Download</span>
              </div> */}

              
            <div className="view-invoice-data-top-button-download">
              <div  onClick={generatePDF}><i class="fa-solid fa-download"></i> <span>Download</span></div>
              <span><i class="fa-solid fa-angle-down"></i>
            <div className="view-invoice-data-top-button-download-options">
              <div onClick={()=>setDownloadA4(true)}>A4 Size</div>
              <div onClick={generatePDF}>Single Page</div>
            </div>
              </span>

              </div>
              
              <div
                className="view-invoice-data-top-button-main"
                onClick={() => setUpdateInvoice(true)}
              >
                <i class="fa-solid fa-pen"></i> <span>Edit</span>
              </div>
              <div
                className="view-invoice-data-top-button-alert"
                onClick={() => setDeleteAlert(true)}
              >
                <i class="fa-solid fa-trash-can"></i> <span>Delete</span>
              </div>
            </div>
          </div>
          <div className="view-invoice-data-content">
            <div className="view-invoice-data-content-main">

                
      {/* ------------------------------------------------------------------------------------------------ */}
      {/* download invoice start------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------ */}

      <div ref={divRef} id="content-to-pdf">

{businessData &&
businessData.invoiceSettingData.invoiceTheme === "Stylish" ? (
  // <StylishA4Theme
  <ViewInvoiceStylishTheme
    data={data}
    businessData={businessData}
    heading="tax invoice"
  />
) : businessData &&
  businessData.invoiceSettingData.invoiceTheme === "Excel" ? (
  <ViewInvoiceExcelTheme
    data={data}
    businessData={businessData}
    heading="tax invoice"
  />
) : businessData &&
  businessData.invoiceSettingData.invoiceTheme === "AdvanceGst" ? (
  <ViewInvoiceGstTheme
    data={data}
    businessData={businessData}
    heading="tax invoice"
  />
) : businessData &&
  businessData.invoiceSettingData.invoiceTheme === "BillBook" ? (
  <ViewBillBookTheme
    heading="tax invoice"
    data={data}
    businessData={businessData}
  />
) : (
  ""
)}
</div>

{/* ------------------------------------------------------------------------------------------------ */}
{/* download invoice end------------------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------------------ */}

                
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDataSalesInvoiceComponent;
