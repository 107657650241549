import React, { useState } from "react";
import { TypeAnimation } from "react-type-animation";

import stylish from "../../../media/images/website/StylishTheme.jpg";
import excel from "../../../media/images/website/ExcelTheme.jpg";
import advanceGst from "../../../media/images/website/AdvanceGst.jpg";
import billBook from "../../../media/images/website/BillBook.jpg";
import customise from "../../../media/images/website/customise.png";

import thermal from "../../../media/images/thermal-print.webp";
import a4 from "../../../media/images/A4.png";
import a5 from "../../../media/images/A5.png";
import { Link } from "react-router-dom";

const InvoiceTypesComponent = () => {
  const [billType, setBillType] = useState("A4");
  return (
    <div>
      <div className="invoice-type" id="customiseInvoice">
        <div className="invoice-type-main">
          <div className="invoice-type-head">
            <div className="invoice-type-heading">
              Your bill, Your Brand{" "}
              <TypeAnimation
                sequence={[
                  "Color",
                  3500,
                  "Logo",
                  3500,
                  "Font",
                  3500,
                  "And More!",
                  3500,
                ]}
                speed={50}
                repeat={Infinity}
              />
            </div>
            <div className="invoice-type-text">
              Fully customisable GST & non - GST invoices with multiple invoice
              theme options. Get the power to personalise your invoices that
              reflect your brand's identity, exclusively on invoician GST
              Billing Software
            </div>
            {/* <div className="invoice-type-options">
            <div onClick={()=>setBillType("thermal")} className={billType === "thermal"? "invoice-type-option-active" :""}>Thermal Prints</div>
            <div onClick={()=>setBillType("A4")} className={billType === "A4"? "invoice-type-option-active":""}>A4 Prints</div>
            <div onClick={()=>setBillType("A5")} className={billType === "A5"? "invoice-type-option-active":""}>A5 Prints</div>
            
            </div> */}
          </div>

          {/* <div className="invoice-type-content">
            {
                billType === "thermal"?
                <img src={thermal} alt="" />
                :
                billType === "A4"?
                <img src={a4} alt="" />
                :
                billType === "A5"?
                <img src={a5} alt="" />
                :""

            }

          </div> */}

          <div className="invoice-type-content">



          <div className="invoice-type-content-col">
              <div className="invoice-type-content-heading">
                <div>Advance Gst</div>
                <Link to="/app" >Start Using Templates <i class="fa-solid fa-arrow-right-long"></i></Link>
              </div>
              <img src={advanceGst} alt="" />
            </div>



            <div className="invoice-type-content-col">
              <div className="invoice-type-content-heading">
                <div>Excel</div>
                <Link to="/app" >Start Using Templates <i class="fa-solid fa-arrow-right-long"></i></Link>
              </div>
              <img src={excel} alt="" />
            </div>


            <div className="invoice-type-content-col">
              <div className="invoice-type-content-heading">
                <div>Custom Invoice</div>
                <Link to="/app" >Start Using Templates <i class="fa-solid fa-arrow-right-long"></i></Link>
              </div>
              <img src={customise} alt="" />
            </div>


            <div className="invoice-type-content-col">
              <div className="invoice-type-content-heading">
                <div>Stylish</div>
                <Link to="/app" >Start Using Templates <i class="fa-solid fa-arrow-right-long"></i></Link>
              </div>
              <img src={stylish} alt="" />
            </div>



            <div className="invoice-type-content-col">
              <div className="invoice-type-content-heading">
                <div>Bill Book</div>
                <Link to="/app" >Start Using Templates <i class="fa-solid fa-arrow-right-long"></i></Link>
              </div>
              <img src={billBook} alt="" />
            </div>





          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTypesComponent;
