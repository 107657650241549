import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import logo from "../../../media/images/logo/invoician-logo.png";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import AppConfiguration from "../../components/main/AppConfiguration";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";

const UserWelcomePage = () => {
  const userId = localStorage.getItem("invoician-user-id");

  const [formType, setFormType] = useState("form1");
  const [selectType, setSelectType] = useState(false);
  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerAlertStatus, setTimerAlertStatus] = useState("");

  const [addAddress, setAddAddress] = useState(false);

  // ----------------------------------------

  const [userData, setUserData] = useState("");

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { fetchUser, createBusiness } = useContext(AuthContext);

  const fetchUserData = (e) => fetchUser(userId, setUserData);

  useEffect(() => {
    // fetchUser( userId, setUserData );
    fetchUserData();
  }, []);

  // functions ========================================================================================

  const [data, setData] = useState({
    businessName: "",
    companyPhoneNumber: "",
    businessRegistrationType: "",
    businessType: [],
    industryType: "",
    companyGstRegistered: false,
    companyGSTIN: "",
    billingRequirment: "",
    businessSize: "",
    findUs: "",
    languageToTalk: "",

    // ---------------------------------------------------

    companyBillingAddress: "",
    companyState: "",
    companyPincode: "",
    companyCity: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setData({
      ...data,
      [name]: value,
    });
  };

  const [popupData, setPopupData] = useState({
    companyBillingAddress: "",
    companyState: "",
    companyPincode: "",
    companyCity: "",
  });

  const handlePopupChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setPopupData({
      ...popupData,
      [name]: value,
    });

    console.log("first ----------", value);
  };

  const handlePopupData = (e) => {
    e.preventDefault();

    setData({
      ...data,
      companyBillingAddress: popupData.companyBillingAddress,
      companyState: popupData.companyState,
      companyPincode: popupData.companyPincode,
      companyCity: popupData.companyCity,
    });

    console.log("popupData is :", popupData);
    setAddAddress(false);
  };

  // ==========================================================================================================
  // ==========================================================================================================
  // ==========================================================================================================

  const typeRef = useRef(null);
  const typeListRef = useRef(null);

  const handleClickOutside = (event) => {
    // Check if the click is outside the button and dropdown
    if (
      typeListRef.current &&
      !typeListRef.current.contains(event.target) &&
      typeRef.current &&
      !typeRef.current.contains(event.target)
    ) {
      setSelectType(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // ==========================================================================================================
  // ==========================================================================================================
  const handleForm1 = (e) => {
    e.preventDefault();

    setFormType("form2");
  };
  const handleForm2 = (e) => {
    e.preventDefault();

    setFormType("form3");
  };

  // ==========================================================================================================

  const handleBusiness = async (e) => {
    setLoading(true);
    e.preventDefault();

    createBusiness(
      userId,
      data,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      fetchUserData
    );

    setLoading(false);
  };

  // ==========================================================================================================
  // ==========================================================================================================

  if (userData.businessData && userData.businessData.length > 0) {
    return <Navigate to="/app" />;
  }

  return (
    <div>
      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}

      <AppConfiguration />

      {loading ? <LoadingComponent /> : ""}

      {addAddress ? (
        <div className="welcome-page-address-popup">
          <form
            onSubmit={handlePopupData}
            className="welcome-page-address-popup-main"
          >
            <div className="welcome-page-address-popup-head">
              <div>Company Address</div>
              <i
                className="fa-solid fa-xmark"
                onClick={() => setAddAddress(false)}
              ></i>
            </div>

            <div className="welcome-page-address-popup-form">
              <div className="welcome-page-address-popup-col">
                <div className="welcome-page-address-popup-label">
                  Street Address 
                  {/* <span>*</span> */}
                </div>
                <textarea
                  onChange={handlePopupChange}
                  name="companyBillingAddress"
                  required
                  type="text"
                  value={popupData.companyBillingAddress}
                />
              </div>

              <div className="welcome-page-address-popup-row">
                <div className="welcome-page-address-popup-col">
                  <div className="welcome-page-address-popup-label">State</div>
                  <input
                    onChange={handlePopupChange}
                    name="companyState"
                    type="text"
                    value={popupData.companyState}
                    list="state-type"
                  />

                  <datalist id="state-type">
                    {/* <option value="Andaman and Nicobar Islands">
        Andaman and Nicobar Islands
      </option> */}
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    {/* <option value="Dadra and Nagar Haveli and Daman and Diu">
        Dadra and Nagar Haveli and Daman and Diu
      </option> */}
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand </option>
                  </datalist>
                </div>

                <div className="welcome-page-address-popup-col">
                  <div className="welcome-page-address-popup-label">
                    Pincode
                  </div>
                  <input
                    onChange={handlePopupChange}
                    name="companyPincode"
                    type="number"
                    value={popupData.companyPincode}
                  />
                </div>
              </div>

              <div className="welcome-page-address-popup-col">
                <div className="welcome-page-address-popup-label">City</div>
                <input
                  onChange={handlePopupChange}
                  name="companyCity"
                  type="text"
                  value={popupData.companyCity}
                />
              </div>

              <div className="welcome-page-address-popup-buttons">
                <div onClick={() => setAddAddress(false)}>Cancle</div>
                <button>Save</button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}

      <div className="welcome-page">
        <div className="welcome-page-main">
          {formType === "form1" ? (
            // <form onSubmit={handleForm1}>
            <form onSubmit={handleBusiness}>
              <div className="welcome-page-content">
                {/* <div className="welcome-page-top">
                  <div className="welcome-page-top-circle">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <div className="welcome-page-top-line"></div>
                  <div className="welcome-page-top-circle"></div>
                  <div className="welcome-page-top-line"></div>
                  <div className="welcome-page-top-circle"></div>
                </div> */}

                <div className="welcome-page-heading">
                  Welcome🙏, Let's set up invoician for your business
                </div>

                <div className="welcome-page-col">
                  <label>
                    Your business name <span>*</span>
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    name="businessName"
                    type="text"
                    // placeholder="Enter your business name"
                  />
                </div>

                <div className="welcome-page-col">
                  <label>
                    Company Phone Number <span>*</span>
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    name="companyPhoneNumber"
                    type="number"
                    // placeholder="Enter Company Phone Number"
                  />
                </div>

                <div className="welcome-page-col">
                  <label>Company Address</label>
                  <div
                    className="welcome-page-col-input"
                    onClick={() => setAddAddress(true)}
                  >
                    {data.companyBillingAddress ? (
                      <>{data.companyBillingAddress}</>
                    ) : (
                      ""
                    )}
                    {data.companyCity ? <>, {data.companyCity}</> : ""}
                    {data.companyState ? <>, {data.companyState}</> : ""}
                    {data.companyPincode ? <>- {data.companyPincode}</> : ""}
                  </div>
                </div>

                {/* <div className="welcome-page-col">
                  <label>
                    Business Registration Type <span>*</span>
                  </label>

                  <select
                    required
                    name="businessRegistrationType"
                    onChange={handleChange}
                    value={data.businessRegistrationType}
                  >
                    <option disabled selected value="">
                      Select Registration Type
                    </option>
                    <option value="Private Limited Company">
                      Private Limited Company
                    </option>
                    <option value="Public Limited Company">
                      Public Limited Company
                    </option>
                    <option value="Partnerships Firm">Partnerships Firm</option>
                    <option value="Limited Liability Partnership">
                      Limited Liability Partnership
                    </option>
                    <option value="One Person Company">
                      One Person Company
                    </option>
                    <option value="Sole Proprietorship">
                      Sole Proprietorship
                    </option>
                    <option value="Section 8 Company">Section 8 Company</option>
                    <option value="Business Not Registered">
                      Business Not Registered
                    </option>
                  </select>
                </div>

                <div className="welcome-page-col">
                  <label>
                    Business Type (Select multiple, if applicable)
                  </label>

                  <div className="setting-business-page-form-col-dropdown" ref={typeRef} onClick={ () => setSelectType(true) } >
                    <div className="setting-business-page-form-col-dropdown-select">{data.businessType.length === 1 && data.businessType[0] === "" ?"select":data.businessType.length>0?data.businessType.map( (e, i) => <>{e}{e.length>0 ? i< data.businessType.length-1?", ":"":""} </>): "select"} <i className="fa-solid fa-angle-down"></i></div>
{
    selectType?

                    <div className="setting-business-page-form-col-select-options" ref={typeListRef}>
                        
                        {
                        data.businessType.some( e => e === "Retailer")?
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData({...data, businessType : data.businessType.filter( e => e !== "Retailer")});  }}>Retailer <i className="fa-regular fa-square-check"></i></div>
                        :
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData( { ...data, businessType : [...data.businessType, "Retailer"] });  }}>Retailer <i className="fa-regular fa-square"></i></div>
                        }
                        
                        {
                        data.businessType.some( e => e === "Wholesaler")?
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData({...data, businessType : data.businessType.filter( e => e !== "Wholesaler")});  }}>Wholesaler <i className="fa-regular fa-square-check"></i></div>
                        :
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData( { ...data, businessType : [...data.businessType, "Wholesaler"] });  }}>Wholesaler <i className="fa-regular fa-square"></i></div>
                        }
                        
                        {
                        data.businessType.some( e => e === "Distributor")?
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData({...data, businessType : data.businessType.filter( e => e !== "Distributor")});  }}>Distributor <i className="fa-regular fa-square-check"></i></div>
                        :
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData( { ...data, businessType : [...data.businessType, "Distributor"] });  }}>Distributor <i className="fa-regular fa-square"></i></div>
                        }
                        
                        {
                        data.businessType.some( e => e === "Manufacturer")?
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData({...data, businessType : data.businessType.filter( e => e !== "Manufacturer")});  }}>Manufacturer <i className="fa-regular fa-square-check"></i></div>
                        :
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData( { ...data, businessType : [...data.businessType, "Manufacturer"] });  }}>Manufacturer <i className="fa-regular fa-square"></i></div>
                        }
                        
                        {
                        data.businessType.some( e => e === "Services")?
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData({...data, businessType : data.businessType.filter( e => e !== "Services")});  }}>Services <i className="fa-regular fa-square-check"></i></div>
                        :
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData( { ...data, businessType : [...data.businessType, "Services"] });  }}>Services <i className="fa-regular fa-square"></i></div>
                        }
                        
                        
                    </div>
                    :""}

                    </div>
                  
                </div>

                <div className="welcome-page-col">
                  <label>
                    Industry Type <span>*</span>
                  </label>
                  <select
                    required
                    name="industryType"
                    onChange={handleChange}
                    value={data.industryType}
                  >
                    <option disabled selected value="">
                      Select Industry Type
                    </option>
                    <option value="Others">Others</option>

                    <option value="Accounting and Financial Services">
                      Accounting and Financial Services
                    </option>
                    <option value="Agriculture">Agriculture</option>
                    <option value="Automobile">Automobile</option>
                    <option value="Battery">Battery</option>
                    <option value="Broadband/ cable/ internet">
                      Broadband/ cable/ internet
                    </option>
                    <option value="Building Material and Construction">
                      Building Material and Construction
                    </option>
                    <option value="Cleaning and Pest Control">
                      Cleaning and Pest Control
                    </option>
                    <option value="Consulting">Consulting</option>
                    <option value="Dairy (Milk)">Dairy (Milk)</option>
                    <option value="Doctor / Clinic / Hospital">
                      Doctor / Clinic / Hospital
                    </option>
                    <option value="Education-Schooling/Coaching">
                      Education-Schooling/Coaching
                    </option>
                    <option value="Electrical works">Electrical works</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Event planning and management">
                      Event planning and management
                    </option>
                    <option value="FMCG">FMCG</option>
                    <option value="Fitness - Gym and Spa">
                      Fitness - Gym and Spa
                    </option>
                    <option value="Footwear">Footwear</option>
                    <option value="Fruits and Vegetables">
                      Fruits and Vegetables
                    </option>
                    <option value="Furniture">Furniture</option>
                    <option value="Garment/Clothing">Garment/Clothing</option>
                    <option value="General Store(Kirana)">
                      General Store(Kirana)
                    </option>
                    <option value="Gift Shop">Gift Shop</option>
                    <option value="Hardware">Hardware</option>
                    <option value="Home services">Home services</option>
                    <option value="Hotels and Hospitality">
                      Hotels and Hospitality
                    </option>
                    <option value="Information Technology">
                      Information Technology
                    </option>
                    <option value="Interiors">Interiors</option>
                    <option value="Jewellery">Jewellery</option>
                    <option value="Liquor">Liquor</option>
                    <option value="Machinery">Machinery</option>
                    <option value="Meat">Meat</option>
                    <option value="Medicine(Pharma)">Medicine(Pharma)</option>
                    <option value="Mobile and accessories">
                      Mobile and accessories
                    </option>
                    <option value="Oil And Gas">Oil And Gas</option>
                    <option value="Opticals">Opticals</option>
                    <option value="Other services">Other services</option>
                    <option value="Packaging">Packaging</option>
                    <option value="Paints">Paints</option>
                    <option value="Photography">Photography</option>
                    <option value="Plywood">Plywood</option>
                    <option value="Printing">Printing</option>
                    <option value="Real estate - Rentals and Lease">
                      Real estate - Rentals and Lease
                    </option>
                    <option value="Restaurants/ Cafe/ Catering">
                      Restaurants/ Cafe/ Catering
                    </option>
                    <option value="Safety Equipments">Safety Equipments</option>
                    <option value="Salon">Salon</option>
                    <option value="Scrap">Scrap</option>
                    <option value="Service Centres">Service Centres</option>
                    <option value="Sports Equipments">Sports Equipments</option>
                    <option value="Stationery">Stationery</option>
                    <option value="Tailoring/ Boutique">
                      Tailoring/ Boutique
                    </option>
                    <option value="Textiles">Textiles</option>
                    <option value="Tiles/Sanitary Ware">
                      Tiles/Sanitary Ware
                    </option>
                    <option value="Tours and Travel">Tours and Travel</option>
                    <option value="Transport and Logistics">
                      Transport and Logistics
                    </option>
                    <option value="Utensils">Utensils</option>
                    <option value="Others">Others</option>

                  </select>
                </div> */}

                <div className="welcome-page-col">
                  <label>
                    Is your business GST registered? <span>*</span>
                  </label>

                  <div className="setting-business-page-form-radio-options">
                    <div
                      className="setting-business-page-form-radio"
                      onClick={() => {
                        setData({ ...data, companyGstRegistered: true });
                      }}
                    >
                      Yes{" "}
                      <input
                        checked={data.companyGstRegistered ? true : false}
                        type="radio"
                      />
                    </div>

                    <div
                      className="setting-business-page-form-radio"
                      onClick={() => {
                        setData({
                          ...data,
                          companyGstRegistered: false,
                          companyGSTIN: "",
                        });
                      }}
                    >
                      no{" "}
                      <input
                        checked={!data.companyGstRegistered ? true : false}
                        type="radio"
                      />
                    </div>
                  </div>
                </div>

                {data.companyGstRegistered ? (
                  <div className="welcome-page-col">
                    <label>
                      GSTIN No <span>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      name="companyGSTIN"
                      onChange={handleChange}
                      placeholder="Enter your GST Number"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="welcome-page-button">
                <button>Get Started</button>
              </div>
            </form>
          ) : formType === "form2" ? (
            <form onSubmit={handleForm2}>
              <div className="welcome-page-content">
                <div className="welcome-page-top">
                  <div className="welcome-page-top-circle">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <div className="welcome-page-top-line"></div>
                  <div className="welcome-page-top-circle">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <div className="welcome-page-top-line"></div>
                  <div className="welcome-page-top-circle"></div>
                </div>

                <div className="welcome-page-col">
                  <label>Select your billing requirement?</label>

                  <div className="welcome-page-col-radio">
                    <div className="welcome-page-col-radio-col">
                      <div className="welcome-page-col-radio-text">
                        Basic Billing
                      </div>
                      <input
                        required
                        type="radio"
                        onChange={handleChange}
                        name="billingRequirment"
                        value="Basic"
                      />
                    </div>

                    <div className="welcome-page-col-radio-col">
                      <div className="welcome-page-col-radio-text">
                        Advance Billing
                      </div>
                      <input
                        required
                        type="radio"
                        onChange={handleChange}
                        name="billingRequirment"
                        value="Advance"
                      />
                    </div>

                    <div className="welcome-page-col-radio-col">
                      <div className="welcome-page-col-radio-text">
                        Enterprise Billing
                      </div>
                      <input
                        required
                        type="radio"
                        onChange={handleChange}
                        name="billingRequirment"
                        value="Enterprise"
                      />
                    </div>
                  </div>
                </div>

                <div className="welcome-page-col">
                  <label>Size of your business?</label>
                  <div className="welcome-page-col-radio">
                    <div className="welcome-page-col-radio-col">
                      <div className="welcome-page-col-radio-text">
                        Less than 40 lakhs
                      </div>
                      <input
                        required
                        type="radio"
                        onChange={handleChange}
                        name="businessSize"
                        value="Less than 40 lakhs"
                      />
                    </div>

                    <div className="welcome-page-col-radio-col">
                      <div className="welcome-page-col-radio-text">
                        40 lakhs to 1 crore
                      </div>
                      <input
                        required
                        type="radio"
                        onChange={handleChange}
                        name="businessSize"
                        value="40 lakhs to 1 crore"
                      />
                    </div>

                    <div className="welcome-page-col-radio-col">
                      <div className="welcome-page-col-radio-text">
                        More than I core
                      </div>
                      <input
                        required
                        type="radio"
                        onChange={handleChange}
                        name="businessSize"
                        value="More than I core"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="welcome-page-button">
                <button>Continue</button>
              </div>
            </form>
          ) : formType === "form3" ? (
            <form onSubmit={handleBusiness}>
              <div className="welcome-page-content">
                <div className="welcome-page-top">
                  <div className="welcome-page-top-circle">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <div className="welcome-page-top-line"></div>
                  <div className="welcome-page-top-circle">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <div className="welcome-page-top-line"></div>
                  <div className="welcome-page-top-circle">
                    <i className="fa-solid fa-check"></i>
                  </div>
                </div>

                <div className="welcome-page-col">
                  <label>How did you find out about invoician?</label>
                  <div className="welcome-page-col-radio">
                    <div className="welcome-page-col-radio-col">
                      <div className="welcome-page-col-radio-text">
                        Google or other search engine
                      </div>
                      <input
                        required
                        type="radio"
                        onChange={handleChange}
                        name="findUs"
                        value="Google or other search engine"
                      />
                    </div>

                    <div className="welcome-page-col-radio-col">
                      <div className="welcome-page-col-radio-text">
                        Ad on YouTube, Instagram, Facebook etc
                      </div>
                      <input
                        required
                        type="radio"
                        onChange={handleChange}
                        name="findUs"
                        value="Ad on YouTube, Instagram, Facebook etc"
                      />
                    </div>

                    <div className="welcome-page-col-radio-col">
                      <div className="welcome-page-col-radio-text">
                        Recommended by someone
                      </div>
                      <input
                        required
                        type="radio"
                        onChange={handleChange}
                        name="findUs"
                        value="Recommended by someone"
                      />
                    </div>

                    <div className="welcome-page-col-radio-col">
                      <div className="welcome-page-col-radio-text">
                        Blog or news or other websites
                      </div>
                      <input
                        required
                        type="radio"
                        onChange={handleChange}
                        name="findUs"
                        value="Blog or news or other websites"
                      />
                    </div>

                    <div className="welcome-page-col-radio-col">
                      <div className="welcome-page-col-radio-text">Other</div>
                      <input
                        required
                        type="radio"
                        onChange={handleChange}
                        name="findUs"
                        value="Other"
                      />
                    </div>
                  </div>
                </div>

                <div className="welcome-page-col">
                  <label>Which language do you like to talk in?</label>
                  <select
                    required
                    onChange={handleChange}
                    name="languageToTalk"
                  >
                    <option selected disabled>
                      Select
                    </option>
                    <option value="English">English</option>
                    <option value="Hindi">Hindi</option>
                  </select>
                </div>
              </div>

              <div className="welcome-page-button">
                <button>Begin Billing</button>
              </div>
            </form>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* <div className="user-welcome-page">
        <div className="user-welcome-page-main">
          <div className="user-welcome-page-main-head">
            <div className="user-welcome-page-main-head-logo">
              <img src={logo} alt="invoician logo" />
            </div>

            <div className="user-welcome-page-main-heading-1">Welcome !</div>
            <div className="user-welcome-page-main-heading-2">
              Let's set up invoician for your business
            </div>
          </div>

          <div className="user-welcome-page-main-form">
            <form onSubmit={handleBusiness}>
              <div className="user-welcome-page-main-form-row">
                <label>Your business name *</label>
                <input
                  required
                  type="text"
                  placeholder="Enter your business name"
                  onChange={handleChange}
                  name="businessName"
                />
              </div>

              <div className="user-welcome-page-main-form-row">
                <label>Your company number *</label>
                <input
                  required
                  type="text"
                  placeholder="Enter your company number"
                  onChange={handleChange}
                  name="companyPhoneNumber"
                />
              </div>

              <button>Done</button>
            </form>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default UserWelcomePage;
