import React from "react";

const AboutFeaturesComponent = () => {
  return (
    <div>
      <div className="about-features">
        <div className="about-features-main">
          <div className="about-features-section-1">
            <div className="about-features-col">
              <div className="about-features-col-icon">
                <i class="fa-brands fa-android"></i>
                <i class="fa-brands fa-windows"></i>
              </div>
              <div className="about-features-col-text">Platforms</div>
              <div className="about-features-col-heading">
                Android & Windows
              </div>
            </div>

            <div className="about-features-col">
              <div className="about-features-col-icon">
                <i class="fa-solid fa-mobile-screen"></i>
              </div>
              <div className="about-features-col-text">Mobile</div>
              <div className="about-features-col-heading">100% FR</div>
            </div>

            <div className="about-features-col">
              <div className="about-features-col-icon">
                <i class="fa-solid fa-language"></i>
              </div>
              <div className="about-features-col-text">Language</div>
              <div className="about-features-col-heading">English, Hindi</div>
            </div>

            <div className="about-features-col">
              <div className="about-features-col-icon">
                <i class="fa-solid fa-desktop"></i>
                <i class="fa-solid fa-mobile-screen-button"></i>
              </div>
              <div className="about-features-col-text">Device</div>
              <div className="about-features-col-heading">
                  Mobile, Desktop
              </div>
            </div>
          </div>
          <div className="about-features-section-2">
            <b>A business accounting app</b> like Invoician (available for
            Android, IOS and Desktop) keeps your financial data accurate and
            up-to-date at all times. It works offline, so you can use it even
            without a stable internet connection. The app gives you a clear view
            of your business finances by showing what you own (assets), what you
            owe (liabilities), and the value of your business (equity). This
            makes it easier to plan and manage your accounting tasks
            effectively.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFeaturesComponent;
