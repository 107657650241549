import React, { useRef } from "react";
import logo from "../../../media/images/logo/invoician-logo.png";
// import logo from "../../../media/images/logo/logo-red.png";
// import logo from "../../../media/images/logo/invoician-white-logo.png";
// import logo from "../../../media/images/logo/logo-2.png";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };


  
  // const location = useLocation();
  // const path = location.pathname;
  

  return (
    <div>
      <div className="navbar-main">
        <div className="navbar">

          <div className="nav-logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <div className="nav-menu" ref={navRef}>
            <Link to="/">Home</Link>
            <Link to="/pricing">Pricing</Link>
            <Link to="/about-us">About Us</Link>
            {/* <Link to="">Partner with us</Link> */}

            {/* {
              path === "/" ||
              path === "/other" 
              ?
            <div className="nav-drop">
              <span>
                Features <i className="fa-solid fa-caret-down"></i>
              </span>
              <div className="nav-drop-content">
                <div className="nav-d-c-link">
                  <a href="#customiseInvoice">Customise Invoice</a>
                  <a href="#inventoryManagement">Inventory Management</a>
                </div>
              </div>
            </div>
              :
              ""
            } */}

            <div className="nav-drop">
              <span>
                Tools <i className="fa-solid fa-caret-down"></i>
              </span>
              <div className="nav-drop-content">
                <div className="nav-d-c-link">
                  <Link to="/invoice-generator">Invoice Generator</Link>
                  <Link to="/business-card-maker">Business Card Maker</Link>
                  <Link to="/qr-code-generator">QR Code Generator</Link>
                  <Link to="/barcode-generator">Barcode Generator</Link>
                  <Link to="/gst-calculator">GST Calculator</Link>
                  <Link to="/business-loan-emi-calculator">EMI Calculator</Link>
                </div>
              </div>
            </div>

            {/* <Link to="">About Us</Link> */}
            {/* <Link to="">Blog</Link> */}
          <Link className="nav-login-button nav-login-button-small" to="/login">Login</Link>

            <button className="nav-btn nav-close-btn" onClick={showNavbar}>
              <i className="fa-solid fa-circle-xmark"></i>
            </button>
          </div>

          <Link className="nav-login-button" to="/login" >Login</Link>

          <button className="nav-btn menu-btn" onClick={showNavbar}>
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
