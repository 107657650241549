import React, { useState } from 'react'
import HeaderComponent from './../components/main/HeaderComponent'
import TalkToExpertPopup from './../components/popups/TalkToExpertPopup'
import FreeTrailPopup from './../components/popups/FreeTrailPopup'
import TimerPopupAlertComponent from '../../components/extra/TimerPopupAlertComponent'
import QuerySuccessPopup from './../components/popups/QuerySuccessPopup'
import HeaderFeaturesComponent from '../components/main/HeaderFeaturesComponent'
import OurFeaturesComponent from '../components/main/OurFeaturesComponent'
import AppFeaturesComponent from '../components/main/AppFeaturesComponent'
import IndustrySliderComponent from '../components/main/IndustrySliderComponent'
import OurBenefitComponent from '../components/main/OurBenefitComponent'
import FaqComponent from '../components/main/FaqComponent'
import StartInvoicingComponent from '../components/main/StartInvoicingComponent'
import FooterComponent from '../components/main/FooterComponent'
import InvoiceTypesComponent from '../components/main/InvoiceTypesComponent'
import WhyInvoicianComponent from '../components/main/WhyInvoicianComponent'
import StartInvoicianTodayComponent from '../components/main/StartInvoicianTodayComponent'
import AllInOneSoftwareComponent from '../components/main/AllInOneSoftwareComponent'

const HomePage = () => {
  const [talkExpertPopup, setTalkExpertPopup] = useState(false)
  const [freeDemoPopup, setFreeDemoPopup] = useState(false)

  const [querySuccess, setQuerySuccess] = useState(false)
    // ------------- timer popup alert ------------
  
    const [timerAlertMessage, setTimerAlertMessage] = useState("");
    const [timerPopupAlert, setTimerPopupAlert] = useState(false);
    const [timerAlertStatus, setTimerAlertStatus] = useState("");
  
  

  // useEffect(() => {
  //     const timer = setTimeout(() => {
  //       setFreeDemoPopup(false)
  //       setTalkExpertPopup(true)
  //     }, 15000); 
  
  //     return () => clearTimeout(timer); 
  //   }, []); 
  
  

  // useEffect(() => {
  //     const timer = setTimeout(() => {
  //       setFreeDemoPopup(true)
  //     }, 5000); 
  
  //     return () => clearTimeout(timer); 
  //   }, []); 
  

    
  
  return (
    <div>

{
  querySuccess?
  <QuerySuccessPopup open={setQuerySuccess}/>
  :""
}

{timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}
      
{
  talkExpertPopup?
      <TalkToExpertPopup open={setTalkExpertPopup}
      setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          setQuerySuccess={setQuerySuccess}
      />
      :""
}
{
  freeDemoPopup?
<FreeTrailPopup open={setFreeDemoPopup}/>
:""
}

<HeaderComponent/>

<HeaderFeaturesComponent/>

<InvoiceTypesComponent/>

{/* <OurFeaturesComponent/> */}

<AppFeaturesComponent/>

<IndustrySliderComponent/>

<AllInOneSoftwareComponent/>

<OurBenefitComponent/>

<StartInvoicianTodayComponent/>

<WhyInvoicianComponent/>


<FaqComponent/>

<StartInvoicingComponent/>
<FooterComponent/>

      
    </div>
  )
}

export default HomePage