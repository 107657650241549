import React from "react";
import img from "../../../media/images/background/free-trail-popup.jpg"
import { Link } from "react-router-dom";

const FreeTrailPopup = ( {open} ) => {
  return (
    <div>
      <div className="talk-expert-popup">
        <div className="free-trail-main">
          <div className="talk-expert-popup-close">
            <i className="fa-solid fa-xmark" onClick={ ()=> open(false) }></i>
          </div>
          <Link to="/app" target="_blank" className="free-trail-content">
            <img src={img} alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FreeTrailPopup;
