import React, { useState } from "react";
import CreateAccountPopup from "../create/CreateAccountPopup";
import TimerPopupAlertComponent from "../../../../components/extra/TimerPopupAlertComponent";
import UpdateAccountPopup from "../update/UpdateAccountPopup";
import CreateAdjustBalancePopup from "../create/CreateAdjustBalancePopup";
import UpdateAdjustBalancePopup from "../update/UpdateAdjustBalancePopup";
import CreateTransferBalancePopup from "../create/CreateTransferBalancePopup";
import UpdateTransferBalancePopup from "../update/UpdateTransferBalancePopup";
import AppMobileNavbar from "../../pageComponents/AppMobileNavbar";

const CashBankPageComponent = ({
  businessData,
  combinedData,
  fetchBusinessData,
}) => {
  // =================================================================================================

  const [createBankAccount, setCreateBankAccount] = useState(false);
  const [updateBankAccount, setUpdateBankAccount] = useState(false);

  const [createAdjustBalance, setCreateAdjustBalance] = useState(false);
  const [updateAdjustBalance, setUpdateAdjustBalance] = useState(false);

  const [adjustBalanceData, setAdjustBalanceData] = useState(null);
  const [adjustBalanceId, setAdjustBalanceId] = useState(null);

  const [createTransferBalance, setCreateTransferBalance] = useState(false);
  const [updateTransferBalance, setUpdateTransferBalance] = useState(false);

  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertStatus, setTimerAlertStatus] = useState("");

  const [bankId, setBankId] = useState(null);


  const [ViewData, setViewData] = useState(false)

  const [ViewBankDetails, setViewBankDetails] = useState(false)

  const bankData =
    businessData && businessData.bankData.find((e) => bankId === e._id);

  // console.log("bankData is :", bankData);

  // =================================================================================================
  // =================================================================================================

  const [viewType, setViewType] = useState("Cash");
  const [dataViewFilter, setDataViewFilter] = useState("week");

  // ============================================================================
  // ============================================================================

  const today = new Date();
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay()); // Set to the start of the week (Sunday)

  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // First day of this month

  const startOfYear = new Date(today.getFullYear(), 0, 1);
  // ============================================================================
  // ============================================================================
  // ============================================================================

  // total --------------------------------

  const totalBalance = combinedData.reduce(
    (sum, e) =>
      e.transactionType === "sales Invoice" ||
      e.transactionType === "purchase Return" ||
      e.transactionType === "debit Note" ||
      e.transactionType === "Add Money"
        ? sum + e.receivedAmount
        : e.transactionType === "purchase Invoice" ||
          e.transactionType === "sales Return" ||
          e.transactionType === "credit Note" ||
          e.transactionType === "Reduce Money" ||
          e.transactionDate
        ? sum - e.receivedAmount
        : sum,

    0
  );

  // ============================================================================
  // ============================================================================
  // ============================================================================

  // console.log("combinedData is :", combinedData);

  const cashData = combinedData.filter(
    (e) =>
      (e.paymentType === "Cash" ||
        e.transactionIn === "Cash" ||
        e.transferFrom === "Cash" ||
        e.transferTo === "Cash") &&
      e.receivedAmount
  );

  const totalCash = cashData.reduce(
    (sum, e) =>
      e.transactionType === "sales Invoice" ||
      e.transactionType === "purchase Return" ||
      e.transactionType === "debit Note" ||
      e.transactionType === "Add Money" ||
      e.transferTo === "Cash"
        ? sum + e.receivedAmount
        : e.transactionType === "purchase Invoice" ||
          e.transactionType === "sales Return" ||
          e.transactionType === "credit Note" ||
          e.transactionType === "Reduce Money" ||
          e.transferFrom === "Cash" ||
          e.transactionDate
        ? sum - e.receivedAmount
        : sum,

    0
  );

  // ========================================
  const openingBalance = 0;
  let balance = openingBalance;

  const balanceCashData = cashData.reverse().map((e) => {
    balance =
      e.transactionType === "sales Invoice" ||
      e.transactionType === "purchase Return" ||
      e.transactionType === "debit Note" ||
      e.transactionType === "Add Money" ||
      e.transferTo === "Cash"
        ? balance + e.receivedAmount
        : e.transactionType === "purchase Invoice" ||
          e.transactionType === "sales Return" ||
          e.transactionType === "credit Note" ||
          e.transactionType === "Reduce Money" ||
          e.transferFrom === "Cash" ||
          e.transactionDate
        ? balance - e.receivedAmount
        : balance;

    return { ...e, balanceAmount: balance };
  });

  // ================================================================================================

  const filteredCashData = balanceCashData
    .reverse()
    .filter((e) =>
      dataViewFilter === "today"
        ? new Date(e.invoiceDate).toDateString() === today.toDateString()
        : dataViewFilter === "week"
        ? new Date(e.invoiceDate) >= startOfWeek &&
          new Date(e.invoiceDate) <= today
        : dataViewFilter === "month"
        ? new Date(e.invoiceDate) >= startOfMonth &&
          new Date(e.invoiceDate) <= today
        : dataViewFilter === "year"
        ? new Date(e.invoiceDate) >= startOfYear &&
          new Date(e.invoiceDate) <= today
        : e
    );

  // ================================================================================================
  // ================================================================================================
  // ================================================================================================
  // ================================================================================================
  // ================================================================================================
  // ================================================================================================
  // ================================================================================================
  // ================================================================================================

  const bankInvoiceData = combinedData.filter(
    (e) =>
      (e.paymentType === "Bank Transfer" ||
        e.paymentType === "UPI" ||
        e.paymentType === "Card" ||
        e.paymentType === "Cheque" ||
        e.paymentType === "Netbanking" ||
        (e.transactionIn && e.transactionIn !== "Cash") ||
        (e.transferTo && e.transferFrom)) &&
      e.receivedAmount
  );

  // ========================================
  const filteredBank = (e) =>
    bankInvoiceData.filter(
      (a) =>
        a.selectedBankAccount === e ||
        a.transactionIn === e ||
        a.transferTo === e ||
        a.transferFrom === e
    );

  console.log("bankInvoiceData is :", bankInvoiceData);

  console.log("filteredBank is :", filteredBank(bankId));

  const openingBankBalance = 0;
  let bankbalance = openingBankBalance;

  const totalBankAmount = filteredBank(bankId)
    .reverse()
    .map((e) => {
      bankbalance =
        e.transactionType === "sales Invoice" ||
        e.transactionType === "purchase Return" ||
        e.transactionType === "debit Note" ||
        e.transactionType === "Add Money" ||
        e.transferTo === bankId
          ? bankbalance + e.receivedAmount
          : e.transactionType === "purchase Invoice" ||
            e.transactionType === "sales Return" ||
            e.transactionType === "credit Note" ||
            e.transactionType === "Reduce Money" ||
            e.transferFrom === bankId ||
            e.transactionDate
          ? bankbalance - e.receivedAmount
          : bankbalance;

      return { ...e, balanceAmount: bankbalance };
    });

  const totalBankBalance = (id) => {
    const totalBalance = filteredBank(id).reduce(
      (sum, e) =>
        e.transactionType === "sales Invoice" ||
        e.transactionType === "purchase Return" ||
        e.transactionType === "debit Note" ||
        e.transactionType === "Add Money" ||
        e.transferTo === id
          ? sum + e.receivedAmount
          : e.transactionType === "purchase Invoice" ||
            e.transactionType === "sales Return" ||
            e.transactionType === "credit Note" ||
            e.transactionType === "Reduce Money" ||
            e.transferFrom === id ||
            e.transactionDate
          ? sum - e.receivedAmount
          : sum,

      0
    );

    return totalBalance;
  };

  // ================================================================================================

  const filteredBankData = totalBankAmount
    .reverse()
    .filter((e) =>
      dataViewFilter === "today"
        ? new Date(e.invoiceDate).toDateString() === today.toDateString()
        : dataViewFilter === "week"
        ? new Date(e.invoiceDate) >= startOfWeek &&
          new Date(e.invoiceDate) <= today
        : dataViewFilter === "month"
        ? new Date(e.invoiceDate) >= startOfMonth &&
          new Date(e.invoiceDate) <= today
        : dataViewFilter === "year"
        ? new Date(e.invoiceDate) >= startOfYear &&
          new Date(e.invoiceDate) <= today
        : e
    );

  // ================================================================================================
  // ================================================================================================

  const filteredUnlinked = bankInvoiceData.filter(
    (e) => !e.selectedBankAccount && !e.transactionIn && !e.transferTo
  );

  // console.log("filteredUnlinked is :", filteredUnlinked);

  const totalUnlinkedBalance = filteredUnlinked.reduce(
    (sum, e) =>
      e.transactionType === "sales Invoice" ||
      e.transactionType === "purchase Return" ||
      e.transactionType === "debit Note"
        ? sum + e.receivedAmount
        : e.transactionType === "purchase Invoice" ||
          e.transactionType === "sales Return" ||
          e.transactionType === "credit Note" ||
          e.transactionDate
        ? sum - e.receivedAmount
        : sum,

    0
  );

  const openingUnlinkBalance = 0;
  let unlinkbalance = openingUnlinkBalance;

  const totalUnlinkedAmount = filteredUnlinked.reverse().map((e) => {
    unlinkbalance =
      e.transactionType === "sales Invoice" ||
      e.transactionType === "purchase Return" ||
      e.transactionType === "debit Note"
        ? unlinkbalance + e.receivedAmount
        : e.transactionType === "purchase Invoice" ||
          e.transactionType === "sales Return" ||
          e.transactionType === "credit Note" ||
          e.transactionDate
        ? unlinkbalance - e.receivedAmount
        : unlinkbalance;

    return { ...e, balanceAmount: unlinkbalance };
  });

  // ================================================================================================

  const filteredUnlinkedData = totalUnlinkedAmount
    .reverse()
    .filter((e) =>
      dataViewFilter === "today"
        ? new Date(e.invoiceDate).toDateString() === today.toDateString()
        : dataViewFilter === "week"
        ? new Date(e.invoiceDate) >= startOfWeek &&
          new Date(e.invoiceDate) <= today
        : dataViewFilter === "month"
        ? new Date(e.invoiceDate) >= startOfMonth &&
          new Date(e.invoiceDate) <= today
        : dataViewFilter === "year"
        ? new Date(e.invoiceDate) >= startOfYear &&
          new Date(e.invoiceDate) <= today
        : e
    );

  // ================================================================================================
  // ================================================================================================

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  // ================================================================================================
  // ================================================================================================
  // ================================================================================================

  const handleAdjustBalance = (e) => {
    if (e.transactionIn) {
      setUpdateAdjustBalance(true);
      setAdjustBalanceData(e);
      setAdjustBalanceId(e._id);
    } else if (e.transferFrom || e.transferTo) {
      setUpdateTransferBalance(true);
      setAdjustBalanceData(e);
      setAdjustBalanceId(e._id);
      // alert("clicked")
    }
  };

  return (
    <div>
      {createBankAccount ? (
        <CreateAccountPopup
          open={setCreateBankAccount}
          fetchData={fetchBusinessData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
        />
      ) : (
        ""
      )}

      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}

      {updateBankAccount ? (
        <UpdateAccountPopup
          open={setUpdateBankAccount}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          bankData={bankData}
          bankId={bankId}
          fetchBusinessData={fetchBusinessData}
        />
      ) : (
        ""
      )}

      {createAdjustBalance ? (
        <CreateAdjustBalancePopup
          open={setCreateAdjustBalance}
          fetchData={fetchBusinessData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          totalCash={totalCash}
          businessData={businessData}
          totalBankBalance={totalBankBalance}
        />
      ) : (
        ""
      )}

      {updateAdjustBalance ? (
        <UpdateAdjustBalancePopup
          open={setUpdateAdjustBalance}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          balanceData={adjustBalanceData}
          balanceId={adjustBalanceId}
          fetchData={fetchBusinessData}
          totalCash={totalCash}
          businessData={businessData}
          totalBankBalance={totalBankBalance}
        />
      ) : (
        ""
      )}

      {createTransferBalance ? (
        <CreateTransferBalancePopup
          open={setCreateTransferBalance}
          fetchData={fetchBusinessData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          totalCash={totalCash}
          businessData={businessData}
          totalBankBalance={totalBankBalance}
        />
      ) : (
        ""
      )}

      {updateTransferBalance ? (
        <UpdateTransferBalancePopup
          open={setUpdateTransferBalance}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          balanceData={adjustBalanceData}
          balanceId={adjustBalanceId}
          fetchData={fetchBusinessData}
          totalCash={totalCash}
          businessData={businessData}
          totalBankBalance={totalBankBalance}
        />
      ) : (
        ""
      )}

      <div className="app-cash-bank">
        <div className="app-cash-bank-main">
          <div className="app-cash-bank-top">
            <div className="app-cash-bank-top-heading">
              {" "}
              <AppMobileNavbar  businessData={businessData}/> Cash and Bank
            </div>
            <div className="app-cash-bank-top-buttons">
              <div
                className="app-cash-bank-top-button-1"
                onClick={() => setCreateAdjustBalance(true)}
              >
                <i class="fa-solid fa-plus-minus"></i> Add/Reduce Money
              </div>
              <div
                className="app-cash-bank-top-button-1"
                onClick={() => setCreateTransferBalance(true)}
              >
                <i class="fa-solid fa-right-left"></i> Transfer Money
              </div>

              <div className="app-cash-bank-button-add-account">
                <div onClick={() => setCreateBankAccount(true)}>
                  + Add New Account
                </div>
                <div className="app-cash-bank-button-add-account-drop">
                  <i className="fa-solid fa-angle-down"></i>
                  <div className="app-cash-bank-button-add-account-drop-list">
                    <div onClick={() => setCreateAdjustBalance(true)}>
                      <i class="fa-solid fa-plus-minus"></i> Add/Reduce Money
                    </div>
                    <div onClick={() => setCreateTransferBalance(true)}>
                      <i class="fa-solid fa-right-left"></i> Transfer Money
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="app-cash-bank-top-button-2"
                onClick={() => setCreateBankAccount(true)}
              >
                + Add New Account
              </div> */}
            </div>
          </div>

          <div className="app-cash-bank-content">

            <div className="app-cash-bank-content-menu">
              <div className="app-cash-bank-content-menu-col">
                <div>
                  <div>Total Balance</div>
                  <div>
                    ₹ {new Intl.NumberFormat("en-IN").format(totalBalance)}
                  </div>
                </div>
              </div>

              <div className="app-cash-bank-content-menu-heading">Cash</div>
              <div className="app-cash-bank-content-menu-col">
                <div
                  onClick={() => setViewType("Cash")}
                  className={
                    viewType === "Cash"
                      ? "app-cash-bank-content-menu-col-active"
                      : ""
                  }
                >
                  <div>Cash in hand</div>
                  <div>₹ {formatNumber(totalCash) * 1}</div>
                </div>
              </div>

              <div className="app-cash-bank-content-menu-heading">
                Bank Accounts
              </div>
              <div className="app-cash-bank-content-menu-col">
                <div
                  onClick={() => setViewType("Unlinked")}
                  className={
                    viewType === "Unlinked"
                      ? "app-cash-bank-content-menu-col-active"
                      : ""
                  }
                >
                  <div>Unlinked Transactions</div>
                  <div>₹ {formatNumber(totalUnlinkedBalance)}</div>
                </div>
              </div>

              <div className="app-cash-bank-content-menu-col">
                {businessData.bankData.map((e) => (
                  <div
                    onClick={() => {
                      setViewType(e._id);
                      setBankId(e._id);
                    }}
                    className={
                      viewType === e._id
                        ? "app-cash-bank-content-menu-col-active"
                        : ""
                    }
                  >
                    <div>{e.accountName}</div>
                    <div>₹ {formatNumber(totalBankBalance(e._id))}</div>
                  </div>
                ))}
              </div>
            </div>



            

            <div className="app-cash-bank-content-main">
              {/* ================================================================================================================= */}

              
{/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* mobile view ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}

                    <div className="app-cash-bank-content-small-total">
                      <div>Total Amount</div>
                      <span>₹ {new Intl.NumberFormat("en-IN").format(totalBalance)}</span>
                    </div>

                    <div className="app-cash-bank-content-small-top">
                      <div className="app-cash-bank-content-small-top-name">
                        <select onChange={ (e) => {setViewType(e.target.value); setBankId(e.target.value)} } name="" id="">
                          <option value="Cash">Cash And Bank</option>
                          <option value="Unlinked">Unlinked Transactions</option>
                          {
                            businessData.bankData.map( e =>(
                              <option value={e._id}>{e.accountName}</option>

                            ))
                          }
                        </select>
                         
                        <i className="fa-solid fa-angle-down"></i>
                      </div>
                      <div className="app-cash-bank-content-small-top-amount">
                        {
                          viewType === "Cash"?
                          <>₹ {formatNumber(totalCash) * 1}</>
                          :viewType === "Unlinked"?
                          <>₹ {formatNumber(totalUnlinkedBalance)}</>
                          :
                          <>
                          {
                            ViewBankDetails?
                            <div onClick={()=> setViewBankDetails(false)}>Hide Details <i className="fa-solid fa-angle-up"></i> </div>
                            :
                          <span onClick={()=> setViewBankDetails(true)}>view Details <i className="fa-solid fa-angle-down"></i> </span>
                          }
                          </>
                        }
                      </div>
                    </div>
{ ViewBankDetails ?
<>
{
  viewType !== "Cash" && 
  viewType !== "Unlinked"? 
  <div className="app-cash-bank-content-small-bank-details">

                    <div className="app-cash-bank-content-bank-details">
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>Balance:</div>
                            <span>₹ {formatNumber(totalBankBalance(bankId))}</span>
                          </div>

                        {bankData && bankData.accountHolderName ? (
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>Account Holder's Name:</div>
                            <span>{bankData.accountHolderName}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {bankData && bankData.accountName ? (
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>Account Name:</div>
                            <span>{bankData.accountName}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {bankData && bankData.accountNumber ? (
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>Account Number:</div>
                            <span>{bankData.accountNumber}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {bankData && bankData.IFSCcode ? (
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>IFSC Code:</div>
                            <span>{bankData.IFSCcode}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {bankData && bankData.bankName ? (
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>Bank & Branch:</div>
                            <span>{bankData.bankName}</span>
                          </div>
                        ) : (
                          ""
                        )}

                        {bankData && bankData.upiId ? (
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>UPI ID:</div>
                            <span>{bankData.upiId}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      </div>
                      :""}
                      </>
                      :""}



                    <div className="app-cash-bank-content-small-head">
                      <div className="app-cash-bank-content-small-head-heading">
                        Transactions
                      </div>
                      <div className="app-cash-bank-content-small-head-filter">
                        <i class="fa-regular fa-calendar"></i>
                        <select
                          value={dataViewFilter}
                          onChange={(e) => setDataViewFilter(e.target.value)}
                        >
                          <option value="today">Today</option>
                          <option value="week">This Week</option>
                          <option value="month">This Month</option>
                          <option value="year">This Year</option>
                          <option value="all">All</option>
                        </select>
                      </div>
                    </div>                  
                    
                    


              
              

              {viewType === "Cash" ? (
                <>
                  <div className="app-cash-bank-content-data">
                    <div className="app-cash-bank-content-heading">
                      Transactions
                    </div>
                    <div className="app-cash-bank-content-options">
                      <div className="app-cash-bank-content-options-filter">
                        <i class="fa-regular fa-calendar"></i>
                        <select
                          value={dataViewFilter}
                          onChange={(e) => setDataViewFilter(e.target.value)}
                        >
                          <option value="today">Today</option>
                          <option value="week">This Week</option>
                          <option value="month">This Month</option>
                          <option value="year">This Year</option>
                          <option value="all">All</option>
                        </select>
                      </div>
                      {/* <div className="app-cash-bank-content-options-button">
                        Download Statement <i class="fa-solid fa-download"></i>
                      </div> */}
                    </div>

                    <div className="app-cash-bank-content-table">
                      <div className="app-cash-bank-content-table-head">
                        <div>Date</div>
                        <div>Type</div>
                        <div>Txn No</div>
                        <div>Name</div>
                        <div>Mode</div>
                        <div>Paid</div>
                        <div>Received</div>
                        <div>Balance</div>
                      </div>

                      <div className="app-cash-bank-content-table-data">
                        {filteredCashData.map((e) => {
                          return (
                            <div
                              onClick={(a) => handleAdjustBalance(e)}
                              className={
                                e.transactionIn ||
                                e.transferFrom ||
                                e.transferTo
                                  ? "app-cash-bank-content-table-body app-cash-bank-content-table-body-hover"
                                  : "app-cash-bank-content-table-body"
                              }
                            >
                              <div>
                                {new Date(e.invoiceDate)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(",", "")}
                              </div>
                              <div>
                                {e.transactionType && e.transactionType}{" "}
                                {e.transferFrom ? "Transfer" : ""}
                              </div>
                              <div>{e.invoiceNumber && e.invoiceNumber}</div>
                              <div>
                                {e.name
                                  ? e.name
                                  : e.invoicePartyData
                                  ? e.invoicePartyData.partyName
                                  : ""}
                              </div>
                              <div>{e.paymentType && e.paymentType}</div>

                              <div>
                                {e.transactionType === "purchase Invoice" ||
                                e.transactionType === "sales Return" ||
                                e.transactionType === "credit Note" ||
                                e.transactionType === "Reduce Money" ||
                                e.transferFrom === "Cash" ||
                                e.transactionDate ? (
                                  <>₹ {formatNumber(e.receivedAmount)}</>
                                ) : (
                                  "-"
                                )}
                              </div>

                              <div>
                                {e.transactionType === "sales Invoice" ||
                                e.transactionType === "purchase Return" ||
                                e.transactionType === "debit Note" ||
                                e.transactionType === "Add Money" ||
                                e.transferTo === "Cash" ? (
                                  <>₹ {formatNumber(e.receivedAmount)}</>
                                ) : (
                                  "-"
                                )}
                              </div>

                              <div>₹ {formatNumber(e.balanceAmount) * 1}</div>
                            </div>
                          );
                        })}
                      </div>

                      {filteredCashData.length <= 0 ? (
                        <div className="app-cash-bank-content-table-empty">
                          No Transactions
                        </div>
                      ) : (
                        // <div className="app-cash-bank-content-table-end">
                        //   <div></div>
                        //   <div></div>
                        //   <div></div>
                        //   <div></div>
                        //   <div>Total</div>
                        //   <div>
                        //     ₹{" "}
                        //     {formatNumber(
                        //       filteredCashData
                        //         .filter(
                        //           (e) =>
                        //             e.transactionType === "purchase Invoice" ||
                        //             e.transactionType === "sales Return" ||
                        //             e.transactionType === "credit Note"
                        //         )
                        //         .reduce((sum, e) => sum + e.receivedAmount, 0)
                        //     )}
                        //   </div>
                        //   <div>
                        //     ₹{" "}
                        //     {formatNumber(
                        //       filteredCashData
                        //         .filter(
                        //           (e) =>
                        //             e.transactionType === "sales Invoice" ||
                        //             e.transactionType === "purchase Return" ||
                        //             e.transactionType === "debit Note"
                        //         )
                        //         .reduce((sum, e) => sum + e.receivedAmount, 0)
                        //     )}
                        //   </div>
                        //   <div></div>
                        // </div>
                        ""
                      )}
                    </div>
                  </div>


                  



                  
{/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* mobile view ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}




                    <div className="app-cash-bank-content-small-data">

{

filteredCashData.length > 0 ? 
  
  filteredCashData.map( e => 
    
                      <div className="app-cash-bank-content-small-data-list">
                        <div className="app-cash-bank-content-small-data-list-col-1">
                          <div className="app-cash-bank-content-small-data-list-col-1-heading">
                          {e.transactionType && e.transactionType}{" "}
                          {e.transferFrom ? "Transfer" : ""}
                           <span>
                           {e.invoiceNumber && e.invoiceNumber?
                           <># {e.invoiceNumber}</>
                           :""}
                           </span>
                           </div>
                          

                          {
                            ViewData  === e._id?
                          <div className="app-cash-bank-content-small-data-list-col-1-view">

                            <div className="app-cash-bank-content-small-data-list-row">₹ {new Intl.NumberFormat('en-IN').format(formatNumber(e.balanceAmount) * 1)}</div>

                            <div className="app-cash-bank-content-small-data-list-row">
                              <div>Date :</div>
                              <span>{new Date(e.invoiceDate)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(",", "")}</span>
                            </div>
                            
                            {e.name ? 
                            
                            <div className="app-cash-bank-content-small-data-list-row"><div>Name :</div><span>{e.name}</span></div>
                                  : e.invoicePartyData?
                                   
                            <div className="app-cash-bank-content-small-data-list-row"><div>Name :</div><span>{e.invoicePartyData.partyName}</span></div>
                                  : ""}
                            
                            
                            {e.transactionType === "purchase Invoice" ||
                                e.transactionType === "sales Return" ||
                                e.transactionType === "credit Note" ||
                                e.transactionType === "Reduce Money" ||
                                e.transferFrom === "Cash" ||
                                e.transactionDate ? (
                                  
                                  <div className="app-cash-bank-content-small-data-list-row">
                              <div>Paid :</div>
                              <span>₹ {formatNumber(e.receivedAmount)}</span>
                            </div>
                              
                            ) : (
                              ""
                            )}
                            
                            {e.transactionType === "sales Invoice" ||
                                e.transactionType === "purchase Return" ||
                                e.transactionType === "debit Note" ||
                                e.transactionType === "Add Money" ||
                                e.transferTo === "Cash" ? (
                            
                            <div className="app-cash-bank-content-small-data-list-row">
                              <div>Received :</div>
                              <span>₹ {formatNumber(e.receivedAmount)}</span>
                            </div>
                                  
                                ) : (
                                  ""
                                )}
                            

                          </div>

                          :
                          <div className="app-cash-bank-content-small-data-list-col-1-option">{new Date(e.invoiceDate)
                            .toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })
                            .replace(",", "")} <span onClick={ () =>{ setViewData(e._id); } }>view<i className="fa-solid fa-angle-down"></i></span></div>
                          }

                          
                        </div>

                        <div className="app-cash-bank-content-small-data-list-col-2">
                      {
                        ViewData === e._id?

                        <div className="app-cash-bank-content-small-data-list-col-2-view">
                          {
                            e.transactionIn ||
                            e.transferFrom ||
                            e.transferTo
                            ?
                            <div onClick={(a) => handleAdjustBalance(e)} className="app-cash-bank-content-small-data-list-col-2-botton"><i class="fa-solid fa-pen"></i>edit</div>
                            :
                            <div></div>
                          }
                          <div className="app-cash-bank-content-small-data-list-col-2-option" onClick={ () =>{ setViewData(false); } }>close <i className="fa-solid fa-angle-up"></i></div>
                        </div>
                      :
                          <div className="app-cash-bank-content-small-data-list-balance">₹ {new Intl.NumberFormat('en-IN').format(formatNumber(e.balanceAmount) * 1)}</div>
                      }

                        </div>
                      </div>
  )

  :
    <div className="app-cash-bank-content-table-empty">
      No Transactions
    </div>

}



                    </div>

                    
                    


                </>


              ) : viewType === "Unlinked" ? (
                <>
                  <div className="app-cash-bank-content-data">
                    <div className="app-cash-bank-content-heading">
                      Transactions
                    </div>

                    <div className="app-cash-bank-content-options">
                      <div className="app-cash-bank-content-options-filter">
                        <i class="fa-regular fa-calendar"></i>
                        <select
                          value={dataViewFilter}
                          onChange={(e) => setDataViewFilter(e.target.value)}
                        >
                          <option value="today">Today</option>
                          <option value="week">This Week</option>
                          <option value="month">This Month</option>
                          <option value="year">This Year</option>
                          <option value="all">All</option>
                        </select>
                      </div>
                      {/* <div className="app-cash-bank-content-options-button">
                        Download Statement <i class="fa-solid fa-download"></i>
                      </div> */}
                    </div>

                    <div className="app-cash-bank-content-table">
                      <div className="app-cash-bank-content-table-head">
                        <div>Date</div>
                        <div>Type</div>
                        <div>Txn No</div>
                        <div>Name</div>
                        <div>Mode</div>
                        <div>Paid</div>
                        <div>Received</div>
                        <div>Balance</div>
                      </div>

                      <div className="app-cash-bank-content-table-data">
                        {filteredUnlinkedData.map((e) => {
                          return (
                            <div className="app-cash-bank-content-table-body">
                              <div>
                                {new Date(e.invoiceDate)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(",", "")}
                              </div>
                              <div>
                                {e.transactionType && e.transactionType}
                              </div>
                              <div>{e.invoiceNumber && e.invoiceNumber}</div>
                              <div>
                                {e.name
                                  ? e.name
                                  : e.invoicePartyData
                                  ? e.invoicePartyData.partyName
                                  : ""}
                              </div>
                              <div>{e.paymentType && e.paymentType}</div>

                              <div>
                                {e.transactionType === "purchase Invoice" ||
                                e.transactionType === "sales Return" ||
                                e.transactionType === "credit Note" ||
                                e.transactionDate ? (
                                  <>₹ {formatNumber(e.receivedAmount)}</>
                                ) : (
                                  "-"
                                )}
                              </div>

                              <div>
                                {e.transactionType === "sales Invoice" ||
                                e.transactionType === "purchase Return" ||
                                e.transactionType === "debit Note" ? (
                                  <>₹ {formatNumber(e.receivedAmount)}</>
                                ) : (
                                  "-"
                                )}
                              </div>

                              <div>₹ {formatNumber(e.balanceAmount)}</div>
                            </div>
                          );
                        })}
                      </div>

                      {filteredUnlinkedData.length <= 0 ? (
                        <div className="app-cash-bank-content-table-empty">
                          No Transactions
                        </div>
                      ) : (
                        // <div className="app-cash-bank-content-table-end">
                        //   <div></div>
                        //   <div></div>
                        //   <div></div>
                        //   <div></div>
                        //   <div>Total</div>
                        //   <div>
                        //     ₹{" "}
                        //     {formatNumber(
                        //       filteredUnlinkedData
                        //         .filter(
                        //           (e) =>
                        //             e.transactionType === "purchase Invoice" ||
                        //             e.transactionType === "sales Return" ||
                        //             e.transactionType === "credit Note"
                        //         )
                        //         .reduce((sum, e) => sum + e.receivedAmount, 0)
                        //     )}
                        //   </div>
                        //   <div>
                        //     ₹{" "}
                        //     {formatNumber(
                        //       filteredUnlinkedData
                        //         .filter(
                        //           (e) =>
                        //             e.transactionType === "sales Invoice" ||
                        //             e.transactionType === "purchase Return" ||
                        //             e.transactionType === "debit Note"
                        //         )
                        //         .reduce((sum, e) => sum + e.receivedAmount, 0)
                        //     )}
                        //   </div>
                        //   <div></div>
                        // </div>
                        ""
                      )}
                    </div>
                  </div>


                  
                  
{/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* mobile view ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}




<div className="app-cash-bank-content-small-data">

{

filteredUnlinkedData.length > 0 ? 
  
filteredUnlinkedData.map( e => 
    
                      <div className="app-cash-bank-content-small-data-list">
                        <div className="app-cash-bank-content-small-data-list-col-1">
                          <div className="app-cash-bank-content-small-data-list-col-1-heading">
                          {e.transactionType && e.transactionType}{" "}
                          {e.transferFrom ? "Transfer" : ""}
                           <span>
                           {e.invoiceNumber && e.invoiceNumber?
                           <># {e.invoiceNumber}</>
                           :""}
                           </span>
                           </div>
                          

                          {
                            ViewData  === e._id?
                          <div className="app-cash-bank-content-small-data-list-col-1-view">

                            <div className="app-cash-bank-content-small-data-list-row">₹ {new Intl.NumberFormat('en-IN').format(formatNumber(e.balanceAmount) * 1)}</div>

                            <div className="app-cash-bank-content-small-data-list-row">
                              <div>Date :</div>
                              <span>{new Date(e.invoiceDate)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(",", "")}</span>
                            </div>
                            
                            {e.name ? 
                            
                            <div className="app-cash-bank-content-small-data-list-row"><div>Name :</div><span>{e.name}</span></div>
                                  : e.invoicePartyData?
                                   
                            <div className="app-cash-bank-content-small-data-list-row"><div>Name :</div><span>{e.invoicePartyData.partyName}</span></div>
                                  : ""}
                            
                            
                            {e.transactionType === "purchase Invoice" ||
                                e.transactionType === "sales Return" ||
                                e.transactionType === "credit Note" ||
                                e.transactionType === "Reduce Money" ||
                                e.transferFrom === "Cash" ||
                                e.transactionDate ? (
                                  
                                  <div className="app-cash-bank-content-small-data-list-row">
                              <div>Paid :</div>
                              <span>₹ {formatNumber(e.receivedAmount)}</span>
                            </div>
                              
                            ) : (
                              ""
                            )}
                            
                            {e.transactionType === "sales Invoice" ||
                                e.transactionType === "purchase Return" ||
                                e.transactionType === "debit Note" ||
                                e.transactionType === "Add Money" ||
                                e.transferTo === "Cash" ? (
                            
                            <div className="app-cash-bank-content-small-data-list-row">
                              <div>Received :</div>
                              <span>₹ {formatNumber(e.receivedAmount)}</span>
                            </div>
                                  
                                ) : (
                                  ""
                                )}
                            

                          </div>

                          :
                          <div className="app-cash-bank-content-small-data-list-col-1-option">{new Date(e.invoiceDate)
                            .toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })
                            .replace(",", "")} <span onClick={ () =>{ setViewData(e._id); } }>view<i className="fa-solid fa-angle-down"></i></span></div>
                          }

                          
                        </div>

                        <div className="app-cash-bank-content-small-data-list-col-2">
                      {
                        ViewData === e._id?

                        <div className="app-cash-bank-content-small-data-list-col-2-view">
                          {
                            e.transactionIn ||
                            e.transferFrom ||
                            e.transferTo
                            ?
                            <div onClick={(a) => handleAdjustBalance(e)} className="app-cash-bank-content-small-data-list-col-2-botton"><i class="fa-solid fa-pen"></i>edit</div>
                            :
                            <div></div>
                          }
                          <div className="app-cash-bank-content-small-data-list-col-2-option" onClick={ () =>{ setViewData(false); } }>close <i className="fa-solid fa-angle-up"></i></div>
                        </div>
                      :
                          <div className="app-cash-bank-content-small-data-list-balance">₹ {new Intl.NumberFormat('en-IN').format(formatNumber(e.balanceAmount) * 1)}</div>
                      }

                        </div>
                      </div>
  )

  :
    <div className="app-cash-bank-content-table-empty">
      No Transactions
    </div>

}



                    </div>


                  
                </>
              ) : viewType === bankId ? (
                <>
                  <div className="app-cash-bank-content-data">
                    <div className="app-cash-bank-content-heading">
                      Transactions
                    </div>

                    <div className="app-cash-bank-content-bank-data">
                      <div className="app-cash-bank-content-bank-details">
                        {bankData && bankData.accountHolderName ? (
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>Account Holder's Name:</div>
                            <span>{bankData.accountHolderName}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {bankData && bankData.accountName ? (
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>Account Name:</div>
                            <span>{bankData.accountName}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {bankData && bankData.accountNumber ? (
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>Account Number:</div>
                            <span>{bankData.accountNumber}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {bankData && bankData.IFSCcode ? (
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>IFSC Code:</div>
                            <span>{bankData.IFSCcode}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {bankData && bankData.bankName ? (
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>Bank & Branch:</div>
                            <span>{bankData.bankName}</span>
                          </div>
                        ) : (
                          ""
                        )}

                        {bankData && bankData.upiId ? (
                          <div className="app-cash-bank-content-bank-details-col">
                            <div>UPI ID:</div>
                            <span>{bankData.upiId}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="app-cash-bank-content-bank-buttons">
                        <div
                          className="app-cash-bank-content-bank-buttons-update"
                          onClick={() => setUpdateBankAccount(true)}
                        >
                          Update Bank Details <i class="fa-solid fa-pen"></i>
                        </div>
                        {/* <div className="app-cash-bank-content-bank-buttons-update">Delete Bank <i class="fa-solid fa-trash"></i></div> */}
                      </div>
                    </div>

                    <div className="app-cash-bank-content-options">
                      <div className="app-cash-bank-content-options-filter">
                        <i class="fa-regular fa-calendar"></i>
                        <select
                          value={dataViewFilter}
                          onChange={(e) => setDataViewFilter(e.target.value)}
                        >
                          <option value="today">Today</option>
                          <option value="week">This Week</option>
                          <option value="month">This Month</option>
                          <option value="year">This Year</option>
                          <option value="all">All</option>
                        </select>
                      </div>
                      {/* <div className="app-cash-bank-content-options-button">
                        Download Statement <i class="fa-solid fa-download"></i>
                      </div> */}
                    </div>

                    <div className="app-cash-bank-content-table">
                      <div className="app-cash-bank-content-table-head">
                        <div>Date</div>
                        <div>Type</div>
                        <div>Txn No</div>
                        <div>Name</div>
                        <div>Mode</div>
                        <div>Paid</div>
                        <div>Received</div>
                        <div>Balance</div>
                      </div>

                      <div className="app-cash-bank-content-table-data app-cash-bank-content-table-data-bank">
                        {filteredBankData.map((e) => {
                          return (
                            <div
                              onClick={(a) => handleAdjustBalance(e)}
                              className={
                                e.transactionIn ||
                                e.transferFrom ||
                                e.transferTo
                                  ? "app-cash-bank-content-table-body app-cash-bank-content-table-body-hover"
                                  : "app-cash-bank-content-table-body"
                              }
                            >
                              <div>
                                {new Date(e.invoiceDate)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(",", "")}
                              </div>
                              <div>
                                {e.transactionType && e.transactionType}{" "}
                                {e.transferFrom ? "Transfer" : ""}
                              </div>
                              <div>{e.invoiceNumber && e.invoiceNumber}</div>
                              <div>
                                {e.name
                                  ? e.name
                                  : e.invoicePartyData
                                  ? e.invoicePartyData.partyName
                                  : ""}
                              </div>
                              <div>{e.paymentType && e.paymentType}</div>

                              <div>
                                {e.transactionType === "purchase Invoice" ||
                                e.transactionType === "sales Return" ||
                                e.transactionType === "credit Note" ||
                                e.transactionType === "Reduce Money" ||
                                e.transferFrom === bankId ||
                                e.transactionDate ? (
                                  <>₹ {formatNumber(e.receivedAmount)}</>
                                ) : (
                                  "-"
                                )}
                              </div>

                              <div>
                                {e.transactionType === "sales Invoice" ||
                                e.transactionType === "purchase Return" ||
                                e.transactionType === "debit Note" ||
                                e.transactionType === "Add Money" ||
                                e.transferTo === bankId ? (
                                  <>₹ {formatNumber(e.receivedAmount)}</>
                                ) : (
                                  "-"
                                )}
                              </div>

                              <div>₹ {formatNumber(e.balanceAmount)}</div>
                            </div>
                          );
                        })}
                      </div>

                      {filteredBankData.length <= 0 ? (
                        <div className="app-cash-bank-content-table-empty">
                          No Transactions
                        </div>
                      ) : (
                        <div className="app-cash-bank-content-table-end">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div>Total</div>
                          <div>
                            ₹{" "}
                            {formatNumber(
                              filteredBankData
                                .filter(
                                  (e) =>
                                    e.transactionType === "purchase Invoice" ||
                                    e.transactionType === "sales Return" ||
                                    e.transactionType === "credit Note"
                                )
                                .reduce((sum, e) => sum + e.receivedAmount, 0)
                            )}
                          </div>
                          <div>
                            ₹{" "}
                            {formatNumber(
                              filteredBankData
                                .filter(
                                  (e) =>
                                    e.transactionType === "sales Invoice" ||
                                    e.transactionType === "purchase Return" ||
                                    e.transactionType === "debit Note"
                                )
                                .reduce((sum, e) => sum + e.receivedAmount, 0)
                            )}
                          </div>
                          <div></div>
                        </div>
                      )}
                    </div>
                  </div>

                  
                  
{/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* mobile view ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}




<div className="app-cash-bank-content-small-data">

{

filteredBankData.length > 0 ? 
  
filteredBankData.map( e => 
    
                      <div className="app-cash-bank-content-small-data-list">
                        <div className="app-cash-bank-content-small-data-list-col-1">
                          <div className="app-cash-bank-content-small-data-list-col-1-heading">
                          {e.transactionType && e.transactionType}{" "}
                          {e.transferFrom ? "Transfer" : ""}
                           <span>
                           {e.invoiceNumber && e.invoiceNumber?
                           <># {e.invoiceNumber}</>
                           :""}
                           </span>
                           </div>
                          

                          {
                            ViewData  === e._id?
                          <div className="app-cash-bank-content-small-data-list-col-1-view">

                            <div className="app-cash-bank-content-small-data-list-row">₹ {new Intl.NumberFormat('en-IN').format(formatNumber(e.balanceAmount) * 1)}</div>

                            <div className="app-cash-bank-content-small-data-list-row">
                              <div>Date :</div>
                              <span>{new Date(e.invoiceDate)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(",", "")}</span>
                            </div>
                            
                            {e.name ? 
                            
                            <div className="app-cash-bank-content-small-data-list-row"><div>Name :</div><span>{e.name}</span></div>
                                  : e.invoicePartyData?
                                   
                            <div className="app-cash-bank-content-small-data-list-row"><div>Name :</div><span>{e.invoicePartyData.partyName}</span></div>
                                  : ""}
                            
                            
                            {e.transactionType === "purchase Invoice" ||
                                e.transactionType === "sales Return" ||
                                e.transactionType === "credit Note" ||
                                e.transactionType === "Reduce Money" ||
                                e.transferFrom === "Cash" ||
                                e.transactionDate ? (
                                  
                                  <div className="app-cash-bank-content-small-data-list-row">
                              <div>Paid :</div>
                              <span>₹ {formatNumber(e.receivedAmount)}</span>
                            </div>
                              
                            ) : (
                              ""
                            )}
                            
                            {e.transactionType === "sales Invoice" ||
                                e.transactionType === "purchase Return" ||
                                e.transactionType === "debit Note" ||
                                e.transactionType === "Add Money" ||
                                e.transferTo === "Cash" ? (
                            
                            <div className="app-cash-bank-content-small-data-list-row">
                              <div>Received :</div>
                              <span>₹ {formatNumber(e.receivedAmount)}</span>
                            </div>
                                  
                                ) : (
                                  ""
                                )}
                            

                          </div>

                          :
                          <div className="app-cash-bank-content-small-data-list-col-1-option">{new Date(e.invoiceDate)
                            .toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })
                            .replace(",", "")} <span onClick={ () =>{ setViewData(e._id); } }>view<i className="fa-solid fa-angle-down"></i></span></div>
                          }

                          
                        </div>

                        <div className="app-cash-bank-content-small-data-list-col-2">
                      {
                        ViewData === e._id?

                        <div className="app-cash-bank-content-small-data-list-col-2-view">
                          {
                            e.transactionIn ||
                            e.transferFrom ||
                            e.transferTo
                            ?
                            <div onClick={(a) => handleAdjustBalance(e)} className="app-cash-bank-content-small-data-list-col-2-botton"><i class="fa-solid fa-pen"></i>edit</div>
                            :
                            <div></div>
                          }
                          <div className="app-cash-bank-content-small-data-list-col-2-option" onClick={ () =>{ setViewData(false); } }>close <i className="fa-solid fa-angle-up"></i></div>
                        </div>
                      :
                          <div className="app-cash-bank-content-small-data-list-balance">₹ {new Intl.NumberFormat('en-IN').format(formatNumber(e.balanceAmount) * 1)}</div>
                      }

                        </div>
                      </div>
  )

  :
    <div className="app-cash-bank-content-table-empty">
      No Transactions
    </div>

}



                    </div>
                  
                  
                </>
              ) : (
                ""
              )}
              {/* ================================================================================================================= */}
              {/* ================================================================================================================= */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashBankPageComponent;
