import React from "react";
import { Link } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const SalesReportGraph = ({ salesInvoiceData, businessData }) => {
  console.log("salesInvoiceData is :", salesInvoiceData);


  function getDayOfWeek(dateInput) {
    const date = new Date(dateInput);  // Create Date object from the input
    const dayOfWeek = date.getDay();   // Get day of the week (0 for Sunday, 1 for Monday, etc.)
    const daysOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
    return daysOfWeek[dayOfWeek]; // Return the full name of the day
  }
  
  
  // Get today's date
  const today = new Date();

  // Create an array of the last 7 days' dates
  const last7Days = [];
  for (let i = 0; i < 7; i++) {
    const day = new Date(today);
    day.setDate(today.getDate() - i);
    last7Days.push(day.toISOString().split("T")[0]); // Get date in yyyy-mm-dd format
  }

  // Sum values for each of the last 7 days
  const filteredData = 
    last7Days.map((day) => {
      // Filter and sum the values for the current day
      const totalValue = salesInvoiceData
        .filter((item) => item.invoiceDate === day)
        .reduce((acc, item) => acc + item.invoiceTotalAmount, 0); // Accumulate values for the day
      return { day: getDayOfWeek(day), date: day, Sales: totalValue };
    });

  console.log("filterData is:", filteredData);

  return (
    <div>
      <div className="sales-report-graph">

      <div className="sales-report-graph-main">

        <Link to="reports" className="sales-report-graph-heading"><span>Sales Report - </span> { new Date( filteredData[filteredData.length-1].date).toDateString()} <span>to</span> { new Date( filteredData[0].date).toDateString()} </Link>

        <div className="sales-report-graph-content">
            <div style={{ width: '100%', height: "100%" }}>
                  <ResponsiveContainer>
                    <LineChart data={filteredData.reverse()}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="day" />
                      <YAxis />
                      <Tooltip />
                      {/* <Legend /> */}
                      {/* First line (UV) */}
                      {/* <Line type="monotone" dataKey="Sales" stroke="#174555" /> */}
                      <Line type="" dataKey="Sales" stroke="#174555" strokeWidth={1.5} />
                      {/* Second line (PV) */}
                      {/* <Line type="" dataKey="pv" stroke="#82ca9d" /> */}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
            
        </div>

      </div>

      </div>
    </div>
  );
};

export default SalesReportGraph;
