import React from "react";
import Slider from "react-slick";

import img1 from "../../../media/images/website/slider-img1.webp";
import img2 from "../../../media/images/website/slider-img2.webp";

import Lifestyle from "../../../media/images/website/LifestyleFashion.jpg";
import Retail from "../../../media/images/website/RetailStores.jpg";
import Supermarket from "../../../media/images/website/SupermarketChains.jpg";
import Freelance from "../../../media/images/website/FreelanceProfessionals.jpg";
import Medical from "../../../media/images/website/MedicalShopsPharmacies.jpg";
import Startups from "../../../media/images/website/StartupsSMEs.jpg";
import Construction from "../../../media/images/website/ConstructionBusiness.jpg";



const IndustrySliderComponent = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div>
      <div className="industry-slider">
        <div className="industry-slider-main">
          <div className="industry-slider-heading">Built for your Industry</div>
          <div className="industry-slider-head-text">
            Invoician GST billing software and accounting tools have numerous
            features. Collectively, they help businesses across industries.
            Invoician app is built to serve every industry. It is customisable to
            meet the requirement of your business as per your unique business
            requirements. Some of the most popular industries use the Invoician GST
            accounting software. It helps manage all of their business
            requirements.
          </div>

          <div className="industry-slider-content">
            {/* slider start ------------------------------------------------------------------------------------------------------------------ */}

            <div className="slider-container">
              <Slider {...settings}>
               
                

                {/* slide - 1 ------------------------------------------------------------------------------------------------------------------ */}

                <div>
                  <div className="industry-slider-slide">
                    <div className="industry-slider-slide-image">
                      <img src={Lifestyle} alt="" />
                    </div>
                    <div className="industry-slider-slide-content">
                      <div className="industry-slider-slide-heading">
                      Lifestyle and Fashion
                      </div>
                      <div className="industry-slider-slide-text">
                      Invoician is a versatile solution for businesses in the lifestyle category, including apparel shops, footwear stores, and others. It ensures that the latest trends are always available at your customers’ doorstep by leveraging sales data and analytical reports through its GST accounting software.
                      <br /><br />
With Invoician, you can identify and phase out outdated inventory that doesn’t sell, enhancing operational efficiency. Additionally, the platform allows you to minimize losses by introducing attractive discounts to clear unsold stock. This approach frees up space for fresh, in-demand products while maintaining profitability.
                      <br /><br />
Invoician’s GST billing software empowers you to make informed business decisions, streamline your operations, and stay ahead in the ever-changing world of lifestyle and fashion.

                        
                      </div>
                    </div>
                  </div>
                </div>
                

                {/* slide - 2 ------------------------------------------------------------------------------------------------------------------ */}

                <div>
                  <div className="industry-slider-slide">
                    <div className="industry-slider-slide-image">
                      <img src={Retail} alt="" />
                    </div>
                    <div className="industry-slider-slide-content">
                      <div className="industry-slider-slide-heading">
                      Retail Stores
                      </div>
                      <div className="industry-slider-slide-text">
                      Invoician billing and invoicing software enables retail businesses to create professional customer invoices effortlessly. It allows the inclusion of essential business information directly in the invoice, streamlining the billing process for retail business owners.
                      <br /><br />
With Invoician’s GST billing app, small business owners can efficiently track their inventory, including item prices, discounts, taxes, and more, to ensure faster customer billing. Additionally, the software supports creating custom invoices that enhance professionalism and brand identity.
                      <br /><br />
Retailers can also include multiple payment options in their invoices, making the checkout process smoother and more convenient for their customers. Invoician empowers retail stores to maintain efficiency, professionalism, and customer satisfaction.

                        
                      </div>
                    </div>
                  </div>
                </div>
                

                {/* slide - 3 ------------------------------------------------------------------------------------------------------------------ */}

                <div>
                  <div className="industry-slider-slide">
                    <div className="industry-slider-slide-image">
                      <img src={Supermarket} alt="" />
                    </div>
                    <div className="industry-slider-slide-content">
                      <div className="industry-slider-slide-heading">
                      Supermarket Chains
                      </div>
                      <div className="industry-slider-slide-text">
                      Supermarkets deal with a wide variety of products and cater to multiple customers simultaneously throughout their working hours. To manage this efficiently, they need the best invoicing software for seamless and error-free invoice creation.
                      <br /><br />
Invoician billing app is an ideal solution for supermarket chains. It comes equipped with intelligent inventory management features, helping track all items accurately while maintaining detailed sales records.
                      <br /><br />
Additionally, Invoician simplifies bookkeeping and enables the quick generation of GST reports, making it easier for supermarkets to stay organized, compliant, and efficient in their operations.

                        
                      </div>
                    </div>
                  </div>
                </div>
                

                {/* slide - 4 ------------------------------------------------------------------------------------------------------------------ */}

                <div>
                  <div className="industry-slider-slide">
                    <div className="industry-slider-slide-image">
                      <img src={Freelance} alt="" />
                    </div>
                    <div className="industry-slider-slide-content">
                      <div className="industry-slider-slide-heading">
                      Freelance Professionals
                      </div>
                      <div className="industry-slider-slide-text">
                        
                      Invoician billing software and accounting tools are tailored to meet the unique needs of freelancers. It enables them to create professional quotes and invoices for their clients independently, without requiring a team’s assistance.
                      <br /><br />
With Invoician GST accounting software, freelancers can save costs by using customizable professional templates. The platform ensures a seamless experience with reliable customer support.
                      <br /><br />
Freelancers can easily accept domestic payments through the Invoician app and efficiently monitor outstanding dues using its intuitive business dashboard. Invoician empowers freelancers to manage their finances effortlessly while maintaining a professional image.

                      </div>
                    </div>
                  </div>
                </div>
                

                {/* slide - 5 ------------------------------------------------------------------------------------------------------------------ */}

                <div>
                  <div className="industry-slider-slide">
                    <div className="industry-slider-slide-image">
                      <img src={Medical} alt="" />
                    </div>
                    <div className="industry-slider-slide-content">
                      <div className="industry-slider-slide-heading">
                      Medical Shops and Pharmacies
                      </div>
                      <div className="industry-slider-slide-text">
                      Intelligent billing software is essential for effectively managing inventory in the pharmaceutical industry. Invoician’s billing and inventory management tools ensure pharmacies have up-to-date information on inventory levels, including the quantity of individual items and their expiry dates.
                      <br /><br />
Invoician is an ideal solution for the medical industry, catering to the needs of doctors, pharmacists, and other healthcare professionals. It ensures all essential features are available to maintain a well-stocked pharmacy, so critical medicines are always accessible.
                      <br /><br />
With its comprehensive capabilities, Invoician simplifies the complex operations of medical shops and hospitals, covering every requirement for maintaining a reliable healthcare infrastructure.

                        
                      </div>
                    </div>
                  </div>
                </div>
                

                {/* slide - 6 ------------------------------------------------------------------------------------------------------------------ */}

                <div>
                  <div className="industry-slider-slide">
                    <div className="industry-slider-slide-image">
                      <img src={Startups} alt="" />
                    </div>
                    <div className="industry-slider-slide-content">
                      <div className="industry-slider-slide-heading">
                      Startups and SMEs
                      </div>
                      <div className="industry-slider-slide-text">
                      Startups must focus on growing their business while learning from past experiences. By analyzing data from yearly balance sheets and sales reports, they can make well-informed decisions to drive success.
                      <br /><br />
Invoician accounting software is tailored to meet all accounting requirements of startups and SMEs. Its GST billing app offers the option to set up a digital store, enabling businesses to expand their customer base with ease.
                      <br /><br />
Additionally, Invoician helps SMEs comply with tax laws effortlessly. Businesses can quickly create GST reports within minutes and use them to file returns seamlessly. Invoician is the perfect tool for startups and SMEs aiming to achieve growth and operational excellence.

                        
                      </div>
                    </div>
                  </div>
                </div>
                

                {/* slide - 7 ------------------------------------------------------------------------------------------------------------------ */}

                <div>
                  <div className="industry-slider-slide">
                    <div className="industry-slider-slide-image">
                      <img src={Construction} alt="" />
                    </div>
                    <div className="industry-slider-slide-content">
                      <div className="industry-slider-slide-heading">
                      Construction Business
                      </div>
                      <div className="industry-slider-slide-text">
                      Invoician's GST billing app is an ideal solution for the construction industry, helping you track all necessary raw materials efficiently. It simplifies inventory management by enabling pre-orders and sending alerts when inventory levels are low, ensuring timely action from management.
                      <br /><br />
Invoician business billing software is designed for construction and general contractors, offering a perfect tool to monitor construction materials and streamline operations. By making processes seamless, it allows businesses to focus on growth and make informed investments.
                      <br /><br />
With Invoician, managing the unique requirements of the construction business becomes effortless, enhancing efficiency and productivity.

                        
                      </div>
                    </div>
                  </div>
                </div>
                


              </Slider>
            </div>

            {/* slider end ------------------------------------------------------------------------------------------------------------------ */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustrySliderComponent;
