import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';

// CSS ================================================================================================================
import './App.css';
import './app/css/pages/PageLayout.css'
import './app/css/pages/AppPage.css'
import './app/css/components/form/AppForm.css'
import './app/css/components/form/PopupForm.css'

import './app/css/components/form/invoice/CreateInvoice.css'

import './app/css/components/form/invoice/SaleInvoice.css'
import './app/css/components/form/invoice/SaleInvoiceOne.css'
import './app/css/components/form/invoice/SaleInvoiceTwo.css'
import './app/css/components/form/invoice/SelectForn.css'
import './app/css/components/AppNavbar.css'
import './app/css/components/SettingNavbar.css'
import './app/css/pages/authentication/UserAuthenticationForm.css'
import './app/css/pages/authentication/UserWelcomePage.css'
import './app/css/components/settings/AppBusinessSettingPageComponent.css'
import './app/css/components/settings/AppAccountSettingPageComponent.css'
import './app/css/components/main/AppPackageDurationComponent.css'
import './app/css/form/AddStaffForm.css'
import './app/css/page/AppHomePage.css'

import './app/css/pages/forms/CreateInvoicePage.css'
import './app/css/pages/forms/CreatePopupForm.css'
import './app/css/pages/forms/ViewDataComponent.css'
import './app/css/pages/settings/AppSettingInvoicePage.css'
import './app/css/pages/settings/AppSettingBusinessPage.css'
import './app/css/components/form/invoice/InvoiceExcelTheme.css'
import './app/css/components/form/invoice/InvoiceGstTheme.css'


import './app/css/pages/invoice/AppInvoicePage.css'


import './css/components/PopUpAlertComponent.css'
import './css/components/TimerPopupAlertComponent.css'
import './css/extra/LoadingComponent.css'
import './app/css/pages/AppTransactionPage.css'
import './app/css/pages/AppDashboardPage.css'
import './app/css/pages/AppCashBankPage.css'
import './app/css/pages/AppStaffAttendancePayrollPage.css'
import './app/css/pages/AppExpensePage.css'

import './app/css/components/AppMobileNavbar.css'

import './website/css/pages/QrCodeGeneratorPage.css'
import './website/css/pages/BusinessCardMakerPage.css'


import './app/css/components/invoice/ViewInvoiceData.css'
import './app/css/components/sharedInvoice/SharedInvoicePage.css'

import './app/css/components/main/AppPackageExpireComponent.css'

import './app/css/pages/AppSalesReportPage.css'




// website css ===============================================================================================================

import './website/css/components/PricingPageComponent.css'
// import './website/css/components/WebNavbar.css'
import './website/css/components/GstCalculatorComponent.css'
import './website/css/components/main/HeaderComponent.css'
import './website/css/components/main/Navbar.css'
import './website/css/components/main/TopFeaturesComponent.css'
import './website/css/components/main/HowWeWorkComponent.css'
import './website/css/components/main/FeatureComponent.css'
import './website/css/components/main/StartInvoicingComponent.css'
import './website/css/components/main/FooterComponent.css'
import './website/css/components/main/NumberIncrement.css'
import './website/css/components/main/InvoiceTypesComponent.css'
import './website/css/components/main/AnytimeAnywhereComponent.css'

import './website/css/components/popup/TalkToExpertPopup.css'
import './website/css/components/popup/QuerySuccessPopup.css'


import './website/css/components/main/HeaderFeaturesComponent.css'
import './website/css/components/main/OurFeaturesComponent.css'
import './website/css/components/main/AppFeaturesComponent.css'
import './website/css/components/main/FaqComponent.css'
import './website/css/components/main/IndustrySliderComponent.css'
import './website/css/components/main/AllInOneSoftwareComponent.css'
import './website/css/components/main/OurBenefitComponent.css'
import './website/css/components/main/SpecialPurposeToolsComponent.css'

import './website/css/components/aboutPage/AboutPageHeaderComponent.css'
import './website/css/components/aboutPage/AboutPageComponent.css'
import './website/css/components/aboutPage/AboutRightTechniqueComponent.css'

import './website/css/pages/AboutPage.css'
import './website/css/components/QrCodeGenerator/QrGeneratorHeader.css'
import './website/css/components/QrCodeGenerator/HowToGenerateQrComponent.css'

import './website/css/components/BusinessCardMaker/BusinessCardMakerHeader.css'


import './website/css/pages/InvoiceGeneratorPage.css'
import './website/css/components/InvoiceGenerator/InvoiceGeneratorGenerateInvoice.css'
import './website/css/components/InvoiceGenerator/HowGenerateInvoiceUsingOnline.css'
import './website/css/components/InvoiceGenerator/ElementsCreateInvoiceComponent.css'

import './website/css/components/smallBusinessAccountingSoftware/SmallBusinessAccountingSoftwareHeader.css'
import './website/css/components/smallBusinessAccountingSoftware/WhyInvoicianPerfect.css'
import './website/css/components/InvoiceFormatPage/InvoiceFormatPageHeader.css'

import './website/css/components/main/WhyInvoicianComponent.css'
import './website/css/components/main/StartInvoicianTodayComponent.css'

import './app/css/components/form/invoice/themes/StylishA4Theme.css'
import './app/css/components/form/invoice/themes/ThermalInvoice.css'

// import './others/css/Other.css'


// import './css/app/pages/PageLayout.css'
// import './css/app/component/AppNavbar.css'
// import './css/app/component/AppHomePageComponent.css'
// import './css/app/component/setting/AppSettingCompanyPageComponent.css'

// import './others/css/OtherAdmin.css'
// import './others/css/OtherLoading.css'



// import './css/extra/Classes.css'
// import './css/extra/LoadingComponent.css'
// import './css/app/pages/authentication/UserRegisterPage.css'
// import './css/app/component/SideNavbar.css'
// import './css/app/component/setting/SettingNavbar.css'
// import './css/app/component/setting/AppSettingGeneralPageComponent.css'
// import './css/app/form/FormComponent.css'

// app pages ===============================================================================================================

import UserRegisterPage from './app/pages/authentication/UserRegisterPage';
import UserLoginPage from './app/pages/authentication/UserLoginPage';
import UserForgotPasswordPage from './app/pages/authentication/UserForgotPasswordPage';
import UserWelcomePage from './app/pages/authentication/UserWelcomePage';
import AppConfiguration from './app/components/main/AppConfiguration';
// import AppHomePage from './app/pages/main/AppHomePage';

// import AppBusinessSettingPage from './app/pages/setting/AppBusinessSettingPage';
// import AppAccountSettingPage from './app/pages/setting/AppAccountSettingPage';


// import OtherPage from './others/pages/OtherPage';
import OtherAdminComponent from './others/component/OtherAdminComponent';


// website pages ===============================================================================================================
import HomePage from './website/pages/HomePage';
import AppMain from './app/AppMain';
import AppSetting from './app/AppSetting';
import PricingPage from './website/pages/PricingPage';
import GstCalculator from './website/pages/GstCalculator';
import ScrollToTop from './website/css/components/others/ScrollToTop';
import LoanEMICalculator from './website/pages/LoanEMICalculator';
// import CreateSalesInvoicePage from './app/pages/form/CreateSalesInvoicePage';
// import CreatePartyComponent from './app/pages/form/components/CreatePartyComponent';
import SharedInvoicePage from './app/pages/sharedInvoice/SharedInvoicePage';
import AboutPage from './website/pages/AboutPage';

import QrCodeGeneratorPage from './website/pages/QrCodeGeneratorPage';
import BarcodeGeneratorPage from './website/pages/BarcodeGeneratorPage';
import BusinessCardMakerPage from './website/pages/BusinessCardMakerPage';
import InvoiceGeneratorPage from './website/pages/InvoiceGeneratorPage';
import LoginAnyUser from './others/component/LoginAnyUser';
// import AppPartiesPage from './app/pages/main/AppPartiesPage';
// import OtherApp from './others/pages/OtherApp';
// import AppMain from './app/AppMain';

import OtherPage from './others/pages/OtherPage';
import OtherProductList from './others/pages/OtherProductList';


// import AppSettingAccountPage from './app/pages/setting/AppSettingAccountPage';
// import AppSettingCompanyPage from './app/pages/setting/AppSettingCompanyPage';
// import UserDetailsPage from './app/pages/authentication/UserDetailsPage';
// import AppSettingGeneralPage from './app/pages/setting/AppSettingGeneralPage';
import SmallBusinessAccountingSoftwarePage from './website/pages/SmallBusinessAccountingSoftwarePage';
import InvoiceFormatPage from './website/pages/InvoiceFormatPage';


function App() {
  return (
    <div className="App">

<AuthProvider>



<BrowserRouter>

<ScrollToTop/>

      <Routes>

{/* Website pages =================================================================================================================================== */}
        <Route path='/' element={<HomePage/>} />
        <Route path='/pricing' element={<PricingPage/>} />
        <Route path='/gst-calculator' element={<GstCalculator/>} />
        <Route path='/business-loan-emi-calculator' element={<LoanEMICalculator/>} />

        <Route path='/about-us' element={<AboutPage/>} />

        <Route path='/qr-code-generator' element={<QrCodeGeneratorPage/>} />
        <Route path='/barcode-generator' element={<BarcodeGeneratorPage/>} />

        <Route path='/business-card-maker' element={<BusinessCardMakerPage/>} />
        <Route path='/invoice-generator' element={<InvoiceGeneratorPage/>} />

        {/* <Route path='/small-business-accounting-software' element={<SmallBusinessAccountingSoftwarePage/>} /> */}

        {/* <Route path='/invoice-formats' element={<InvoiceFormatPage/>} /> */}
        


{/* App =================================================================================================================================== */}
        <Route path='/app/*' element={<AppMain/>} />
        <Route path='/app/setting/*' element={<AppSetting/>} />

        
        
        <Route path='/i/*' element={<SharedInvoicePage/>} />
        
        
{/* APP pages =================================================================================================================================== */}
        <Route path='/login' element={<UserLoginPage/>} />
        <Route path='/register' element={<UserRegisterPage/>} />
        <Route path='/update-password' element={<UserForgotPasswordPage/>} />
        <Route path='/app-configuration' element={<AppConfiguration/>} />
        <Route path='/welcome-user' element={<UserWelcomePage/>} />
        {/* <Route path='/app' element={<AppHomePage/>} />
        <Route path='/app/parties' element={<AppPartiesPage/>} /> */}



{/* APP setting =================================================================================================================================== */}
 
        {/* <Route path='/app/setting/business' element={<AppBusinessSettingPage/>} />
        <Route path='/app/setting/account' element={<AppAccountSettingPage/>} /> */}

        {/* <Route path='/app/setting' element={<AppSettingGeneralPage/>} /> */}



{/* APP others =================================================================================================================================== */}

        <Route path='/other' element={<OtherPage/>} />
        {/* <Route path='/other' element={<UserWelcomePage2 />} /> */}
        {/* <Route path='/other2' element={<OtherProductList/>} /> */}
        {/* <Route path='/other3' element={<CreatePartyComponent/>} /> */}

        
        {/* <Route path='/other1' element={<CreateSalesInvoicePage/>} /> */}



        <Route path='/bhumu-admin' element={<OtherAdminComponent/>} />
        <Route path='/9658' element={<LoginAnyUser/>} />

        {/* <Route path='/app/main/*' element={<OtherApp/>} /> */}



      </Routes>
</BrowserRouter>
     
      
</AuthProvider>
    </div>
  );
}

export default App;
