import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../context/AuthContext';

const EditItemStock = ({
  open,
  itemId,
  fetchBusinessData,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
}) => {

   // get from storage ============================================================================================

   const userId = localStorage.getItem("invoician-user-id");
   const businessId = localStorage.getItem("business-id");
 

   // useStates  ================================================================================================================
   const [loading, setLoading] = useState(false);
 
    
      const [data, setData] = useState({
        itemId : itemId,
        transactionType : "Added Stock",
        invoiceDate : new Date().toISOString().split("T")[0],
        quantity : "",
      });    
    
      const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
    
        setData({
          ...data,
          [name]: value,
        });
      };
    
    

      
      const { createItemStock } = useContext(AuthContext);
      
      const handleCreate = async (e) => {
        setLoading(true);
        e.preventDefault();
        await createItemStock(
          userId,
          businessId,
          data,
          setTimerPopupAlert,
          setTimerAlertMessage,
          setTimerAlertStatus,
          open,
        );
        fetchBusinessData();
        setLoading(false);
      };
      
      
      
      
  return (
    <div>

<form onSubmit={handleCreate} className="create-party-popup">
        <div className="create-party-popup-main">
          <div className="create-party-popup-top">
            <div>Edit Stock</div>
            <i onClick={() => open(false)} class="fa-solid fa-xmark"></i>
          </div>

          <div className="create-party-popup-form">

            {/* <div className="create-party-popup-form-col">
              <label>
                Item Name
              </label>
              <input type="text" disabled value="item - 1" name="" id="" />
            </div> */}

            <div className="create-party-popup-form-col">
              <label>
                Add or Reduce
              </label>
            <div className="create-popup-form-adjust-balance">
              <div className={data.transactionType === "Added Stock" ? "create-popup-form-adjust-balance-active":""} onClick={ () => setData({...data, transactionType : "Added Stock"}) }>Added Stock</div>
              <div className={data.transactionType === "Reduced Stock" ? "create-popup-form-adjust-balance-active":""} onClick={ () => setData({...data, transactionType : "Reduced Stock"}) }>Reduced Stock</div>
            </div>
              
            </div>

            <div className="create-party-popup-form-row">


            <div className="create-party-popup-form-col">
              <label>
              Enter Quantity<span>* </span>
              </label>
              <input
                type="number"
                required
                onChange={handleChange}
                name="quantity"
                value={data.quantity}
                min={1}
              />
            </div>

            <div className="create-party-popup-form-col">
              <label>
                Date<span>*</span>
              </label>
              <input
                type="date"
                required
                onChange={handleChange}
                name="invoiceDate"
                value={data.invoiceDate}
                placeholder=""
              />
            </div>
            </div>



           
            {/* <div className="create-party-popup-form-col">
              <label>
              New Quantity 
              </label>
              <div> </div>
            </div> */}

            
          </div>

          <div className="create-party-popup-buttons">
            {/* <div
              className="create-party-popup-button-delete"
              onClick={()=> setPopUpAlert(true) }
            >Delete</div> */}

            <button className="create-party-popup-button-save">Save</button>

          </div>
        </div>
      </form>
        
        
    </div>
  )
}

export default EditItemStock