import React from "react";

const HeaderFeaturesComponent = () => {
  return (
    <div>
      <div className="header-features">
        <div className="header-features-main">

          <div className="header-features-col">
            <div className="header-features-icon"><i class="fa-regular fa-face-laugh-beam"></i></div>
            <div className="header-features-heading">500,000 <div>+</div></div>
            <div className="header-features-text">Happy Customers</div>
          </div>
          

          <div className="header-features-col">
            <div className="header-features-icon"><i class="fa-solid fa-laptop"></i></div>
            <div className="header-features-heading">Free</div>
            <div className="header-features-text">Get Started for Free</div>
          </div>
          

          <div className="header-features-col">
            <div className="header-features-icon"><i class="fa-brands fa-google"></i></div>
            <div className="header-features-heading">5 <div>&nbsp;Stars</div></div>
            <div className="header-features-text">Rating on Google</div>
          </div>

          <div className="header-features-col">
            <div className="header-features-icon">
            {/* <i class="fa-solid fa-tablet-screen-button"></i> */}
            <i class="fa-solid fa-desktop"></i>
                <i class="fa-solid fa-mobile-screen-button"></i>
            </div>
            <div className="header-features-heading">Multi-<div>Device</div></div>
            <div className="header-features-text">Use together on Mobile/Desktop</div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default HeaderFeaturesComponent;
