import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import QRCode from "react-qr-code";
import phonePe from "../../../media/images/icons/phonepe.png"
import gpay from "../../../media/images/icons/gpay.png"
import paytm from "../../../media/images/icons/paytm.png"
import upi from "../../../media/images/icons/upi.png"

const SharedInvoiceExcelTheme = ({invoiceData}) => {

  const data = invoiceData&&invoiceData.invoiceData


  
  const { link } = useContext(AuthContext);
  
  
    
  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };
  

  
  const calculateTaxedPrice = (price, taxRate, quantity, discount, cess) => {
    const discountedPrice = price - (price * discount) / 100;

    return (
      (discountedPrice +
        (discountedPrice * taxRate) / 100 +
        (cess * discountedPrice) / 100) *
      quantity
    );
  };

  
  const taxableAmount = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (item.itemPurchasePrice * item.quantity -
        (item.itemPurchasePrice * item.discount * item.quantity) / 100)
    );
  }, 0);


  
  const totalGst = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) *
        item.itemGst) /
        100) *
        item.quantity
    );
  }, 0);


  const totalCess = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) * item.cess) /
        100) *
        item.quantity
    );
  }, 0);
  
// --------------------


const taxablePurchaseAmount = data.invoiceItemsData.reduce((acc, item) => {
  return (
    acc +
    (item.itemPurchasePrice * item.quantity -
      (item.itemPurchasePrice * item.discount * item.quantity) / 100)
  );
}, 0);

const totalPurchaseGst = data.invoiceItemsData.reduce((acc, item) => {
  return (
    acc +
    (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) *
      item.itemGst) /
      100) *
      item.quantity
  );
}, 0);

  const totalPurchaseCess = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) * item.cess) /
        100) *
        item.quantity
    );
  }, 0);

  
  return (
    <div>
      <div className="">

      <div className="invoice-excel-theme-heading">
        tax invoice <div>Original for recipient</div>
      </div>
      
      <div className="invoice-excel-theme">
        <div className="invoice-excel-theme-main">

          <div className="invoice-excel-theme-head">
            <div className="invoice-excel-theme-head-company">
              <div className="invoice-excel-theme-head-company-name">{invoiceData.invoiceCompanyData.businessName}</div>
              {
                invoiceData.invoiceCompanyData.companyAddress?
              <div className="invoice-excel-theme-head-company-Address">
                {invoiceData.invoiceCompanyData.companyAddress}
              </div>
            :""
            }



{
                invoiceData.invoiceCompanyData.companyPhoneNumber?
              
              <div className="invoice-excel-theme-head-company-number">
               <b>Mobile : </b> {invoiceData.invoiceCompanyData.companyPhoneNumber}               
              </div>              
              :""}

{invoiceData.invoiceCompanyData.companyEmail?
  <div className="invoice-excel-theme-head-company-email"><b>Email : </b>{invoiceData.invoiceCompanyData.companyEmail}</div>
  :""

}

{
  invoiceData.invoiceCompanyData.companyGSTIN?
              <div className="invoice-excel-theme-head-company-gst"><b>GSTIN : </b>{invoiceData.invoiceCompanyData.companyGSTIN}</div>
              :""}
            </div>
            <div className="invoice-excel-theme-head-text" style={{ color: `${invoiceData.invoiceThemeColor}` }}>INVOICE</div>
          </div>

          <div className="invoice-excel-theme-options">
            
            <div className="invoice-excel-theme-options-col-1">
              <div className="invoice-excel-theme-options-tabel">
                
                <div className="invoice-excel-theme-options-tabel-row">
                  <div>Invoice#</div>
                  <div>{data.invoiceNumber ? data.invoiceNumber : "__________"}</div>
                </div>

                <div className="invoice-excel-theme-options-tabel-row">
                  <div>Invoice Date</div>
                  {/* <div>{data.invoiceDate ? new Date(data.invoiceDate).toDateString() : "__________"}</div> */}
                  <div>{data.invoiceDate ? (
                <>
                  {new Date(data.invoiceDate)
                    .getDate()
                    .toString()
                    .padStart(2, 0)}
                  /
                  {(new Date(data.invoiceDate).getMonth() + 1)
                    .toString()
                    .padStart(2, 0)}
                  /{new Date(data.invoiceDate).getFullYear()}
                </>
              ) : (
                "__________"
              )}</div>
                </div>
{
  data.invoiceEnableDueDate?
                <div className="invoice-excel-theme-options-tabel-row">
                  <div>Due Date</div>
                  <div>
                  {new Date(data.invoiceDueDate)
                    .getDate()
                    .toString()
                    .padStart(2, 0)}
                  /
                  {(new Date(data.invoiceDueDate).getMonth() + 1)
                    .toString()
                    .padStart(2, 0)}
                  /{new Date(data.invoiceDueDate).getFullYear()}
                  </div>
                </div>
                :""}

              </div>

            </div>

{
  data.invoiceDetailsOptions || data.invoiceVehicleNo || data.invoicePoNo ?

            <div className="invoice-excel-theme-options-col-2">
              <div className="invoice-excel-theme-options-tabel">

             
            {
              data.invoicePoNo?
              <div className="invoice-excel-theme-options-tabel-row">
                  <div>P.O. No.</div>
                  <div>{data.invoicePoNo}</div>
                </div>
                      :""
                    }
            {
              data.invoiceVehicleNo?
              <div className="invoice-excel-theme-options-tabel-row">
                  <div>Vehicle No.</div>
                  <div>{data.invoiceVehicleNo}</div>
                </div>
                      :""
                    }


{data.invoiceDetailsOptions ? (
                <>
                {
                  Object.entries(data.invoiceDetailsOptions)
                    .filter(([key, value]) => value) // Show only fields with values
                    .map(([key, value]) => (
                      <div className="invoice-excel-theme-options-tabel-row" key={key}>
                        <div>{key} </div>
                        <div>{value}</div>
                      </div>
                    ))

                  }
         </>   
          ) : (
            ""
          )}
                


              </div>

            </div>
            :""}

          </div>

          <div className="invoice-excel-theme-options">
            
            <div className="invoice-excel-theme-address">
             <div className="invoice-excel-theme-address-head" style={{ backgroundColor: `${invoiceData.invoiceThemeColor}20` }}>Bill To</div>
              <div className="invoice-excel-theme-address-content">
                {
                  data.invoicePartyData && data.invoicePartyData.partyName?
                  <div className="invoice-excel-theme-address-content-name">{data.invoicePartyData.partyName}</div>
                  :""
                }

{(data.invoicePartyData &&
                data.invoicePartyData.partyBillingAddress) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingState) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingCity) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingPinCode) ? (
                <div  className="invoice-excel-theme-address-content-text">
                  {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingAddress}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingCity ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingCity}
                    </>
                  ) : (
                    ""
                  )}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingState ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingState}
                    </>
                  ) : (
                    ""
                  )}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingPinCode ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingPinCode}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
                
                {/* <div className="invoice-excel-theme-address-content-text">Lorem ipsum dolor sit amet consectetur, adipisicing elit.</div> */}

                {
                  data.invoicePartyData && data.invoicePartyData.partyNumber ?
                  <div className="invoice-excel-theme-address-content-text"><b>Number : </b>{data.invoicePartyData && data.invoicePartyData.partyNumber}</div>
                  :""
                }
                
                {data.invoicePartyData && data.invoicePartyData.partyGSTIN ? 
                <div className="invoice-excel-theme-address-content-text"><b>GSTIN : </b>{data.invoicePartyData && data.invoicePartyData.partyGSTIN}</div>
                :""}
              </div>
            </div>
            

            {data.invoicePartyData.partyBothAddressSame ?

            <div className="invoice-excel-theme-address">
             <div className="invoice-excel-theme-address-head" style={{ backgroundColor: `${invoiceData.invoiceThemeColor}20` }}>Ship To</div>
              <div className="invoice-excel-theme-address-content">

                {
                  data.invoicePartyData && data.invoicePartyData.partyName?
                  <div className="invoice-excel-theme-address-content-name">{data.invoicePartyData.partyName}</div>
                  :""
                }

                {(data.invoicePartyData &&
                  data.invoicePartyData.partyBillingAddress) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyBillingState) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyBillingPinCode) ? (
                  <div>
                    {data.invoicePartyData &&
                      data.invoicePartyData.partyBillingAddress}
                    {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingState ? (
                      <>
                        ,{" "}
                        {data.invoicePartyData &&
                          data.invoicePartyData.partyBillingState}
                      </>
                    ) : (
                      ""
                    )}
                    {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingPinCode ? (
                      <>
                        ,{" "}
                        {data.invoicePartyData &&
                          data.invoicePartyData.partyBillingPinCode}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                
{
  data.invoicePartyData && data.invoicePartyData.partyNumber?
  <div className="invoice-excel-theme-address-content-text"><b>Mobile : </b>{data.invoicePartyData && data.invoicePartyData.partyNumber}</div>
:""
}
                
              </div>
            </div>

            :

            (data.invoicePartyData &&
              data.invoicePartyData.partyShippingAddress) ||
            (data.invoicePartyData &&
              data.invoicePartyData.partyShippingCity) ||
            (data.invoicePartyData &&
              data.invoicePartyData.partyShippingState) ||
            (data.invoicePartyData &&
              data.invoicePartyData.partyShippingPinCode) ?


              <div className="invoice-excel-theme-address">
              <div className="invoice-excel-theme-address-head" style={{ backgroundColor: `${invoiceData.invoiceThemeColor}20` }}>Ship To</div>
               <div className="invoice-excel-theme-address-content">
 
               

               <div>
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyShippingAddress}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingCity ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingCity}
                        </>
                      ) : (
                        ""
                      )}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingState ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingState}
                        </>
                      ) : (
                        ""
                      )}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingPinCode ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingPinCode}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
               
               
               
                 
 {
   data.invoicePartyData && data.invoicePartyData.partyNumber?
   <div className="invoice-excel-theme-address-content-text"><b>Mobile : </b>{data.invoicePartyData && data.invoicePartyData.partyNumber}</div>
 :""
 }
                 
               </div>
             </div>

            

            :""}

            
          </div>


          <div  className="invoice-excel-theme-table">

            <table >
                <tr style={{ backgroundColor: `${invoiceData.invoiceThemeColor}` }} >
                  <td style={{ backgroundColor: `${invoiceData.invoiceThemeColor}` }}>#</td>
                  <td style={{ backgroundColor: `${invoiceData.invoiceThemeColor}` }}>Items</td>
                  {
                    data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                    0 ?
                    <td style={{ backgroundColor: `${invoiceData.invoiceThemeColor}` }}>HSN/SAC</td>
                    :""
                  }
                  <td style={{ backgroundColor: `${invoiceData.invoiceThemeColor}` }}>Qty</td>
                  <td style={{ backgroundColor: `${invoiceData.invoiceThemeColor}` }}>Rate</td>
                  {
                    data.invoiceItemsData.filter((e) => e.discount).length >
                    0 ?
                    <td style={{ backgroundColor: `${invoiceData.invoiceThemeColor}` }}>Disc</td>
                    :""
                  }
                  {
                    data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? 
                    <td style={{ backgroundColor: `${invoiceData.invoiceThemeColor}` }}>Tax</td>
                    :""
                  }
                  {
                    data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 
                    <td style={{ backgroundColor: `${invoiceData.invoiceThemeColor}` }}>CESS</td>
                    :""
                  }
                  <td style={{ backgroundColor: `${invoiceData.invoiceThemeColor}` }}>Amount</td>
                </tr>


                {data.invoiceItemsData.map((e, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>
                {e.itemName}
                  <span>
                    <div>{e.itemDescription}</div>
                  </span>
                </td>

                {
                  data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                  0 ?
                  <td>{e.itemHsnCode ? e.itemHsnCode : "-"}</td>
                  :""
                }
                <td>{formatNumber(Number(e.quantity))*1}</td>
                <td> {
                      formatNumber(Number(e.itemPrice))*1
                      }
                </td>
                {
                  data.invoiceItemsData.filter((e) => e.discount).length >
                  0 ?
                  <td>{e.discount ? formatNumber(e.discount)*1 : 0}%</td>
                  :""
                }

                {
                  data.invoiceItemsData.filter((e) => e.itemGst).length >
                  0 ? 
                  <td>{e.itemGst ? e.itemGst : 0}%</td>
                  :""
                }
                {
                  data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 
                  <td>{e.cess ? e.cess : 0}%</td>
                  :''
                }
                <td>
                {
                      formatNumber(
                        calculateTaxedPrice(
                          e.itemPrice,
                          e.itemGst,
                          e.quantity,
                          e.discount,
                          e.cess
                        )
                      ) * 1
                      
                      }
                </td>
              </tr>
                ))}


                <tr >
                  <td></td>
                <td>Sub Total</td>
                  {
                    data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                    0 ?
                    <td></td>
                    :""
                  }
                  <td></td>
                  <td></td>
                  {
                    data.invoiceItemsData.filter((e) => e.discount).length >
                    0 ?
                    <td></td>
                    :""
                  }
                  {
                    data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? 
                    <td></td>
                    :""
                  }
                  {
                    data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 
                    <td></td>
                    :""
                  }
                  {/* <td className="invoice-excel-theme-table-left-border">₹ {formatNumber(totalGst) * 1}</td>
                  <td className="invoice-excel-theme-table-left-border">₹ {formatNumber(totalCess) * 1}</td>
                  <td className="invoice-excel-theme-table-left-border">₹ {formatNumber(data.invoiceSubTotalAmount) * 1}</td> */}
                  <td>₹ {formatNumber(data.invoiceSubTotalAmount) * 1}</td>
                </tr>
                
                
              
            </table>
            
          </div>


          <div className="invoice-excel-theme-end">

          <div className="invoice-excel-theme-end-col-1">
            
            {
              data.invoiceNotes ?
              <>
          <div className="invoice-excel-theme-end-heading">Notes</div>
          <div className="invoice-excel-theme-end-text"><pre>{data.invoiceNotes}</pre></div>
              </>
              :""
            }

{
  invoiceData.invoiceTermsConditions ? 
  <>
          <div className="invoice-excel-theme-end-heading">Terms and Conditions</div>
          <div className="invoice-excel-theme-end-text"><pre>{invoiceData.invoiceTermsConditions}</pre></div>
  </>
  :""
}
          
            {/* <ViewInvoiceUpiComponent businessData={businessData} data={data}/> */}

            {


data.paymentType === "Bank Transfer" ||
data.paymentType === "UPI" ||
data.paymentType === "Card" ||
data.paymentType === "Cheque" ||
data.paymentType === "Netbanking" ?

invoiceData.invoiceBankData?
              
            <div className="invoice-theme-1-end-col-1-bank-datails">
              
              <div className="invoice-theme-1-end-col-1-bank-datails-heading">
                Bank Details
              </div>

              <table className="invoice-theme-1-end-col-1-bank-datails-data">
                <tr>
                  <td>Name:</td>
                  <td>{invoiceData.invoiceBankData.accountHolderName}</td>
                </tr>
                <tr>
                  <td>IFSC Code:</td>
                  <td>{invoiceData.invoiceBankData.IFSCcode}</td>
                </tr>
                <tr>
                  <td>Account No:</td>
                  <td>{invoiceData.invoiceBankData.accountNumber}</td>
                </tr>
                <tr>
                  <td>Bank:</td>
                  <td>{invoiceData.invoiceBankData.bankName}</td>
                </tr>
              </table>   

              
{
invoiceData.invoiceBankData.upiId?
     
              <div className="invoice-theme-1-end-col-1-bank-datails-upi">
              <div className="invoice-theme-1-end-col-1-bank-datails-upi-col-1">
              <div className="invoice-theme-1-end-col-1-bank-datails-heading">
                Payment Qr Code
              </div>
                <b>UPI ID:</b>
                <div>{invoiceData.invoiceBankData.upiId}</div>
              <div className="invoice-theme-1-end-col-1-bank-icons">
                <img src={phonePe} alt="" />
                <img src={gpay} alt="" />
                <img src={paytm} alt="" />
                <img src={upi} alt="" />

              </div>
              </div>
              <div className="invoice-theme-1-end-col-1-bank-datails-upi-col-2">
              <QRCode
      value={`upi://pay?pa=${invoiceData.invoiceBankData.upiId}`}
      size={70}
      level="M"
    />
                
              </div>
              
              </div>
              :""}      

              
            </div>

            : 
            ""
            :""
          }
           

          </div>

          <div className="invoice-excel-theme-end-col-2">

          <div className="invoice-excel-theme-end-col-2-amount"  style={{ backgroundColor: `${invoiceData.invoiceThemeColor}20` }}>
            
            <div className="invoice-excel-theme-end-col-row">
              <div>Taxable Value</div>
              <div>
              ₹ {
                  formatNumber(taxableAmount) * 1
                  }
              </div>
            </div>
{
  data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? 
  <>
            <div className="invoice-excel-theme-end-col-row">
              <div>SGST</div>
              <div>
              ₹ {
                      formatNumber(totalGst) / 2
                    }
              </div>
            </div>

            <div className="invoice-excel-theme-end-col-row">
              <div>CGST</div>
              <div>
              ₹ {
                      formatNumber(totalGst) / 2
                    }
              </div>
            </div>
  </>
  :""
}
{
  data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 
  <div className="invoice-excel-theme-end-col-row">
              <div>CESS</div>
              <div>₹ {
                    formatNumber(totalCess) / 1
                    }</div>
            </div>
            :""
            }
{
  data.invoiceExtraDiscount ? 
  <div className="invoice-excel-theme-end-col-row">
              <div>Discount</div>
              <div>₹ {formatNumber(data.invoiceExtraDiscount) * 1}</div>
            </div>
            :""
            }

            <div style={{ borderTop: `solid 2px ${invoiceData.invoiceThemeColor}`, borderBottom: `solid 2px ${invoiceData.invoiceThemeColor}` }} className="invoice-excel-theme-end-col-row-total">
              <div>Total Amount</div>
              <div>₹ {(data.invoiceTotalAmount.toFixed(2) * 1).toLocaleString()}</div>
            </div>

{
  data.receivedAmount ?
  <>
  <div className="invoice-excel-theme-end-col-row">
              <div>Paid Amount</div>
              <div>₹ {data.receivedAmount}</div>
            </div>

            <div className="invoice-excel-theme-end-col-row">
              <div>Balance</div>
              <div>₹{" "}
                      {Math.round(
                        data.invoiceTotalAmount.toFixed(2) - data.receivedAmount
                      )}
              </div>
            </div>
  </>
  :""          }

          </div>


      
          {/* {
  businessData.companySignatureType === "Box"?

          <div className="invoice-excel-theme-signature">

          <div className="invoice-excel-theme-signature-empty"></div>
          <div className="invoice-excel-theme-signature-text">
            <span>Authorized Signatory For</span>
            <div>{businessData.businessName}</div>
          </div>

          </div>

:
businessData.companySignatureType === "Image"?


businessData.companySignature ?

<div className="invoice-excel-theme-signature">

<img src={`${link}/${businessData.companySignature }`} alt="comapny signature"/>
<div className="invoice-excel-theme-signature-text">
  <span>Authorized Signatory For</span>
  <div>{businessData.businessName}</div>
</div>

</div>
:""
          :""} */}



                      




          </div>
          </div>








        </div>
      </div>
      </div>
    </div>
  );
};

export default SharedInvoiceExcelTheme;