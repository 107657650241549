import React, { useState } from "react";
import icon from '../../../media/images/website/homepage-lifetime-free-basic-usage.webp'
import img from '../../../media/images/website/benefits_of_gst_billing_and_invoicing_software.jpg'


const OurBenefitComponent = () => {
  const [free, setFree] = useState(false);
  const [track, setTrack] = useState(false);
  const [cashflow , setCashflow ] = useState(false);
  const [online, setOnline] = useState(false);
  const [payment, setPayment] = useState(false);
  const [backup, setBackup] = useState(false);
  const [brand, setBrand] = useState(false);
  const [inventory, setInventory] = useState(false);

  const handleViewMore = (e) => {
    e(true);
  };

  const handleViewLess = (e) => {
    e(false);
  };

  return (
    <div>
      <div className="our-benefit">
        <div className="our-benefit-main">
          <div className="our-benefit-heading">
            Benefits of GST Billing Software
          </div>
          <div className="our-benefit-content">
            <div className="our-benefit-content-col-1">

              <div className="our-benefit-content-col-row">
                <div className="our-benefit-content-details">
                  <div className="our-benefit-content-heading">Lifetime free basic usage</div>
                  {free ? (
                    <>
                      <div className="our-benefit-content-text">
                      With our free GST accounting and billing software, you can easily create customized invoices, manage your dashboard, and track inventory items. The free version also offers many other useful features to help streamline your business operations.
                      <br /><br />
We have ensured that all essential features of our business accounting tool are available at no cost. These free features are offered for lifetime use to Android mobile users. Our goal is to bring millions of small business owners into the digital economy, and providing lifetime free access on mobile devices makes our invoicing tool ideal for SMEs.
                      <br /><br />
Invoician is dedicated to delivering high-quality services, and it has become an integral part of the growing business landscape across the country. You can download the free app from the Play Store and sign up to use the accounting software at no cost. For businesses looking for advanced features, premium options and the desktop application are available through a subscription.
                      <br /><br />
Invoician's GST billing software simplifies business management for all industries, including SMEs, freelancers, and professionals. Our billing solutions help drive business growth and enhance operational efficiency.
                      <br /><br />
Using professional billing software allows SMEs to scale their operations without straining their finances. With affordable subscription pricing, Invoician offers premium packages for Android and Windows users, making it easy to reduce costs while improving business processes.
                        
                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewLess(setFree)}
                      >
                        - Read less
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="our-benefit-content-text">
                      With our free GST accounting and billing software, you can easily create customized invoices, manage your dashboard, and track inventory items. The free version also offers many other useful features to help streamline
                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewMore(setFree)}
                      >
                        + Read more
                      </div>
                    </>
                  )}
                </div>
                <div className="our-benefit-content-icon">
                  <img
                    src={icon}
                    alt=""
                  />
                </div>
              </div>

              <div className="our-benefit-content-col-row">
                <div className="our-benefit-content-details">
                  <div className="our-benefit-content-heading">Track your business status</div>
                  {track ? (
                    <>
                      <div className="our-benefit-content-text">
                      The Invoician business dashboard streamlines the entire management process, allowing you to monitor key business metrics such as cash flow, inventory levels, open orders, and payment statuses in one centralized location.
<br /><br />
With our free GST billing software and invoicing tools, managing your business becomes easy, even on the go, with mobile access. The software simplifies accounting and enhances efficiency by automatically storing data during invoicing.
<br /><br />
The business dashboard provides real-time updates on your business operations, available for free in our billing app. In addition, our GST billing software includes essential features like cash flow tracking, bank status, stock/inventory monitoring, and open order management.
<br /><br />
The dashboard is designed to make business management effortless, offering insights into your store sales and overall business performance. The GST billing app allows you to analyze operations and make data-driven decisions.
<br /><br />
Our billing software helps save valuable time and ensures your business runs smoothly. By using these insights, you can adjust your strategy as needed, keeping your operations effective and efficient—all through the free invoicing software.

                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewLess(setTrack)}
                      >
                        - Read less
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="our-benefit-content-text">
                      The Invoician business dashboard streamlines the entire management process, allowing you to monitor key business metrics such as cash flow, inventory levels, open orders, and payment statuses in one centralized location.
                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewMore(setTrack)}
                      >
                        + Read more
                      </div>
                    </>
                  )}
                </div>
                <div className="our-benefit-content-icon">
                  <img
                    src={icon}
                    alt=""
                  />
                </div>
              </div>

              <div className="our-benefit-content-col-row">
                <div className="our-benefit-content-details">
                  <div className="our-benefit-content-heading">Manage cashflow seamlessly</div>
                  {cashflow  ? (
                    <>
                      <div className="our-benefit-content-text">
                      GST billing and accounting software enables businesses to efficiently record transactions and track payments. More than 1 crore businesses have experienced the benefits of our free billing software features. Cash flow management is extensively used for billing, accounting, and other key business needs.
                      <br /><br />
Invoician’s billing software automates business management to reduce accounting errors. By integrating this software, you can effortlessly handle your business's cash flow. This comprehensive tool supports managing cash transactions, including features like tracking bank withdrawals and deposits.
                      <br /><br />
Our free GST invoicing software is ideal for creating a real-time cash book. It helps businesses maintain cash flow while aligning with data on expenses, payments, purchases, and more. Managing cash flow becomes simple with this GST accounting software.
                      <br /><br />
The GST billing solution also offers added advantages through its daybook feature. This free software eliminates the need for manual data entry, providing a more efficient way to track financial figures. By using cash flow data, businesses can make informed, timely decisions and ensure a smooth workflow, avoiding the risk of a debt trap caused by poor fund management.
                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewLess(setCashflow)}
                      >
                        - Read less
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="our-benefit-content-text">
                      GST billing and accounting software enables businesses to efficiently record transactions and track payments. More than 1 crore businesses have experienced the benefits of our free billing software features. Cash flow management 
                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewMore(setCashflow)}
                      >
                        + Read more
                      </div>
                    </>
                  )}
                </div>
                <div className="our-benefit-content-icon">
                  <img
                    src={icon}
                    alt=""
                  />
                </div>
              </div>

              <div className="our-benefit-content-col-row">
                <div className="our-benefit-content-details">
                  <div className="our-benefit-content-heading">Online/Offline billing</div>
                  {online ? (
                    <>
                      <div className="our-benefit-content-text">
                      With our billing tool, you don't need to halt your business operations due to unreliable internet connectivity. The app’s offline billing functionality allows you to generate bills even when you're not connected to the internet.
                      <br /><br />
The Invoician app lets you create invoices for your customers without needing to be online. Our business accounting software ensures your transactions are validated, and your database gets updated as soon as you reconnect to the internet.
                      <br /><br />
Thanks to the features of our billing software, your business can keep running smoothly even during weak internet connections. The offline billing option supports payments through cash and eWallets, which do not require an active internet connection, making it an ideal solution for remote areas, especially in India.
                      <br /><br />
Our GST accounting software enables you to generate bills instantly when a customer makes a purchase. Both the online and offline features of the Invoician app are particularly useful in rural regions, where network connectivity can be a challenge.
                      <br /><br />
These app features provide customers with the convenience of receiving their invoices without waiting in long lines. Moreover, the seamless billing process allows you to record transactions immediately, without any additional effort.

                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewLess(setOnline)}
                      >
                        - Read less
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="our-benefit-content-text">
                      With our billing tool, you don't need to halt your business operations due to unreliable internet connectivity. The app’s offline billing functionality allows you to generate bills even when you're not connected to the internet.
                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewMore(setOnline)}
                      >
                        + Read more
                      </div>
                    </>
                  )}
                </div>
                <div className="our-benefit-content-icon">
                  <img
                    src={icon}
                    alt=""
                  />
                </div>
              </div>

            </div>

            <div className="our-benefit-content-col-2">
              <img
                src={img}
                alt=""
              />
            </div>

            <div className="our-benefit-content-col-3">

              <div className="our-benefit-content-col-row">
                <div className="our-benefit-content-icon">
                  <img
                    src={icon}
                    alt=""
                  />
                </div>
                <div className="our-benefit-content-details">
                  <div className="our-benefit-content-heading">Provide multiple payment options</div>
                  {payment ? (
                    <>
                      <div className="our-benefit-content-text">
                      Offering multiple payment options increases the likelihood of timely payments from your customers by providing them with convenient choices. Payment methods such as UPI, QR codes, NEFT, IMPS, e-wallets, and credit/debit cards can make a big difference in your business's cash flow.
                      <br /><br />
Convenience plays a crucial role in customer satisfaction, and one of the best ways to offer comfort is by letting your customers choose their preferred payment method. With the Invoician invoicing app, you can easily create invoices that offer various payment options.
                      <br /><br />
You can either select the payment methods that are most popular with your customers or provide a comprehensive list of options. For added convenience, you can include a QR code in your invoices, allowing customers to scan and pay directly through their UPI ID.
                      <br /><br />
Customers can choose from different payment methods, including bank transfers (NEFT, RTGS, IMPS), cash, cheque, or card payments. Offering all these payment options helps ensure prompt payments and avoids delays.
                      <br /><br />
This approach not only provides better credit opportunities for your customers but also encourages them to shop more with you. Offering multiple payment options helps you build trust and makes customers more likely to choose your business over competitors who offer fewer choices.
                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewLess(setPayment)}
                      >
                        - Read less
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="our-benefit-content-text">
                      Offering multiple payment options increases the likelihood of timely payments from your customers by providing them with convenient choices. Payment methods such as UPI, QR codes, NEFT, IMPS, e-wallets, and credit/
                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewMore(setPayment)}
                      >
                        + Read more
                      </div>
                    </>
                  )}
                </div>
              </div>


              <div className="our-benefit-content-col-row">
                <div className="our-benefit-content-icon">
                  <img
                    src={icon}
                    alt=""
                  />
                </div>
                <div className="our-benefit-content-details">
                  <div className="our-benefit-content-heading">Keep data safe with backups</div>
                  {backup ? (
                    <>
                      <div className="our-benefit-content-text">
                      Our free GST billing software in India helps you keep your data safe with automatic backups. This feature ensures that all your important data stored in the app is secure. For extra protection, you can also create a local backup on devices like a pen drive or hard disk.
                      <br /><br />
Data is the backbone of any business, and losing it can impact your growth and sales. That’s why having a reliable backup system is essential. With our software, you can automatically back up your data and safeguard it for peace of mind.
                      <br /><br />
Additionally, our software allows you to generate detailed reports and analyze sales data. This helps you plan better strategies for future business growth.
                      <br /><br />
By using our free GST billing app, you can ensure your business data is safe and easily accessible whenever needed. Invoician’s advanced accounting features make it simple to track sales, create reports, and build a solid business plan.
                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewLess(setBackup)}
                        >
                        - Read less
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="our-benefit-content-text">
                    Our free GST billing software in India helps you keep your data safe with automatic backups. This feature ensures that all your important data stored in the app is secure. For extra protection, you can also create a local backup on devices 
                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewMore(setBackup)}
                      >
                        + Read more
                      </div>
                    </>
                  )}
                </div>
              </div>


              <div className="our-benefit-content-col-row">
                <div className="our-benefit-content-icon">
                  <img
                    src={icon}
                    alt=""
                  />
                </div>
                <div className="our-benefit-content-details">
                  <div className="our-benefit-content-heading">Build a positive brand image</div>
                  {brand ? (
                    <>
                      <div className="our-benefit-content-text">
                      Creating professional quotes and estimates during negotiations helps build a strong and trustworthy brand image. Clear and detailed quotes also establish transparency, which earns customer trust.
                      <br /><br />
Invoician GST Billing Software makes it easy to create professional invoices and quotes. A well-designed custom invoice not only helps you stand out from competitors but also shows you as a reliable seller.
                      <br /><br />
You can personalize your invoices with your business logo, brand colors, fonts, and style. This highlights your brand identity and leaves a lasting impression on buyers. Buyers are more likely to choose sellers who provide professional, custom quotes over plain text ones.
                      <br /><br />
Custom quotes look reliable and include all essential details, such as product or service descriptions, discounts, taxes, and terms of sale. This ensures your customers understand the pricing and value of your offerings.
                      <br /><br />
By using GST billing software, you simplify the process, appear more professional, and provide your customers with complete and transparent information about their purchase.

                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewLess(setBrand)}
                      >
                        - Read less
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="our-benefit-content-text">
                      Creating professional quotes and estimates during negotiations helps build a strong and trustworthy brand image. Clear and detailed quotes also establish transparency, which earns customer trust.

                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewMore(setBrand)}
                      >
                        + Read more
                      </div>
                    </>
                  )}
                </div>
              </div>


              <div className="our-benefit-content-col-row">
                <div className="our-benefit-content-icon">
                  <img
                    src={icon}
                    alt=""
                  />
                </div>
                <div className="our-benefit-content-details">
                  <div className="our-benefit-content-heading">Plan your inventory space</div>
                  {inventory ? (
                    <>
                      <div className="our-benefit-content-text">
                      Using our GST billing software, you can keep track of available items in your store. It can help you set up low inventory alerts to place advance orders and detect possible theft.
<br /><br />
Inventory management is crucial for businesses with a wide variety of items, and our free billing software helps in such cases. Using our GST invoicing software, you can keep track of available items in your store.
<br /><br />

Our billing software features can help you detect theft in your store as the items in stock get reduced in the inventory as the sales are made. Periodic checks can help detect the inventory mismatch using the inventory management system in GST accounting software. You can refer to your security cameras as soon as you observe that some items are missing.

<br /><br />
A professional inventory management tool can help you perfectly manage items in your warehouse. It can help you organise your stock space as per the customer demands. You can save space by eliminating the things you no longer need in your store.

<br /><br />
Using the best billing software is excellent as you can use the data to create sales reports, and you can understand how much inventory is ideally suitable based on the sales from last year and current trends.

<br /><br />
GST billing software and accounting features help keep track of the inventory items in crucial businesses in all industries. You can set up alerts on low inventory and help ensure that you place the order before running out of essential items in your store.

                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewLess(setInventory)}
                      >
                        - Read less
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="our-benefit-content-text">
                      Using our GST billing software, you can keep track of available items in your store. It can help you set up low inventory alerts to place advance orders and detect possible theft.
                      </div>
                      <div
                        className="our-benefit-content-button"
                        onClick={() => handleViewMore(setInventory)}
                      >
                        + Read more
                      </div>
                    </>
                  )}
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurBenefitComponent;
