import React from "react";
import logo from "../../../media/images/logo/invoician-logo.png";
import { Link } from "react-router-dom";

const QuerySuccessPopup = ({open}) => {
  return (
    <div>
      <div className="success-popup">
        <div className="success-popup-main">
          <div className="success-popup-close">
            <i className="fa-solid fa-xmark" onClick={()=>open(false)}></i>
          </div>
          <div className="success-popup-heading-1">
            <i class="fa-solid fa-circle-check"></i> We'll call you soon!
          </div>
          <div className="success-popup-logo">
            <img src={logo} alt="" />
          </div>
          <div className="success-popup-text">
            Meanwhile, <Link to="/app" target="_blank">Start Free Invoicing</Link>
          </div>
          <div className="success-popup-text">
            <b>Explore</b> its powerful <b>Invoicing</b> and <b>Staff Management</b>{" "}
            features.
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuerySuccessPopup;
