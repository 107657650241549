import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../../../media/images/logo/invoician-white-logo.png";

const FooterComponent = () => {
  return (
    <div>
        <div className="footer">
            <div className="footer-main">
            <div className="footer-container">
            <div className="footer-container-col-1">

            <div className="footer-container-col-1-col">
                <Link to="/" ><i class="fa-solid fa-angle-right"></i>Home</Link>
                <Link to="/pricing" ><i class="fa-solid fa-angle-right"></i>Pricing</Link>
                <Link to="tel:+91 99585 01500" target='_blank' ><i class="fa-solid fa-angle-right"></i>Contact</Link>
                <Link to="https://wa.me/+919958501500?text=Hello ! I'm Interested in business solution service. Please, arrange free demo for me." target="_blank" ><i class="fa-solid fa-angle-right"></i>Book Demo</Link>
                <Link to="/invoice-generator" ><i class="fa-solid fa-angle-right"></i>Invoice Generator</Link>
            </div>

            <div className="footer-container-col-1-col">
                <Link to="/business-card-maker" ><i class="fa-solid fa-angle-right"></i>Business Card Maker</Link>
                <Link to="/qr-code-generator" ><i class="fa-solid fa-angle-right"></i>QR Code Generator</Link>
                <Link to="/barcode-generator" ><i class="fa-solid fa-angle-right"></i>Barcode Generator</Link>
                <Link to="/gst-calculator" ><i class="fa-solid fa-angle-right"></i>GST Calculator</Link>
                <Link to="/business-loan-emi-calculator" ><i class="fa-solid fa-angle-right"></i>EMI Calculator</Link>
                {/* <Link to="/gst-calculator" ><i class="fa-solid fa-angle-right"></i>Fee Calculator</Link> */}
            </div>

            <div className="footer-container-col-1-col">
                <Link to="tel:+91 99585 01500" target='_blank'><i class="fa-solid fa-phone-volume"></i> +91 99585 01500</Link>
                <Link to="/" ><i class="fa-solid fa-envelope"></i> support@invoician.com</Link>
                <Link to="https://wa.me/+919958501500?text=Hello ! I'm Interested in business solution service. Please, arrange free demo for me." target="_blank" ><i class="fa-brands fa-whatsapp"></i> +91 99585 01500</Link>
                <Link to="/" ><i class="fa-solid fa-location-arrow"></i> Ocean Plaza, Sector 18 Noida -201301 </Link>
            </div>

            </div>

            <div className="footer-container-col-2">
            <div className="footer-container-col-2-heading">Stay Connected</div>
            <div className="footer-container-col-2-text">Take control of your business today! Available on Web.</div>
            <div     className="footer-container-col-2-input">
                <input type="number"  placeholder='Your mobile number...' />
                <button>SUBSCRIBE</button>
            </div>

            </div>
            </div>

            <div className="footer-end">
            <div className="footer-end-col-1">
            <div className="footer-end-col-1-logo">
                <img src={logo} alt="" />
            </div>
            <div className="footer-end-col-1-text"><i class="fa-regular fa-copyright"></i> 2024, invoician. All Rights Reserved.</div>

            </div>
            <div className="footer-end-col-2">
                <Link to="/"><i class="fa-brands fa-facebook-f"></i></Link>
                <Link to="/"><i class="fa-brands fa-instagram"></i></Link>
                <Link to="/"><i class="fa-brands fa-youtube"></i></Link>
                <Link to="/"><i class="fa-brands fa-linkedin-in"></i></Link>
            </div>

            </div>


            </div>
        </div>
    </div>
  )
}

export default FooterComponent