import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";

const CreateAccountPopup = ({
  open,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
  fetchData,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useStates  ================================================================================================================

  const [data, setData] = useState({
    accountName: "",
    openingBalance: 0,
    asOfDate: new Date().toISOString().split("T")[0],
    addBankDetails: true,
    accountNumber: "",
    IFSCcode: "",
    bankName: "",
    accountHolderName: "",
    upiId: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  //   --------------------------------------------------------------------------------------------------------------

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };

  const { createBank } = useContext(AuthContext);

  const handleCreate = async (e) => {
    e.preventDefault();
    await createBank(
      userId,
      businessId,
      data,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      open,
    );

    fetchData && fetchData();
  };

  return (
    <div>
      {/* <form className="create-party-popup"> */}
      <form onSubmit={handleCreate} className="create-party-popup">
        <div className="create-party-popup-main">
          <div className="create-party-popup-top">
            <div>Add Bank Account</div>
            <i onClick={() => open(false)} class="fa-solid fa-xmark"></i>
          </div>

          <div className="create-party-popup-form">

            {/* <div className="create-party-popup-form-col">
              <label>
                Account Name <span>*</span>
              </label>
              <input
                type="text"
                required
                onChange={handleChange}
                name="accountName"
                placeholder="ex: Union Bank"
              />
            </div> */}

              {/* <div className="create-party-popup-form-row">
              <div className="create-party-popup-form-col">
                <label>Opening Balance</label>
                <input
                  type="number"
                  onChange={handleChange}
                  name="openingBalance"
                  value={data.openingBalance}
                  placeholder="ex: ₹ 1000000"
                  min={0}
                />
              </div>

              <div className="create-party-popup-form-col">
                <label>As of Date</label>
                <input
                  type="date"
                  onChange={handleChange}
                  name="asOfDate"
                  value={data.asOfDate}
                />
              </div>
              </div> */}

            {data.addBankDetails ? (
              <>
                {/* <div className="create-party-popup-form-head">
                  <div>Bank Details</div>
                  <span
                    onClick={() => {
                      setData({ ...data, addBankDetails: false });
                    }}
                  >
                    Remove
                  </span>
                </div> */}

                <div className="create-party-popup-form-row">

                <div className="create-party-popup-form-col">
              <label>
                Bank Name <span>*</span>
              </label>
              <input
                type="text"
                required
                onChange={handleChange}
                name="accountName"
                placeholder="ex: Union Bank"
                list="bankList"
              />
            </div>

              <datalist id="bankList">

<option value="State Bank of India">State Bank of India</option>
<option value="Punjab National Bank">Punjab National Bank</option>
<option value="Bank of Baroda">Bank of Baroda</option>
<option value="Canara Bank">Canara Bank</option>
<option value="Union Bank of India">Union Bank of India</option>
<option value="Bank of India">Bank of India</option>
<option value="Indian Bank">Indian Bank</option>
<option value="Central Bank of India">Central Bank of India</option>
<option value="Indian Overseas Bank">Indian Overseas Bank</option>
<option value="UCO Bank">UCO Bank</option>
<option value="Bank of Maharashtra">Bank of Maharashtra</option>
<option value="Oriental Bank of Commerce">Oriental Bank of Commerce</option>
<option value="HDFC Bank">HDFC Bank</option>
<option value="ICICI Bank">ICICI Bank</option>
<option value="Axis Bank">Axis Bank</option>
<option value="Kotak Mahindra Bank">Kotak Mahindra Bank</option>
<option value="IndusInd Bank">IndusInd Bank</option>
<option value="Yes Bank">Yes Bank</option>
<option value="IDFC First Bank">IDFC First Bank</option>
<option value="Federal Bank">Federal Bank</option>
<option value="RBL Bank">RBL Bank</option>
<option value="South Indian Bank">South Indian Bank</option>
<option value="City Union Bank">City Union Bank</option>
<option value="Tamilnad Mercantile Bank">Tamilnad Mercantile Bank</option>
                
                
              </datalist>
              
              
                  
                  
                  <div className="create-party-popup-form-col">
                    <label>
                      Bank Account Number<span>*</span>
                    </label>
                    <input
                      type="number"
                      required
                      onChange={handleChange}
                      name="accountNumber"
                        placeholder="ex: 1234567890"
                    />
                  </div>

                  <div className="create-party-popup-form-col">
                    <label>
                      IFSC Code<span>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      onChange={handleChange}
                      name="IFSCcode"
                        placeholder="ex: UNION00001"
                    />
                  </div>

                  <div className="create-party-popup-form-col">
                    <label>
                      Bank & Branch Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      onChange={handleChange}
                      name="bankName"
                        placeholder="ex: UNION, Old Delhi"
                    />
                  </div>

                  <div className="create-party-popup-form-col">
                    <label>
                      Account Holder Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      onChange={handleChange}
                      name="accountHolderName"
                        placeholder="ex: User Name"
                    />
                  </div>

                  <div className="create-party-popup-form-col">
                    <label>UPI ID</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="upiId"
                        placeholder="ex: user123@union"
                    />
                  </div>
                </div>
              </>
            ) : (
              <div
                className="create-party-popup-form-link"
                onClick={() => {
                  setData({ ...data, addBankDetails: true });
                }}
              >
                + Add Bank Details
              </div>
            )}
          </div>

          <div className="create-party-popup-buttons">
            <div
              className="create-party-popup-button-cancel"
              onClick={() => open(false)}
            >
              Cancel
            </div>
            {/* <button className="create-party-popup-button-save" onClick={handleSaveData}>Save</button> */}
            <button className="create-party-popup-button-save">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateAccountPopup;
