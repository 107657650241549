import React from "react";
import img from '../../../media/images/website/effortlessly.jpg'



const AboutPageEffortlessly = () => {
  return (
    <div>
      <div className="about-effortlessly">
        <div className="about-effortlessly-main">
        <div className="about-effortlessly-heading">With Invoician, a Businessman can effortlessly</div>
        <div className="about-effortlessly-content">
        <ul className="about-effortlessly-content-list">

            <li>Make your business GST-ready easily.</li>
            <li>Manage all your contacts in one place and promote your business with ease.</li>
            <li>Track your business performance in real-time.</li>
            <li>Create, print, and share personalized invoices with ease.</li>
            <li>Set up automatic backups to keep your business data safe from accidental loss.</li>
            <li>Easily track expired items and prepare them for future sales using batch numbers.</li>
            <li>Set automatic payment reminders to stay on top of your finances.</li>
            <li>Use the accounting software offline without needing an internet connection.</li>

            
        </ul>
        <div className="about-effortlessly-content-image">
            <img src={img} alt="" />
        </div>

        </div>

        </div>
      </div>
    </div>
  );
};

export default AboutPageEffortlessly;
