import React, { useState } from "react";

const FaqComponent = () => {
  const [viewAnswer, setViewAnswer] = useState(false);

  const faqData = [
    {
      no: 1,
      question: "Which is the best software for billing?",
      answer: [
        "Invoician app is the best billing software available for SMEs in India. The Invoician app provides access to a range of useful features. They can make the business management process seamless for business owners.",
        "Using our billing tool, you can save a lot of time and effort required in creating GST invoices. You can use automation tools, managing inventory using tracking features. Further, you can create reports using stored data, and much more.",
      ],
    },
    {
      no: 2,
      question: "What is billing software?",
      answer: [
        "Professional invoicing and billing software is used to create personalized GST bills. It is used to provide better looking custom invoices to customers by businesses. These invoices help reflect the brand’s identity. Further, it caters the brand’s value to clients.",
        "You can stand out among competitors using Invoician’s GST billing software. It comes with fully customizable and appealing invoice designs. Further, you can use the business dashboard in the billing app to track down paid and unpaid invoices. You can send payment reminders to the customers too.",
      ],
    },
    {
      no: 3,
      question: "How much does a billing software cost?",
      answer: [
        "You can use Invoician billing software on Android devices for free anytime. All businesses can get a 7 days free trial with the desktop version. Invoician billing software only charges for providing access to premium features. You can try premium features for free using trial access in desktop software.",
      ],
    },
    {
      no: 4,
      question: "Which software is best for billing?",
      answer: [
        "Invoician billing software provides all features required in billing software. Invoician is best-suited for small and medium businesses. They can easily rely on Invoician app features for their business requirements. The best part is that it comes with free access using the Android mobile app.",
      ],
    },
    {
      no: 5,
      question: "Is there free billing software?",
      answer: [
        "Yes, you can get the professional billing software by Invoician for free. You can download the app on Android devices at zero cost and use it for your daily business requirements. You do not need to pay to use the app’s basic features.",
      ],
    },
    {
      no: 6,
      question: "Which software is used for GST billing?",
      answer: [
        "Invoician is the best business choice for creating GST bills for customers in India. Over one crore businesses use Invoician to bill their customers. It is because they trust the Invoician billing software. Invoician is the best choice as it comes with a range of useful features that eliminate the requirement to have multiple billing software’s. It acts as one stop solution for all business requirements. The best billing solutions makes the entire business management process seamless.",
      ],
    },
    {
      no: 7,
      question: "Which is the best free billing software for PC?",
      answer: [
        "The billing software by Invoician is the best software. It is best-suited to manage billing requirements for a business. The best part is that the app offers customers a free trial with no restrictions.",
        "So, before making a payment for the software, businesses can try out the app features. It will ensure that the app is useful for their business. All of it makes Invoician the best choice among free billing software’s available.",
      ],
    },
    {
      no: 8,
      question: "Which is the best free billing software for PC?",
      answer: [
        "The billing software by Invoician is the best software. It is best-suited to manage billing requirements for a business. The best part is that the app offers customers a free trial with no restrictions.",
        "So, before making a payment for the software, businesses can try out the app features. It will ensure that the app is useful for their business. All of it makes Invoician the best choice among free billing software’s available.",
      ],
    },
    {
      no: 9,
      question: "Which is the best free billing software in India?",
      answer: [
        "Over one crore businesses in India prefer to use Invoician as their billing software. It makes the entire process seamless for businesses. From creating customized GST invoices to creating business reports. The billing and accounting tools available in the Invoician billing app make life better for business owners.",
        "Employees can use the billing software easily as it has a user-friendly interface. It helps create tax invoices seamlessly. Numerous other features in the Invoician app make it stand out among competitors. All features combined makes Invoician the best billing software in India.",
      ],
    },
    {
      no: 10,
      question: "How do I invoice clients for free?",
      answer: [
        "You can create GST and non-GST invoices for your clients. All you need is to use the best billing software by Invoician. You can download the Invoician invoicing software. Start with entering new party details. Once done, you can create and send invoices without paying any fees."
      ],
    },
    {
      no: 11,
      question: "What is online billing software?",
      answer: ["Online billing software is used to create bills for customers online. The billing tool is used by businesses to get paid for a contract. The billing software that makes online bills do not require you to download the app. You can go to online billing software by Invoician, enter details for the invoice to create GST bills. Once generated, you can send them over to your customers."],
    },
    {
      no: 12,
      question: "What is the cheapest billing software?",
      answer: [
        "Invoician brings you the cheapest billing software that comes with various accounting features. You can use the app at a small subscription cost. It helps create professional invoices for customers.",
        "Using affordable billing software, you can reduce the cost of operating your business. After all, Invoician assists you in various business management tasks."
      ],
    },
    {
      no: 13,
      question: "How do I get a computerized GST bill?",
      answer: [
        "You can use Invoician invoice templates to create computerized GST bills for your customers. The invoicing tools and billing software by Invoician make the entire process seamless. It does not make a dent in your financials, as free templates does not require you to pay.",
        "You can download the Invoician software, automate your business’s billing process. It will save your employees time by creating computerized bills."
      ],
    },
    {
      no: 14,
      question: "What is offline invoicing?",
      answer: [
        "Offline invoicing refers to creating invoices using offline invoicing software. It doesn’t have the requirement of an active internet connection. Using offline invoicing tool features is crucial for most business owners in India.",
        "It is due to the lack of connectivity in rural areas. Many businesses can’t access the internet during load shedding. Long hours of a power cut makes it impossible to bill without offline access. So they must have the best invoicing software for Windows and Mac like Invoician.",
        "Using the Invoician invoicing app makes it easier for them to create GST invoices without internet access. The customers don’t have to wait due to internet connectivity issues. Invoician ensures that the data is safe by helping businesses enable automatic data backups."
      ],
    },
    {
      no: 15,
      question: "What is offline billing software?",
      answer: [
        "Offline billing software creates professional GST bills for customers without an active internet connection. An offline billing tool is helpful for businesses in poor network connectivity. Regions like hilly and rural regions are best examples.",
        "Using the offline version helps ensure that customers get uninterrupted billing experience. The app data remains secure as the business owner can also do local backups. Further, the app can set up automatic backups too."
      ],
    },
  ];

  return (
    <div>
      <div className="faq-component">
        <div className="faq-component-main">
          <div className="faq-component-heading">
            Frequently Asked Questions
          </div>
          <div className="faq-component-content">
            {faqData.map((e) => (
              <div className="faq-component-content-col">
                <div
                  className="faq-component-question"
                  onClick={() =>
                    setViewAnswer(viewAnswer === e.no ? false : e.no)
                  }
                >
                  {e.question}




                  
                  {
                  viewAnswer === e.no ? 
                    <i className="fa-solid fa-angle-up"></i>
                  : 
                    <i className="fa-solid fa-angle-down"></i>
                  }


                </div>

                {viewAnswer === e.no ? (
                  <div className="faq-component-answer">
                    {e.answer.map((e) => (
                      <div>{e}</div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqComponent;
