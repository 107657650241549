import React from 'react'
import Navbar from './../components/main/Navbar'
import QrGeneratorHeader from './../components/qrCodePage/QrGeneratorHeader'
import HowToGenerateQrComponent from './../components/qrCodePage/HowToGenerateQrComponent'
import WhatIsQrCodeComponent from './../components/qrCodePage/WhatIsQrCodeComponent'
import QrCodeBenefitComponent from './../components/qrCodePage/QrCodeBenefitComponent'
import FooterComponent from './../components/main/FooterComponent'
import SpecialPurposeToolsComponent from './../components/main/SpecialPurposeToolsComponent'
import QrCodeFaqComponent from './../components/qrCodePage/QrCodeFaqComponent'
import QrCodeTypeComponent from './../components/qrCodePage/QrCodeTypeComponent'
import QrCodeCustomersSayComponent from './../components/qrCodePage/QrCodeCustomersSayComponent'
import QrCodeFreeGenerator from './../components/qrCodePage/QrCodeFreeGenerator'

const QrCodeGeneratorPage = () => {
  return (
    <div>
      

        <div className="qr-code-generator-page">
        <div className="qr-code-generator-page-main">

 

            <Navbar/>

            <QrGeneratorHeader/>

            <HowToGenerateQrComponent/>

            <QrCodeFreeGenerator/>

            <WhatIsQrCodeComponent/>

            <QrCodeBenefitComponent/>

            <QrCodeTypeComponent/>
            
            <QrCodeCustomersSayComponent/>

            <SpecialPurposeToolsComponent/>

            <QrCodeFaqComponent/>
            
            <FooterComponent/>
            



        </div>

        </div>

        
        
    </div>
  )
}

export default QrCodeGeneratorPage