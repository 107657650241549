import React from "react";

const AboutUsComponent = () => {
  return (
    <div>
      <div className="about-page-about">
        <div className="about-page-about-main">
          <div className="about-page-about-text">
            <b>Invoician</b> is a <b>FREE Business Accounting Software</b> built for Invoician
            is a FREE accounting software designed for Indian small businesses.
            It helps with invoicing, managing inventory, accounting, and more.
            The aim is to simplify daily tasks for business owners so they can
            spend less time on paperwork and more time growing their business.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsComponent;
