import React, { useState } from "react";
import ReactQRCode from "react-qr-code";
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';



const QrGeneratorHeader = () => {

    const [qrType, setQrType] = useState("text")

    const [qrValue, setQrValue] = useState("https://invoician.com/")
    
    const [inputValue, setInputValue] = useState("")

    const handleInput = (e) => {
        setInputValue(e.target.value)
    }

    const handleGenerateCode = (e, type) => {
        e.preventDefault()

        if(type === "wa"){
            setQrValue(`https://wa.me/+91${inputValue}`)
        }
        else if(type === "phone"){
            setQrValue(`tel:+91${inputValue}`)
        }
        else{
            setQrValue(inputValue)
        }
        
    }

    
    
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // download png :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    
    
    // Function to capture and download the div as PNG
  const handleDownloadPng = () => {
    const divElement = document.getElementById('capture-div'); // Get the div by ID
    
    html2canvas(divElement).then((canvas) => {
      // Convert canvas to PNG image
      const image = canvas.toDataURL('image/png');

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = image;
      link.download = 'qr-code.png'; // Set the default download filename
      link.click(); // Trigger the download
    });
  };
    
    
    
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // download jpg :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    
    
  const handleDownloadJpg = () => {
    const divElement = document.getElementById('capture-div'); 
    
    html2canvas(divElement).then((canvas) => {
        
      const image = canvas.toDataURL('image/jpeg');

      const link = document.createElement('a');
      link.href = image;
      link.download = 'qr-code.jpg';
      link.click(); 
    });
  };
    
    
    
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // download pdf :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    
    
  const handleDownloadPdf = () => {
     const divElement = document.getElementById('capture-div'); 
     
     const options = {
       margin:       1,
       filename:     'qr-code.pdf',  
       image:        { type: 'jpeg', quality: 0.98 },  
       html2canvas:  { scale: 2 },  
       jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }  
     };
 
     html2pdf().from(divElement).set(options).save();
   };
    
    
    
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    
    
  return (
    <div>
      <div className="qr-generator-header">
        <div className="qr-generator-header-main">

          <div id="qr-generator" className="qr-generator-header-heading">QR Code Generator Online!</div>

          <div className="qr-generator-header-content">
                <div className="qr-generator-header-content-details">
                    <div className="qr-generator-header-content-details-options">
                        <div onClick={ () => setQrType("text") } className={qrType === "text" ? "qr-generator-header-content-details-options-active" : ""}>Text</div>
                        <div onClick={ () => setQrType("url") } className={qrType === "url" ? "qr-generator-header-content-details-options-active" : ""}>URL</div>
                        <div onClick={ () => setQrType("wa") } className={qrType === "wa" ? "qr-generator-header-content-details-options-active" : ""}>WhatsApp</div>
                        <div onClick={ () => setQrType("phone") } className={qrType === "phone" ? "qr-generator-header-content-details-options-active" : ""}>Phone</div>
                        {/* <div onClick={ () => setQrType("contact") } className={qrType === "contact" ? "qr-generator-header-content-details-options-active" : ""}>Contact</div> */}
                    </div>



{
                    qrType === "text"?
                                    <form onSubmit={handleGenerateCode} className="qr-generator-header-content-form">
                                        <label>Enter Text</label>
                                        <input required type="text" placeholder="Your Text Here" onChange={handleInput}/>
                                        <button>Generate QR Code</button>
                                    </form>
                                    :
    qrType === "url"?
                    <form onSubmit={handleGenerateCode} className="qr-generator-header-content-form">
                        <label>Enter URL</label>
                        <input required type="text" placeholder="www.invoician.com" onChange={handleInput}/>
                        <button>Generate QR Code</button>
                    </form>
                                    :
    qrType === "wa"?
                    <form onSubmit={e=>handleGenerateCode(e, "wa")} className="qr-generator-header-content-form">
                        <label>Enter 10-digit Whatsapp Number</label>
                        <input required type="number" placeholder="Enter Whatsapp Number" onChange={handleInput}/>
                        <button>Generate QR Code</button>
                    </form>
                                    :
    qrType === "phone"?
                    <form onSubmit={e=>handleGenerateCode(e, "phone")} className="qr-generator-header-content-form">
                        <label>Enter 10-digit Phone No.</label>
                        <input required type="number" placeholder="Enter Phone No." onChange={handleInput}/>
                        <button>Generate QR Code</button>
                    </form>
                    
                    
:""
                    }
                    

                </div>
                <div className="qr-generator-header-content-code">

                <div className="qr-generator-header-content-code-blank"></div>

                <div className="qr-generator-header-content-code-details">

                <div id="capture-div" className="qr-generator-header-content-code-code">
                <ReactQRCode value={qrValue}  level="H" />
                </div>
                <div onClick={handleDownloadPng} className="qr-generator-header-content-code-button">Download PNG</div>
                <div className="qr-generator-header-content-code-buttons">
                    <div onClick={handleDownloadJpg}>JPG</div>
                    <div onClick={handleDownloadPdf}>PDF</div>
                </div>

                    
                </div>

                </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default QrGeneratorHeader;
