import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import PopUpAlertComponent from "../../../../components/extra/PopUpAlertComponent";
import UpdateExpenseComponent from "../update/UpdateExpenseComponent";
// import UpdateExpenseComponent from "../UpdateExpenseComponent";

const ViewDataExpense = ({
  open,
  fetchBusinessData,
  businessData,
  // expenseData,
  expenseId,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  const expenseData = businessData.expenseData.find((e) => e._id === expenseId);

  // useStates  ================================================================================================================

  const [popUpAlert, setPopUpAlert] = useState(false);
  const [updateExpense, setUpdateExpense] = useState(false);

  const [ViewData, setViewData] = useState(false)

  const calculateTax = (price, taxRate, quantity, discount, cess) => {
    const discountedPrice = price - (price * discount) / 100;

    return (
      ((discountedPrice * taxRate) / 100 + (cess * discountedPrice) / 100) *
      quantity
    );
  };

  const calculateTaxedPrice = (price, taxRate, quantity, discount, cess) => {
    const discountedPrice = price - (price * discount) / 100;

    return (
      (discountedPrice +
        (discountedPrice * taxRate) / 100 +
        (cess * discountedPrice) / 100) *
      quantity
    );
  };

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  // ==========================================================================================================================================
  // delete expense ===========================================================================================================================
  // ==========================================================================================================================================

  const { deleteSingleExpense } = useContext(AuthContext);

  const handleDelete = async (e) => {
    await deleteSingleExpense(
      userId,
      businessId,
      expenseId,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      open
    );
    fetchBusinessData && fetchBusinessData();
  };

  return (
    <div>
      {popUpAlert ? (
        <PopUpAlertComponent
          open={setPopUpAlert}
          ok={handleDelete}
          heading={`You want to delete expense data?`}
        />
      ) : (
        ""
      )}

      {updateExpense ? (
        <UpdateExpenseComponent
          open={setUpdateExpense}
          fetchBusinessData={fetchBusinessData}
          businessData={businessData}
          expenseData={expenseData}
          expenseId={expenseId}
        />
      ) : (
        ""
      )}

      <div className="view-staff">
        <div className="view-staff-main">
          {/* **************************************************************************************************** */}

          <div className="view-staff-col-data">
            <div className="view-staff-top">
              <div className="view-staff-top-heading">
                <i
                  class="fa-solid fa-arrow-left"
                  onClick={() => open(false)}
                ></i>{" "}
                Expense # {expenseData && expenseData.expenseNumber}
              </div>
              <div className="view-staff-top-buttons">
                <div
                  className="view-expense-component-top-edit"
                  onClick={() => setUpdateExpense(true)}
                >
                  <i class="fa-solid fa-pen-to-square"></i>
                  <span>Edit</span>
                </div>
                <div
                  className="view-expense-component-top-delete"
                  onClick={() => setPopUpAlert(true)}
                >
                  <i class="fa-solid fa-trash"></i>
                </div>
              </div>
            </div>

            <div className="view-expense-content">
              <div className="view-expense-content-details-data">
                <div className="app-staff-page-details-data-row-4">
                  <div className="app-staff-page-details-data-col">
                    <div className="app-staff-page-details-data-col-heading">
                      Party Name
                    </div>
                    <div className="app-staff-page-details-data-col-value">
                      {expenseData.expensePartyData
                        ? expenseData.expensePartyData.partyName
                        : "-"}
                    </div>
                  </div>

                  <div className="app-staff-page-details-data-col">
                    <div className="app-staff-page-details-data-col-heading">
                      Date
                    </div>
                    <div className="app-staff-page-details-data-col-value">
                      {expenseData.expenseDate
                        ? new Date(expenseData.expenseDate).toDateString()
                        : "-"}
                    </div>
                  </div>

                  <div className="app-staff-page-details-data-col">
                    <div className="app-staff-page-details-data-col-heading">
                      Expense category
                    </div>
                    <div className="app-staff-page-details-data-col-value">
                      {expenseData.expenseCategory
                        ? expenseData.expenseCategory
                        : "-"}
                    </div>
                  </div>

                  <div className="app-staff-page-details-data-col">
                    <div className="app-staff-page-details-data-col-heading">
                      Payment Mode
                    </div>
                    <div className="app-staff-page-details-data-col-value">
                      {expenseData.paymentType ? expenseData.paymentType : "-"}
                    </div>
                  </div>
                  {expenseData.paymentType === "Bank Transfer" ||
                  expenseData.paymentType === "UPI" ||
                  expenseData.paymentType === "Card" ||
                  expenseData.paymentType === "Cheque" ||
                  expenseData.paymentType === "Netbanking" ? (
                    <div className="app-staff-page-details-data-col">
                      <div className="app-staff-page-details-data-col-heading">
                        Payment In
                      </div>
                      <div className="app-staff-page-details-data-col-value">
                        {expenseData.selectedBankAccount
                          ? businessData.bankData
                              .filter(
                                (e) => e._id === expenseData.selectedBankAccount
                              )
                              .map((e) => <>{e.bankName}</>)
                          : "-"}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="app-staff-page-details-data-col">
                    <div className="app-staff-page-details-data-col-heading">
                      Notes
                    </div>
                    <div className="app-staff-page-details-data-col-value">
                      {expenseData.expenseNote ? expenseData.expenseNote : "-"}
                    </div>
                  </div>

                  {!expenseData.expenseItemsData.length > 0 ? (
                    <div className="app-staff-page-details-data-col">
                      <div className="app-staff-page-details-data-col-heading">
                        Total Expense Amount
                      </div>
                      <div className="app-staff-page-details-data-col-value">
                        ₹{" "}
                        {expenseData.expenseTotalAmount
                          ? expenseData.expenseTotalAmount
                          : "-"}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {expenseData.expenseItemsData.length > 0 ? (
                <div className="view-party-page-transaction-data">
                  <div className="view-party-page-transaction-data-main">
                    <div className="view-party-page-transaction-data-table">
                      <div className="view-party-page-transaction-data-table-head view-expense-content-table-head">
                        <div>Item Name</div>
                        <div>QTY</div>
                        <div>PRICE/ITEM(₹)</div>
                        <div>DISCOUNT(₹)</div>
                        <div>Tax(₹)</div>
                        <div>Total Amount</div>
                      </div>

                      {expenseData &&
                        expenseData.expenseItemsData.map((e) => (
                          <div className="view-party-page-transaction-data-table-body view-expense-content-table-body">
                            <div>{e.itemName}</div>
                            <div>{e.quantity}</div>
                            <div>{e.itemPurchasePrice}</div>
                            <div>{e.discount ? e.discount : 0}</div>

                            <div>
                              {formatNumber(
                                calculateTax(
                                  e.itemPurchasePrice,
                                  e.itemGst,
                                  e.quantity,
                                  e.discount,
                                  e.cess
                                )
                              ) * 1}
                            </div>

                            <div>
                              {formatNumber(
                                calculateTaxedPrice(
                                  e.itemPurchasePrice,
                                  e.itemGst,
                                  e.quantity,
                                  e.discount,
                                  e.cess
                                )
                              ) * 1}
                            </div>
                          </div>
                        ))}
                    </div>

                    <div className="view-party-page-transaction-data-small">

                      {
                        expenseData &&
                        expenseData.expenseItemsData.map( (e, i) => (
                          

                      <div className="view-party-page-transaction-data-small-col">

                        <div className="view-party-page-transaction-data-small-col-head">
                          <div className="view-party-page-transaction-data-small-name">
                            {i+1}) {e.itemName}
                          </div>
{
  ViewData === e._id?

                          <div onClick={ () => setViewData(null)} className="view-party-page-transaction-data-small-close">
                            ₹ {e.itemPurchasePrice}
                              <i className="fa-solid fa-caret-up"></i>
                          </div>
:
                          <div onClick={ () => setViewData(e._id)} className="view-party-page-transaction-data-small-open">
                            ₹ {e.itemPurchasePrice}
                              <i className="fa-solid fa-caret-down"></i>
                          </div>
}

                        </div>
                        {
  ViewData === e._id?

                        <div className="view-party-page-transaction-data-small-col-view">
                          <div className="view-party-page-transaction-data-small-view-col"><div>Quantity :</div><span>{e.quantity}</span></div>
                          <div className="view-party-page-transaction-data-small-view-col"><div>Price :</div><span>₹ {e.itemPurchasePrice}</span></div>
                          <div className="view-party-page-transaction-data-small-view-col"><div>Discount :</div><span>₹ {e.discount ? e.discount : 0}</span></div>
                          <div className="view-party-page-transaction-data-small-view-col"><div>Tax :</div><span>₹ {formatNumber(
                                calculateTax(
                                  e.itemPurchasePrice,
                                  e.itemGst,
                                  e.quantity,
                                  e.discount,
                                  e.cess
                                )
                              ) * 1}</span></div>
                          <div className="view-party-page-transaction-data-small-view-col"><div>Total Amount :</div><span>₹ {formatNumber(
                                calculateTaxedPrice(
                                  e.itemPurchasePrice,
                                  e.itemGst,
                                  e.quantity,
                                  e.discount,
                                  e.cess
                                )
                              ) * 1}</span></div>

                        </div>
                        :""}


                        
                      </div>
                        ))
}

                    </div>

                    <div className="view-expense-content-table-end-margin"></div>
                    {expenseData.expenseDiscount ? (
                      <div className="view-expense-content-table-end">
                        <span>Discount</span>
                        <div>
                          <b>₹ {formatNumber(expenseData.expenseDiscount)}</b>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="view-expense-content-table-end">
                      <span>Total Expense Amount</span>
                      <div>
                        <b>₹ {expenseData.expenseTotalAmount}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDataExpense;
