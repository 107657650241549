import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";

const CreateAdjustBalancePopup = ({
  open,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
  fetchData,
  totalCash,
  businessData,
  totalBankBalance,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useStates  ================================================================================================================

  const [data, setData] = useState({
    transactionIn: "Cash",
    transactionType: "Add Money",
    invoiceDate: new Date().toISOString().split("T")[0],
    receivedAmount: 0,
    transactionRemark: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  //   --------------------------------------------------------------------------------------------------------------

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };

  const { createAdjustBalance } = useContext(AuthContext);

  const handleCreate = async (e) => {
    e.preventDefault();
    await createAdjustBalance(
      userId,
      businessId,
      data,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      open
    );

    fetchData && fetchData();
  };

  // ===========================================================================
  // ===========================================================================

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  return (
    <div>
      {/* <form className="create-party-popup"> */}
      <form onSubmit={handleCreate} className="create-party-popup">
        <div className="create-party-popup-main">
          <div className="create-party-popup-top">
            <div>Adjust Balance</div>
            <i onClick={() => open(false)} class="fa-solid fa-xmark"></i>
          </div>

          <div className="create-party-popup-form">
            <div className="create-party-popup-form-col">
              <label>
                Adjust money in<span>*</span>
              </label>
              <select
                required
                name="transactionIn"
                onChange={handleChange}
                value={data.transactionIn}
              >
                <option disabled selected value="">
                  select
                </option>
                <option value="Cash">Cash</option>

                {businessData.bankData.map((e) => (
                  <option value={e._id}>{e.accountName}</option>
                ))}
              </select>
            </div>

            <div className="create-party-popup-form-col">
              <label>Add or Reduce</label>
              <div className="create-popup-form-adjust-balance">
                <div
                  className={
                    data.transactionType === "Add Money"
                      ? "create-popup-form-adjust-balance-active"
                      : ""
                  }
                  onClick={() =>
                    setData({ ...data, transactionType: "Add Money" })
                  }
                >
                  Add Money
                </div>
                <div
                  className={
                    data.transactionType === "Reduce Money"
                      ? "create-popup-form-adjust-balance-active"
                      : ""
                  }
                  onClick={() =>
                    setData({ ...data, transactionType: "Reduce Money" })
                  }
                >
                  Reduce Money
                </div>
              </div>
            </div>

            <div className="create-party-popup-form-row">
              <div className="create-party-popup-form-col">
                <label>Current Balance</label>
                <div>
                  ₹{" "}
                  {data.transactionIn === "Cash"
                    ? formatNumber(totalCash)
                    : totalBankBalance(data.transactionIn)}
                </div>
              </div>

              <div className="create-party-popup-form-col">
                <label>
                  Date<span>*</span>
                </label>
                <input
                  type="date"
                  required
                  onChange={handleChange}
                  name="invoiceDate"
                  value={data.invoiceDate}
                  placeholder=""
                />
              </div>
            </div>

            <div className="create-party-popup-form-col">
              <label>
                Enter Amount<span>*</span>
              </label>
              <input
                type="number"
                required
                onChange={handleChange}
                name="receivedAmount"
                value={data.receivedAmount}
                placeholder="ex: ₹ 200"
                min={1}
              />
            </div>
            <div className="create-party-popup-form-col">
              <label>New Balance</label>
              <div>
                ₹{" "}
                {
                  data.transactionIn === "Cash"?
                formatNumber(
                  data.transactionType === "Add Money"
                    ? totalCash + Number(data.receivedAmount)
                    : data.transactionType === "Reduce Money"
                    ? totalCash - Number(data.receivedAmount)
                    : ""
                )
                :
                formatNumber(
                  data.transactionType === "Add Money"
                    ? totalBankBalance(data.transactionIn) + Number(data.receivedAmount)
                    : data.transactionType === "Reduce Money"
                    ? totalBankBalance(data.transactionIn) - Number(data.receivedAmount)
                    : ""
                )
                
                }
              </div>
            </div>

            <div className="create-party-popup-form-col">
              <label>Remarks</label>
              <textarea
                type=""
                onChange={handleChange}
                name="transactionRemark"
                value={data.transactionRemark}
                placeholder=""
              />
            </div>
          </div>

          <div className="create-party-popup-buttons">
            <div
              className="create-party-popup-button-cancel"
              onClick={() => open(false)}
            >
              Cancel
            </div>
            {/* <button className="create-party-popup-button-save" onClick={handleSaveData}>Save</button> */}
            <button className="create-party-popup-button-save">Save</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateAdjustBalancePopup;
