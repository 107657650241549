import React from 'react'
import { Link } from 'react-router-dom'

const StartInvoicingComponent = () => {
  return (
    <div>


        <div className="start-invoicing">
        <div className="start-invoicing-main">
            <div>
        <div className="start-invoicing-heading-1">Your trust and security are our priority</div>
        <div className="start-invoicing-heading-2">Start today and get <div className='flipX' >life time</div> free of the Premium plan.</div>
            </div>
        <Link to="/login" className="start-invoicing-link">Start Invoicing</Link>

        </div>

        </div>
        
    </div>
  )
}

export default StartInvoicingComponent  