import React from "react";
import img from "../../../media/images/website/tax_invoice_new.webp";

const ElementsCreateInvoiceComponent = () => {
  return (
    <div>
      <div className="elements-create-invoice">
        <div className="elements-create-invoice-main">
          <div className="elements-create-invoice-heading">
            Elements to Create an Invoice on the Invoician Invoice Generator Tool
          </div>
          <div className="elements-create-invoice-content">
          <div className="elements-create-invoice-image">
            <img src={img} alt="" />
          </div>

          <div className="elements-create-invoice-details">
          <div className="elements-create-invoice-details-text">
          When creating an invoice for your customer using the Invoician invoice maker,
          <br />
it's essential to include specific mandatory details to ensure compliance and clarity.
          <br />
Here are the required information:
          <br />
          </div>
          <div className="elements-create-invoice-details-heading">&#x2714; Invoice Number: <span>A unique identifier for the invoice. </span></div>
          <div className="elements-create-invoice-details-heading">&#x2714; Invoice Date: <span>The date when the invoice is issued. </span></div>
          <div className="elements-create-invoice-details-heading">&#x2714; Supplier Details:</div>
          <div className="elements-create-invoice-details-points">
            <li>Business Name</li>
            <li>Address</li>
            <li>Contact Information</li>
            <li>GSTIN (if applicable)</li>
          </div>

          <div className="elements-create-invoice-details-heading">&#x2714; Customer Details:</div>
          <div className="elements-create-invoice-details-points">
            <li>Name</li>
            <li>Address</li>
            <li>Contact Information</li>
            <li>GSTIN (if applicable)</li>
          </div>

          <div className="elements-create-invoice-details-heading">&#x2714; Description of Goods or Services Provided:</div>
          <div className="elements-create-invoice-details-points">

            <li>Item Name</li>
            <li>Quantity</li>
            <li>Unit Price</li>
            <li>Total Amount for Each Item</li>
            
          </div>


          <div className="elements-create-invoice-details-heading">&#x2714; Tax Details:</div>
          <div className="elements-create-invoice-details-points">

           <li>Applicable GST Rates</li>
           <li>Tax Amount for Each Item</li>
            
          </div>
          <div className="elements-create-invoice-details-heading">&#x2714; Total Amount:</div>
          <div className="elements-create-invoice-details-points">

            <li>Subtotal (before tax)</li>
            <li>Total Tax Amount</li>
            <li>Grand Total (including taxes)</li>
            
          </div>


          <div className="elements-create-invoice-details-heading">&#x2714; Payment Terms: <span>Due date and accepted payment methods. </span></div>
          <div className="elements-create-invoice-details-heading">&#x2714; Signature: <span>Authorized signatory (if applicable). </span></div>

            
          </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ElementsCreateInvoiceComponent;
