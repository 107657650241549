import React from "react";
import Navbar from "../components/main/Navbar";
import BarcodeGeneratorHeader from "../components/barcodeGenerator/BarcodeGeneratorHeader";
import HowToGenerateBarcodeComponent from "../components/barcodeGenerator/HowToGenerateBarcodeComponent";
import BarcodeFreeGenerator from "../components/barcodeGenerator/BarcodeFreeGenerator";
import WhatIsBarcodeComponent from "../components/barcodeGenerator/WhatIsBarcodeComponent";
import UseBarcodeComponent from "../components/barcodeGenerator/UseBarcodeComponent";
import BarcodeManagingComponent from "../components/barcodeGenerator/BarcodeManagingComponent";
import FooterComponent from "../components/main/FooterComponent";
import QrCodeCustomersSayComponent from "../components/qrCodePage/QrCodeCustomersSayComponent";
import SpecialPurposeToolsComponent from "../components/main/SpecialPurposeToolsComponent";
import BarcodeFaqComponent from "../components/barcodeGenerator/BarcodeFaqComponent";

const BarcodeGeneratorPage = () => {
  return (
    <div>
      <div className="barcode-generator-page">
        <div className="barcode-generator-page-main">

          <Navbar />

          <BarcodeGeneratorHeader/>

          <HowToGenerateBarcodeComponent/>
          
          <BarcodeFreeGenerator/>

          <WhatIsBarcodeComponent/>

          <UseBarcodeComponent/>

          <BarcodeManagingComponent/>

            <QrCodeCustomersSayComponent/>

            <SpecialPurposeToolsComponent/>

            <BarcodeFaqComponent/>




          <FooterComponent/>
          
          
          
        </div>
      </div>
    </div>
  );
};

export default BarcodeGeneratorPage;
