import React, { useContext } from "react";
import { toWords } from "number-to-words";
import { AuthContext } from "../../../context/AuthContext";

import QRCode from "react-qr-code";
import phonePe from "../../../media/images/icons/phonepe.png"
import gpay from "../../../media/images/icons/gpay.png"
import paytm from "../../../media/images/icons/paytm.png"
import upi from "../../../media/images/icons/upi.png"


const SharedInvoiceStylishTheme = ({invoiceData}) => {

  const { link } = useContext(AuthContext);

  const data = invoiceData&&invoiceData.invoiceData



  const calculateTaxedPrice = (price, taxRate, quantity, discount, cess) => {
    const discountedPrice = price - (price * discount) / 100;

    return (
      (discountedPrice +
        (discountedPrice * taxRate) / 100 +
        (cess * discountedPrice) / 100) *
      quantity
    );
  };



  const taxableAmount = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (item.itemPrice * item.quantity -
        (item.itemPrice * item.discount * item.quantity) / 100)
    );
  }, 0);

  const totalGst = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPrice - (item.discount * item.itemPrice) / 100) *
        item.itemGst) /
        100) *
        item.quantity
    );
  }, 0);

  const totalCess = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPrice - (item.discount * item.itemPrice) / 100) * item.cess) /
        100) *
        item.quantity
    );
  }, 0);


// --------------------


const taxablePurchaseAmount = data.invoiceItemsData.reduce((acc, item) => {
  return (
    acc +
    (item.itemPurchasePrice * item.quantity -
      (item.itemPurchasePrice * item.discount * item.quantity) / 100)
  );
}, 0);

const totalPurchaseGst = data.invoiceItemsData.reduce((acc, item) => {
  return (
    acc +
    (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) *
      item.itemGst) /
      100) *
      item.quantity
  );
}, 0);

  const totalPurchaseCess = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) * item.cess) /
        100) *
        item.quantity
    );
  }, 0);




  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  
  return (
    <div>

      <div className="invoice-theme-1-theme">
        {/* invoice address *****************************************************************************************************88 */}
        <div className="invoice-theme-1-heading">
          tax invoice <span>ORIGINAL FOR RECIPIENT</span>
        </div>

        
        <div className="invoice-theme-1-company">

          <div className="invoice-theme-1-company-logo">
            {invoiceData.invoiceCompanyData.companyLogo ? (
              <img
                src={`${link}/${invoiceData.invoiceCompanyData.companyLogo}`}
                // alt="logo"
              />
            ) : (
              "" 
            )}
          </div>
          
          <div className="invoice-theme-1-company-details">
            <div className="invoice-theme-1-company-details-name" style={{color:`${invoiceData.invoiceThemeColor}`}}>
              {invoiceData.invoiceCompanyData.businessName}
            </div>
            {
              invoiceData.invoiceCompanyData.companyAddress?
              <div className="invoice-theme-1-company-details-text">
                {invoiceData.invoiceCompanyData.companyAddress}
            </div>
            :""
            }

            <div className="invoice-theme-1-company-details-row">

              {
                invoiceData.invoiceCompanyData.companyPhoneNumber?
              
              <div className="invoice-theme-1-company-details-text">
                  <b>Mobile :</b> {invoiceData.invoiceCompanyData.companyPhoneNumber}
                  
              </div>
              :""}

{
  invoiceData.invoiceCompanyData.companyGSTIN?

              <div className="invoice-theme-1-company-details-text">

                  <b>GSTIN :</b> {invoiceData.invoiceCompanyData.companyGSTIN}
                 
              </div>
              :""}

{invoiceData.invoiceCompanyData.companyEmail?
              <div className="invoice-theme-1-company-details-text">
                
                    <b>Email :</b> {invoiceData.invoiceCompanyData.companyEmail}
                 
              </div>
              :""}
              
            </div>

          </div>

          
          
        </div>

        {/* invoice details *****************************************************************************************************88 */}
        <div
          className="invoice-theme-1-invoive-details"
          // style={{color:`${invoiceData.invoiceThemeColor}`}}
          style={{ backgroundColor: `${invoiceData.invoiceThemeColor}20`, borderTop: `5px solid ${invoiceData.invoiceThemeColor}` }}
        >
          <div className="invoice-theme-1-invoive-details-col">
            <div className="invoice-theme-1-invoive-details-col-heading">
            Invoice No. :
            </div>
            <div className="invoice-theme-1-invoive-details-col-data">
              {data.invoiceNumber ? data.invoiceNumber : "__________"}
            </div>
          </div>

          <div className="invoice-theme-1-invoive-details-col">
            <div className="invoice-theme-1-invoive-details-col-heading">
            Invoice Date :
            </div>
            <div className="invoice-theme-1-invoive-details-col-data">
              {data.invoiceDate ? (
                <>
                  {new Date(data.invoiceDate)
                    .getDate()
                    .toString()
                    .padStart(2, 0)}
                  /
                  {(new Date(data.invoiceDate).getMonth() + 1)
                    .toString()
                    .padStart(2, 0)}
                  /{new Date(data.invoiceDate).getFullYear()}
                </>
              ) : (
                "__________"
              )}
            </div>
          </div>

    {data.invoiceEnableDueDate ? (
            <div className="invoice-theme-1-invoive-details-col">
              <div className="invoice-theme-1-invoive-details-col-heading">
                Due Date :
              </div>
              <div className="invoice-theme-1-invoive-details-col-data">
                <>
                  {new Date(data.invoiceDueDate)
                    .getDate()
                    .toString()
                    .padStart(2, 0)}
                  /
                  {(new Date(data.invoiceDueDate).getMonth() + 1)
                    .toString()
                    .padStart(2, 0)}
                  /{new Date(data.invoiceDueDate).getFullYear()}
                </>
              </div>
            </div>
          ) : (
            ""
          )}

        </div>

        {/* party details *****************************************************************************************************88 */}

        <div className="invoice-theme-1-party-details">
          {/* ------------------------------------------------------------------------- */}
          {/* ------------------------------------------------------------------------- */}
          {/* ------------------------------------------------------------------------- */}
          {/* ------------------------------------------------------------------------- */}
          {/* ------------------------------------------------------------------------- */}

          {/* **************************** */}
          <div className="invoice-theme-1-party-details-col-1">
            <div>
              <div className="invoice-theme-1-party-details-col-1-heading">
                Bill From{" "}
              </div>

              {data.invoicePartyData && data.invoicePartyData.partyName ? (
                <div className="invoice-theme-1-party-details-col-1-name">
                  {data.invoicePartyData.partyName}
                </div>
              ) : (
                "-------------"
              )}
              {(data.invoicePartyData &&
                data.invoicePartyData.partyBillingAddress) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingState) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingCity) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingPinCode) ? (
                <div>
                  {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingAddress}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingCity ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingCity}
                    </>
                  ) : (
                    ""
                  )}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingState ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingState}
                    </>
                  ) : (
                    ""
                  )}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingPinCode ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingPinCode}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {data.invoicePartyData && data.invoicePartyData.partyNumber ? (
                <div className="invoice-theme-1-party-details-col-1-text">
                  <b>Mobile :</b>{" "}
                  {data.invoicePartyData && data.invoicePartyData.partyNumber}
                </div>
              ) : (
                ""
              )}
              {data.invoicePartyData && data.invoicePartyData.partyGSTIN ? (
                <div className="invoice-theme-1-party-details-col-1-text">
                  <b>GSTIN : </b>
                  {data.invoicePartyData && data.invoicePartyData.partyGSTIN}
                </div>
              ) : (
                ""
              )}
            </div>

            {data.invoicePartyData.partyBothAddressSame ? (
              <div>
                <div className="invoice-theme-1-party-details-col-1-heading">
                  Ship From{" "}
                </div>

                {data.invoicePartyData && data.invoicePartyData.partyName ? (
                  <div className="invoice-theme-1-party-details-col-1-name">
                    {data.invoicePartyData.partyName}
                  </div>
                ) : (
                  "-------------"
                )}
                {(data.invoicePartyData &&
                  data.invoicePartyData.partyBillingAddress) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyBillingState) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyBillingPinCode) ? (
                  <div>
                    {data.invoicePartyData &&
                      data.invoicePartyData.partyBillingAddress}
                    {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingState ? (
                      <>
                        ,{" "}
                        {data.invoicePartyData &&
                          data.invoicePartyData.partyBillingState}
                      </>
                    ) : (
                      ""
                    )}
                    {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingPinCode ? (
                      <>
                        ,{" "}
                        {data.invoicePartyData &&
                          data.invoicePartyData.partyBillingPinCode}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {data.invoicePartyData && data.invoicePartyData.partyNumber ? (
                  <div className="invoice-theme-1-party-details-col-1-text">
                    <b>Mobile :</b>{" "}
                    {data.invoicePartyData && data.invoicePartyData.partyNumber}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <>
                {(data.invoicePartyData &&
                  data.invoicePartyData.partyShippingAddress) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyShippingCity) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyShippingState) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyShippingPinCode) ? (
                  <div>
                    <div className="invoice-theme-1-party-details-col-1-heading">
                      Ship From{" "}
                    </div>
                    {data.invoicePartyData &&
                    data.invoicePartyData.partyName ? (
                      <div className="invoice-theme-1-party-details-col-1-name">
                        {data.invoicePartyData.partyName}
                      </div>
                    ) : (
                      "-------------"
                    )}
                    <div>
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyShippingAddress}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingCity ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingCity}
                        </>
                      ) : (
                        ""
                      )}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingState ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingState}
                        </>
                      ) : (
                        ""
                      )}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingPinCode ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingPinCode}
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    {data.invoicePartyData &&
                    data.invoicePartyData.partyNumber ? (
                      <div className="invoice-theme-1-party-details-col-1-text">
                        <b>Mobile :</b>{" "}
                        {data.invoicePartyData &&
                          data.invoicePartyData.partyNumber}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          {/* ******************************** */}
          {/* ---------------------------------------------------------------- */}
          {/* ---------------------------------------------------------------- */}
          {/* ---------------------------------------------------------------- */}
          {/* ---------------------------------------------------------------- */}
          {/* ---------------------------------------------------------------- */}
          {/* ---------------------------------------------------------------- */}
          {/* ---------------------------------------------------------------- */}
            <div className="invoice-theme-1-party-details-col-2">
              <table>
          {data.invoiceDetailsOptions ? (
                <>
                {
                  Object.entries(data.invoiceDetailsOptions)
                    .filter(([key, value]) => value) // Show only fields with values
                    .map(([key, value]) => (
                      <tr key={key}>
                        <td>{key} </td>
                        <td>{value}</td>
                      </tr>
                    ))

                  }
         </>   
          ) : (
            ""
          )}
            {
              data.invoicePoNo?
              <tr >
                        <td>P.O. No. </td>
                        <td>{data.invoicePoNo}</td>
                      </tr>
                      :""
                    }
            {
              data.invoiceVehicleNo?
              <tr >
                        <td>Vehicle No.</td>
                        <td>{data.invoiceVehicleNo}</td>
                      </tr>
                      :""
                    }
            
              </table>
            </div>
        </div>

        {/* party details *****************************************************************************************************88 */}
        <>
          <div className="invoice-theme-1-item-data">
            <table className="invoice-theme-1-item-data-table">
              <thead style={{ backgroundColor: `${invoiceData.invoiceThemeColor}20` }}>
                <tr>
                  <th>NO</th>
                  <th>ITEMS/SERVICES</th>
                  {data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                  0 ? (
                    <th>HSN/SAC</th>
                  ) : (
                    ""
                  )}
                  <th>QTY.</th>
                  <th>RATE</th>
                  {data.invoiceItemsData.filter((e) => e.discount).length >
                  0 ? (
                    <th>DISC.</th>
                  ) : (
                    ""
                  )}
                  {data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? (
                    <th>TAX</th>
                  ) : (
                    ""
                  )}
                  {data.invoiceItemsData.filter((e) => e.cess).length > 0 ? (
                    <th>CESS</th>
                  ) : (
                    ""
                  )}
                  <th>AMOUNT</th>
                </tr>
              </thead>

              <tbody>
                {data.invoiceItemsData.map((e, i) => (
                  <tr>
                    <td style={{ borderBottom: `solid ${invoiceData.invoiceThemeColor}30 2px` }}>
                      {i + 1}
                    </td>
                    <td style={{ borderBottom: `solid ${invoiceData.invoiceThemeColor}30 2px` }}>
                      {e.itemName}
                      <div>{e.itemDescription}</div>
                    </td>

                    {data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                    0 ? (
                      <td
                        style={{ borderBottom: `solid ${invoiceData.invoiceThemeColor}30 2px` }}
                      >
                        {e.itemHsnCode ? e.itemHsnCode : "-"}
                      </td>
                    ) : (
                      ""
                    )}

                    <td style={{ borderBottom: `solid ${invoiceData.invoiceThemeColor}30 2px` }}>
                      {formatNumber(Number(e.quantity))*1}
                    </td>

                    <td style={{ borderBottom: `solid ${invoiceData.invoiceThemeColor}30 2px` }}>
                      {
                      formatNumber(Number(e.itemPrice))*1
                      }
                    </td>
                    {data.invoiceItemsData.filter((e) => e.discount).length >
                    0 ? (
                      <td
                        style={{ borderBottom: `solid ${invoiceData.invoiceThemeColor}30 2px` }}
                      >
                        {e.discount ? formatNumber(e.discount)*1 : 0}%
                      </td>
                    ) : (
                      ""
                    )}
                    {data.invoiceItemsData.filter((e) => e.itemGst).length >
                    0 ? (
                      <td
                        style={{ borderBottom: `solid ${invoiceData.invoiceThemeColor}30 2px` }}
                      >
                        {e.itemGst ? e.itemGst : 0}%
                      </td>
                    ) : (
                      ""
                    )}
                    {data.invoiceItemsData.filter((e) => e.cess).length > 0 ? (
                      <td
                        style={{ borderBottom: `solid ${invoiceData.invoiceThemeColor}30 2px` }}
                      >
                        {e.cess ? e.cess : 0}%
                      </td>
                    ) : (
                      ""
                    )}

                    <td style={{ borderBottom: `solid ${invoiceData.invoiceThemeColor}30 2px` }}>

                      {
                      formatNumber(
                        calculateTaxedPrice(
                          e.itemPrice,
                          e.itemGst,
                          e.quantity,
                          e.discount,
                          e.cess
                        )
                      ) * 1
                      
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            className="invoice-theme-1-item-data-sub-total"
            style={{ backgroundColor: `${invoiceData.invoiceThemeColor}20`, borderTop: `5px solid ${invoiceData.invoiceThemeColor}` }}
          >
            <div>Subtotal</div>
            <div> ₹ {formatNumber(data.invoiceSubTotalAmount) * 1}</div>
          </div>
        </>

        {/* bill end *****************************************************************************************************88 */}

        {/* bill end *****************************************************************************************************88 */}

        <div className="invoice-theme-1-end">
          <div className="invoice-theme-1-end-col-1">
            {/* ----------------------------------- */}

            {data.invoiceNotes ? (
              <div className="invoice-theme-1-end-col-1-tc">
                <div className="invoice-theme-1-end-col-1-tc-heading">
                  NOTES
                </div>
                <div className="invoice-theme-1-end-col-1-tc-list">
                  <pre>{data.invoiceNotes}</pre>
                </div>
              </div>
            ) : (
              ""
            )}

            {invoiceData.invoiceTermsConditions ? (
              <div className="invoice-theme-1-end-col-1-tc">
                <div className="invoice-theme-1-end-col-1-tc-heading">
                  TERMS AND CONDITIONS
                </div>
                <div className="invoice-theme-1-end-col-1-tc-list">
                  <pre>{invoiceData.invoiceTermsConditions}</pre>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* ----------------------------------- */}
           
           {/* <ViewInvoiceUpiComponent businessData={businessData} data={data}/> */}

           <div>

{


  data.paymentType === "Bank Transfer" ||
  data.paymentType === "UPI" ||
  data.paymentType === "Card" ||
  data.paymentType === "Cheque" ||
  data.paymentType === "Netbanking" ?

invoiceData.invoiceBankData?
                
              <div className="invoice-theme-1-end-col-1-bank-datails">
                
                <div className="invoice-theme-1-end-col-1-bank-datails-heading">
                  Bank Details
                </div>

                <table className="invoice-theme-1-end-col-1-bank-datails-data">
                  <tr>
                    <td>Name:</td>
                    <td>{invoiceData.invoiceBankData.accountHolderName}</td>
                  </tr>
                  <tr>
                    <td>IFSC Code:</td>
                    <td>{invoiceData.invoiceBankData.IFSCcode}</td>
                  </tr>
                  <tr>
                    <td>Account No:</td>
                    <td>{invoiceData.invoiceBankData.accountNumber}</td>
                  </tr>
                  <tr>
                    <td>Bank:</td>
                    <td>{invoiceData.invoiceBankData.bankName}</td>
                  </tr>
                </table>   

                
{
  invoiceData.invoiceBankData.upiId?
       
                <div className="invoice-theme-1-end-col-1-bank-datails-upi">
                <div className="invoice-theme-1-end-col-1-bank-datails-upi-col-1">
                <div className="invoice-theme-1-end-col-1-bank-datails-heading">
                  Payment Qr Code
                </div>
                  <b>UPI ID:</b>
                  <div>{invoiceData.invoiceBankData.upiId}</div>
                <div className="invoice-theme-1-end-col-1-bank-icons">
                  <img src={phonePe} alt="" />
                  <img src={gpay} alt="" />
                  <img src={paytm} alt="" />
                  <img src={upi} alt="" />

                </div>
                </div>
                <div className="invoice-theme-1-end-col-1-bank-datails-upi-col-2">
                <QRCode
        value={`upi://pay?pa=${invoiceData.invoiceBankData.upiId}`}
        size={70}
        level="M"
      />
                  
                </div>
                
                </div>
                :""}      

                
              </div>

              : 
              ""
              :""
            }
        
    </div>
           
           
          </div>

          {/* ====================================================================== */}

          <div className="invoice-theme-1-end-col-2">
            <div className="invoice-theme-1-end-col-2-amount">
              <div className="invoice-theme-1-end-col-2-amount-col">
                <div className="invoice-theme-1-end-col-2-amount-col-heading">
                  Taxable Value
                </div>
                <div className="invoice-theme-1-end-col-2-amount-col-data">
                  ₹ {
                  formatNumber(taxableAmount) * 1
                  }
                </div>
              </div>
              {data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? (
                <>
                  <div className="invoice-theme-1-end-col-2-amount-col">
                    <div className="invoice-theme-1-end-col-2-amount-col-heading">
                      CGST
                    </div>
                    <div className="invoice-theme-1-end-col-2-amount-col-data">
                      ₹ {
                      formatNumber(totalGst) / 2
                    }
                    </div>
                  </div>

                  <div className="invoice-theme-1-end-col-2-amount-col">
                    <div className="invoice-theme-1-end-col-2-amount-col-heading">
                      SGST
                    </div>
                    <div className="invoice-theme-1-end-col-2-amount-col-data">
                      ₹ {
                      formatNumber(totalGst) / 2
                    }
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {data.invoiceItemsData.filter((e) => e.cess).length > 0 ? (
                <div className="invoice-theme-1-end-col-2-amount-col">
                  <div className="invoice-theme-1-end-col-2-amount-col-heading">
                    CESS 
                  </div>
                  <div className="invoice-theme-1-end-col-2-amount-col-data">
                    ₹ {
                    formatNumber(totalCess) / 1
                    }
                  </div>
                </div>
              ) : (
                ""
              )}

              {data.invoiceExtraDiscount ? (
                <div className="invoice-theme-1-end-col-2-amount-col">
                  <div className="invoice-theme-1-end-col-2-amount-col-heading">
                    Discount
                  </div>
                  <div className="invoice-theme-1-end-col-2-amount-col-data">
                    ₹ {formatNumber(data.invoiceExtraDiscount) * 1}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div style={{ borderBottom: `2px solid ${invoiceData.invoiceThemeColor}`,  borderTop: `2px solid ${invoiceData.invoiceThemeColor}` }} className="invoice-theme-1-end-col-2-amount-col invoice-theme-1-end-col-2-amount-col-total">
                <div>Total Amount</div>
                <div className="invoice-theme-1-end-col-2-amount-col-data">
                  ₹ {(data.invoiceTotalAmount.toFixed(2) * 1).toLocaleString()}
                </div>
              </div>

              {data.receivedAmount ? (
                <>
                  <div className="invoice-theme-1-end-col-2-amount-col">
                    <div className="invoice-theme-1-end-col-2-amount-col-heading">
                      Paid Amount
                    </div>
                    <div className="invoice-theme-1-end-col-2-amount-col-data">
                      ₹ {data.receivedAmount}
                    </div>
                  </div>

                  <div className="invoice-theme-1-end-col-2-amount-col">
                    <div className="invoice-theme-1-end-col-2-amount-col-heading">
                      Balance
                    </div>
                    <div className="invoice-theme-1-end-col-2-amount-col-data">
                      ₹{" "}
                      {Math.round(
                        data.invoiceTotalAmount.toFixed(2) - data.receivedAmount
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="invoice-theme-1-end-col-2-amount-text">
                <div>Total Amount (in words) :</div>
                <div>{toWords(data.invoiceTotalAmount)} rupees</div>
              </div>

             
              <div className="invoice-theme-1-end-col-2-signature">

              
{/* {
  businessData.companySignatureType === "Box"?
  <>
                          <div className="invoice-theme-1-end-col-2-signature-empty"></div>

                          <div className="invoice-theme-1-end-col-2-signature-text">
                            <div>AUTHORISED SIGNATORY FOR</div>
                            <span>{businessData.businessName}</span>
                          </div>
  </>
  :
  businessData.companySignatureType === "Image"?

  
  businessData.companySignature ?
  <>
                          <img src={`${link}/${businessData.companySignature }`} alt="comapny signature"/>


                          <div className="invoice-theme-1-end-col-2-signature-text">
                            <div>AUTHORISED SIGNATORY FOR</div>
                            <span>{businessData.businessName}</span>
                          </div>
                          
  </>
  :""
  :""
} */}
            </div>
              
              
            </div>
          </div>
        </div>
      </div>
 
    </div>
  );
};

export default SharedInvoiceStylishTheme;
