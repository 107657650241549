import React from "react";

const QrCodeTypeComponent = () => {
  return (
    <div>
      <div className="qr-code-types">
        <div className="qr-code-types-main">
          <div className="qr-code-types-heading">
            What are the Different Types of QR Codes?
          </div>

          <div className="qr-code-types-content">
            <div className="qr-code-types-col">
              <div className="qr-code-types-col-icon">
                <i class="fa-solid fa-link"></i>
              </div>
              <div className="qr-code-types-col-heading">URL</div>
              <div className="qr-code-types-col-text">
                URL URLs help open links to a contact from a web page. You can
                use it to Link your YouTube video.
              </div>
            </div>

            <div className="qr-code-types-col">
              <div className="qr-code-types-col-icon">
                <i class="fa-solid fa-address-card"></i>
              </div>
              <div className="qr-code-types-col-heading">VCard</div>
              <div className="qr-code-types-col-text">Use vCard QR codes to create your digital business card having to save directly to phone feature.</div>
            </div>

            <div className="qr-code-types-col">
              <div className="qr-code-types-col-icon">
              <i class="fa-solid fa-envelope"></i>
              </div>
              <div className="qr-code-types-col-heading">Email</div>
              <div className="qr-code-types-col-text">Using email QR type, you can set a predefined message to get delivered to the recipient to his email address.</div>
            </div>

            <div className="qr-code-types-col">
              <div className="qr-code-types-col-icon">
              <i class="fa-solid fa-wifi"></i>
              </div>
              <div className="qr-code-types-col-heading">WiFi</div>
              <div className="qr-code-types-col-text">Using a QR code for a Wi-Fi setup, you can eliminate the requirement of manually typing the password.</div>
            </div>

            <div className="qr-code-types-col">
              <div className="qr-code-types-col-icon">
              <i class="fa-solid fa-message"></i>
              </div>
              <div className="qr-code-types-col-heading">SMS</div>
              <div className="qr-code-types-col-text">Send a predefined text message to any phone number using SMS type QR code by simply scanning the QR code.</div>
            </div>

            <div className="qr-code-types-col">
              <div className="qr-code-types-col-icon">
                  <i class="fa-solid fa-font"></i>
              </div>
              <div className="qr-code-types-col-heading">Plain Text</div>
              <div className="qr-code-types-col-text">Store up to 300 characters in any language and display them on the screen when scanned.</div>
            </div>

           
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCodeTypeComponent;
