import React from 'react'
import Navbar from '../components/main/Navbar'
import InvoiceGeneratorPageHeader from '../components/InvoiceGeneratorPage/InvoiceGeneratorPageHeader'
import InvoiceGeneratorGenerateInvoice from '../components/InvoiceGeneratorPage/InvoiceGeneratorGenerateInvoice'
import HowGenerateInvoiceUsingOnline from '../components/InvoiceGeneratorPage/HowGenerateInvoiceUsingOnline'
import ElementsCreateInvoiceComponent from '../components/InvoiceGeneratorPage/ElementsCreateInvoiceComponent'
import FooterComponent from '../components/main/FooterComponent'

const InvoiceGeneratorPage = () => {
  return (
    <div>

        <div className="invoice-generator-page">
            <div className="invoice-generator-page-main">

                <Navbar/>

                <InvoiceGeneratorPageHeader/>
                <InvoiceGeneratorGenerateInvoice/>

                <HowGenerateInvoiceUsingOnline/>
                <ElementsCreateInvoiceComponent/>



                <FooterComponent/>


                
            </div>
        </div>
        
    </div>
  )
}

export default InvoiceGeneratorPage