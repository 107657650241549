import React from "react";
import Navbar from "../components/main/Navbar";
import FooterComponent from "../components/main/FooterComponent";
import BusinessCardMakerHeader from "../components/businessCardMakerPage/BusinessCardMakerHeader";
import HowToGenerateBusinessCardComponent from "../components/businessCardMakerPage/HowToGenerateBusinessCardComponent";
import BusinessCardFreeGenerator from "../components/businessCardMakerPage/BusinessCardFreeGenerator";
import BusinessCardCreateImpact from "../components/businessCardMakerPage/BusinessCardCreateImpact";
import BusinessCardCompetitionComponent from "../components/businessCardMakerPage/BusinessCardCompetitionComponent";
import CreateBusinessCardsComponent from "../components/businessCardMakerPage/CreateBusinessCardsComponent";
import BusinessCardUniqueComponent from "../components/businessCardMakerPage/BusinessCardUniqueComponent";
import SpecialPurposeToolsComponent from "../components/main/SpecialPurposeToolsComponent";
import BusinessCardFaqComponent from "../components/businessCardMakerPage/BusinessCardFaqComponent";
import QrCodeCustomersSayComponent from "../components/qrCodePage/QrCodeCustomersSayComponent";

const BusinessCardMakerPage = () => {
  return (
    <div>
      <div className="business-card-maker-page">
        <div className="business-card-maker-page-main">
          <Navbar />

          <BusinessCardMakerHeader />

          <HowToGenerateBusinessCardComponent />
          <BusinessCardFreeGenerator />
          <BusinessCardCreateImpact />
          <BusinessCardCompetitionComponent />
          <BusinessCardUniqueComponent />
          <CreateBusinessCardsComponent />
          <SpecialPurposeToolsComponent />
          <QrCodeCustomersSayComponent />
          <BusinessCardFaqComponent />

          <FooterComponent />
        </div>
      </div>
    </div>
  );
};

export default BusinessCardMakerPage;
