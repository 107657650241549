import React, { useContext, useEffect, useRef, useState } from "react";
import img1 from "../../../media/images/icons/sam_onboarding_1.svg";
import img2 from "../../../media/images/icons/sam_onboarding_2.svg";
import img3 from "../../../media/images/icons/sam_onboarding_3.svg";
import CreateStaffPopup from "../../pages/form/CreateStaffPopup";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";
import ViewStaffAttendancePayrollComponent from "../../pages/form/components/ViewStaffAttendancePayrollComponent";
import { AuthContext } from "../../../context/AuthContext";
import AppMobileNavbar from "../pageComponents/AppMobileNavbar";

const AppStaffAttendancePayrollPageComponent = ({
  businessData,
  fetchBusinessData,
}) => {

  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  const [editAttendanceOption, setEditAttendanceOption] = useState(null)


  // useState ============================================================================================
  
  const [createStaff, setCreateStaff] = useState(false);
  
  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertStatus, setTimerAlertStatus] = useState("");
  
  const [viewStaff, setViewStaff] = useState(false);
  
  const [staffId, setStaffId] = useState(null);
  const [staffData, setStaffData] = useState(null);
  
  // const [dataDate, setDataDate] = useState(new Date().toLocaleDateString())
  
  // ============================================================================================

  const today = new Date().toISOString().split("T")[0];
  
  // const [dataDate, setDataDate] = useState(new Date().toISOString().split("T")[0])
  
  const [dataDate, setDataDate] = useState(today)
  
  const getPreviousDay = () => {
    const previousDay = new Date(dataDate);
    previousDay.setDate(previousDay.getDate() - 1);
    setDataDate(previousDay.toISOString().split("T")[0]);
  };
  
  const getNextDay = () => {
    const previousDay = new Date(dataDate);
    previousDay.setDate(previousDay.getDate() + 1);
    setDataDate(previousDay.toISOString().split("T")[0]);
  };


  
  
  
  // ============================================================================================
  // ============================================================================================
  
  
  const todaysData = businessData.staffData.map(e => 
    e.attendanceData.filter( e => new Date(e.date).toLocaleDateString() === new Date(dataDate).toLocaleDateString() )
  )
  
  const presentStaff = todaysData.flat().filter( e => e.attendanceStatus === "present" ).length;
  const absentStaff = todaysData.flat().filter( e => e.attendanceStatus === "absent" ).length;
  const hdStaff = todaysData.flat().filter( e => e.attendanceStatus === "half-day" ).length;
  const woStaff = todaysData.flat().filter( e => e.attendanceStatus === "week-off" ).length;
  const plStaff = todaysData.flat().filter( e => e.attendanceStatus === "paid-leave" ).length;
  
  
  
  
  // ============================================================================================
  // ============================================================================================
  // ============================================================================================
  
  
  
  const { createAttendance, updateAttendance } = useContext(AuthContext);
  
  const handleAttendance = async ( id, date, status) => {
    
    await createAttendance(
        userId,
        businessId,
        id,
        {
          date : date,
          attendanceStatus : status
        },
        setTimerPopupAlert,
        setTimerAlertMessage,
        setTimerAlertStatus,
        fetchBusinessData,
      );
      
    };

    const handleUpdateAttendance = async ( idStaff, idAttendance, date, status) => {
      
    await updateAttendance(
        userId,
        businessId,
        idStaff,
        idAttendance,
        {
          date : date,
          attendanceStatus : status
        },
        setTimerPopupAlert,
        setTimerAlertMessage,
        setTimerAlertStatus,
        fetchBusinessData,
      );

    };
    
    
    // ============================================================================================
    // ============================================================================================
    // ============================================================================================
    
    
    const attendanceOptionRef = useRef(null);
    const attendanceOptionListRef = useRef(null);
    
    const handleClickOutside = (event) => {
      // Check if the click is outside the button and dropdown
      if (
        attendanceOptionListRef.current &&
        !attendanceOptionListRef.current.contains(event.target) &&
        attendanceOptionRef.current &&
        !attendanceOptionRef.current.contains(event.target)
    ) {
      setEditAttendanceOption(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  
  // =========================================================================================================

  const wDate = (e) => {

    const date = new Date(e);
const day = date.getDate(); // Get the day of the month
const month = date.getMonth() + 1; // Get the month (0-indexed, so we add 1)
const year = date.getFullYear(); // Get the full year

const formattedDate = `${day}/${month}/${year}`;

return formattedDate
    
  }


  
    
  
  

  return (
    <div>
      
      {viewStaff ? (
        <ViewStaffAttendancePayrollComponent
          open={setViewStaff}
          staffId={staffId}
          setStaffId={setStaffId}
          staffData={staffData}
          setStaffData={setStaffData}
          businessData={businessData}
          fetchBusinessData={fetchBusinessData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          handleAttendance={handleAttendance}
          handleUpdateAttendance={handleUpdateAttendance}
        />
      ) : (
        ""
      )}

      {createStaff ? (
        <CreateStaffPopup
          open={setCreateStaff}
          fetchData={fetchBusinessData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
        />
      ) : (
        ""
      )}

      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}

      <div className="app-staff-page">
        <div className="app-staff-page-main">
          <div className="app-staff-page-top">
            <div className="app-staff-page-heading">
                <AppMobileNavbar  businessData={businessData}/>
              Staff Attendance & Payroll
            </div>
            <div className="app-staff-page-buttons">
              <div
                className="app-staff-page-button-add"
                onClick={() => setCreateStaff(true)}
              >
                + Add Staff
              </div>
            </div>
          </div>

          {businessData && businessData.staffData.length > 0 ? (
            <div className="app-staff-page-data">
              <div className="app-staff-page-data-top">
                <div className="app-staff-page-data-top-date">
                  {/* {new Date(dataDate).toLocaleDateString('en-GB')}
                   ------------  */}
                  {new Date(dataDate).toDateString()} 
                  {/* -------------
                  {new Date(dataDate).toLocaleDateString('en-GB')}
                  ________
                  {formatDate(dataDate)} */}
                  {/* {new Date(dataDate).toLocaleDateString()} */}
                </div>
                <div className="app-staff-page-data-top-option">
                  <i onClick={getPreviousDay} className="fa-solid fa-angle-left"></i>
                  <input value={dataDate} onChange={e=>setDataDate(e.target.value)} max={today} type="date" />
                  {
                    dataDate >= today ?
                    <span><i className="fa-solid fa-angle-right"></i></span>
                    :
                    <i onClick={getNextDay} className="fa-solid fa-angle-right"></i>
                  }
                </div>
              </div>

              <div className="app-staff-page-data-table">
                <div className="app-staff-page-data-table-top">
                  <div>
                    <div className="app-staff-page-data-table-top-heading">
                      <span>Present</span> (P)
                    </div>
                    <div className="app-staff-page-data-table-top-value">{presentStaff}</div>
                  </div>
                  <div>
                    <div className="app-staff-page-data-table-top-heading">
                       <span>Absent</span> (A)
                    </div>
                    <div className="app-staff-page-data-table-top-value">{absentStaff}</div>
                  </div>
                  <div>
                    <div className="app-staff-page-data-table-top-heading">
                      <span>Half day</span> (HD)
                    </div>
                    <div className="app-staff-page-data-table-top-value">{hdStaff}</div>
                  </div>
                  <div>
                    <div className="app-staff-page-data-table-top-heading">
                      <span>Paid Leave</span> (PL)
                    </div>
                    <div className="app-staff-page-data-table-top-value">{plStaff}</div>
                  </div>
                  <div>
                    <div className="app-staff-page-data-table-top-heading">
                      <span>Weekly off</span> (WO)
                    </div>
                    <div className="app-staff-page-data-table-top-value">{woStaff}</div>
                  </div>
                </div>
                <div className="app-staff-page-data-table-head">
                  <div>Staff Name</div>
                  <div>Mobile Number</div>
                  {/* <div>Last Month Due</div> */}
                  {/* <div>Balance</div> */}
                  <div>Mark Attendance</div>
                </div>
                <div className="app-staff-page-data-table-body">
                  {businessData.staffData.map((e) => (
                    <div
                      className="app-staff-page-data-table-body-data"
                    >
                      <div  onClick={() => { setViewStaff(true); setStaffId(e._id); setStaffData(e) }}>{e.staffName} </div>
                      <div  onClick={() => { setViewStaff(true); setStaffId(e._id); setStaffData(e) }}>{e.staffNumber} </div>
                      {/* <div  onClick={() => { setViewStaff(true); setStaffId(e._id); setStaffData(e) }}>-</div> */}
                      {/* <div  onClick={() => { setViewStaff(true); setStaffId(e._id); setStaffData(e) }}>{e.staffSalaryCycle}</div> */}
                      {
                        e.attendanceData.filter( a =>  new Date(a.date).toLocaleDateString() === new Date(dataDate).toLocaleDateString() ).length>0?
                        
                        e.attendanceData.filter( a =>  new Date(a.date).toLocaleDateString() === new Date(dataDate).toLocaleDateString() )
                        .map( a => (
                        <div className="app-staff-attendance-options">
                          <div onClick={ () => handleUpdateAttendance( e._id, a._id, dataDate,  "present")} className={a.attendanceStatus === "present" ? "app-staff-attendance-present" : ""}>P</div>
                          <div onClick={ () => handleUpdateAttendance( e._id, a._id, dataDate,  "absent")} className={a.attendanceStatus === "absent" ? "app-staff-attendance-absent" : ""}>A</div>
                          <div onClick={ () => setEditAttendanceOption(e._id)}>
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                          </div>

                        <div div className="app-staff-attendance-type" ref={attendanceOptionRef}>
                          { 
                          editAttendanceOption === e._id ?
                            <div className="app-staff-attendance-type-options" ref={attendanceOptionListRef}>
                              <div  onClick={ () => {handleUpdateAttendance( e._id, a._id, dataDate,  "half-day"); setEditAttendanceOption(null)}}>Half Day</div>
                              <div  onClick={ () => {handleUpdateAttendance( e._id, a._id, dataDate,  "paid-leave"); setEditAttendanceOption(null)}}>Paid Leave</div>
                              <div  onClick={ () => {handleUpdateAttendance( e._id, a._id, dataDate,  "week-off"); setEditAttendanceOption(null)}}>Week Off</div>
                            </div>
                          :""
                        }
                        </div>
                          { a.attendanceStatus === "half-day"? <div className={a.attendanceStatus === "half-day" ? "app-staff-attendance-half-day" : ""}>HD</div> : "" }
                          { a.attendanceStatus === "week-off"? <div className={a.attendanceStatus === "week-off" ? "app-staff-attendance-week-off" : ""}>WO</div> : "" }
                          { a.attendanceStatus === "paid-leave"? <div className={a.attendanceStatus === "paid-leave" ? "app-staff-attendance-paid-leave" : ""}>PL</div> : "" }
                          
                        </div>
                        ))
                        

                        :
                      <div className="app-staff-attendance-options">
                        <div onClick={ () => handleAttendance( e._id, dataDate,  "present")}>P</div>
                        <div onClick={ () => handleAttendance( e._id, dataDate,  "absent")}>A</div>
                        <div onClick={ () => setEditAttendanceOption(e._id)}>
                          <i class="fa-solid fa-ellipsis-vertical"></i>
                     
                        </div>
                        <div div className="app-staff-attendance-type" ref={attendanceOptionRef}>
                        {
                          editAttendanceOption === e._id ?
                            <div className="app-staff-attendance-type-options" ref={attendanceOptionListRef}>
                              <div  onClick={ () => { handleAttendance( e._id, dataDate,  "half-day"); setEditAttendanceOption(null)}}>Half Day</div>
                              <div  onClick={ () => { handleAttendance( e._id, dataDate,  "paid-leave"); setEditAttendanceOption(null)}}>Paid Leave</div>
                              <div  onClick={ () => { handleAttendance( e._id, dataDate,  "week-off"); setEditAttendanceOption(null)}}>Week Off</div>
                            </div>
                          :""
                        }
                        </div>


                      </div>
                      }

                    </div>
                  ))}
                </div>
                {/* <div className="app-staff-page-data-table-end">
                  <div>Pending Amount</div>
                  <div></div>
                  <div>0</div>
                  <div>0</div>
                  <div></div>
                </div> */}
              </div>
            </div>
          ) : (
            <div className="app-staff-page-content">
              <div className="app-staff-page-content-section-1">
                <div className="app-staff-page-content-section-1-col">
                  <div className="app-staff-page-content-section-1-col-img">
                    <img src={img1} />
                  </div>
                  <div className="app-staff-page-content-section-1-col-text">
                    Mark your staff's attendance digitally
                  </div>
                </div>

                <div className="app-staff-page-content-section-1-col">
                  <div className="app-staff-page-content-section-1-col-img">
                    <img src={img2} />
                  </div>
                  <div className="app-staff-page-content-section-1-col-text">
                    Simplify payroll by adding salary, advance & pending
                    payments
                  </div>
                </div>

                <div className="app-staff-page-content-section-1-col">
                  <div className="app-staff-page-content-section-1-col-img">
                    <img src={img3} />
                  </div>
                  <div className="app-staff-page-content-section-1-col-text">
                    Set custom reminders to mark attendance timely
                  </div>
                </div>
              </div>

              <div className="app-staff-page-content-section-2">
                <div className="app-staff-page-content-section-2-text-1">
                  Mark attendance and manage payroll
                </div>
                <div className="app-staff-page-content-section-2-text-2">
                  Add staff to Mark attendance and manage payroll with ease!
                </div>
                <div
                  className="app-staff-page-content-section-2-text-3"
                  onClick={() => setCreateStaff(true)}
                >
                  + Add Staff
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppStaffAttendancePayrollPageComponent;
