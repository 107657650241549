import React from "react";

const QrCodeFaqComponent = () => {
  return (
    <div>
      <div className="qr-code-faq">
        <div className="qr-code-faq-main">
          <div className="qr-code-faq-heading">Frequently Asked Questions</div>
          <div className="qr-code-faq-content">
            <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              What is a QR code generator?
            </div>
            <div className="qr-code-faq-answer">A QR code generator is a tool that gets used to create different types of QR codes. You can use a QR code for various purposes depending on the requirement.</div>

            <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Is it free to create a QR code?
            </div>
            <div className="qr-code-faq-answer">
            Yes. You can create a static QR code instantly for free using the Invoician QR code generator. Once created, you can use them forever without paying any fees. You can avail of premium features like QR insights to manage your business efficiently using the paid version with minimum yearly charges.
            </div>

            <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              What type of information gets stored in a QR code?
            </div>
            <div className="qr-code-faq-answer">
            You can keep a wide variety of data stored within a QR code. Their popularity lies in their versatility. You can use them to keep a URL to make it easier to open a web page, or you can save contact details. It is to eliminate the requirement of manual typing of name, phone number and address. There are many more use cases of QR code.
            </div>

            <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Can I use the QR codes generated using Invoician for commercial purposes?
            </div>
            <div className="qr-code-faq-answer">
            Yes. You can use the QR codes as per your requirement. They are fit to use for all sorts of commercial purposes.
            </div>

            <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Do QR codes expire?
            </div>
            <div className="qr-code-faq-answer">
            No. The QR codes do not expire. You can use them for as long as you want. The only case when they are left unusable is when the data stored in them gets distorted. For example, when the link in the QR code gets damaged for the content is removed.
            </div>

            <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              How can I manage QR codes?
            </div>
            <div className="qr-code-faq-answer">
            You can easily manage your QR codes using Invoician. After signing up in the Invoician app, you can access all the QR codes you have generated. You can modify or delete information using the app.
            </div>

            <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              What are some measurable differences between a static and a dynamic QR code?
            </div>
            <div className="qr-code-faq-answer">
            Static QR code cannot get tracked once it gets generated. It is Virtually Indestructible and gets used when you have a fixed set of information. On the other hand, information in the dynamic QR codes can get tracked whenever required. You can update the content and change their links even after you have printed the QR code.
            </div>

            <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Do I need to train an employee to generate QR codes online?
            </div>
            <div className="qr-code-faq-answer">
            No. It requires no skills to generate a QR code using the Invoician QR code generator online. Anyone with the knowledge to use a computer can create QR codes using the Invoician app.
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCodeFaqComponent;
