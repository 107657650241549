import React from 'react'
import Navbar from './../components/main/Navbar'
import AboutPageHeaderComponent from './../components/aboutPage/AboutPageHeaderComponent'
import AboutUsComponent from './../components/aboutPage/AboutUsComponent'
import FooterComponent from './../components/main/FooterComponent'
import AboutRightTechniqueComponent from './../components/aboutPage/AboutRightTechniqueComponent'
import AboutFeaturesComponent from './../components/aboutPage/AboutFeaturesComponent'
import AboutPageEffortlessly from './../components/aboutPage/AboutPageEffortlessly'

const AboutPage = () => {
  return (
    <div>

        <div className="about-page">

        <Navbar/>

        <AboutPageHeaderComponent />
            
            <AboutUsComponent/>
            
            <AboutRightTechniqueComponent/>

            
            <AboutFeaturesComponent/>

            <AboutPageEffortlessly/>
            
            

            <FooterComponent/>
            
        </div>
        
        
    </div>
  )
}

export default AboutPage