import React from "react";
import qr from "../../../media/images/website/qrCode.png"

const QrCodeFreeGenerator = () => {
  return (
    <div>
      <div className="qr-code-free">
        <div className="qr-code-free-main">

          <div className="qr-code-free-heading">Free QR Code Generator Online!</div>
          <div className="qr-code-free-text">Create unlimited QR codes using the Invoician online QR code generator. You can select from different types of QR codes and use them according to your requirement. Using a Invoician QR generator doesn't require you to pay.</div>
        
          
          <div className="qr-code-free-image">
            <img src={qr} alt="" />
          </div>

          <div className="qr-code-free-text">Provide convenience to your customers by enabling QR payments now! Download Invoician.</div>
        
          
        </div>
      </div>
    </div>
  );
};

export default QrCodeFreeGenerator;
