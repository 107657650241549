import React from 'react'
import img from '../../../media/images/website/mobile-head.webp'

const AboutPageHeaderComponent = () => {
  return (
    <div>
        <div className="about-header">
            <div className="about-header-main">
            <div className="about-header-heading">Free GST Billing</div>
            <div className="about-header-image">
              <img src={img} alt="" />
            </div>

            <div className="about-header-button">Start Invoicing Now!</div>

            </div>

        </div>
    </div>
  )
}

export default AboutPageHeaderComponent