import React, { useState } from "react";

const AppFeaturesComponent = () => {
  const [viewDetails, setViewDetails] = useState(1);

  const featureData = [
    {
      no: 1,
      icon: "fa-solid fa-paper-plane",
      heading: "Send Estimate and Quotations",
      details: [
        "With our free billing software, creating essential documents is simple and efficient. You can generate quotations, estimates, and precise GST invoices with ease. The GST billing app’s built-in features enable you to send quotes and estimates to customers anytime via WhatsApp, email, SMS, or by printing.",
        "Invoician billing software provides a professional appearance with instant estimates and quotes. It automates most processes, ensuring your quotes and estimates are error-free. Additionally, you can set up due dates to seamlessly track invoices.",
        "You can also effortlessly convert estimates and quotations into sales invoices whenever needed. With Invoician software, this transformation happens in just a few clicks. This free billing software offers businesses a complete solution to save time while generating instant quotes.",
        "By using our accounting software, you can enhance your business’s productivity and efficiency. The app adds a layer of professionalism that helps attract and retain your valued customers. Opting for advanced GST billing software is a smart investment for your business. Invoician simplifies the creation of estimates and quotations, streamlining your workflow.",
      ],
    },
    {
      no: 2,
      icon: "fa-solid fa-location-dot",
      heading: "Track Orders",
      details: [
        "Our advanced GST-compliant software simplifies the creation of sales and purchase orders. It allows you to set due dates, ensuring seamless order tracking. With this GST accounting and billing solution, inventory management becomes effortless with auto stock adjustment, ensuring item availability at all times.",
        "The built-in tracking features are invaluable for timely order fulfillment, helping avoid unnecessary losses and saving time for other daily tasks. This enhances customer satisfaction by ensuring efficient service. Additionally, you can attach tax invoices to orders and monitor payment statuses directly within the app.",
        "With our GST billing app, you can easily refine your purchase and sales order formats. The Invoician App offers multiple options for export, including Word, PDF, and Excel. By leveraging automation, you can save on labor costs and effort by converting orders into sale or purchase invoices instantly.",
        "Track open, closed, and overdue orders effortlessly with our free billing app. Its comprehensive features make it an ideal choice for businesses, streamlining the entire tracking process. Ultimately, it enhances employee productivity and boosts overall business performance.",
      ],
    },
    {
      no: 3,
      icon: "fa-solid fa-paint-roller",
      heading: "Choose Themes",
      details: [
        "Creating and sharing professional invoices with your clients enhances your brand identity. The GST Billing App offers two unique invoice themes for thermal printers and twelve customizable themes for regular printers.",
        "With this user-friendly GST invoice software, you can effortlessly enhance your invoice design using the available customization options. It allows you to create clean and impressive invoices for your clients, leaving a lasting impression.",
        "The Billing App for GST is the perfect solution for managing your accounting and inventory needs. It’s simple to use and provides various GST invoice formats to suit your business requirements. Many businesses trust our free billing software to project a professional image and strengthen their brand reputation.",
        "This free billing app ensures you achieve the highest standards for your invoices quickly and efficiently. It offers multiple theme options, all fully customizable, for both thermal and regular printers. GST Invoicing Software is versatile and ideal for various industries, including retail, pharmaceuticals, gyms, restaurants, and more.",
      ],
    },
    {
      no: 4,
      icon: "fa-solid fa-file-pen",
      heading: "Record Expenses",
      details: [
        "Tracking and documenting all business expenses is crucial for effective accounting and seamless tax filing. With GST billing software, it becomes simpler to monitor spending and generate accurate financial reports.",
        "Our free billing app offers an efficient way to record expenses, enabling businesses to optimise their spending and save money. Whether it’s GST or non-GST expenses, our free GST billing software helps you manage them effortlessly.",
        "Invoician billing solutions provide several advantages over competitors, helping businesses reduce costs and boost sales. The free billing software ensures quick recording of outstanding expenses and simplifies future tracking.",
        "Designed for growing businesses, our free mobile app helps maintain financial control. By leveraging GST software to record expenses, companies can optimise spending, save more, and develop smarter strategies. This results in enhanced profitability and sustainable business growth.",
      ],
    },
    {
      no: 5,
      icon: "fa-solid fa-file-invoice",
      heading: "Receivables and Payables",
      details: [
        "A professional and free billing and accounting software helps users efficiently manage transaction records and monitor business cash flow with ease. This ensures a secure way to maintain all transaction details.",
        "The Free GST Invoicing Software simplifies tracking party-wise receivables and payables. With the business dashboard in the GST mobile app, you can easily monitor the money you’re owed and the payments you need to make using the Invoician app.",
        "It also allows you to identify customers with outstanding payments. You can set up payment reminders to ensure timely collections and send free reminders to parties via WhatsApp, SMS, or email. The app supports various online payment solutions, enabling you to collect dues effortlessly while saving time on daily tasks.",
        "The bulk payment reminder feature further streamlines your efforts by allowing you to send reminders to multiple customers simultaneously. The GST Billing and Invoicing Software automates calculations, making it easier to track receivables and payables seamlessly.",
        "By leveraging the cash flow management system in the Invoician app, you can avoid excessive debt and reassess your business strategy when early signs of cash flow issues arise. Additionally, the Invoician billing app enables party-to-party balance transfers, ensuring smooth financial management for your business.",
      ],
    },
    {
      no: 6,
      icon: "fa-solid fa-receipt",
      heading: "Delivery Challan",
      details: [
        "Streamline Your Business with “Delivery Challan” on Invoician",
        "Ensure smooth delivery operations with Invoician's free GST invoicing app. Create and attach delivery challans to your consignments to guarantee acknowledgment upon delivery.",
        "Track your delivery challans effortlessly to ensure your goods reach customers safely. In case of misplaced consignments, our GST billing software allows you to provide quick instructions and manage consignor and consignee details seamlessly.",
        "Add terms and conditions to your delivery challans to avoid disputes and clarify order scope, ensuring smoother operations for your business and a hassle-free experience for your customers.",
        "Efficiently convert delivery challans into invoices once consignments are delivered. This process helps in auto stock adjustment, maintaining delivery acknowledgment records, and enhancing productivity.",
        "With Invoician, you can manage payments easily by offering multiple payment options, both online and offline, to prevent delayed collections. Elevate your business operations with Invoician's ultimate GST billing and accounting software.",
      ],
    },
    {
      no: 7,
      icon: "fa-solid fa-building-columns",
      heading: "Bank Accounts",
      details: [
        "Businesses can efficiently manage, track, and process online and offline payments with ease. By choosing a user-friendly free GST billing app for mobile, these tasks become even simpler. Regardless of whether your revenue comes from banks or e-wallets, you can seamlessly record transactions in the free billing software.",
        "The app enables sending and receiving money through bank accounts and facilitates bank-to-bank transfers, ensuring smooth cash flow management. It’s an ideal solution for businesses handling all cash-ins and cash-outs, leveraging the Invoician invoicing app.",
        "To utilize the bank accounts feature within the GST Accounting Software, you need to link your business bank account to the app. With the Invoician app, you can easily manage credit cards, overdrafts, and loan accounts, making it the perfect tool for maintaining an organized bank account book without any hassle. Additionally, the app simplifies withdrawing or depositing money directly into your bank accounts.",
        "The free invoicing and accounting software can be accessed from anywhere with an internet connection, directly from your mobile. Invoician's free accounting software also allows manual adjustment of amounts and efficient management of cheque payments.",
        "The Invoician app offers features like open cheque management, enabling quick deposits or withdrawals while ensuring they are promptly closed. Alongside various other payment options, the app provides a streamlined way to track and manage cheque payments.",
      ],
    },
    {
      no: 8,
      icon: "fa-solid fa-chart-line",
      heading: "Business Reports",
      details: [
        "Businesses must make data-driven decisions to ensure sustained growth. Leverage over 37 business reports generated with our free billing software to meet all your business needs.",
        "Invoician accounting software provides professional balance sheets and enhances your business’s operational efficiency. With the ability to easily export reports in PDF or Excel, you can streamline your processes. • Accounting and management • Billing and e-payments • GST Reports and taxation Users can instantly view and analyze data with our free GST Invoicing & Accounting Software. The app allows you to generate graphical reports for tracking sales and expenses. This free software delivers precise business insights, including account details and profit analysis. Some of the key reports available include: • GSTR 1 format • GSTR 3B • GST-related reports • Balance Sheet • Profit & Loss",
      ],
    },
    {
      no: 9,
      icon: "fa-solid fa-file-invoice",
      heading: "GST Invoicing / Billing",
      details: [
        "Our free, all-in-one GST billing software is a valuable tool for your business, designed to streamline your billing processes and save time on accounting. It's particularly effective for small and medium enterprises, allowing them to handle their accounting needs more efficiently.",
        "With this free GST billing software, business owners can easily manage a variety of tasks, including GST return filing, invoicing, inventory management, and billing. The software also offers customization options, enabling businesses to tailor the fields to meet their specific needs.",
        "You can generate GST-compliant invoices for your clients in just 20 seconds and print or share them instantly. The app supports the GST invoice format, ensuring that your billing process is always compliant.",
        "Additionally, the barcode scanner functionality accelerates the billing process, while shortcut keys help you complete repetitive tasks more quickly. The Bill wise payment feature is another key highlight of the software, allowing you to easily link payments with sales invoices.",
        "The mobile app enables businesses to manage multiple parties, making it simple to keep track of clients, invoice due dates, and outstanding payments. You can effortlessly monitor overdue payments and access previous invoices at any time.",
      ],
    },
    {
      no: 10,
      icon: "fa-solid fa-cloud-arrow-up",
      heading: "Automatic Data Backup",
      details: [
        "Our GST Billing & Accounting Software ensures 100% security for your data, providing an easy and accurate way to store it. With our free app, you can safeguard your data by creating backups locally, externally, or even on Google Drive.",
        "The software makes data recovery effortless and fast, with encrypted data for added security. Invoician is a powerful, free invoicing & accounting tool designed for seamless use.",
        "Invoician's GST billing software in India offers a simple, hassle-free backup system with an auto-backup feature. Once activated, it automatically creates backups daily, ensuring you never lose your valuable data.",
        "Many businesses across India trust this free accounting and invoicing software to simplify their work while enhancing data security. The app’s encryption system ensures that only the owner has access to the data, boosting security even further.",
        "No one in the Invoician team can access your business data, ensuring complete privacy. You can create backups as needed, using various backup options to ensure the security of your data at all times.",
      ],
    },
    {
      no: 11,
      icon: "fa-solid fa-print",
      heading: "Regular / Thermal Printer",
      details: [
        "If you need your invoice in a specific format, whether it’s a traditional bill format or Excel, this free billing software is the ideal solution. Invoician is fully compatible with both thermal and regular (laser) printers, enabling you to print your desired invoice quickly and efficiently.",
        "Invoician’s invoicing and accounting software/app is designed to simplify the printing of your invoices and bills. It offers flexible size options, including standard A4 & A5 paper sizes, thermal paper sizes of 2” & 3”, and other custom paper sizes, allowing you to print in the format that suits your needs.",
        "Simply connect the app to your regular or thermal printer via Bluetooth or plug-in to begin printing invoices. In addition to printing, you can use the Invoician app to create and send professional invoices digitally through email, SMS, or WhatsApp, eliminating the need for physical copies.",
        "With the free GST invoicing app, you can select from multiple formats like Excel, Word, or PDF, customize your invoices, and print them for your customers. The process is smooth, with reduced costs compared to previous years. You can print your invoices using both regular and thermal printers, providing convenience and efficiency in your business operations."
      ],
    },
    {
      no: 12,
      icon: "fa-solid fa-cart-shopping",
      heading: "Online Store",
      details: [
        "Set up your online store in just a few hours with the Invoician GST billing software. Our mobile billing app allows you to easily list all your products and services online, providing a comprehensive catalogue for your customers. This will help you enhance your online sales and reach more customers.",
        "Best of all, Invoician’s billing software doesn’t charge any fees for using the online store features, making it easy to take your business online. Simply share the link to your online store with your customers, allowing them to place orders and pick up their items from your store once packed.",
        "By using the online store feature in the GST billing and invoicing app, you can minimize wait times at your counter by packing orders in advance. This enables you to serve customers faster and increase foot traffic to your store. Going online with Invoician will help you attract more local customers and boost your business.",
        "Providing doorstep delivery or pick-up services is the best way to serve your community and increase your sales. The Invoician GST billing software allows you to update your online store anytime, keeping your offerings fresh and current."
      ],
    }
  ];

  return (
    <div>
      <div className="app-feature">
        <div className="app-feature-main">
          <div className="app-feature-heading">
            Features of GST Billing and Accounting Software
          </div>
          <div className="app-feature-content">
            <div className="app-feature-menu">
              {featureData.map((e) => (
                <div
                  className="app-feature-menu-option"
                  onClick={() => setViewDetails(e.no)}
                >
                  <div className="app-feature-menu-icon">
                    <i class={e.icon}></i>
                  </div>
                  <div className="app-feature-menu-text">{e.heading}</div>
                </div>
              ))}
            </div>

            {featureData
              .filter((e) => e.no === viewDetails)
              .map((e) => (
                <div className="app-feature-data">
                  <div className="app-feature-data-heading">{e.heading}</div>
                  <div className="app-feature-data-text">
                    {e.details.map((e) => (
                      <div>{e}</div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFeaturesComponent;
