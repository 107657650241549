import React from "react";
import img from '../../../media/images/website/set-your-business.webp'


const BusinessCardCompetitionComponent = () => {
  return (
    <div>
      <div className="what-qr">
        <div className="what-qr-main">
          <div className="what-qr-image"><img src={img} alt="" /></div>
          <div className="what-qr-content">
          <div className="what-qr-heading">Set Your Business Apart From The Competition</div>
          <div className="what-qr-text">
          Getting your business card design is often expensive, but the Invoician business card maker allows you to make your business cards for free. All you need is a few minutes, and you can create something that will resonate with new and prospective customers. The best part is the availability of a wide range of design templates available to ensure that your cards can be unique. It is essential when you are trying to create a unique identity for your business to stand out among competitors.
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCardCompetitionComponent;
