import React from "react";
import icon from '../../../media/images/website/indian-product.webp'

const AboutRightTechniqueComponent = () => {
  return (
    <div>
      <div className="right-technique">
        <div className="right-technique-main">
          <div className="right-technique-heading">
            Adopt Right Technology for your Business
          </div>
          <div className="right-technique-content">
            <div className="right-technique-content">

              <div className="right-technique-content-col">
                <div className="right-technique-content-icon">
                    <i className="fa-regular fa-face-laugh-beam"></i>
                </div>
                <div className="right-technique-content-text">Happy Customers</div>
                <div className="right-technique-content-heading">500,000 +</div>
              </div>

              <div className="right-technique-content-col">
                <div className="right-technique-content-icon">
                    <i class="fa-brands fa-google"></i>
                </div>
                <div className="right-technique-content-text">Rate on Google</div>
                <div className="right-technique-content-heading">5 Stars</div>
              </div>


              <div className="right-technique-content-col">
                <div className="right-technique-content-icon">
                    <img src={icon} alt="" />
                </div>
                <div className="right-technique-content-text">Made in</div>
                <div className="right-technique-content-heading">India</div>
              </div>



              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutRightTechniqueComponent;
