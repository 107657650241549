import React from 'react'
import AppMobileNavbar from '../pageComponents/AppMobileNavbar'
import GstCalculatorComponent from '../../../website/components/pages/GstCalculatorComponent'

const AppGstCalculatorPageComponent = ({businessData}) => {
  return (
    <div>


      <div className="view-app-gst-page">

      <div className="view-invoice-page">
        <div className="view-invoice-page-main">
          <div className="view-invoice-page-top">
            <div className="view-invoice-page-top-heading">
              <div className="view-invoice-page-top-bars">
                <AppMobileNavbar businessData={businessData}/>
              </div>
              <div className="view-invoice-page-top-label">GST Calculator</div>
            </div>

          </div>

          <div className="view-invoice-page-content">

            <GstCalculatorComponent/>

          </div>
        </div>
      </div>
        
        
        
    </div>
    </div>
  )
}

export default AppGstCalculatorPageComponent