import React, { useState } from "react";
import { Link } from "react-router-dom";
import sales from "../../../media/images/website/sales-purchase.jpg";
import quotation from "../../../media/images/website/quotation-proforma.jpg";
import delivery from "../../../media/images/website/delivery.jpg";
import manage from "../../../media/images/website/manage-stocks.jpg";
import multipleBank from "../../../media/images/website/multiple-bank-accounts.jpg";
import staff from "../../../media/images/website/staff-attendance.jpg";
import multipleBusiness from "../../../media/images/website/multiple-business.jpg";
import multipleUsers from "../../../media/images/website/multiple-users.jpg";
import multipleDevice from "../../../media/images/website/multiple-device.jpg";

const AllInOneSoftwareComponent = () => {
  const [viewType, setViewType] = useState("billing");

  return (
    <div>
      <div className="all-in-one-software">
        <div className="all-in-one-software-main">
          <div className="all-in-one-software-heading">
            <span>Invoician</span> All-in-one billing software to help grow your business{" "}
            <span>5x</span>
          </div>
          <div className="all-in-one-software-content">
            <div className="all-in-one-software-content-options">
              <div
                onClick={() => setViewType("billing")}
                className={
                  viewType === "billing"
                    ? "all-in-one-software-content-options-active"
                    : ""
                }
              >
                Billing{" "}
              </div>
              <div
                onClick={() => setViewType("tools")}
                className={
                  viewType === "tools"
                    ? "all-in-one-software-content-options-active"
                    : ""
                }
              >
                Business Tools
              </div>
              <div
                onClick={() => setViewType("management")}
                className={
                  viewType === "management"
                    ? "all-in-one-software-content-options-active"
                    : ""
                }
              >
                Business Management
              </div>
            </div>

            <div className="all-in-one-software-content-details">
              {viewType === "billing" ? (
                <div className="all-in-one-software-content-details-col">
                  <div>
                    <div>
                      <img src={sales} alt="" />
                    </div>
                    <Link to="">Sales Purchase and Expenses</Link>
                  </div>

                  <div>
                    <div>
                      <img src={quotation} alt="" />
                    </div>
                    <Link to="">Quotations Estimates & Proforma Invoices</Link>
                  </div>

                  <div>
                    <div>
                      <img src={delivery} alt="" />
                    </div>
                    <Link to="">Delivery Challan</Link>
                  </div>
                </div>
              ) : viewType === "tools" ? (
                <div className="all-in-one-software-content-details-col">
                  <div>
                    <div>
                      <img src={manage} alt="" />
                    </div>
                    <Link to="">Manage Stock Items</Link>
                  </div>

                  <div>
                    <div>
                      <img src={multipleBank} alt="" />
                    </div>
                    <Link to="">Multiple Bank Account</Link>
                  </div>

                  <div>
                    <div>
                      <img src={staff} alt="" />
                    </div>
                    <Link to="">Staff Attendance and Payroll</Link>
                  </div>
                </div>
              ) : viewType === "management" ? (
                <div className="all-in-one-software-content-details-col">
                  <div>
                    <div>
                      <img src={multipleBusiness} alt="" />
                    </div>
                    <Link to="">Multiple Businesses</Link>
                  </div>

                  <div>
                    <div>
                      <img src={multipleUsers} alt="" />
                    </div>
                    <Link to="">Multiple Users</Link>
                  </div>

                  <div>
                    <div>
                      <img src={multipleDevice} alt="" />
                    </div>
                    <Link to="">Multiple Device Login</Link>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllInOneSoftwareComponent;
