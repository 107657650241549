import React, { useState } from "react";
import img1 from "../../../media/images/website/business-details.webp"
import img2 from "../../../media/images/website/vendor-details.webp"
import img3 from "../../../media/images/website/invoice-details.webp"
import img4 from "../../../media/images/website/item-details.webp"
import img5 from "../../../media/images/website/preview.webp"
import img6 from "../../../media/images/website/download-print-share.webp"



const HowGenerateInvoiceUsingOnline = () => {

    const [viewType, setViewType] = useState("Business")
    
  return (
    <div>
      <div className="how-generate-invoice-online">
        <div className="how-generate-invoice-online-main">

          <div className="how-generate-invoice-online-heading">
            How to Generate an Invoice Using the Online GST Invoice Generator?
          </div>

          <div className="how-generate-invoice-online-content">

            <div className="how-generate-invoice-online-menu">
                <div onClick={ () => setViewType("Business") } className={viewType==="Business"?"how-generate-invoice-online-menu-active":""}>Enter Business Details</div>
                <div onClick={ () => setViewType("Add") } className={viewType==="Add"?"how-generate-invoice-online-menu-active":""}>Add Customer Details</div>
                <div onClick={ () => setViewType("Update") } className={viewType==="Update"?"how-generate-invoice-online-menu-active":""}>Update Invoice Details</div>
                <div onClick={ () => setViewType("Product") } className={viewType==="Product"?"how-generate-invoice-online-menu-active":""}>Enter Product Details</div>
                <div onClick={ () => setViewType("Preview") } className={viewType==="Preview"?"how-generate-invoice-online-menu-active":""}>Preview the Invoice</div>
                <div onClick={ () => setViewType("Download") } className={viewType==="Download"?"how-generate-invoice-online-menu-active":""}>Download, Print & Share</div>
            </div>

            <div className="how-generate-invoice-online-details">

{

viewType==="Business" ?
<div className="how-generate-invoice-online-details-col">
                    <img src={img1} alt="" />
                    <div>Once Invoician's invoice generator is launched, add all your business details, including your logo, company name, GST information, address, and any other details important to your business.</div>
                </div>
                :
viewType==="Add" ?
<div className="how-generate-invoice-online-details-col">
                    <img src={img2} alt="" />
                    <div>In Invoician's invoice generator, select the Party tab from the left menu and fill in the customer details, including name, address, contact information, and valid GST number.</div>
                </div>
                :
viewType==="Update" ?
<div className="how-generate-invoice-online-details-col">
                    <img src={img3} alt="" />
                    <div>Manage invoices effortlessly by entering the bill number, billing date, and due date in Invoician's online bill maker for smooth tracking and timely payments.</div>
                </div>
                :
viewType==="Product" ?
<div className="how-generate-invoice-online-details-col">
                    <img src={img4} alt="" />
                    <div>List down the item details along with its relative cost. With Invoician, you can add any number of items and leave notes about the invoice to make everything clear. If tax is applicable then include tax details.
<br /><br />
Check the overall amount and if there is any adjustment then make the changes accordingly.</div>
                </div>
                :
viewType==="Preview" ?
<div className="how-generate-invoice-online-details-col">
                    <img src={img5} alt="" />
                    <div>Take a look at the final invoice after entering the details, you can check out how your invoice looks through the preview. If you feel that any changes are required, you can go to the previous tab in the online invoice generator and make the changes.</div>
                </div>
                :
viewType==="Download" ?
<div className="how-generate-invoice-online-details-col">
                    <img src={img6} alt="" />
                    <div>Select 'save online' to download the generated invoice in your preferred format, such as PDF, and share it directly via email, WhatsApp, or copy the link.
<br /><br />
You can also adjust the paper size using the Invoician online invoice generator to print your customer a copy of the invoice.</div>
                </div>
                :
    ""
    
}


                
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default HowGenerateInvoiceUsingOnline;
