import React from "react";
import img1 from "../../../media/images/website/business-card-how-1.webp";
import img2 from "../../../media/images/website/business-card-how-2.webp";
import img3 from "../../../media/images/website/business-card-how-3.webp";

import imgEnd from "../../../media/images/website/Ad-Barcode-generator.png";

const HowToGenerateBusinessCardComponent = () => {
  return (
    <div>
      <div className="how-generate-qr">
        <div className="how-generate-qr-main">
          <div className="how-generate-qr-head">
            <div className="how-generate-qr-head-heading">
            How To Make Your Business Card Using Invoician?
            </div>
            <div className="how-generate-qr-head-text">
            Business card maker by Invoician allows you to design unique business cards to represent your business in the best way. The templates available give you the power to create business cards that set you apart from the competition. You can be as creative with customising templates as you like. The ready-made templates will help accelerate the designing procedure. You can try out different images, colour schemes, font styles, and text layouts before finalising a design.
            </div>
          </div>

          <div className="how-generate-qr-content">
            <div className="how-generate-qr-content-col">
              <div className="how-generate-qr-content-image">
                <img src={img1} alt="" />
              </div>
             
              <div className="how-generate-qr-content-text">
              <span>Step 1:</span>Fill in your business details .
              </div>

            </div>

            <div className="how-generate-qr-content-col">
              <div className="how-generate-qr-content-image">
                <img src={img2} alt="" />
              </div>
              
              
              <div className="how-generate-qr-content-text">
              <span>Step 2:</span>Select a template for your business card .
              </div>
            </div>

            <div className="how-generate-qr-content-col">
              <div className="how-generate-qr-content-image">
                <img src={img3} alt="" />
              </div>
              <div className="how-generate-qr-content-heading">
              
              </div>
              <div className="how-generate-qr-content-text">
              <span>Step 3:</span>Download your business card in the desired format.
              </div>
            </div>
          </div>

          <a href="#card-generator" className="how-generate-qr-button">
            Create Your Business Card
          </a>
        </div>


      </div>
    </div>
  );
};

export default HowToGenerateBusinessCardComponent;
