import React, { useState } from "react";
import Barcode from "react-barcode";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";

const BarcodeGeneratorHeader = () => {
  const [qrType, setQrType] = useState("text");

  const [qrValue, setQrValue] = useState("https://invoician.com/");

  const [inputValue, setInputValue] = useState("");
  const [inputHeading, setInputHeading] = useState("");

  const [codeValue, setCodeValue] = useState("ABCD1234EFGHI");

  const [codeHeading, setCodeHeading] = useState("");

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleGenerateCode = (e) => {

    e.preventDefault();

    setCodeValue(inputValue)
    setCodeHeading(inputHeading)

    
  };

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // download png :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  // Function to capture and download the div as PNG
  const handleDownloadPng = () => {
    const divElement = document.getElementById("capture-div"); // Get the div by ID

    html2canvas(divElement).then((canvas) => {
      // Convert canvas to PNG image
      const image = canvas.toDataURL("image/png");

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = image;
      link.download = "barcode.png"; // Set the default download filename
      link.click(); // Trigger the download
    });
  };

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // download jpg :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const handleDownloadJpg = () => {
    const divElement = document.getElementById("capture-div");

    html2canvas(divElement).then((canvas) => {
      const image = canvas.toDataURL("image/jpeg");

      const link = document.createElement("a");
      link.href = image;
      link.download = "barcode.jpg";
      link.click();
    });
  };

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // download pdf :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const handleDownloadPdf = () => {
    const divElement = document.getElementById("capture-div");

    const options = {
      margin: 1,
      filename: "barcode.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(divElement).set(options).save();
  };

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <div>
      <div className="qr-generator-header">
        <div className="qr-generator-header-main">
          <div id="qr-generator" className="qr-generator-header-heading">
            Online Barcode Generator
          </div>

          <div className="barcode-generator-header-content">
            <form onSubmit={handleGenerateCode} className="barcode-generator-header-content-form">
              <label>Item Name</label>
              <input onChange={e=>setInputHeading(e.target.value)} type="text" placeholder="Item 1" />

              <label>Item code</label>
              <input onChange={e=>setInputValue(e.target.value)} type="text" placeholder="ABCD1234EFGHI" />

              <button>Generate Barcode</button>
            </form>

            <div className="barcode-generator-header-content-details">
              <div className="barcode-generator-header-content-details-heading">{codeHeading?codeHeading:""}</div>
              <div id="capture-div" className="barcode-generator-header-content-details-code">
                <Barcode
                  value={codeValue}
                  width={2}
                  height={100}
                  format="CODE128" // You can change the barcode format to others like 'EAN13', 'UPC', etc.
                  displayValue={true} // Set to false if you don't want to display the value below the barcode
                  background="#fff" // Barcode background color
                  lineColor="#000" // Barcode line color
                />
              </div>

              <div
                onClick={handleDownloadPng}
                className="qr-generator-header-content-code-button"
              >
                Download PNG
              </div>
              <div className="qr-generator-header-content-code-buttons">
                <div onClick={handleDownloadJpg}>JPG</div>
                <div onClick={handleDownloadPdf}>PDF</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarcodeGeneratorHeader;
