import React from "react";

const BusinessCardFreeGenerator = () => {
  return (
    <div>
      <div className="qr-code-free">
        <div className="qr-code-free-main">
          <div className="qr-code-free-heading">
          Business Cards Maker App
          </div>
          <div className="qr-code-free-text">
          Get ready to make a great first impression by creating a unique business card design using Invoician. Look around for the best business card template created by our professional designers and customise it to make your custom business cards for free.
          </div>

        </div>
      </div>
    </div>
  );
};

export default BusinessCardFreeGenerator;
