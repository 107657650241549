import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SharedInvoiceStylishTheme from "./SharedInvoiceStylishTheme";
import DownloadingComponent from "../../../components/extra/DownloadingComponent";
import html2pdf  from "html2pdf.js";
import SharedInvoiceExcelTheme from "./SharedInvoiceExcelTheme";
import SharedInvoiceGstTheme from "./SharedInvoiceGstTheme";
import SharedInvoiceBillBookTheme from "./SharedInvoiceBillBookTheme";

const ViewSharedInvoicePageComponent = ({ invoiceData }) => {
  const data = invoiceData&&invoiceData.invoiceData

     const [downloading, setDownloading] = useState(false);

     
        const divRef = useRef(null);
        const [invoiceHeight, setInvoiceHeight] = useState(1000);
      
        const handleInvoiceHeight = (e) => {
          if (divRef.current) {
            setInvoiceHeight(divRef.current.offsetHeight);
          }
        };
     
   
    const generatePDF = async () => {
      const element = document.getElementById("content-to-pdf");
      setDownloading(true);
      const options = {
        margin: 0,
        filename: `${
          data.invoicePartyData && data.invoicePartyData.partyName
        } Invoice-${data.invoiceNumber}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        
        jsPDF: {
          unit: "px",
          format: [800, invoiceHeight + 50],
          orientation: "portrait",
        },
        
        // jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
      };
      await html2pdf().from(element).set(options).save();
      setDownloading(false);
    };
    
    
    useEffect(() => {
      handleInvoiceHeight();
    }, [generatePDF]);
  
  
  
  return (
    <div>
{downloading ? <DownloadingComponent /> : ""}

      <div className="shared-invoice-page">
        <div className="shared-invoice-page-main">
          <div className="shared-invoice-page-main-head">
          <div className="shared-invoice-page-main-head-content">
          <div className="shared-invoice-page-main-heading">
            <span>Invoice Amount</span>
            <div>₹ {invoiceData&&invoiceData.invoiceData.invoiceTotalAmount}</div>
          </div>
          <div className="shared-invoice-page-main-button" onClick={generatePDF}>
          <i className="fa-solid fa-download"></i> Download
          </div>
            
          </div>
          </div>
          <div className="shared-invoice-page-content">
          <div className="shared-invoice-page-content-main">

          <div ref={divRef} id="content-to-pdf" className="shared-invoice-page-content-main-download">
            {
              invoiceData.invoiceTheme === "Stylish"?
              <SharedInvoiceStylishTheme invoiceData={invoiceData}/>
              :invoiceData.invoiceTheme === "Excel"?
              <SharedInvoiceExcelTheme invoiceData={invoiceData}/>
              :invoiceData.invoiceTheme === "AdvanceGst"?
              <SharedInvoiceGstTheme invoiceData={invoiceData}/>
              :invoiceData.invoiceTheme === "BillBook"?
              <SharedInvoiceBillBookTheme invoiceData={invoiceData}/>
              :""
            }
            
          </div>

          </div>
          </div>
          <div className="shared-invoice-page-main-end">
            <div>Create similar invoices on <Link to="/">invoician.com</Link></div>
            <Link to="/app" >Try Now</Link>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSharedInvoicePageComponent;
