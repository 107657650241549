import React from "react";
import appStore from '../../../media/images/website/app-store.webp'
import playStore from '../../../media/images/website/play-store.webp'
// import img from '../../../media/images/website/start-using-card.jpg'
import img from '../../../media/images/website/start-using-card.webp'


const StartInvoicianTodayComponent = () => {
  return (
    <div>
      <div className="start-invoician-today">
        <div className="start-invoician-today-main">
          <div className="start-invoician-today-content">
            <div className="start-invoician-today-heading">Start using <br />inoician today</div>
            <div className="start-invoician-today-form">
                <span>+91 - </span>
                <input type="number" placeholder="Enter mobile number" />
                <button>Start Now!</button>
            </div>
            <div className="start-invoician-today-heading-1">Download app on</div>
            <div className="start-invoician-today-buttons">
                <img src={playStore} alt="" />
                <img src={appStore} alt="" />
            </div>
          </div>
          <div className="start-invoician-today-image">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartInvoicianTodayComponent;
