import React from "react";
import img1 from "../../../media/images/website/qr-code-generator-how-to-1.webp";
import img2 from "../../../media/images/website/qr-code-generator-how-to-2.webp";
import img3 from "../../../media/images/website/qr-code-generator-how-to-3.webp";

import imgEnd from "../../../media/images/website/Ad-Barcode-generator.png";

const HowToGenerateBarcodeComponent = () => {
  return (
    <div>
      <div className="how-generate-qr">
        <div className="how-generate-qr-main">
          <div className="how-generate-qr-head">
            <div className="how-generate-qr-head-heading">
              How to generate a online barcode using Invoician?
            </div>
          </div>

          <div className="how-generate-qr-content">
            <div className="how-generate-qr-content-col">
              <div className="how-generate-qr-content-image">
                <img src={img1} alt="" />
              </div>
             
              <div className="how-generate-qr-content-text">
              <span>Step 1:</span>Enter Your Item Information in Barcode Generator Tool
              </div>

            </div>

            <div className="how-generate-qr-content-col">
              <div className="how-generate-qr-content-image">
                <img src={img2} alt="" />
              </div>
              
              
              <div className="how-generate-qr-content-text">
              <span>Step 2:</span>Click on Generate Barcode to create your barcode .
              </div>
            </div>

            <div className="how-generate-qr-content-col">
              <div className="how-generate-qr-content-image">
                <img src={img3} alt="" />
              </div>
              <div className="how-generate-qr-content-heading">
              
              </div>
              <div className="how-generate-qr-content-text">
              <span>Step 3:</span>Choose the size of your barcode then download in PNG, JPG or PDF.
              </div>
            </div>
          </div>

          <a href="#qr-generator" className="how-generate-qr-button">
            Create Your Barcodes
          </a>
        </div>

        <div className="how-generate-qr-end">
          <img src={imgEnd} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HowToGenerateBarcodeComponent;
