import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";
import LoadingComponent from "../../../components/extra/LoadingComponent";

const AppSettingCreateNewBusiness = ({open}) => {

  const userId = localStorage.getItem("invoician-user-id");

  

const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerAlertStatus, setTimerAlertStatus] = useState("");
  const [loading, setLoading] = useState(false);

    
          const [data, setData] = useState({
            businessName: "",
            companyPhoneNumber: "",
            companyGstRegistered: false,
            companyGSTIN: "",
        
            // ---------------------------------------------------
        
            companyBillingAddress: "",
            companyState: "",
            companyPincode: "",
            companyCity: "",
          });
        
        const [addAddress, setAddAddress] = useState(false);
          
          
    
    
        
          const [popupData, setPopupData] = useState({
            companyBillingAddress: "",
            companyState: "",
            companyPincode: "",
            companyCity: "",
          });
        
          const handlePopupChange = (e) => {
            const value = e.target.value;
            const name = e.target.name;
            setPopupData({
              ...popupData,
              [name]: value,
            });
        
            // console.log("first ----------", value);
          };
        
          const handlePopupData = (e) => {
            e.preventDefault();
        
            setData({
              ...data,
              companyBillingAddress: popupData.companyBillingAddress,
              companyState: popupData.companyState,
              companyPincode: popupData.companyPincode,
              companyCity: popupData.companyCity,
            });
        
            // console.log("popupData is :", popupData);
            setAddAddress(false);
          };
        
        
          const handleChange = (e) => {
            const value = e.target.value;
            const name = e.target.name;
            setData({
              ...data,
              [name] : value
            })
          }
        
          const handleSubmit = (e) => {
            e.preventDefault();
            alert(true)
            console.log("data is :", data)
          }
          
          
        
          const reload = () => window.location.reload();
          
            const { createBusiness } = useContext(AuthContext);
          
            const handleBusiness = async (e) => {
              setLoading(true);
              e.preventDefault();
          
              createBusiness(
                userId,
                data,
                setTimerPopupAlert,
                setTimerAlertMessage,
                setTimerAlertStatus,
                reload
              );
          
              setLoading(false);
            };
            
            
    
    
  return (
    <div>

{timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}

      {loading ? <LoadingComponent /> : ""}


{addAddress ? (
        <div className="welcome-page-address-popup">
          <form
            onSubmit={handlePopupData}
            className="welcome-page-address-popup-main"
          >
            <div className="welcome-page-address-popup-head">
              <div>Company Address</div>
              <i
                className="fa-solid fa-xmark"
                onClick={() => setAddAddress(false)}
              ></i>
            </div>

            <div className="welcome-page-address-popup-form">
              <div className="welcome-page-address-popup-col">
                <div className="welcome-page-address-popup-label">
                  Street Address 
                  {/* <span>*</span> */}
                </div>
                <textarea
                  onChange={handlePopupChange}
                  name="companyBillingAddress"
                  required
                  type="text"
                  value={popupData.companyBillingAddress}
                />
              </div>

              <div className="welcome-page-address-popup-row">
                <div className="welcome-page-address-popup-col">
                  <div className="welcome-page-address-popup-label">State</div>
                  <input
                    onChange={handlePopupChange}
                    name="companyState"
                    type="text"
                    value={popupData.companyState}
                    list="state-type"
                  />

                  <datalist id="state-type">
                    {/* <option value="Andaman and Nicobar Islands">
        Andaman and Nicobar Islands
      </option> */}
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    {/* <option value="Dadra and Nagar Haveli and Daman and Diu">
        Dadra and Nagar Haveli and Daman and Diu
      </option> */}
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand </option>
                  </datalist>
                </div>

                <div className="welcome-page-address-popup-col">
                  <div className="welcome-page-address-popup-label">
                    Pincode
                  </div>
                  <input
                    onChange={handlePopupChange}
                    name="companyPincode"
                    type="number"
                    value={popupData.companyPincode}
                  />
                </div>
              </div>

              <div className="welcome-page-address-popup-col">
                <div className="welcome-page-address-popup-label">City</div>
                <input
                  onChange={handlePopupChange}
                  name="companyCity"
                  type="text"
                  value={popupData.companyCity}
                />
              </div>

              <div className="welcome-page-address-popup-buttons">
                <div onClick={() => setAddAddress(false)}>Cancle</div>
                <button>Save</button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}

        
      <form onSubmit={handleBusiness} className="setting-business-page">
        <div className="setting-business-page-main">
          {/**************************************************************************************************************************/}

          <div className="setting-business-page-head">
            <div className="setting-business-page-head-col">
              {/* <AppMobileSettingNavbar/> */}
              <div className="setting-business-page-heading">
                <div className="setting-business-page-main-heading">
                    Let's set up new business
                </div>                
              </div>

            </div>

            <div className="setting-business-page-buttons">
              <div className="setting-business-page-button-cancel" onClick={()=>open(false)} >Cancel</div>
              {/* <div className="setting-business-page-button-cancel" onClick={handleCancel} >Cancel</div> */}
              <button className="setting-business-page-button">Create</button>

                {/* {
                    submitButton?
                    <button className="setting-business-page-button">Save Changes</button>
                    :
                    <div className="setting-business-page-button-off">Save Changes</div>
                } */}
            </div>
          </div>

          {/**************************************************************************************************************************/}

          <div className="setting-business-page-form">

            
          <div className="welcome-page-col">
                  <label>
                    Your business name <span>*</span>
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    name="businessName"
                    type="text"
                    placeholder="Enter your business name"
                  />
                </div>

                <div className="welcome-page-col">
                  <label>
                    Company Phone Number <span>*</span>
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    name="companyPhoneNumber"
                    type="number"
                    placeholder="Enter Company Phone Number"
                  />
                </div>

                <div className="welcome-page-col">
                  <label>Company Address</label>
                  <div
                    className="welcome-page-col-input"
                    onClick={() => setAddAddress(true)}
                  >
                    {data.companyBillingAddress ? (
                      <>{data.companyBillingAddress}</>
                    ) : (
                      ""
                    )}
                    {data.companyCity ? <>, {data.companyCity}</> : ""}
                    {data.companyState ? <>, {data.companyState}</> : ""}
                    {data.companyPincode ? <>- {data.companyPincode}</> : ""}
                  </div>
                </div>


                <div className="welcome-page-col">
                  <label>
                    Is your business GST registered? <span>*</span>
                  </label>

                  <div className="setting-business-page-form-radio-options">
                    <div
                      className="setting-business-page-form-radio"
                      onClick={() => {
                        setData({ ...data, companyGstRegistered: true });
                      }}
                    >
                      Yes{" "}
                      <input
                        checked={data.companyGstRegistered ? true : false}
                        type="radio"
                      />
                    </div>

                    <div
                      className="setting-business-page-form-radio"
                      onClick={() => {
                        setData({
                          ...data,
                          companyGstRegistered: false,
                          companyGSTIN: "",
                        });
                      }}
                    >
                      no{" "}
                      <input
                        checked={!data.companyGstRegistered ? true : false}
                        type="radio"
                      />
                    </div>
                  </div>
                </div>

                {data.companyGstRegistered ? (
                  <div className="welcome-page-col">
                    <label>
                      GSTIN No <span>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      name="companyGSTIN"
                      onChange={handleChange}
                      placeholder="Enter your GST Number"
                    />
                  </div>
                ) : (
                  ""
                )}
            
            
           
          </div>

          {/**************************************************************************************************************************/}
          {/**************************************************************************************************************************/}
          {/**************************************************************************************************************************/}
          {/**************************************************************************************************************************/}
        </div>
      </form>
    </div>
  );
};

export default AppSettingCreateNewBusiness;
