import React, { useState } from "react";
import html2canvas from "html2canvas";
import img1 from "../../../media/images/website/BusinessCard/image1.png";
import img2 from "../../../media/images/website/BusinessCard/image2.png";
import img3 from "../../../media/images/website/BusinessCard/image3.png";
import img4 from "../../../media/images/website/BusinessCard/image4.png";
import img5 from "../../../media/images/website/BusinessCard/image5.png";
// import img6 from "../../../media/images/website/BusinessCard/image6.png";
// import img7 from "../../../media/images/website/BusinessCard/image7.png";
// import img8 from "../../../media/images/website/BusinessCard/image8.png";
// import img9 from "../../../media/images/website/BusinessCard/image9.png";
// import img10 from "../../../media/images/website/BusinessCard/image10.png";

const BusinessCardMakerHeader = () => {
  const [cardTheme, setCardTheme] = useState(1);

  const [data, setData] = useState({
    businessName: "",
    contactNumber1: "",
    contactNumber2: "",
    email: "",
    website: "",
    address: "",
    image: "",
  });

  const [cardData, setCardData] = useState();

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    setCardData({
      businessName: data.businessName,
      contactNumber1: data.contactNumber1,
      contactNumber2: data.contactNumber2,
      email: data.email,
      website: data.website,
      address: data.address,
      image: imageUrl,
    });

    e.preventDefault();

    console.log("data is :", data);
  };

  //   :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  //   handle image :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  //   :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const [imageUrl, setImageUrl] = useState("");

  // Handle the image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file); // Create an object URL for the image
      setImageUrl(url); // Set the image URL for rendering
    }
  };


  
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // download png :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const handleDownloadPng = () => {
    const divElement = document.getElementById("capture-div");

    html2canvas(divElement).then((canvas) => {
      const image = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = image;
      link.download = "card.png"; 
      link.click();
    });
  };

  
  

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // download jpg :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const handleDownloadJpg = () => {
    const divElement = document.getElementById("capture-div");

    html2canvas(divElement).then((canvas) => {
      const image = canvas.toDataURL("image/jpeg");

      const link = document.createElement("a");
      link.href = image;
      link.download = "card.jpg";
      link.click();
    });
  };

  //   :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <div>
      <div className="business-card-maker-header">
        <div id="card-generator" className="business-card-maker-header-main">
          <div className="business-card-maker-header-heading">
            Business Card Maker
          </div>
          <div className="business-card-maker-header-content">
            <form
              onSubmit={handleSubmit}
              className="business-card-maker-header-content-form"
            >
              <label>
                Business Name <span>*</span>
              </label>
              <input
                onChange={handleChange}
                name="businessName"
                required
                type="text"
                placeholder="Business Name"
              />

              <label>
                Contact Number 1 <span>*</span>
              </label>
              <input
                onChange={handleChange}
                name="contactNumber1"
                required
                type="number"
                placeholder="+91 00000 00000"
              />

              <label>Contact Number 2</label>
              <input
                onChange={handleChange}
                name="contactNumber2"
                type="number"
                placeholder="+91 00000 00000"
              />

              <label>
                Email <span>*</span>
              </label>
              <input
                onChange={handleChange}
                name="email"
                required
                type="text"
                placeholder="example@email.com"
              />

              <label>Website</label>
              <input
                onChange={handleChange}
                name="website"
                type="text"
                placeholder="www.invoician.com"
              />

              <label>
                Address <span>*</span>
              </label>

              <textarea
                onChange={handleChange}
                name="address"
                required
                rows={3}
                placeholder="Address line,
City, State,
PIN"
              />

              <label>Upload Logo</label>

              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />

              <button>
                <i class="fa-solid fa-id-card"></i> Generate Business Card
              </button>
            </form>
            <div className="business-card-maker-header-content-details">
              <div
                className="business-card-maker-header-content-card-main"
              >
              <div
                id="capture-div"
                className="business-card-maker-header-content-card"
              >
                {
                
                cardTheme === 1 ? 

                   <div className="business-card-maker-theme-1">

                    
                     {cardData ? cardData.image ?
                       <div className="business-card-maker-theme-1-logo">
                       <img
                         src={cardData.image}
                         alt="Uploaded preview"
                         style={{ maxWidth: "300px", maxHeight: "300px" }}
                       />
                   </div>
                     
                     :""
                     :
                   <div className="business-card-maker-theme-1-logo">
                     {cardData ? "" : <div>Logo</div>}
                   </div>
                    }
                   
                    
                    
                    
                   <div className="business-card-maker-theme-1-details">
                     <div className="business-card-maker-theme-1-name">
                       {cardData ? cardData.businessName : "Business Name"}
                     </div>

                     {cardData ? (
                       cardData.contactNumber1 ? (
                         <div className="business-card-maker-theme-1-text">
                           <i class="fa-solid fa-phone"></i>
                           <div>
                           <div>{cardData.contactNumber1}</div>
                           <div>{cardData.contactNumber2}</div>
                           </div>
                         </div>
                       ) : (
                         ""
                       )
                     ) : (
                       <div className="business-card-maker-theme-1-text">
                         <i class="fa-solid fa-phone"></i>
                         <div>
                         <div>+91 99999 00000</div>
                         <div>+91 99999 00000</div>
                         </div>
                       </div>
                     )}

                     {cardData ? (
                       cardData.email ? (
                         <div className="business-card-maker-theme-1-text">
                           <i class="fa-solid fa-envelope"></i>
                           <div>{cardData.email}</div>
                         </div>
                       ) : (
                         ""
                       )
                     ) : (
                       <div className="business-card-maker-theme-1-text">
                         <i class="fa-solid fa-envelope"></i>
                         <div>email@gmail.com</div>
                       </div>
                     )}

                     {cardData ? (
                       cardData.website ? (
                         <div className="business-card-maker-theme-1-text">
                           <i class="fa-solid fa-globe"></i>
                           <div>{cardData.website}</div>
                         </div>
                       ) : (
                         ""
                       )
                     ) : (
                       <div className="business-card-maker-theme-1-text">
                         <i class="fa-solid fa-globe"></i>
                         <div>www.invoician.com</div>
                       </div>
                     )}

                     {cardData ? (
                       cardData.address ? (
                         <div className="business-card-maker-theme-1-text">
                           <i class="fa-solid fa-location-dot"></i>
                           <div>
                             <pre>{cardData.address}</pre>
                           </div>
                         </div>
                       ) : (
                         ""
                       )
                     ) : (
                       <div className="business-card-maker-theme-1-text">
                         <i class="fa-solid fa-location-dot"></i>
                         <div>
                           Company full address, Company location, Area, New
                           Delhi, Delhi, 110001
                         </div>
                       </div>
                     )}
                   </div>
                   


                   
                 </div>


                : cardTheme === 2 ? (
                  <div className="business-card-maker-theme-2">
                    <div className="business-card-maker-theme-2-col">
                      <div className="business-card-maker-theme-2-name">
                        {cardData ? cardData.businessName : "Business Name"}
                      </div>
                      <div className="business-card-maker-theme-2-logo">
                        {cardData ? "" : <div>Logo</div>}

                        {cardData && cardData.image && (
                          <img
                            src={cardData.image}
                            alt="Uploaded preview"
                            style={{ maxWidth: "300px", maxHeight: "300px" }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="business-card-maker-theme-2-details">
                      <div className="business-card-maker-theme-2-text">
                        <div>
                          {cardData
                            ? cardData.contactNumber1
                            : "+91 99999 00000"}
                        </div>
                        <div>
                          {cardData
                            ? cardData.contactNumber2
                            : "+91 99999 00000"}
                        </div>
                      </div>
                      <div className="business-card-maker-theme-2-text">
                        {cardData ? cardData.email : "email@gmail.com"}
                      </div>
                      <div className="business-card-maker-theme-2-text">
                        {cardData ? cardData.website : "www.invoician.com"}
                      </div>
                      <div className="business-card-maker-theme-2-text">
                        {cardData ? (
                          <pre>{cardData.address}</pre>
                        ) : (
                          "Company full address, Company location, Area, New Delhi, Delhi, 110001"
                        )}
                      </div>
                    </div>
                  </div>
                ) 
                : cardTheme === 3 ? 
                  <div className="business-card-maker-theme-3">
                    <div className="business-card-maker-theme-3-details">
                      <div className="business-card-maker-theme-3-name">
                        {cardData ? cardData.businessName : "Business Name"}
                      </div>

                      {cardData ? (
                        cardData.contactNumber1 ? (
                          <div className="business-card-maker-theme-3-text">
                            <i class="fa-solid fa-phone"></i>
                            <div>
                            <div>{cardData.contactNumber1}</div>
                            <div>{cardData.contactNumber2}</div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="business-card-maker-theme-3-text">
                          <i class="fa-solid fa-phone"></i>
                          <div>
                          <div>+91 99999 00000</div>
                          <div>+91 99999 00000</div>
                          </div>
                        </div>
                      )}

                      {cardData ? (
                        cardData.email ? (
                          <div className="business-card-maker-theme-3-text">
                            <i class="fa-solid fa-envelope"></i>
                            <div>{cardData.email}</div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="business-card-maker-theme-3-text">
                          <i class="fa-solid fa-envelope"></i>
                          <div>email@gmail.com</div>
                        </div>
                      )}

                      {cardData ? (
                        cardData.website ? (
                          <div className="business-card-maker-theme-3-text">
                            <i class="fa-solid fa-globe"></i>
                            <div>{cardData.website}</div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="business-card-maker-theme-3-text">
                          <i class="fa-solid fa-globe"></i>
                          <div>www.invoician.com</div>
                        </div>
                      )}

                      {cardData ? (
                        cardData.address ? (
                          <div className="business-card-maker-theme-3-text">
                            <i class="fa-solid fa-location-dot"></i>
                            <div>
                              <pre>{cardData.address}</pre>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="business-card-maker-theme-3-text">
                          <i class="fa-solid fa-location-dot"></i>
                          <div>
                            Company full address, Company location, Area, New
                            Delhi, Delhi, 110001
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="business-card-maker-theme-3-logo">
                      {cardData ? "" : <div>Logo</div>}

                      {cardData && cardData.image && (
                        <img
                          src={cardData.image}
                          alt="Uploaded preview"
                          style={{ maxWidth: "300px", maxHeight: "300px" }}
                        />
                      )}
                    </div>
                  </div>

                  :
                  
                 cardTheme === 4 ? 
                  <div className="business-card-maker-theme-4">
                    <div className="business-card-maker-theme-4-details">
                      <div className="business-card-maker-theme-4-name">
                        {cardData ? cardData.businessName : "Business Name"}
                      </div>

                      {cardData ? (
                        cardData.contactNumber1 ? (
                          <div className="business-card-maker-theme-4-text">
                            <i class="fa-solid fa-phone"></i>
                            <div>
                            <div>{cardData.contactNumber1}</div>
                            <div>{cardData.contactNumber2}</div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="business-card-maker-theme-4-text">
                          <i class="fa-solid fa-phone"></i>
                          <div>
                          <div>+91 99999 00000</div>
                          <div>+91 99999 00000</div>
                          </div>
                        </div>
                      )}

                      {cardData ? (
                        cardData.email ? (
                          <div className="business-card-maker-theme-4-text">
                            <i class="fa-solid fa-envelope"></i>
                            <div>{cardData.email}</div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="business-card-maker-theme-4-text">
                          <i class="fa-solid fa-envelope"></i>
                          <div>email@gmail.com</div>
                        </div>
                      )}

                      {cardData ? (
                        cardData.website ? (
                          <div className="business-card-maker-theme-4-text">
                            <i class="fa-solid fa-globe"></i>
                            <div>{cardData.website}</div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="business-card-maker-theme-4-text">
                          <i class="fa-solid fa-globe"></i>
                          <div>www.invoician.com</div>
                        </div>
                      )}

                      {cardData ? (
                        cardData.address ? (
                          <div className="business-card-maker-theme-4-text">
                            <i class="fa-solid fa-location-dot"></i>
                            <div>
                              <pre>{cardData.address}</pre>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="business-card-maker-theme-4-text">
                          <i class="fa-solid fa-location-dot"></i>
                          <div>
                            Company full address, Company location, Area, New
                            Delhi, Delhi, 110001
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="business-card-maker-theme-4-logo">
                      {cardData ? "" : <div>Logo</div>}

                      {cardData && cardData.image && (
                        <img
                          src={cardData.image}
                          alt="Uploaded preview"
                          style={{ maxWidth: "300px", maxHeight: "300px" }}
                        />
                      )}
                    </div>
                  </div>

                  :
                  
                cardTheme === 5 ? 

                <div className="business-card-maker-theme-5">

                 
                  {cardData ? cardData.image ?
                    <div className="business-card-maker-theme-5-logo">
                    <img
                      src={cardData.image}
                      alt="Uploaded preview"
                      style={{ maxWidth: "300px", maxHeight: "300px" }}
                    />
                </div>
                  
                  :""
                  :
                <div className="business-card-maker-theme-5-logo">
                  {cardData ? "" : <div>Logo</div>}
                </div>
                 }
                
                 
                 
                 
                <div className="business-card-maker-theme-5-details">
                  <div className="business-card-maker-theme-5-name">
                    {cardData ? cardData.businessName : "Business Name"}
                  </div>

                  {cardData ? (
                    cardData.contactNumber1 ? (
                      <div className="business-card-maker-theme-5-text">
                        <i class="fa-solid fa-phone"></i>
                        <div>
                        <div>{cardData.contactNumber1}</div>
                        <div>{cardData.contactNumber2}</div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    <div className="business-card-maker-theme-5-text">
                      <i class="fa-solid fa-phone"></i>
                      <div>
                      <div>+91 99999 00000</div>
                      <div>+91 99999 00000</div>
                      </div>
                    </div>
                  )}

                  {cardData ? (
                    cardData.email ? (
                      <div className="business-card-maker-theme-5-text">
                        <i class="fa-solid fa-envelope"></i>
                        <div>{cardData.email}</div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    <div className="business-card-maker-theme-5-text">
                      <i class="fa-solid fa-envelope"></i>
                      <div>email@gmail.com</div>
                    </div>
                  )}

                  {cardData ? (
                    cardData.website ? (
                      <div className="business-card-maker-theme-5-text">
                        <i class="fa-solid fa-globe"></i>
                        <div>{cardData.website}</div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    <div className="business-card-maker-theme-5-text">
                      <i class="fa-solid fa-globe"></i>
                      <div>www.invoician.com</div>
                    </div>
                  )}

                  {cardData ? (
                    cardData.address ? (
                      <div className="business-card-maker-theme-5-text">
                        <i class="fa-solid fa-location-dot"></i>
                        <div>
                          <pre>{cardData.address}</pre>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    <div className="business-card-maker-theme-5-text">
                      <i class="fa-solid fa-location-dot"></i>
                      <div>
                        Company full address, Company location, Area, New
                        Delhi, Delhi, 110001
                      </div>
                    </div>
                  )}
                </div>
                


                
              </div>



                  
                  :<div></div>
                }
              </div>
              </div>

              <div className="business-card-maker-header-content-options">
                <img
                  onClick={(e) => setCardTheme(1)}
                  className={
                    cardTheme === 1
                      ? "business-card-maker-header-content-option-active"
                      : ""
                  }
                  src={img1}
                  alt=""
                />
                <img
                  onClick={(e) => setCardTheme(2)}
                  className={
                    cardTheme === 2
                      ? "business-card-maker-header-content-option-active"
                      : ""
                  }
                  src={img2}
                  alt=""
                />
                <img
                  onClick={(e) => setCardTheme(3)}
                  className={
                    cardTheme === 3
                      ? "business-card-maker-header-content-option-active"
                      : ""
                  }
                  src={img3}
                  alt=""
                />
                <img
                  onClick={() => setCardTheme(4)}
                  className={
                    cardTheme === 4
                      ? "business-card-maker-header-content-option-active"
                      : ""
                  }
                  src={img4}
                  alt=""
                />
                <img
                  onClick={() => setCardTheme(5)}
                  className={
                    cardTheme === 5
                      ? "business-card-maker-header-content-option-active"
                      : ""
                  }
                  src={img5}
                  alt=""
                />
              </div>

              <div className="business-card-maker-header-content-buttons">
                <div onClick={handleDownloadPng}>
                  <i class="fa-solid fa-download"></i> PNG
                </div>
                <div onClick={handleDownloadJpg}>
                  <i class="fa-solid fa-download"></i> JPG
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCardMakerHeader;
