import axios from 'axios';
import React, { useEffect, useState } from 'react'
import LoadingComponent from '../../components/extra/LoadingComponent';

const OtherAdminComponent = () => {

  
  // const link = "http://localhost:5500";
  const link = "https://api.invoician.com";
  
    const [allUsers, setAllUsers] = useState()
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("")



  


    const fetchAllUsers = async () => {
        try {
          const response = await  axios.get(`${link}/users`, {
          // const response = await  axios.get(`http://localhost:5500/users`, {
          // const response = await axios.get(`https://api.invoician.com/user/${id}`, {
            headers: {
              Authorization:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
            },
          });
        //   console.log("data is : ", response.data.data);
          setAllUsers(response.data.data);
        } catch (error) {
          // alert("error.response.data.message")
          console.log(error.response);
        }
      };
    
      
  useEffect(() => {
    fetchAllUsers();
  }, []);
    

// ================================================================================================================================================================================================================================
// ================================================================================================================================================================================================================================
// ================================================================================================================================================================================================================================



  // setState =======================================================================================================

  const [token, setToken] = useState(localStorage.getItem("invoician-token"));
  const [storeUserId, setStoreUserId] = useState(
    localStorage.getItem("invoician-user-id")
  );
  const [storeBusinessId, setStoreBusinessId] = useState(
    localStorage.getItem("business-id")
  );

  // save to storage ============================================================================================

  useEffect(() => {
    if (token) {
      localStorage.setItem("invoician-token", token);
    } else {
      localStorage.removeItem("invoician-token");
    }
  }, [token]);

  useEffect(() => {
    if (storeUserId) {
      localStorage.setItem("invoician-user-id", storeUserId);
    } else {
      localStorage.removeItem("invoician-user-id");
    }
  }, [storeUserId]);

  useEffect(() => {
    if (storeBusinessId) {
      localStorage.setItem("business-id", storeBusinessId);
    } else {
      localStorage.removeItem("business-id");
    }
  }, [storeBusinessId]);



// ================================================================================================================================================================================================================================
// ================================================================================================================================================================================================================================


const handleLogin = (e) => {

    // console.log(e)
    
    
    // setToken(response.data.data.userToken);
    //   setStoreUserId(response.data.data._id);
    //   setStoreBusinessId(response.data.data.selectedBusiness);
    //   navigate("/app");
    
    
        // console.log("user id is ", e._id)
        // console.log("business id is ", e.businessData[0]._id)
        // console.log("token is ", e.userToken)

        setStoreUserId(e._id);
        setStoreBusinessId(e.businessData[0]._id);
        setToken(e.userToken);
        // navigate("/app");

        window.open('/app', '_blank');

      

}




  const cellStyle = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'center',
  };
    
    
    
  return (
    <div>

      {loading ? <LoadingComponent /> : ""}
     

{
    sessionStorage.getItem('secret-key') === "124124"?
        <div className="other-admin">
            <h1>all users</h1>

            <div>
              <table style={{width:"100%"}}>
                
                <tr>
                  <th style={cellStyle} >S.No.</th>
                  <th style={cellStyle} >Name</th>
                  <th style={cellStyle} >Email</th>
                  <th style={cellStyle} >Company</th>
                  <th style={cellStyle} >Number</th>
                  <th style={cellStyle} >Login?</th>
                </tr>

                {
                  allUsers&&allUsers.map( (e, i) => (
                    <tr>
                      <td style={cellStyle}>{i+1}</td>
                      <td style={cellStyle}>{e.userName}</td>
                      <td style={cellStyle}>{e.email}</td>
                      {/* <td style={cellStyle}>{e.businessData?e.businessData[0].companyPhoneNumber:"-"}</td> */}

                      <td style={cellStyle}>{e.businessData?e.businessData[0]? e.businessData[0].businessName:"business not added":"-"}</td>

                      <td style={cellStyle}>{e.businessData?e.businessData[0]? e.businessData[0].companyPhoneNumber:"business not added":"-"}</td>

                      <td style={cellStyle}><button onClick={ () => handleLogin(e) }>Login?</button></td>
             
                    </tr>
                  ))
                }

                
              </table>



            </div>

            
        </div>

:
        <div className="other-admin">
            <input type="password" onChange={e=> setPassword(e.target.value)} placeholder='enter password'/>
            <button onClick={e=>sessionStorage.setItem('secret-key', password)}>submit</button>
        </div>

            }
    </div>
  )
}

export default OtherAdminComponent