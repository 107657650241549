import React, { useContext, useEffect, useRef, useState } from "react";
import phonePe from "../../../../media/images/icons/phonepe.png";
import gpay from "../../../../media/images/icons/gpay.png";
import paytm from "../../../../media/images/icons/paytm.png";
import upi from "../../../../media/images/icons/upi.png";
import QRCode from "react-qr-code";
import { AuthContext } from "../../../../context/AuthContext";
import { toWords } from "number-to-words";
import html2pdf from "html2pdf.js";

const StylishA4Theme = ({ open, data, businessData, heading, invoiceType }) => {
  

  const { link } = useContext(AuthContext);

  
  
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // calculate height and add space ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  // total words end ----------------------------

  const totalWordEndRef = useRef(null);
  const [totalWordEnd, setTotalWordEnd] = useState(null);

  useEffect(() => {
    if (totalWordEndRef.current) {
      const rect = totalWordEndRef.current.getBoundingClientRect();
      setTotalWordEnd(rect.top);
    }
  }, []);

  // total words Start ----------------------------

  const totalWordStartRef = useRef(null);
  const [totalWordStart, setTotalWordStart] = useState(null);

  useEffect(() => {
    if (totalWordStartRef.current) {
      const rect = totalWordStartRef.current.getBoundingClientRect();
      setTotalWordStart(rect.top);
    }
  }, []);

  // total words margin value ----------------------------

  const totalWordMargin = () => {
    if (totalWordStart < 1122 && totalWordEnd > 1122) {
      const margin = totalWordEnd - totalWordStart + 20;
      return margin;
    } else {
      console.log(false);
    }
  };

  // total Paids end ----------------------------

  const totalPaidEndRef = useRef(null);
  const [totalPaidEnd, setTotalPaidEnd] = useState(null);

  useEffect(() => {
    if (totalPaidEndRef.current) {
      const rect = totalPaidEndRef.current.getBoundingClientRect();
      setTotalPaidEnd(rect.top);
    }
  }, []);

  // total Paids Start ----------------------------

  const totalPaidStartRef = useRef(null);
  const [totalPaidStart, setTotalPaidStart] = useState(null);

  useEffect(() => {
    if (totalPaidStartRef.current) {
      const rect = totalPaidStartRef.current.getBoundingClientRect();
      setTotalPaidStart(rect.top);
    }
  }, []);

  // total paid margin value ----------------------------

  const totalPaidMargin = () => {
    if (totalPaidStart < 1122 && totalPaidEnd > 1122) {
      const margin = totalPaidEnd - totalPaidStart + 20;
      return margin;
    } else {
      console.log(false);
    }
  };


  
  // ----------------------------------------------
  // total Taxable end ----------------------------

  const totalTaxableEndRef = useRef(null);
  const [totalTaxableEnd, setTotalTaxableEnd] = useState(null);

  useEffect(() => {
    if (totalTaxableEndRef.current) {
      const rect = totalTaxableEndRef.current.getBoundingClientRect();
      setTotalTaxableEnd(rect.top);
    }
  }, []);

  // total Taxable Start ----------------------------

  const totalTaxableStartRef = useRef(null);
  const [totalTaxableStart, setTotalTaxableStart] = useState(null);

  useEffect(() => {
    if (totalTaxableStartRef.current) {
      const rect = totalTaxableStartRef.current.getBoundingClientRect();
      setTotalTaxableStart(rect.top);
    }
  }, []);

  // total Taxable margin value ----------------------------

  const totalTaxableMargin = () => {
    if (totalTaxableStart < 1122 && totalTaxableEnd > 1122) {
      const margin = totalTaxableEnd - totalTaxableStart + 20;
      return margin;
    } else {
      console.log(false);
    }
  };

  
  // ----------------------------------------------
  // Payment end ----------------------------

  const totalPaymentEndRef = useRef(null);
  const [totalPaymentEnd, setTotalPaymentEnd] = useState(null);

  useEffect(() => {
    if (totalPaymentEndRef.current) {
      const rect = totalPaymentEndRef.current.getBoundingClientRect();
      setTotalPaymentEnd(rect.top);
    }
  }, []);

  // total Payment Start ----------------------------

  const totalPaymentStartRef = useRef(null);
  const [totalPaymentStart, setTotalPaymentStart] = useState(null);

  useEffect(() => {
    if (totalPaymentStartRef.current) {
      const rect = totalPaymentStartRef.current.getBoundingClientRect();
      setTotalPaymentStart(rect.top);
    }
  }, []);

  // total Payment margin value ----------------------------

  const totalPaymentMargin = () => {
    if (totalPaymentStart < 1122 && totalPaymentEnd > 1122) {
      const margin = totalPaymentEnd - totalPaymentStart + 30;
      return margin;
    } else {
      console.log(false);
    }
  };  

  
  // ----------------------------------------------
  // Bank end ----------------------------

  const totalBankEndRef = useRef(null);
  const [totalBankEnd, setTotalBankEnd] = useState(null);

  useEffect(() => {
    if (totalBankEndRef.current) {
      const rect = totalBankEndRef.current.getBoundingClientRect();
      setTotalBankEnd(rect.top);
    }
  }, []);

  // total Bank Start ----------------------------

  const totalBankStartRef = useRef(null);
  const [totalBankStart, setTotalBankStart] = useState(null);

  useEffect(() => {
    if (totalBankStartRef.current) {
      const rect = totalBankStartRef.current.getBoundingClientRect();
      setTotalBankStart(rect.top);
    }
  }, []);

  // total Bank margin value ----------------------------

  const totalBankMargin = () => {
    if (totalBankStart < 1122 && totalBankEnd > 1122) {
      const margin = totalBankEnd - totalBankStart + 30;
      return margin;
    } else {
      console.log(false);
    }
  };


  
  // ----------------------------------------------
  // Terms end ----------------------------

  const totalTermsEndRef = useRef(null);
  const [totalTermsEnd, setTotalTermsEnd] = useState(null);

  useEffect(() => {
    if (totalTermsEndRef.current) {
      const rect = totalTermsEndRef.current.getBoundingClientRect();
      setTotalTermsEnd(rect.top);
    }
  }, []);

  // total Terms Start ----------------------------

  const totalTermsStartRef = useRef(null);
  const [totalTermsStart, setTotalTermsStart] = useState(null);

  useEffect(() => {
    if (totalTermsStartRef.current) {
      const rect = totalTermsStartRef.current.getBoundingClientRect();
      setTotalTermsStart(rect.top);
    }
  }, []);

  // total Terms margin value ----------------------------

  const totalTermsMargin = () => {
    if (totalTermsStart < 1122 && totalTermsEnd > 1122) {
      const margin = totalTermsEnd - totalTermsStart  + 20;
      return margin;
    } else {
      console.log(false);
    }
  };


  
  // ----------------------------------------------
  // Notes end ----------------------------

  const totalNotesEndRef = useRef(null);
  const [totalNotesEnd, setTotalNotesEnd] = useState(null);

  useEffect(() => {
    if (totalNotesEndRef.current) {
      const rect = totalNotesEndRef.current.getBoundingClientRect();
      setTotalNotesEnd(rect.top);
    }
  }, []);

  // total Notes Start ----------------------------

  const totalNotesStartRef = useRef(null);
  const [totalNotesStart, setTotalNotesStart] = useState(null);

  useEffect(() => {
    if (totalNotesStartRef.current) {
      const rect = totalNotesStartRef.current.getBoundingClientRect();
      setTotalNotesStart(rect.top);
    }
  }, []);

  // total Notes margin value ----------------------------

  const totalNotesMargin = () => {
    if (totalNotesStart < 1122 && totalNotesEnd > 1122) {
      const margin = totalNotesEnd - totalNotesStart + 30 ;
      return margin;
    } else {
      console.log(false);
    }
  };



  
  // ----------------------------------------------
  // Subtotal end ----------------------------

  const totalSubtotalEndRef = useRef(null);
  const [totalSubtotalEnd, setTotalSubtotalEnd] = useState(null);

  useEffect(() => {
    if (totalSubtotalEndRef.current) {
      const rect = totalSubtotalEndRef.current.getBoundingClientRect();
      setTotalSubtotalEnd(rect.top);
    }
  }, []);

  // total Subtotal Start ----------------------------

  const totalSubtotalStartRef = useRef(null);
  const [totalSubtotalStart, setTotalSubtotalStart] = useState(null);

  useEffect(() => {
    if (totalSubtotalStartRef.current) {
      const rect = totalSubtotalStartRef.current.getBoundingClientRect();
      setTotalSubtotalStart(rect.top);
    }
  }, []);

  // total Subtotal margin value ----------------------------

  const totalSubtotalMargin = () => {
    if (totalSubtotalStart < 1122 && totalSubtotalEnd > 1122) {
      const margin = totalSubtotalEnd - totalSubtotalStart + 30;
      return margin;
    } else {
      console.log(false);
    }
  };




  
  // ----------------------------------------------
  // item start --------------

  const [itemStart, setItemStart] = useState([])
  
  const itemStartRefs = useRef([]);

  useEffect(() => {
    const newHeights = itemStartRefs.current.map((ref) => ref?.getBoundingClientRect().top);
    setItemStart(newHeights);
  }, []);
  

  // item End ----------------------------------------------------------

  const [itemEnd, setItemEnd] = useState([])
  
  const itemEndRefs = useRef([]);

  useEffect(() => {
    const newHeights = itemEndRefs.current.map((ref) => ref?.getBoundingClientRect().top);
    setItemEnd(newHeights);
  }, []);
  
  

   

  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  
   
    
    const mainColor = getComputedStyle(document.documentElement)
      .getPropertyValue("--invoice")
      .trim();
  
    // Function to set RGBA color with opacity
    const rgbaColor = (opacity) => {
      const hex = mainColor.replace("#", "");
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };
  
   

  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  

  
  const calculateTaxedPrice = (price, taxRate, quantity, discount, cess) => {
    const discountedPrice = price - (price * discount) / 100;

    return (
      (discountedPrice +
        (discountedPrice * taxRate) / 100 +
        (cess * discountedPrice) / 100) *
      quantity
    );
  };



  const taxableAmount = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (item.itemPrice * item.quantity -
        (item.itemPrice * item.discount * item.quantity) / 100)
    );
  }, 0);

  const totalGst = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPrice - (item.discount * item.itemPrice) / 100) *
        item.itemGst) /
        100) *
        item.quantity
    );
  }, 0);

  const totalCess = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPrice - (item.discount * item.itemPrice) / 100) * item.cess) /
        100) *
        item.quantity
    );
  }, 0);


// --------------------


const taxablePurchaseAmount = data.invoiceItemsData.reduce((acc, item) => {
  return (
    acc +
    (item.itemPurchasePrice * item.quantity -
      (item.itemPurchasePrice * item.discount * item.quantity) / 100)
  );
}, 0);

const totalPurchaseGst = data.invoiceItemsData.reduce((acc, item) => {
  return (
    acc +
    (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) *
      item.itemGst) /
      100) *
      item.quantity
  );
}, 0);

  const totalPurchaseCess = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) * item.cess) /
        100) *
        item.quantity
    );
  }, 0);




  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  
  
  
  
    const generatePDF = async () => {
      const element = document.getElementById("content-to-pdf");
      // setDownloading(true);
      const options = {
        margin: 0,
        filename: `inv.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
  
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };
      await html2pdf().from(element).set(options).save();
      open&&open(false)

      // setDownloading(false);
    };
  
        
// useEffect(() => {
//   if(totalWordEnd || totalWordStart || totalPaidEnd || totalPaidStart || totalTaxableEnd || totalTaxableStart || totalPaymentEnd || totalPaymentStart || totalBankEnd || totalBankStart || totalTermsEnd || totalTermsStart || totalNotesEnd || totalNotesStart || totalSubtotalEnd || totalSubtotalStart || itemStart || itemEnd ){
//     generatePDF();

//   }
// }, [totalWordEnd, totalWordStart, totalPaidEnd, totalPaidStart, totalTaxableEnd, totalTaxableStart, totalPaymentEnd, totalPaymentStart, totalBankEnd, totalBankStart, totalTermsEnd, totalTermsStart, totalNotesEnd, totalNotesStart, totalSubtotalEnd, totalSubtotalStart, itemStart, itemEnd ])
  


useEffect(() => {
  setTimeout(() => {
    generatePDF();
  },0);
}, [])

  
  
  
  
  // ----------------------------------------------------------
  
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
 
  return (
    <div>
    <div className="stylish-a4-theme">
      <div id="content-to-pdf" className="stylish-a4-theme-data">
        
          <div className="stylish-a4-theme-main">
            <div>
              <div className="stylish-a4-theme-label">
                {heading?heading:""} {heading === "tax invoice" ? <span>ORIGINAL FOR RECIPIENT</span>:""}
              </div>

              <div className="stylish-a4-theme-header">
                {
                  businessData.companyLogo?
                <div className="stylish-a4-theme-header-logo">
                  <img
                src={`${link}/${businessData.companyLogo}`}
                alt="profile"
              />
                </div>
                :""}

                <div className="stylish-a4-theme-header-details">
                  <div className="stylish-a4-theme-header-company">
                    {businessData.businessName}
                  </div>

                  {
              businessData.invoiceSettingData.invoiceAddress?
                  <div className="stylish-a4-theme-header-address">
                   
              {
                businessData.companyBillingAddress?
                <>{businessData.companyBillingAddress},</>
              :""
              }
              {
                businessData.companyCity?
                <> {businessData.companyCity},</>
              :""
              }
              {
                businessData.companyState?
                <> {businessData.companyState},</>
              :""
              }
              {
                businessData.companyPincode?
                <> {businessData.companyPincode}</>
              :""
              }
            
                  </div>
                  :""}

                  <div className="stylish-a4-theme-header-row">
                    
                    {businessData.companyPhoneNumber ?
                    <div className="stylish-a4-theme-header-col">
                      <div>Mobile:</div>
                      <span>{businessData.companyPhoneNumber }</span>
                    </div>
                    :""}

{
  businessData.invoiceSettingData.invoiceEmail?
  businessData.companyEmail ?
  <div className="stylish-a4-theme-header-col">
                      <div>Email:</div>
                      <span>businessData.companyEmail </span>
                    </div>
                    :""
                    :""
                    }

{
  businessData.invoiceSettingData.invoiceGSTIN?
  businessData.companyGSTIN ?
                    <div className="stylish-a4-theme-header-col">
                      <div>GSTIN:</div>
                      <span>{businessData.companyGSTIN}</span>
                    </div>
                    :""
                    :""
}


                  </div>
                </div>
              </div>

              <div style={{ backgroundColor: rgbaColor(0.2) }} className="stylish-a4-theme-invoice-option">
                <div className="stylish-a4-theme-invoice-option-col">
                  <div>Invoice No:</div>
                  <span>{data.invoiceNumber ? data.invoiceNumber : "__________"}</span>
                </div>

                <div className="stylish-a4-theme-invoice-option-col">
                  <div>Date:</div>
                  <span>
                  {data.invoiceDate ? (
                <>
                  {new Date(data.invoiceDate)
                    .getDate()
                    .toString()
                    .padStart(2, 0)}
                  /
                  {(new Date(data.invoiceDate).getMonth() + 1)
                    .toString()
                    .padStart(2, 0)}
                  /{new Date(data.invoiceDate).getFullYear()}
                </>
              ) : (
                "__________"
              )}
                  </span>
                </div>

{data.invoiceEnableDueDate ?
                <div className="stylish-a4-theme-invoice-option-col">
                  <div>Due Date:</div>
                  <span> <>
                  {new Date(data.invoiceDueDate)
                    .getDate()
                    .toString()
                    .padStart(2, 0)}
                  /
                  {(new Date(data.invoiceDueDate).getMonth() + 1)
                    .toString()
                    .padStart(2, 0)}
                  /{new Date(data.invoiceDueDate).getFullYear()}
                </></span>
                </div>
                :""}

              </div>

              <div className="stylish-a4-theme-details">

                <div className="stylish-a4-theme-address">
                  
                  <div className="stylish-a4-theme-address-data">
                    <div className="stylish-a4-theme-address-heading">
                      Bill From
                    </div>
                    {
                    data.invoicePartyData && data.invoicePartyData.partyName ?
                    <div className="stylish-a4-theme-address-name">
                      {data.invoicePartyData.partyName}
                    </div>
                    :"__________"
                    }

                   

                    {(data.invoicePartyData &&
                data.invoicePartyData.partyBillingAddress) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingState) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingCity) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingPinCode) ? (
                <div className="stylish-a4-theme-address-address">
                  {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingAddress}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingCity ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingCity}
                    </>
                  ) : (
                    ""
                  )}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingState ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingState}
                    </>
                  ) : (
                    ""
                  )}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingPinCode ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingPinCode}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
                   


                    {data.invoicePartyData && data.invoicePartyData.partyNumber ? (
                <div className="stylish-a4-theme-address-text">
                  <span>Mobile :</span>{" "}
                  {data.invoicePartyData && data.invoicePartyData.partyNumber}
                </div>
              ) : (
                ""
              )}
              {data.invoicePartyData && data.invoicePartyData.partyGSTIN ? (
                <div className="stylish-a4-theme-address-text">
                  <span>GSTIN : </span>
                  {data.invoicePartyData && data.invoicePartyData.partyGSTIN}
                </div>
              ) : (
                ""
              )}

                  </div>



                   


 
                  {data.invoicePartyData.partyBothAddressSame ? (
              <div className="stylish-a4-theme-address-data">
                <div className="stylish-a4-theme-address-heading">
                  Ship From{" "}
                </div>

                {data.invoicePartyData && data.invoicePartyData.partyName ? (
                  <div className="stylish-a4-theme-address-name">
                    {data.invoicePartyData.partyName}
                  </div>
                ) : (
                  "-------------"
                )}
                {(data.invoicePartyData &&
                  data.invoicePartyData.partyBillingAddress) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyBillingState) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyBillingPinCode) ? (
                  <div className="stylish-a4-theme-address-address">
                    {data.invoicePartyData &&
                      data.invoicePartyData.partyBillingAddress}
                    {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingState ? (
                      <>
                        ,{" "}
                        {data.invoicePartyData &&
                          data.invoicePartyData.partyBillingState}
                      </>
                    ) : (
                      ""
                    )}
                    {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingPinCode ? (
                      <>
                        ,{" "}
                        {data.invoicePartyData &&
                          data.invoicePartyData.partyBillingPinCode}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {data.invoicePartyData && data.invoicePartyData.partyNumber ? (
                  <div className="stylish-a4-theme-address-text">
                    <b>Mobile :</b>{" "}
                    {data.invoicePartyData && data.invoicePartyData.partyNumber}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <>
                {(data.invoicePartyData &&
                  data.invoicePartyData.partyShippingAddress) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyShippingCity) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyShippingState) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyShippingPinCode) ? (
                  <div className="stylish-a4-theme-address-data">
                    <div className="stylish-a4-theme-address-heading">
                      Ship From{" "}
                    </div>
                    {data.invoicePartyData &&
                    data.invoicePartyData.partyName ? (
                      <div  className="stylish-a4-theme-address-name">
                        {data.invoicePartyData.partyName}
                      </div>
                    ) : (
                      "-------------"
                    )}
                    <div className="stylish-a4-theme-address-address">
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyShippingAddress}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingCity ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingCity}
                        </>
                      ) : (
                        ""
                      )}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingState ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingState}
                        </>
                      ) : (
                        ""
                      )}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingPinCode ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingPinCode}
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    {data.invoicePartyData &&
                    data.invoicePartyData.partyNumber ? (
                      <div className="stylish-a4-theme-address-text">
                        <b>Mobile :</b>{" "}
                        {data.invoicePartyData &&
                          data.invoicePartyData.partyNumber}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            )}



                    
                  
                </div>

                <div className="stylish-a4-theme-option">
                <table>
          {data.invoiceDetailsOptions ? (
                <>
                {
                  Object.entries(data.invoiceDetailsOptions)
                    .filter(([key, value]) => value) // Show only fields with values
                    .map(([key, value]) => (
                      <tr key={key}>
                        <td>{key} </td>
                        <td>{value}</td>
                      </tr>
                    ))

                  }
         </>   
          ) : (
            ""
          )}
            {
              data.invoicePoNo?
              <tr >
                        <td>P.O. No. </td>
                        <td>{data.invoicePoNo}</td>
                      </tr>
                      :""
                    }
            {
              data.invoiceVehicleNo?
              <tr >
                        <td>Vehicle No.</td>
                        <td>{data.invoiceVehicleNo}</td>
                      </tr>
                      :""
                    }
            
              </table>
                </div>


                
              </div>

              <div className="stylish-a4-theme-items">
                <div className="stylish-a4-theme-items-table">
                  <table>
                    <tr style={{ backgroundColor: rgbaColor(0.2) }}>
                      

                      
                  <td>NO</td>
                  <td>ITEMS/SERVICES</td>
                  {data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                  0 ? (
                    <td>HSN/SAC</td>
                  ) : (
                    ""
                  )}
                  <td>QTY.</td>
                  <td>RATE</td>
                  {data.invoiceItemsData.filter((e) => e.discount).length >
                  0 ? (
                    <td>DISC.</td>
                  ) : (
                    ""
                  )}
                  {data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? (
                    <td>TAX</td>
                  ) : (
                    ""
                  )}
                  {data.invoiceItemsData.filter((e) => e.cess).length > 0 ? (
                    <td>CESS</td>
                  ) : (
                    ""
                  )}
                  <td>AMOUNT</td>
                
                      
                      
                      
                    </tr>


{
  data.invoiceItemsData.map( (e, i) => (   
     <>
                      <div ref={(el) => itemStartRefs.current[i] = el} style={{ marginTop : itemStart[i] < 1122 && itemEnd[i] > 1122 ? itemEnd[i] - itemStart[i] + 20 : 0 }}></div>
                      <tr>
                    <td style={{ borderBottom: `solid ${rgbaColor(0.1)} 2px` }}>
                      {i + 1}
                    </td>
                    <td style={{ borderBottom: `solid ${rgbaColor(0.1)} 2px` }}>
                      {e.itemName}
                      {
                        businessData.invoiceSettingData.invoiceItemDescription?
                        e.itemDescription?<div>{e.itemDescription}</div>:""
                        :""
                      }
                    </td>

                    {data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                    0 ? (
                      <td
                        style={{ borderBottom: `solid ${rgbaColor(0.1)} 2px` }}
                      >
                        {e.itemHsnCode ? e.itemHsnCode : "-"}
                      </td>
                    ) : (
                      ""
                    )}

                    <td style={{ borderBottom: `solid ${rgbaColor(0.1)} 2px` }}>
                      {formatNumber(Number(e.quantity))*1}
                    </td>

                    <td style={{ borderBottom: `solid ${rgbaColor(0.1)} 2px` }}>
                      {
                        invoiceType === "Purchase"?
                      formatNumber(Number(e.itemPurchasePrice))*1
                      :
                      formatNumber(Number(e.itemPrice))*1
                      }
                    </td>
                    {data.invoiceItemsData.filter((e) => e.discount).length >
                    0 ? (
                      <td
                        style={{ borderBottom: `solid ${rgbaColor(0.1)} 2px` }}
                      >
                        {e.discount ? formatNumber(e.discount)*1 : 0}%
                      </td>
                    ) : (
                      ""
                    )}
                    {data.invoiceItemsData.filter((e) => e.itemGst).length >
                    0 ? (
                      <td
                        style={{ borderBottom: `solid ${rgbaColor(0.1)} 2px` }}
                      >
                        {e.itemGst ? e.itemGst : 0}%
                      </td>
                    ) : (
                      ""
                    )}
                    {data.invoiceItemsData.filter((e) => e.cess).length > 0 ? (
                      <td
                        style={{ borderBottom: `solid ${rgbaColor(0.1)} 2px` }}
                      >
                        {e.cess ? e.cess : 0}%
                      </td>
                    ) : (
                      ""
                    )}

                    <td style={{ borderBottom: `solid ${rgbaColor(0.1)} 2px` }}>

                      {
                        invoiceType === "Purchase"?
                      formatNumber(
                        calculateTaxedPrice(
                          e.itemPurchasePrice,
                          e.itemGst,
                          e.quantity,
                          e.discount,
                          e.cess
                        )
                      ) * 1
                      :
                      formatNumber(
                        calculateTaxedPrice(
                          e.itemPrice,
                          e.itemGst,
                          e.quantity,
                          e.discount,
                          e.cess
                        )
                      ) * 1
                      
                      }
                    </td>
                  </tr>          
                      <div ref={(el) => itemEndRefs.current[i] = el}></div>
     </>
  ) )
}




                  </table>
                </div>

                <span ref={totalSubtotalStartRef}></span>

                <div  style={{ marginTop: totalSubtotalMargin(),  backgroundColor: rgbaColor(0.2)  }} className="stylish-a4-theme-items-total">
                  <div>Subtotal</div>
                  <div>₹ {formatNumber(data.invoiceSubTotalAmount) * 1}</div>
                </div>
                <span ref={totalSubtotalEndRef}></span>


              </div>
            </div>

            <div className="stylish-a4-theme-end">
              <div className="stylish-a4-theme-end-col-1">

              <span ref={totalNotesStartRef}></span>


              {data.invoiceNotes ? (
                <div style={{ marginTop: totalNotesMargin() }} className="stylish-a4-theme-end-col-notes">
                  <div className="stylish-a4-theme-end-heading">NOTES</div>
                  <div className="stylish-a4-theme-end-pre">
                    <pre>{data.invoiceNotes}</pre>
                  </div>
                </div>

              ) : (
              ""
            )}

                <span ref={totalNotesEndRef}></span>



                <span ref={totalTermsStartRef}></span>

                {
                  businessData.businessTermsConditions ?
                <div className="stylish-a4-theme-end-col-terms">
                  <div style={{ marginTop: totalTermsMargin() }} className="stylish-a4-theme-end-heading">
                    TERMS AND CONDITIONS
                  </div>
                  <div className="stylish-a4-theme-end-pre">
                  <pre>{businessData.businessTermsConditions}</pre>
                  </div>
                </div>
                :""
}
                <span ref={totalTermsEndRef}></span>



     

{


  data.paymentType === "Bank Transfer" ||
  data.paymentType === "UPI" ||
  data.paymentType === "Card" ||
  data.paymentType === "Cheque" ||
  data.paymentType === "Netbanking" ?

data.selectedBankAccount? (
              businessData.bankData&&businessData.bankData.filter( e => data.selectedBankAccount === e._id).map( e => (
                
                <div className="stylish-a4-theme-end-col-bank">

                <span ref={totalBankStartRef}></span>

                  <div style={{ marginTop: totalBankMargin() }}  className="stylish-a4-theme-end-col-bank-details">
                    <div className="stylish-a4-theme-end-heading">
                      BANK DETAILS
                    </div>

                    <table>
                  <tr>
                    <td>Name:</td>
                    <td>{e.accountHolderName}</td>
                  </tr>
                  <tr>
                    <td>IFSC Code:</td>
                    <td>{e.IFSCcode}</td>
                  </tr>
                  <tr>
                    <td>Account No:</td>
                    <td>{e.accountNumber}</td>
                  </tr>
                  <tr>
                    <td>Bank:</td>
                    <td>{e.bankName}</td>
                  </tr>
                </table> 
                    
                  </div>
                  <span ref={totalBankEndRef}></span>

                  <span ref={totalPaymentStartRef}></span>


                  {
  e.upiId?
       
                  <div style={{ marginTop: totalPaymentMargin() }} className="stylish-a4-theme-end-col-bank-payment">
                    <div className="stylish-a4-theme-end-col-bank-payment-details">
                      <div className="stylish-a4-theme-end-heading">
                        PAYMENT QR CODE
                      </div>
                      <div>UPI ID:</div>
                      <span>{e.upiId}</span>

                      <div className="stylish-a4-theme-end-col-bank-payment-icons">
                        <img src={phonePe} alt="" />
                        <img src={gpay} alt="" />
                        <img src={paytm} alt="" />
                        <img src={upi} alt="" />
                      </div>
                    </div>

                    <div className="stylish-a4-theme-end-col-bank-payment-code">
                      <QRCode
        value={`upi://pay?pa=${e.upiId}`}
                        size={70}
                        level="M"
                      />
                    </div>
                  </div>
                  
                :""}

                  <span ref={totalPaymentEndRef}></span>

                </div> 
                 ))
                ) : 
                  ""
                  :""
                }
                
              </div>

              <div className="stylish-a4-theme-end-col-2">
                <div className="stylish-a4-theme-end-col-amount">
                  <span ref={totalTaxableStartRef}></span>
                  <div
                    style={{ marginTop: totalTaxableMargin() }}
                    className="stylish-a4-theme-end-col-amount-row-1"
                  >
                    <div>Taxable Value</div>
                    <span>
                    ₹ {
                    invoiceType === "Purchase"?
                  formatNumber(taxablePurchaseAmount) * 1
                  :
                  formatNumber(taxableAmount) * 1
                  }
                    </span>
                  </div>




                  {data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? (
                <>
                  <div className="stylish-a4-theme-end-col-amount-row-1">
                    <div>CGST</div>
                    <span>
                    ₹ {
                      invoiceType === "Purchase"?
                      formatNumber(totalPurchaseGst) / 2
                      :
                      formatNumber(totalGst) / 2
                    }
                    </span>
                  </div>

                  <div className="stylish-a4-theme-end-col-amount-row-1">
                    <div>SGST</div>
                    <span>
                    ₹ {
                      invoiceType === "Purchase"?
                      formatNumber(totalPurchaseGst) / 2
                      :
                      formatNumber(totalGst) / 2
                    }
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}



{data.invoiceItemsData.filter((e) => e.cess).length > 0 ? (
                <div className="stylish-a4-theme-end-col-amount-row-1">
                    <div>CESS</div>
                    <span>
                    ₹ {
                      invoiceType === "Purchase"?
                    formatNumber(totalPurchaseCess) / 1
                    :
                    formatNumber(totalCess) / 1
                    }
                    </span>
                  </div>
              ) : (
                ""
              )}




{data.invoiceExtraDiscount ? (
<div className="stylish-a4-theme-end-col-amount-row-1">
                    <div>Discount</div>
                    <span>₹ {formatNumber(data.invoiceExtraDiscount) * 1}</span>
                  </div>
              ) : (
                ""
              )}



                  <div className="stylish-a4-theme-end-col-amount-row-2">
                    <div>Total Amount</div>
                    <span>₹ {(data.invoiceTotalAmount.toFixed(2) * 1).toLocaleString()}</span>
                  </div>
                  <span ref={totalTaxableEndRef}></span>

                  <span ref={totalPaidStartRef}></span>


                  {data.receivedAmount ? (
                <>
                  <div
                    style={{ marginTop: totalPaidMargin() }}
                    className="stylish-a4-theme-end-col-amount-row-1"
                  >
                    <div>Paid Amount</div>
                    <span> ₹ {data.receivedAmount}</span>
                  </div>

                  <div className="stylish-a4-theme-end-col-amount-row-1">
                    <div>Balance</div>
                    <span>₹{" "}
                      {Math.round(
                        data.invoiceTotalAmount.toFixed(2) - data.receivedAmount
                      )}</span>
                  </div>
                  </>
              ) : (
                ""
              )}

                  <span ref={totalPaidEndRef}></span>

                  <span ref={totalWordStartRef}></span>

                  <div
                    style={{ marginTop: totalWordMargin() }}
                    className="stylish-a4-theme-end-col-amount-words"
                  >
                    <div>Total Amount (in words) :</div>
                    <div>
                      {toWords(data.invoiceTotalAmount)} rupees
                    </div>
                  </div>

                  <span ref={totalWordEndRef}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
  );
};

export default StylishA4Theme;
