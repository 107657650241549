import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import LoadingComponent from '../../../components/extra/LoadingComponent';
import PopUpAlertComponent from '../../../components/extra/PopUpAlertComponent';

const AppMobileSettingNavbar = () => {
  
  // path ================================================================================================================================================

  const location = useLocation();
  const path = location.pathname;
  
  // navbar menu ===============================================================================================================================
  
  const [viewNavbarMenu, setViewNavbarMenu] = useState(false);

  const navbarRef = useRef(null);

  const hideNavbar = (event) => {
    if (
      navbarRef.current &&
      !navbarRef.current.contains(event.target) 
    ) {
      setViewNavbarMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", hideNavbar);

    return () => {
      document.removeEventListener("mousedown", hideNavbar);
    };
  }, []);


    const [popUpAlert, setPopUpAlert] = useState(false);
  
  
  
  // ===============================================================================================================================
  // shortcut options ===============================================================================================================================
  // ===============================================================================================================================
  
  
  

  const [shortCutMenu, setShortCutMenu] = useState(false);

  const shortcutRef = useRef(null);
  const shortcutListRef = useRef(null);

  const handleClickOutside = (event) => {
    // Check if the click is outside the button and dropdown
    if (
      shortcutListRef.current &&
      !shortcutListRef.current.contains(event.target) &&
      shortcutRef.current &&
      !shortcutRef.current.contains(event.target)
    ) {
      setShortCutMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  // ===============================================================================================================================
  
  // fetch data ===============================================================================================================================
    // get from storage -------------------

    const userId = localStorage.getItem("invoician-user-id");
    const businessId = localStorage.getItem("business-id");
  
  
  const [appNavBusinessData, setAppNavBusinessData] = useState();

  const { logout, fetchBusiness, link } = useContext(AuthContext);
  const navigate = useNavigate();
  
  const userLogout = (e) => {
    logout();
    navigate("/login");
  };

  const fetchBusinessData = (e) =>
    fetchBusiness(userId, businessId, setAppNavBusinessData);

  useEffect(() => {
    fetchBusinessData();
  }, []);

  if (!appNavBusinessData )
    return (
      <div>
        <LoadingComponent />
      </div>
    );

  
  
  // ===============================================================================================================================
  // ===============================================================================================================================
  // ===============================================================================================================================
  
  return (
    <div>

{popUpAlert ? <PopUpAlertComponent open={setPopUpAlert} ok={userLogout} heading="You want to logout?" /> :""}

      
      <div className="app-small-navbar">

        <div className="app-small-navbar-bars"  onClick={() => setViewNavbarMenu(true)}><i class="fa-solid fa-bars"></i></div>
        
        <div className={`app-small-navbar-main ${viewNavbarMenu ? "app-small-navbar-main-open" : ""}`} >
          <div className="app-small-navbar-menu" ref={navbarRef} >


            
          <Link onClick={ () => setViewNavbarMenu(false) } to="/app/setting/business" className="app-navbar-top">
              <div className="app-navbar-top-company">
                {appNavBusinessData && appNavBusinessData.companyLogo ? (
                  <div className="app-navbar-top-company-logo">
                    <img
                      src={`${link}/${appNavBusinessData.companyLogo}`}
                      alt="company logo"
                    />
                  </div>
                ) : (
                  <div className="app-navbar-top-company-icon">
                    {appNavBusinessData &&
                      appNavBusinessData.businessName.charAt(0)}
                  </div>
                )}
                <div className="app-navbar-top-company-details">
                  <div className="app-navbar-top-company-name">
                    {" "}
                    {appNavBusinessData &&
                    appNavBusinessData.businessName.length > 15
                      ? `${appNavBusinessData.businessName.slice(0, 13)}...`
                      : appNavBusinessData.businessName}
                  </div>
                  <div className="app-navbar-top-company-number">
                    {appNavBusinessData && appNavBusinessData.companyNumber}
                  </div>
                </div>
              </div>

              <div className="app-navbar-top-setting">
                <i className="fa-solid fa-gear"></i>
              </div>
            </Link>




            
            
            {/* menu =================================================================================================================================== */}

            <div className="">
                         <Link to="/app" className="app-setting-navbar-back"><i className="fa-solid fa-house"></i> Back to Dashboard</Link>
             
             
             
              <div className="app-navbar-menu-links">

                
                                <Link className={path === "/app/setting/account"?"app-navbar-menu-active":""} to="/app/setting/account">
                                  <div>
                                    <i className="fa-solid fa-user"></i>Account
                                  </div>
                                </Link>
                                
                                <Link to="/app/setting/business" className={path === "/app/setting/business"?"app-navbar-menu-active":""}>
                                  <div>
                                    <i className="fa-solid fa-address-card"></i>Manage Business
                                  </div>
                                </Link>
                                
                                
                                <Link to="/app/setting/invoice" className={path === "/app/setting/invoice"?"app-navbar-menu-active":""}>
                                  <div>
                                  <i className="fa-solid fa-file-lines"></i> Invoice Setting
                                  </div>
                                </Link>
                
              </div>

            </div>
            
            
            {/* setting =================================================================================================================================== */}

            
            <div onClick={e=>setPopUpAlert(true)}  className="app-navbar-logout">
            <span >
              Logout <i className="fa-solid fa-right-from-bracket"></i>
            </span>
          </div>
            
          </div>
      </div>


      </div>
      
    </div>
  )
}

export default AppMobileSettingNavbar