import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";

const TalkToExpertPopup = ( {open, setQuerySuccess, setTimerPopupAlert, setTimerAlertMessage, setTimerAlertStatus} ) => {

  
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

const [loading, setLoading] = useState(false);

const [data, setData] = useState({
  contactNumber : "",
  formType : "Talk to expert query",  
})
    
const { createNumberQuery } = useContext(AuthContext);
    
  const handleCreate = async (e) => {
    setLoading(true);
    e.preventDefault();

    await createNumberQuery(
      data,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      open,
      setQuerySuccess,
    );


    setLoading(false);
  };
  
  
  return (
    <div>
      <form onSubmit={handleCreate} className="talk-expert-popup">
        <div className="talk-expert-popup-main">
          <div className="talk-expert-popup-close">
            <i className="fa-solid fa-xmark" onClick={ ()=> open(false) }></i>
          </div>
          <div className="talk-expert-popup-content">
            <div className="talk-expert-popup-content-col-1">
              <div className="talk-expert-popup-content-heading-1">Still Scrolling?</div>
              <div className="talk-expert-popup-content-heading-2">Let Invoician Help</div>
              <div className="talk-expert-popup-content-text">Not Sure if Inoician fits your needs?</div>
              <div className="talk-expert-popup-content-text">Our Experts are here! Get a free consultation now.</div>
              <div className="talk-expert-popup-content-form-heading">Mobile No.</div>
              <div className="talk-expert-popup-content-form">
                <input required onChange={e => setData({...data, contactNumber : e.target.value})} type="number" placeholder="Mobile No."/>
                {/* <button onClick={()=>alert("Under Maintenance")}>Talk To Our Expert Now</button> */}
                {
                  loading?
                  <div className="talk-expert-popup-content-form-button"> <div class="button-loader"></div> Talk To Our Expert Now</div>
                  :
                  <button>Talk To Our Expert Now</button>
                }
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TalkToExpertPopup;
