import React from "react";
import Slider from "react-slick";

const QrCodeCustomersSayComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };
  
  return (
    <div>
      <div className="qr-code-customers-say">
        <div className="qr-code-customers-say-main">
          <div className="qr-code-customers-say-heading">
            What Our Customers Say
          </div>

          <div className="qr-code-customers-say-content">

          <Slider {...settings}>
           
            
            <div className="qr-code-customers-say-content-section">
              <div className="qr-code-customers-say-content-rating">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              </div>
              <div className="qr-code-customers-say-content-heading">
                "Provides complete control"
              </div>
              <div className="qr-code-customers-say-content-text">
              Since I started using this free billing and invoicing software, managing my business has become so much more streamlined. It’s incredibly user-friendly, and even without prior experience in accounting or inventory management, I’ve had no issues navigating it. The invoicing system works seamlessly, saving me hours every week. Plus, the fact that it’s free makes it accessible to new and growing businesses. I’ve recommended it to all my colleagues—it’s truly a fantastic tool for any business in India.
              </div>
              <div className="qr-code-customers-say-content-name">Mohit Kumar dhiran</div>
            </div>
            
            <div className="qr-code-customers-say-content-section">
              <div className="qr-code-customers-say-content-rating">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              </div>
              <div className="qr-code-customers-say-content-heading">
                "Provides complete control"
              </div>
              <div className="qr-code-customers-say-content-text">
              Invoician free billing software has been a game-changer for my boutique! I can easily manage inventory, keep track of the stock, and even handle GST billing without paying a single rupee. It has simplified every transaction, and the employee attendance feature helps manage staff effortlessly. It's an all-in-one solution for small businesses, especially in India. Highly recommended for anyone looking to streamline their business without any additional costs.
              </div>
              <div className="qr-code-customers-say-content-name">Komal Rana</div>
            </div>

            
            
            </Slider>



            
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCodeCustomersSayComponent;
