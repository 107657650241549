import React from "react";

const BusinessCardCreateImpact = () => {
  return (
    <div>
      <div className="business-card-impact">
        <div className="business-card-impact-main">
          <div className="business-card-impact-heading">
            Create an Impact For Your Brand With a Free Business Card Maker.
          </div>

          <div className="business-card-impact-content">

            <div className="business-card-impact-content-text">
              <i class="fa-regular fa-lightbulb"></i>
              Using an online business tool, you can craft a good business card per your requirements. A business card has an important role to play in converting leads. Irrespective of you being a business professional, self-employed, or representing a nonprofit venture, a professional business card is a great ice-breaker.
            </div>

            <div className="business-card-impact-content-text">
              <i class="fa-regular fa-lightbulb"></i>
              Prepare your business card without hassle using Invoician. You can start with a suitable template, add your details to it, and get a professionally customised business card within minutes. It is the simplest and effective way of giving your contact details to people you want to deal with. With the Invoician business card maker app at your side, designing your business cards is quick and easy.
            </div>

            <div className="business-card-impact-content-text">
              <i class="fa-regular fa-lightbulb"></i>
              We have many templates that are designed specifically for different industry requirements in a wide variety of styles. So, no matter what business you do, Invoician business card maker has a template to make you look the best.
            </div>

            <div className="business-card-impact-content-text">
              <i class="fa-regular fa-lightbulb"></i>
              Got something special in mind? You can design business cards from scratch in our online business card maker tool.
            </div>


          </div>


        </div>
      </div>
    </div>
  );
};

export default BusinessCardCreateImpact;
