import axios from 'axios';
import React, { useEffect, useState } from 'react'
import LoadingComponent from '../../components/extra/LoadingComponent';
import PopUpAlertComponent from '../../components/extra/PopUpAlertComponent';

const OtherAdminComponent = () => {

  
  // const link = "http://localhost:5500";
  const link = "https://api.invoician.com";
  
  const [popUpAlert, setPopUpAlert] = useState(false)
  const [popUpAlertUpdate, setPopUpAlertUpdate] = useState(false)

    const [allUsers, setAllUsers] = useState()
  const [loading, setLoading] = useState(false);

  const [userId, setUserId] = useState("")
  const [userName, setUserName] = useState("")

  const [password, setPassword] = useState("")

  const [verified, setVerified] = useState()


  


    const fetchAllUsers = async () => {
        try {
          const response = await  axios.get(`${link}/users`, {
          // const response = await  axios.get(`http://localhost:5500/users`, {
          // const response = await axios.get(`https://api.invoician.com/user/${id}`, {
            headers: {
              Authorization:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
            },
          });
          // console.log("data is : ", response.data.data);
          setAllUsers(response.data.data);
        } catch (error) {
          // alert("error.response.data.message")
          console.log(error.response);
        }
      };
    
      
  useEffect(() => {
    fetchAllUsers();
  }, []);
    

// ================================================================================================================================================================================================================================

const deleteClient = async () => {
    setLoading(true);
  
    try {
        const res = await fetch(`${link}/delete-user/${userId}`, {
        // const res = await fetch(`http://localhost:5500/delete-user/${userId}`, {
        method : "DELETE",
        headers: {
            "Authorization": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU'
        }
    })
    
    if(res.ok) {
        // alert("Client deleted successfully")
        // open(false)
        fetchAllUsers()
      } else {
        const res_data = await res.json();
        alert(res_data.message);
    }
} catch (error) {
    alert('server error')
}

setLoading(false);
}


// ================================================================================================================================================================================================================================
    
  const handleUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const response = await fetch(
        `${link}/update-user/${userId}`,
        // `http://localhost:5500/update-user/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU'

          },
          body: JSON.stringify({userVerified : verified}),
        }
      );

      if (response.ok) {
        alert("User updated successfully");
        fetchAllUsers();

      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }

      // console.log(response);
    } catch (error) {
      // console.log("catch err", error);
      alert("server error");
    }
    setLoading(false);
  };


  const cellStyle = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'center',
  };
    
    
    
  return (
    <div>

      {loading ? <LoadingComponent /> : ""}
      {popUpAlert ? <PopUpAlertComponent open={setPopUpAlert} ok={deleteClient} heading={`You want to delete "${userName}" data?`} /> :""}
      {popUpAlertUpdate ? <PopUpAlertComponent open={setPopUpAlertUpdate} ok={handleUpdate} heading={`You want to update "${userName}" data?`} /> :""}


{
    sessionStorage.getItem('admin-password') === "9871599433"?
        <div className="other-admin">
            <h1>all users</h1>

            <div>
              <table style={{width:"100%"}}>
                
                <tr>
                  <th style={cellStyle} >S.No.</th>
                  <th style={cellStyle} >Name</th>
                  <th style={cellStyle} >Email</th>
                  <th style={cellStyle} >Company</th>
                  <th style={cellStyle} >Number</th>
                  <th style={cellStyle} >Status</th>
                  <th style={cellStyle} >Delete?</th>
                  <th style={cellStyle} >Update?</th>
                </tr>

                {
                  allUsers&&allUsers.map( (e, i) => (
                    <tr>
                      <td style={cellStyle}>{i+1}</td>
                      <td style={cellStyle}>{e.userName}</td>
                      <td style={cellStyle}>{e.email}</td>
                      {/* <td style={cellStyle}>{e.businessData?e.businessData[0].companyPhoneNumber:"-"}</td> */}

                      <td style={cellStyle}>{e.businessData?e.businessData[0]? e.businessData[0].businessName:"business not added":"-"}</td>

                      <td style={cellStyle}>{e.businessData?e.businessData[0]? e.businessData[0].companyPhoneNumber:"business not added":"-"}</td>

                      {/* <td style={cellStyle}>{e.businessData?e.businessData[0].companyPhoneNumber? e.businessData[0].companyPhoneNumber:"---":"-"}</td> */}

                      <td style={cellStyle}>{e.userVerified===true ? "verified" : "unverified" }</td>
                      <td style={cellStyle}><b style={{cursor:"pointer"}} onClick={ a=> { setPopUpAlert(true); setUserId(e._id); setUserName(e.userName) }}>delete</b></td>
                      <td style={cellStyle}>{e.userVerified===true ?
                            <form onSubmit={handleUpdate}>
                             <button onClick={ a=> { setVerified("false"); setUserId(e._id); setUserName(e.userName);  }}>disabled user?</button> 
                            </form>
                             : 
                             <form onSubmit={handleUpdate}>
                                 <button onClick={ a=> {  setUserId(e._id); setUserName(e.userName); setVerified("true") }}>enable user?</button> 
                                </form>
                             }</td>
                    </tr>
                  ))
                }

                
              </table>

                {/* {
                    allUsers&&allUsers.map( e=> (
                        <div>
                            <br />
                            <div>{e.userName}</div>
                            <div>{e.email}</div>
                            <div>{e.userVerified===true ? "verified" : "unverified" }</div>
                            <b style={{cursor:"pointer"}} onClick={ a=> { setPopUpAlert(true); setUserId(e._id); setUserName(e.userName) }}>delete</b>
                            <div>{e.userVerified===true ?
                            <form onSubmit={handleUpdate}>
                             <button onClick={ a=> { setVerified("false"); setUserId(e._id); setUserName(e.userName);  }}>disabled user?</button> 
                            </form>
                             : 
                             <form onSubmit={handleUpdate}>
                                 <button onClick={ a=> {  setUserId(e._id); setUserName(e.userName); setVerified("true") }}>enable user?</button> 
                                </form>
                             }</div>
                            
                            <br />
                            
                        </div>
                    ))
                } */}


            </div>

            
        </div>

:
        <div className="other-admin">
            <input type="password" onChange={e=> setPassword(e.target.value)} placeholder='enter password'/>
            <button onClick={e=>sessionStorage.setItem('admin-password', password)}>submit</button>
        </div>

            }
    </div>
  )
}

export default OtherAdminComponent