import React from "react";

const BarcodeFaqComponent = () => {
  return (
    <div>
      <div className="qr-code-faq">
        <div className="qr-code-faq-main">
          <div className="qr-code-faq-heading">Frequently Asked Questions</div>
          <div className="qr-code-faq-content">


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              What to do once I generate a barcode using Invoician?
            </div>
            <div className="qr-code-faq-answer">
            Once generated, you can start using your barcode for your requirements. Print it and attach it to the package or an inventory item as required in your business.              
            </div>

            


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Is the barcode different from SKU?
            </div>
            <div className="qr-code-faq-answer">
            Barcode is a specially generated code that can be read using a barcode scanner only. However, SKU is a unique alphanumeric code, and it is used to assist internal references for the products and services. Being alphanumeric, you need not scan SKU to read it.
            </div>

            


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              How to select the best type of barcode for my business?
            </div>
            <div className="qr-code-faq-answer">
            Consider the three parameters while choosing your barcode.
           
              <li>The scanner you are using.</li>
              <li>The type of data you need to store</li>
              <li>The industry standards that you are obliged to meet</li>
            </div>

            


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              What is the difference between 1D and 2D barcodes?
            </div>
            <div className="qr-code-faq-answer">
            The visible difference between 1D and 2D barcodes is that the 1D barcodes have vertical parallel lines in white and black colors. However, in 2D barcodes, both vertical and horizontal lines exist.
            </div>

            


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Are the barcodes generated unique to my business?
            </div>
            <div className="qr-code-faq-answer">
            Once you register for GS1, you comply with Global standards for barcoding. It allows you to create barcodes that include your company code, making it unique to your company.
            </div>

            


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Is it necessary to include a country code in a barcode?
            </div>
            <div className="qr-code-faq-answer">
            It depends on the barcode you are using. In some barcodes, it is mandatory to have a country code like in EAN-13. However, in barcodes like UPC, you need not include a country code.
            </div>

            


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              How are barcodes used in the packaging industry?
            </div>
            <div className="qr-code-faq-answer">
            Once an item is to get delivered, a unique barcode is generated and attached to the package. The information in this barcode helps determine buyer identification, pickup location, and destination.
            </div>

            


          <div className="qr-code-faq-question">
              <i class="fa-solid fa-circle-question"></i>
              Is it free to use the Invoician barcode generator?
            </div>
            <div className="qr-code-faq-answer">
            Yes, using the Invoician barcode generator is free of cost. You can create unlimited barcodes for your business using the Invoician app.
            </div>

            


            
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarcodeFaqComponent;
